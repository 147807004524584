import React, { useEffect, useRef } from 'react'

export default function Countdown({
  seconds, setSeconds, maxSeconds, step, text, onFinish
}) {
  const intervalRef = useRef(null)

  useEffect(() => {
    intervalRef.current = setInterval(() => {
      setSeconds((prevSeconds) => prevSeconds + step)
    }, 1000)

    return () => {
      if (intervalRef.current) clearInterval(intervalRef.current);
    }
  }, [])

  useEffect(() => {
    if (onFinish) {
      if ((step > 0 && maxSeconds <= seconds) || (step < 0 && seconds <= 0)) {
        onFinish()
      }
    }
  }, [seconds, maxSeconds])

  function resetInterval() {
    clearInterval(intervalRef.current)
    intervalRef.current = null
  }

  function twoDigitFormat(value) {
    if (value >= 10) {
      return value.toString()
    } else {
      return `0${ value }`
    }
  }

  function renderTimer() {
    if (seconds) {
      let newHours = parseInt(seconds / 3600)
      let newMinutes = parseInt(seconds % 3600 / 60)
      let newSeconds = parseInt(seconds % 60)

      return `${ twoDigitFormat(newHours) }:${ twoDigitFormat(newMinutes) }:${ twoDigitFormat(newSeconds) }`
    }
  }

  return(
    <div className="flex-box flex-column content-center items-center">
      <div>{ text }</div>
      <div>{ renderTimer() }</div>
    </div>
  )
}
