import React, { useEffect } from 'react'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import { NO_IMAGE_SOURCE } from '../GlobalConstants'
import TaskNote from '../notes/TaskNote'
import Description from '../shared/Description'
import TaskComments from '../comments/TaskComments'
import TaskResources from '../task/TaskResources'

export default function EventTabs({
  appProps, tabIndex, setTabIndex, user, event, currentTask,
  fetchNotePath, commentsPath, courseLocale, resourceProps, translation
}) {
  const taskDescription = translation.description
  const resourcesCount = event.resourcesCount ? `(${ event.resourcesCount })` : ''
  const commentsDisabled = currentTask && currentTask.settings.disableComments
  const tabs = [ 'description', 'comments', 'downloads', 'notes', 'reminders' ]

  const { translations } = appProps

  useEffect( () => {
    if (currentTask) {

      const currentTab = tabs[tabIndex]

      if (currentTab === 'description' && !taskDescription) {
        if (!commentsDisabled) {
          setTabIndex(1)
        } else if (resourcesCount) {
          setTabIndex(2)
        } else {
          setTabIndex(3)
        }

      } else if (currentTab === 'comments' && commentsDisabled) {
        if (taskDescription) {
          setTabIndex(0)
        } else if (resourcesCount) {
          setTabIndex(2)
        } else {
          setTabIndex(3)
        }

      } else if (currentTab === 'resources' && !resourcesCount) {
        if (taskDescription) {
          setTabIndex(0)
        } else if (!commentsDisabled) {
          setTabIndex(1)
        } else {
          setTabIndex(3)
        }
      }
    }
  }, [currentTask])

  return (
    <Tabs selectedIndex={ tabIndex } onSelect={ index => setTabIndex(index) }>
      <TabList>
        <Tab disabled={ !taskDescription }>
          { translations.course.description }
        </Tab>

        <Tab disabled={ commentsDisabled }>
          { translations.lxp.comments }
        </Tab>

        <Tab disabled={ !resourcesCount }>
          { translations.lxp.downloads } { resourcesCount }
        </Tab>

        <Tab>
          { translations.general.notes }
        </Tab>
{/*
        <Tab>
          { translations.lxp.reminders}
        </Tab>
*/}
      </TabList>

      <TabPanel>
        { taskDescription &&
          <Description
            description={ taskDescription }
          />
        }
      </TabPanel>

      <TabPanel>
        { !commentsDisabled &&
          <TaskComments
            userId = { user.id }
            entityType={ 'MindseedLms::Event' }
            entityUid={ event.uid }
            taskId={ '' }
            commentsPath={ commentsPath }
            translations={ translations }
          />
        }
      </TabPanel>

      <TabPanel>
        { resourcesCount &&
          <TaskResources
            taskId={ currentTask && currentTask.id }
            courseUid={ event.uid }
            courseLocale={ courseLocale }
            resourceProps={ resourceProps }
          />
        }
      </TabPanel>

      <TabPanel>
        <TaskNote
          key={ currentTask && currentTask.id }
          appProps={ appProps }
          entityType={ 'MindseedLms::Event' }
          entityUid={ event.uid }
          taskId={ '' }
          fetchNotePath={ fetchNotePath }
          taskTitle={ '' }
          entityTitle={ translation.title }
          translations={ translations }
        />
      </TabPanel>
{/*
      <TabPanel>
      </TabPanel>
*/}
    </Tabs>
  )
}
