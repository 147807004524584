import React, { useState } from 'react'
import PracticeLogTable from '../../shared/PracticeLogTable'

export default function PracticeLog({ appProps, pageName, pageTitle }) {
  const [userPractices, setUserPractices] = useState(appProps.userPractices)
  const [currentPage, setCurrentPage] = useState(1)
  const [loading, setLoading] = useState(false)

  const { translations } = appProps

  function loadMore(){
    setLoading(true)
    Rails.ajax({
      type: 'GET',
      url: `${appProps.practiceLogPath}?page=${currentPage + 1}`,
      dataType: 'json',
      success: res =>{
        setLoading(false)
        setCurrentPage(currentPage + 1)
        setUserPractices(userPractices.concat(res.userPractices))
      }
    })
  }

  return (
    <>
      <div className='section-title'>
        <h3>{ pageTitle }</h3>
      </div>
      <div className='card table-wrapper'>
        <PracticeLogTable
          userPractices={ userPractices }
          loading={ loading }
          translations={ translations }
        />
        { currentPage < appProps.initialTotalPages && !loading &&
          <div className='button-wrapper flex-box items-center content-start'>
            <a className="button" onClick={ loadMore }>
              { translations.general.load_more }
            </a>
          </div>
        }
      </div>
    </>
  )
}
