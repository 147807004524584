import React, { useState, useEffect } from 'react'
import PracticeLogTable from '../shared/PracticeLogTable'
import moment from 'moment'

export default function ShowLatestLogs({ appProps, selectedDate } ) {
  const [userPractices, setUserPractices] = useState([])
  const [loading, setLoading] = useState(false)
  const formattedDate = new Date(moment(selectedDate).format("YYYY-MM-DD"))

  const { translations } = appProps

  useEffect( () => {
    setLoading(true)
    let year = formattedDate.getFullYear(),
    month = formattedDate.getMonth() + 1,
    day = formattedDate.getDate(),
    passingDate = [year, month, day].join('-')

    Rails.ajax({
      type: 'GET',
      url: `${appProps.practiceLogPath}?selected_date=${passingDate}&practices_per_day=true`,
      dataType: 'json',
      success: res => {
        setLoading(false)
        setUserPractices(res.userPractices)
      }
    })
  }, [selectedDate])

  return (
    <div className = 'practice_log table-wrapper flex-box flex-column flex-1 card'>
      <PracticeLogTable
        userPractices={ userPractices }
        loading={ loading }
        translations={ translations }
      />
    { userPractices.length > 0 &&
      <div className='button-wrapper flex-box items-center content-end' style={{ marginTop: 20 }}>
        <a className="button" href={ appProps.practiceLogPath }>{ translations.general.view_all_logs }</a>
      </div>
    }
    </div>
  )
}
