import React, { useState, useEffect } from 'react'
import Modal from '../../shared/Modal'

export default function Post({ task, courseLocale, socketHeight, fullScreen, setFullScreen }) {

  useEffect( () => {
    const disableRightClick = document.getElementById("postContent");
    disableRightClick.addEventListener("contextmenu", e => e.preventDefault());
  }, [])

  return (
    <>
      { fullScreen &&
        <Modal
          mode={ 'fullpage' }
          visible={ true }
          closeModal={ () => setFullScreen(false) }
        >
          <div
            id='postContent'
            className='post-content editor'
            style={{ height: socketHeight }}
            dangerouslySetInnerHTML={{ __html: task.settings.translations[courseLocale].content }}
          />
        </Modal>
      }
      <div
        id='postContent'
        className='post-content editor'
        style={{ height: socketHeight }}
        dangerouslySetInnerHTML={{ __html: task.settings.translations[courseLocale].content }}
      />

    </>
  )
}
