import React, { useState } from 'react'
import DropMenu from '../shared/DropMenu'
import Description from '../shared/Description'
import moment from 'moment'
import { lrsEvent, formatDate } from '../../helpers/utils'

export default function ListItem({
  appProps, itemClass, image, icon, title, instructors, description,
  price, actionPath, quickMenu, accessibilityProps, entityUid, entityType,
  onBookmark, actionExtra, bookmarkId, startDate, durationType, durationAmount,
  children, titleActionExtra
}) {
  const [loading, setLoading] = useState(false)

  const { translations, bookmarks, saveBookmarkPath, setCurrentAppProps, user, currentLocale } = appProps
  const bookmark = bookmarks && (bookmarkId ? bookmarks.find( bookmark => bookmark.id === bookmarkId ) : bookmarks.find( bookmark => bookmark.markableType === entityType && bookmark.markableId === entityUid ))

  const dropMenuData = [
    // { icon: 'notification', title: 'Create reminder', path: '' },
    { icon: 'bookmark', title: translations.general.bookmark, onClick: handleBookmark, selected: !!bookmark, selectedTitle: translations.general.unbookmark },
    // { icon: 'cloud_download', title: 'Offline use', path: '' }
  ]

  function stringToDate(date) {
    const utcDate = moment.utc(date, 'DD/MM/YYYY HH:mm:ss')
    return utcDate.local()
  }

  function handleBookmark() {
    if (!loading){
      setLoading(true)
      let formData = new FormData()

      if (bookmark) {
        formData.append('id', bookmark.id)
      } else {
        formData.append('markable_type', entityType)
        formData.append('markable_id', entityUid)
      }

      Rails.ajax({
        url: saveBookmarkPath,
        type: 'POST',
        data: formData,
        dataType: 'json',
        success: res => {
          if (onBookmark) {
            onBookmark(!!res.bookmark, bookmark || res.bookmark)
          } else {
            if (res.bookmark) {
              lrsEvent(appProps, { en: { title: title } }, res.bookmark.id, 'bookmark', 'created')
              setCurrentAppProps({ bookmarks: [ ...bookmarks, res.bookmark ] })
            } else if(bookmark) {
              setCurrentAppProps({ bookmarks: bookmarks.filter(currentBookmark => !(currentBookmark.markableType === bookmark.markableType && currentBookmark.markableId === bookmark.markableId) ) })
            }
          }
        },
        error: err => {},
        complete: res => setLoading(false)
      })
    }
  }

  return (
    <div className={ `list-item ${ itemClass || '' }` }>
      { icon && actionExtra && !image &&
        <div className='list-item-image'>
          <a href={ actionPath } onClick={ actionExtra } className={ `icon-overlay ${icon} color-scheme` } rel='nofollow noindex'/>
        </div>
      }
      { image && !icon &&
        <div className={ `list-item-image ${ icon ? 'player' : '' }` }>
          <a
            href={ actionPath || '' }
            rel='nofollow noindex'
            onClick={ actionExtra ? actionExtra : () => {} }
          />
          <img className='image-gallery-image' src={ image } />

          { ['MindseedLms::Course', 'MindseedLms::Bundle', 'MindseedLms::Event'].includes(entityType) &&
            <div className='image-tag'>
              { entityType === 'MindseedLms::Course' && translations.course.course }
              { entityType === 'MindseedLms::Bundle' && translations.bundle.bundle }
              { entityType === 'MindseedLms::Event' && translations.event.event }
            </div>
          }
        </div>
      }
      <div className='list-item-details content-space-between'>
        <div>
          <div className='flex-box content-space-between list-item-short-details'>
            { quickMenu && user &&
              <>
                <div>
                  { startDate &&
                    <div className='information'>
                      { translations.course[stringToDate(startDate) < new Date() ? 'started_at' : 'starts_at'] }: { formatDate(startDate) }
                    </div>
                  }

                  { durationType && durationAmount > 0 &&
                    <div className='information'>
                      { translations.general.duration + ': ' + durationAmount + ' ' + durationType }
                    </div>
                  }
                </div>

                <DropMenu
                  dropMenuData={ dropMenuData }
                />
              </>
            }
          </div>

          <div className='title'>
            { titleActionExtra ?
              <div className='dark' onClick={ titleActionExtra } style={{ cursor: 'pointer'}}>
                { title }
              </div>
            :
              <a className='clickable-link dark' href={ actionPath } rel='nofollow noindex'>
                { title }
              </a>
            }
          </div>

          { instructors && instructors.length > 0 &&
            <div className='instructor-name'>
              <div className='flex-box flex-wrap'>
                { translations.general.with }&nbsp;
                { instructors.map( (instructor, index) => (
                  <a
                    key={ instructor.id }
                    style={{ marginRight: 5 }}
                    className='clickable-link'
                    href={ `/${ currentLocale }/instructors/${ instructor.id }` }
                    rel='nofollow noindex noreferrer'
                  >
                    { `${ instructor.fullName }${ index < (instructors.length - 1) ? ',' : '' }` }
                  </a>
                ))}
              </div>
            </div>
          }

          { description &&
            <Description
              description={ description }
            />
          }

          { price &&
            <div className='price-wrapper flex-box items-end'>
              <div className='price-label'>
                { translations.course.price }:
              </div>
              <div className='price flex-1 flex-box items-end'>
                { price }
              </div>
            </div>
          }
        </div>

        { children }
      </div>
    </div>
  )
}
