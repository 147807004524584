import React from 'react'
import { Slider, Switch } from '@material-ui/core'
import GongSelection from './GongSelection'
import { getTranslation } from '@reactiveonline/frontend_shared_components/utils'

export default function Settings({
  appProps, preparationTime, setPreparationTime, silenceEnabled,
  setSilenceEnabled, silenceTime, setSilenceTime, startGongEnabled, setStartGongEnabled,
  endGongEnabled, setEndGongEnabled, startGongUid, setStartGongUid, endGongUid,
  setEndGongUid, startGongVolume, setStartGongVolume, endGongVolume, setEndGongVolume,
  resetState, timerType, exerciseTimer
}) {
  const { translations, currentLocale, defaultLocale } = appProps
  let timerDescription, timerDuration
  if (exerciseTimer) {
    const timerTranslations = exerciseTimer.settings.translations
    const timerTranslation = getTranslation(Object.keys(timerTranslations).map( locale => ({ locale: locale, ...timerTranslations[locale] }) ), currentLocale, defaultLocale)
    timerDescription = timerTranslation.description
    timerDuration = timerTranslation.duration
  }

  return (
    <div className='timer-settings'>
      { timerType === 'guided' &&
        <div style={{ marginBottom: 10 }}>
          <div className='timer-title'>
            { exerciseTimer.title }
          </div>

          <div
            className='timer-description'
            dangerouslySetInnerHTML={{ __html: timerDescription }}
          />
        </div>
      }

      <div className='setting-item'>
        <div className='flex-box content-space-between'>
          <div className='flex-box'>
            <div className='label'>
              { translations.mindfulness.preparation_time }:
            </div>
            <div className='value color-scheme'>
              { preparationTime } { translations.general.sec }
            </div>
          </div>
        </div>

        <div className='slider-wrapper'>
          <Slider
            value={ preparationTime }
            min={ 0 }
            max={ 60 }
            onChangeCommitted={ (event, value) => setPreparationTime(value) }
          />
          <div className='slider-limits-wrapper flex-box items-center content-space-between'>
            <div className='slider-limit'>
              0 { translations.general.sec }
            </div>
            <div className='slider-limit'>
              60 { translations.general.sec }
            </div>
          </div>
        </div>
      </div>

      <div className='section-divider'/>

      <div className='setting-item'>
        { timerType === 'guided' &&
          <div className='flex-box'>
            <div className='label' style={{ marginBottom: 10 }}>
              { translations.mindfulness.total_practice_time }:
            </div>
            <div className='value color-scheme'>
              { timerDuration } { translations.general.min }
            </div>
          </div>
        }
        <div className='flex-box items-center content-space-between' >
          <div className='flex-box'>
            <div className={ timerType === 'silent' ? 'label' : '' }>{ timerType === 'silent' ? translations.general.duration : translations.mindfulness.extend_duration }:</div>
            <div className='value color-scheme'>
              { silenceTime } { translations.general.min }
            </div>
          </div>
        </div>
        <div className='slider-wrapper flex-box flex-column'>
          <Slider
            value={ silenceTime }
            min={ timerType === 'silent' ? 1 : 0 }
            max={ 20 }
            onChangeCommitted={ (event, newValue) => {
              setSilenceTime(newValue)
              if (newValue === 0 && silenceEnabled){
                setSilenceEnabled(false)
              } else if (newValue !== 0 && !silenceEnabled){
                setSilenceEnabled(true)
              }
            }}
          />
          <div className='slider-limits-wrapper flex-box items-center content-space-between'>
            <div className='slider-limit'>
              {`${ timerType === 'silent' ? 1 : 0 }`} { translations.general.min }
            </div>
            <div className='slider-limit'>
              20 { translations.general.min }
            </div>
          </div>
        </div>
      </div>

      <div className='section-divider'/>

      <div className='setting-item'>
        <GongSelection
          appProps={ appProps }
          heading={ translations.mindfulness.starting_gong }
          gongEnabled={ startGongEnabled }
          setGongEnabled={ setStartGongEnabled }
          gongUid={ startGongUid }
          setGongUid={ setStartGongUid }
          gongVolume={ startGongVolume }
          setGongVolume={ setStartGongVolume }
          selectName={ 'startingGong' }
        />
      </div>

      <div className='section-divider'/>

      <div className='setting-item'>
        <GongSelection
          appProps={ appProps }
          heading={ translations.mindfulness.ending_gong }
          gongEnabled={ endGongEnabled }
          setGongEnabled={ setEndGongEnabled }
          gongUid={ endGongUid }
          setGongUid={ setEndGongUid }
          gongVolume={ endGongVolume }
          setGongVolume={ setEndGongVolume }
          selectName={ 'endingGong' }
        />
      </div>

      <div className='section-divider'/>

      <div className='flex-box content-end items-center setting-item reset'>
        <div className='clickable-link pointer' onClick={ resetState }>
          { translations.general.reset_default }
        </div>
      </div>

    </div>
  )
}
