import React from 'react'
import { Switch, Slider } from '@material-ui/core'
import Select from '../../shared/Select'
import { gongsList } from '../../../helpers/utils'

export default function GongSelection({
  appProps, gongEnabled, setGongEnabled, heading, gongUid, setGongUid,
  gongVolume, setGongVolume, selectName
}) {

  return (
    <div className='flex-box flex-column'>
      <div className='flex-box items-center content-space-between'>
        <div className='label'>{ heading }</div>
        <div>
          <Switch
            checked={ gongEnabled }
            onChange={ () => setGongEnabled(!gongEnabled) }
          />
        </div>
      </div>

      { gongEnabled &&
        <>
          <Select
            appProps={ appProps }
            selectName={ selectName }
            selectorHeading={ heading }
            options={ gongsList }
            initialItemUid={ gongUid }
            selectCallback={ setGongUid }
          />

          <div className='slider-wrapper flex-box items-center'>
            <i
              className='fa-light fa-volume-xmark'
              style={{ marginRight: 10 }}
            />

            <Slider
              min={ 0 }
              max={ 100 }
              value={ gongVolume * 100 }
              onChangeCommitted={ (event, value) => setGongVolume(value / 100) }
            />

            <i
              className='fa-light fa-volume'
              style={{ marginLeft: 10 }}
            />
          </div>
        </>
      }
    </div>
  )
}
