import React, { useState, useEffect, useRef } from 'react'
import Modal from '../../shared/Modal'
import { Slider } from '@material-ui/core'
import StartGong from './StartGong'
import { useInterval } from '../../../helpers/useInterval'
import { lrsEvent, getLrsTimestamp, gongsList } from '../../../helpers/utils'

export default function Timer({
  appProps, prepEnabled, preparationTime, silenceEnabled, silenceMinutes, startGongEnabled,
  endGongEnabled, startGongUid, endGongUid, startGongVolume, endGongVolume,
  timerAudioFile, timerType, timerTitle, onFinish, autoPlay, disableControls,
  translations, timerId, timerTranslations, courseUid, mediaId, courseLocale,
  assignableUid, taskId, taskIsCompleted, taskCompletionRate, isNotModal, onCloseModal
}) {
  const [startPrep, setStartPrep] = useState(prepEnabled)
  const [prepSeconds, setPrepSeconds] = useState(preparationTime)
  const [startAudio, setStartAudio] = useState(timerType === 'audio')
  const [startSilence, setStartSilence] = useState(false)
  const [sessionUid, setSessionUid] = useState(String(Math.random()).substring(2, 12))

  const [playState, setPlayState] = useState(prepEnabled || (timerType === 'audio' && !autoPlay) ? 'paused' : 'playing')
  const [playSeconds, setPlaySeconds] = useState(0)
  const [silenceSeconds, setSilenceSeconds] = useState(0)
  const [audioDuration, setAudioDuration] = useState(0)
  const [startGongDuration, setStartGongDuration] = useState(0)
  const [completed, setCompleted] = useState(false)
  const [startGongEnded, setStartGongEnded] = useState(false)
  const [theme, setTheme] = useState('dark')
  const [audioLoaded, setAudioLoaded] = useState(false)

  const didStartRef = useRef(null)
  const silenceDidStartRef = useRef(null)
  const startingGong = useRef(null)
  const endingGong = useRef(null)
  const timerAudio = useRef(null)
  const completionRateRef = useRef(taskIsCompleted)

  const startingGongPlaying = startGongEnabled && startGongUid && !startGongEnded && (!!(prepEnabled && !startPrep) || !prepEnabled)
  const isAudio = timerType === 'audio'

  const preparationInterval = useInterval(() => {
    setPrepSeconds((prevPrepSeconds) => prevPrepSeconds - 1)
  }, startPrep ? 1000 : null)

  const audioInterval = useInterval(() => {
    if (timerAudio.current) {
      setPlaySeconds(Math.ceil(timerAudio.current.currentTime))
    }
  }, (startAudio && playState === 'playing') ? 1000 : null)

  const lrsInterval = useInterval(() => {
    fireConsumeEvent()
  }, ((startAudio || (timerType === 'timerSilent' && (!startGongEnabled || (startGongEnabled && startGongEnded)))) && playState === 'playing') ? appProps.lrsMediaStep : null)

  const silenceInterval = useInterval(() => {
    const silenceDuration = silenceMinutes * 60
    setSilenceSeconds((prevSilenceSeconds) => {
      if (prevSilenceSeconds < silenceDuration && playState === 'playing') {
        return prevSilenceSeconds + 1
      }
      else if (prevSilenceSeconds >= silenceDuration) {
        finishSilence()
        return prevSilenceSeconds
      }
    })
  }, (silenceEnabled && silenceMinutes > 0 && startSilence && playState === 'playing') ? 1000 : null)

  useEffect(() => {
    if (prepEnabled) {
      setStartPrep(true)
    } else if (timerType !== 'audio') {
      playAudio()
    }

    return () => {
      resetInterval(preparationInterval)
      resetInterval(audioInterval)
      resetInterval(silenceInterval)

      if (startingGong.current) {
        startingGong.current.pause()
        startingGong.current = null
      }

      if (endingGong.current) {
        endingGong.current.pause()
        endingGong.current = null
      }

      if (timerAudio.current) {
        timerAudio.current.pause()
        timerAudio.current = null
      }
    }
  }, [])

  useEffect(() => {
    if(startGongEnded) {
      playAudio()
    }
  },[startGongEnded])

  useEffect(() => {
    if(onFinish) {
      if(taskCompletionRate && taskCompletionRate !== 100 && ( (!silenceMinutes && audioDuration > 0 && (playSeconds/audioDuration)*100 >= taskCompletionRate) || (silenceMinutes && audioDuration > 0 && ((playSeconds + silenceSeconds)/(audioDuration + silenceMinutes*60))*100 >= taskCompletionRate) || !timerAudioFile ) && !completionRateRef.current) {
        onFinish(sessionUid, !!timerAudioFile, audioDuration + (silenceMinutes ? (silenceMinutes * 60) : 0))
        completionRateRef.current = true
      }
    }
  },[playSeconds, silenceSeconds])

  useEffect(() => {
    if(playState === 'playing' && didStartRef.current === 'replay') {
      const sessionUidAux = String(Math.random()).substring(2, 12)
      setSessionUid(sessionUidAux)
      lrsEvent(appProps, timerTranslations, timerId, timerType, 'started', {
        associableUid: courseUid,
        sessionUid: sessionUidAux,
        mediaId: mediaId,
        locale: courseLocale,
        assignableUid: assignableUid
      })
      didStartRef.current = true
      setCompleted(false)
    }
  },[playState, didStartRef.current])

  useEffect(() => {
    if(timerType !== 'audio' || (timerType === 'audio' && autoPlay)) {
      lrsEvent(appProps, timerTranslations, timerId, timerType, 'started', {
        associableUid: courseUid,
        sessionUid: sessionUid,
        mediaId: mediaId,
        locale: courseLocale,
        assignableUid: assignableUid
      })
    }
  },[autoPlay])

  useEffect(() => {
    if(timerType === 'audio' && mediaId && !appProps.isPreview) {
      getLrsTimestamp(appProps.lrsServerUrl, appProps.userUid, taskId, courseUid, mediaId, courseLocale, initLrsTimestamp)
    }
  },[mediaId])

  useEffect(() => {
    if (prepEnabled && preparationTime > 0 && prepSeconds == 0) {
      resetInterval(preparationInterval)
      setStartPrep(false)
      setPlayState('playing')
      playAudio()
    }
  }, [prepSeconds])

  useEffect(() => {
    if (startSilence) {
      resetInterval(audioInterval)
      playSilence()
    }
  }, [startSilence])

  useEffect(() => {
    if (completed) {
      resetInterval(silenceInterval)
      if (onFinish) {
        onFinish(sessionUid, false, audioDuration + (silenceMinutes ? (silenceMinutes * 60) : 0))

      } else {
        lrsEvent(appProps, timerTranslations, timerId, timerType, 'completed', {
          associableUid: courseUid,
          sessionUid: sessionUid,
          mediaId: mediaId,
          locale: courseLocale,
          assignableUid: assignableUid,
          duration: audioDuration + (silenceMinutes ? (silenceMinutes * 60) : 0)
        })
      }

      setTheme('white')
    }
  }, [completed])

  useEffect(() => {
    if(playState === 'playing' && timerType === 'audio' && !autoPlay && !didStartRef.current) {
      lrsEvent(appProps, timerTranslations, timerId, timerType, 'started', {
        associableUid: courseUid,
        sessionUid: sessionUid,
        mediaId: mediaId,
        locale: courseLocale,
        assignableUid: assignableUid
      })
      didStartRef.current = true
    }
    if(timerType === 'audio' && startAudio && (playState === 'paused') && (playSeconds !== 0) && (playSeconds < audioDuration - 1) ) {
      fireConsumeEvent()
      resetInterval(lrsInterval)
    }
  },[playState])

  useEffect(() => {
    if (startAudio && timerType === 'audio' && timerAudioFile) {
      playAudio()
    }
  }, [startAudio, timerAudioFile])

  useEffect(() => {
    if (timerAudio.current) {
      timerAudio.current.addEventListener('ended', finishAudio)
    }

    return () => {
      if (timerAudio.current) {
        timerAudio.current.removeEventListener('ended', finishAudio)
      }
    }
  }, [audioDuration, finishAudio])

  useEffect(() => {
    if(audioLoaded) {
      setAudioDuration(Math.ceil(timerAudio.current.duration))
    }
    if(audioLoaded && ((startGongEnabled && startGongEnded) || !startGongEnabled)) {
      if (timerType !== 'audio' || autoPlay) {
        if(!appProps.isPreview){
          getLrsTimestamp(appProps.lrsServerUrl, appProps.userUid, taskId, courseUid, mediaId, courseLocale, initLrsTimestamp)
        }
        setStartAudio(true)
        setPlayState('playing')
        timerAudio.current.play()
      }
    }
  },[audioLoaded, startGongEnded])

  function fireConsumeEvent(newVal = null) {
    lrsEvent(appProps, timerTranslations, timerId, timerType, 'consumed', {
      associableUid: courseUid,
      timestamp: newVal ? newVal : (timerType != 'timerSilent' ? playSeconds : silenceSeconds),
      sessionUid: sessionUid,
      mediaId: mediaId,
      locale: courseLocale,
      logTimestamp: true,
      assignableUid: assignableUid,
      duration: audioDuration + (silenceMinutes ? (silenceMinutes * 60) : 0)
    })
  }

  function resetInterval(intervalRef) {
    clearInterval(intervalRef.current)
    intervalRef.current = 0
  }

  function onLoadAudio() {
    setAudioLoaded(true)
  }

  function initLrsTimestamp(timestamp) {
    setPlaySeconds(timestamp)
    timerAudio.current.currentTime = timestamp
  }

  function togglePlay() {
    if (timerAudio.current && !silenceDidStartRef.current) {
      timerAudio.current.currentTime = playSeconds
    }

    if (playState === 'playing') {
      if (startAudio && timerAudio.current && !silenceDidStartRef.current) {
        timerAudio.current.pause()
      }
      setPlayState('paused')
    } else {
      if ((startAudio && timerAudio.current) && !silenceDidStartRef.current) {
        timerAudio.current.play()
      }
      setPlayState('playing')
    }
  }

  function playAudio() {
    if (startGongEnabled && startGongUid && !startGongEnded) {
      timerAudio.current = new Audio(timerAudioFile)
      timerAudio.current.addEventListener('loadedmetadata', onLoadAudio)
      timerAudio.current.addEventListener('ended', finishAudio)

      startingGong.current = new Audio(gongsList.find( gong => gong.uid === startGongUid ).value)
      startingGong.current.volume = startGongVolume
      startingGong.current.play()

      startingGong.current.addEventListener('loadedmetadata', onLoadGong)
      startingGong.current.addEventListener('ended', finishGong)

    } else {
      if (['timer', 'audio'].includes(timerType) && timerAudioFile && !startGongEnabled) {
        timerAudio.current = new Audio(timerAudioFile)

        timerAudio.current.addEventListener('loadedmetadata', onLoadAudio)
        timerAudio.current.addEventListener('ended', finishAudio)
      } else if (timerType === 'timerSilent') {
        finishAudio()
      }
    }
  }

  function playSilence() {
    if (!silenceEnabled || silenceMinutes <= 0) {
      finishSilence()
    } else {
      silenceDidStartRef.current = true
      setPlayState(prevPlayState => 'playing')
    }
  }

  function onLoadGong() {
    setStartGongDuration(Math.ceil(startingGong.current.duration))
  }

  function finishGong() {
    startingGong.current.pause()
    startingGong.current = null
    setStartGongEnded(true)
  }

  function finishAudio() {
    if (timerAudio.current && endGongEnabled && endGongUid && !silenceEnabled) {
      endingGong.current = new Audio(gongsList.find( gong => gong.uid === endGongUid ).value)
      endingGong.current.volume = endGongVolume
      endingGong.current.play()
    }

    if (timerAudio.current) {
      timerAudio.current.pause()
      setPlayState('paused')
      setPlaySeconds(audioDuration)
    }

    if (timerType !== 'audio') {
      setStartAudio(false)
      setStartSilence(true)
    } else {
      finishSilence()
    }
  }

  function finishSilence() {
    if (endGongEnabled && endGongUid) {
      if (endingGong.current) endingGong.current.pause()
      endingGong.current = new Audio(gongsList.find( gong => gong.uid === endGongUid ).value)
      endingGong.current.volume = endGongVolume
      endingGong.current.play()
    }
    didStartRef.current = 'replay'
    setStartSilence(false)
    setPlayState('paused')
    setCompleted(true)
  }

  function getAudioTimeString(seconds) {
    let h = parseInt(seconds / 3600)
    let m = parseInt(seconds % 3600 / 60)
    let s = parseInt(seconds % 60)
    let hourString = (h != 0 ? `${ h < 10 ? '0' + h : h }:` : '')

    return `${ hourString }${ (m < 10) ? '0' + m : m }:${ (s < 10) ? '0' + s : s }`
  }

  const Wrapper = props => {
    if (isNotModal) {
      return props.children
    } else {
      return <Modal { ...props }>{ props.children }</Modal>
    }
  }

  return (
    <Wrapper
      visible
      closeModal={ onCloseModal }
      mode='fullpage'
      theme={ theme }
    >
      <div className={ `timer-container flex-box flex-column items-center ${ (isAudio || theme === 'white') ? 'content-center white' : 'content-space-around' }` }>
        { startingGongPlaying &&
          <>
            <StartGong duration={ startGongDuration }/>

            { (timerType === 'timerSilent' || (Math.ceil(audioDuration % 3600 / 60.0) > 0)) ?
              <div className='flex-box flex-column' style={{ color: 'white', textAlign: 'center', margin: '20px 0' }}>
                <div>
                  { `${ translations.general.next }: ${ timerType === 'timerSilent' ? (silenceMinutes + ' ' + translations.mindfulness[silenceMinutes > 1 ? 'silence_minutes' : 'silence_minute']) : (Math.ceil(audioDuration % 3600 / 60.0) + ' ' + translations.mindfulness[Math.ceil(audioDuration % 3600 / 60.0) > 1 ? 'meditation_minutes' : 'meditation_minute']) }` }
                </div>

                <div style={{ marginTop: 10 }}>
                  { timerType !== 'timerSilent' ? timerTitle : '' }
                </div>
              </div>
            :
              <div style={{ color: 'white', textAlign: 'center', margin: '20px 0' }}/>
            }
          </>
        }

        { !startingGongPlaying &&
          <>
            { (!completed || isAudio) &&
              <>
                <div className={ `timer-title ${ isAudio ? 'small color-scheme' : '' }` }>
                  { !!(prepEnabled && startPrep && preparationTime > 0) && `${ prepSeconds }` }
                  { (startAudio || timerType === 'audio') && timerTitle }
                  { startSilence && translations.mindfulness.silent_meditation }
                </div>

                { !(prepEnabled && startPrep && preparationTime > 0) &&
                  <div className='player-wrapper flex-box items-center flex-column'>
                    <div className={ `player-duration ${ isAudio ? 'small color-scheme' : '' }` }>
                      { (startAudio || timerType === 'audio') && getAudioTimeString(audioDuration ? audioDuration - playSeconds : 0) }
                      { startSilence && getAudioTimeString((silenceMinutes * 60) - silenceSeconds) }
                    </div>

                    { (startAudio || startSilence || timerType === 'audio') &&
                      <>
                        { ['timer', 'audio'].includes(timerType) && !disableControls && timerAudioFile && !silenceDidStartRef.current &&
                          <>
                            <div className={ `slider-wrapper ${ isAudio ? 'audio' : '' }` }>
                              <Slider
                                min={ 0 }
                                max={ audioDuration }
                                value={ playSeconds }
                                onChangeCommitted={ (event, newValue) => {
                                  if (startAudio || timerType === 'audio') {
                                    timerAudio.current.currentTime = newValue
                                    setPlaySeconds(newValue)
                                    if (playState === 'playing') {
                                      fireConsumeEvent(newValue)
                                      resetInterval(lrsInterval)
                                    }
                                  }
                                }}
                              />
                            </div>

                            { silenceEnabled && silenceMinutes > 0 &&
                              <div className={ `player-subtitle ${ isAudio ? 'color-scheme' : '' }` }>
                                { `${ translations.general.next }: ${ silenceMinutes } ${ translations.mindfulness[silenceMinutes > 1 ? 'silence_minutes' : 'silence_minute'] }` }
                              </div>
                            }
                          </>
                        }

                        { silenceEnabled && silenceMinutes > 0 && silenceDidStartRef.current &&
                          <div className={ `slider-wrapper ${ silenceDidStartRef.current ? 'disabled' : '' }` }>
                            <Slider
                              min={ 0 }
                              max={ silenceMinutes * 60 }
                              value={ silenceSeconds }
                              color='secondary'
                            />
                          </div>
                        }
                      </>
                    }
                  </div>
                }

                { (startAudio || startSilence || timerType === 'audio') && !disableControls &&
                <div className='player-actions-wrapper'>
                  <div
                    className={ `player-action ${ isAudio ? 'color-scheme' : '' }` }
                    onClick={ togglePlay }
                  >
                    { playState === 'playing' ?
                      <i className='fa-regular fa-circle-pause'/>
                    :
                      <i className='fa-regular fa-circle-play'/>
                    }
                  </div>
                </div>
              }
              </>
            }

            { completed &&
              <div className='completed-wrapper flex-box flex-column content-center items-center'>
                { timerType !== 'audio' &&
                  <>
                    {/* placeholder for image */}
  {/*
                    <div style={{ width: 400, height: 400 }}/>
  */}

                    <div className='completed-heading'>
                      <div className='title'>
                        { translations.mindfulness.completed_title }
                      </div>
                      <div className='subtitle'>
                        { translations.mindfulness.completed_subtitle }
                      </div>
                    </div>

  {/*
                    <div className='flex-column' style={{ width: '40%' }}>
                      { timerType === 'timer' &&
                        <div className='flex-box content-center' style={{ marginBottom: 20 }}>
                          <div className='completed-option' style={{ marginRight: '15px' }}>
                            <div className='bookmark_icon'/>
                            <div>{ translations.general.bookmark }</div>
                          </div>
                          <div className='completed-option'>
                            <div className='notification_icon'></div>
                            <div>{ translations.general.remind_me }</div>
                          </div>
                        </div>
                      }

                      <div style={{ height: 40, margin: 'auto' }}>
                        <button style={{ width: '100%', height: '100%', borderRadius: 10 }}>
                          { translations.mindfulness.another_practice }
                        </button>
                      </div>
                    </div>
  */}
                  </>
                }
              </div>
            }
          </>
        }
      </div>
    </Wrapper>
  )
}
