import React, { useState, useEffect } from 'react'
import EventTabs from './EventTabs'
// import Instructors from '../shared/Instructors'
import OnSiteEvent from './tasks/OnSiteEvent'
import RemoteEvent from './tasks/RemoteEvent'
import { getTaskType } from '../task/utils'
import { lrsEvent } from '../../helpers/utils'
import { getTranslation } from '@reactiveonline/frontend_shared_components/utils'

export default function EventContent({
  appProps, user, event, currentTask,
  courseLocale, fetchNotePath, saveBookmarkPath,
  commentsPath, resourceProps, completeTask, tabIndex,
  setTabIndex
}) {
  const [loading, setLoading] = useState(false)

  const { translations, currentLocale, bookmarks, setCurrentAppProps } = appProps
  const translation = getTranslation(event.translations, courseLocale, currentLocale)
  const imageSource = event.image.isTranslatable ? event.image[currentLocale] : event.image.source
  const currentBookmark = event && bookmarks.find( bookmark => bookmark.markableId == event.uid )

  function renderTask() {
    if (event.type === 'onsiteEvent') {
      return (
        <OnSiteEvent
          task={ currentTask }
          translation={ translation }
          image={ imageSource }
          translations={ translations }
          eventUid={ event.uid }
          instructors={ event.instructors }
        />
      )
    } else if (['zoom', 'teams', 'custom'].includes(event.type)) {
      return (
        <RemoteEvent
          task={ currentTask }
          translation={ translation }
          image={ imageSource }
          translations={ translations }
          eventUid={ event.uid }
          instructors={ event.instructors }
        />
      )
    }
  }

  function handleBookmark(){
    if (!loading) {
      setLoading(true)

      let formData = new FormData()

      if (currentBookmark) {
        formData.append('id', currentBookmark.id)
      } else {
        formData.append('markable_type', 'MindseedLms::Event')
        formData.append('markable_id', event.uid)
        formData.append('course_uid', event.uid)
      }

      Rails.ajax({
        url: saveBookmarkPath,
        type: 'POST',
        data: formData,
        dataType: 'json',
        success: res => {
          if (currentBookmark) {
            setCurrentAppProps({ bookmarks: bookmarks.filter( bookmark => bookmark.id !== currentBookmark.id ) })
          } else if (res.bookmark) {
            setCurrentAppProps({ bookmarks: [ ...bookmarks, res.bookmark ] })
            lrsEvent(appProps, translation, res.bookmark.id, 'bookmark', 'created', { associableUid: event.uid })
          }
        },
        error: err => {},
        complete: res => {
          setLoading(false)
        }
      })
    }
  }

  function handleComment() {
    setTabIndex(1)
    const elements = document.getElementsByClassName('task-content-bottom-container')
    if (elements) {
      elements[0].scrollIntoView({ behavior: 'smooth' })
    }
  }

  return (
    <div className={ `task-content-container fullscreen` }>
      <div className='content-socket-container'>
        <div className='content-socket'>
          { renderTask() }
        </div>

        <div className='flex-box flex-column'>
          <div className='content-user-options flex-box flex-1 content-space-between items-center'>
            <div className='left-options'>
                <i className={ getTaskType(currentTask.type) }/>
                { translation.title }
            </div>

            <div className='right-options flex-box items-center'>
              <div
                className={ `option ${ currentBookmark ? 'bookmark_icon_filled' : 'bookmark_icon' }` }
                onClick={ handleBookmark }
              />
            </div>
          </div>

          { currentTask && currentTask.settings && currentTask.settings.translations && currentTask.settings.translations[courseLocale].seedQuestion &&
            <div className='seed-question-wrapper'>
              <div className='seed-question-divider'/>
              <div className='seed-question flex-box flex-1 items-start'>
                <i className='fa-thin fa-comments-question'/>
                <div className='text'>
                  { currentTask.settings.translations[courseLocale].seedQuestion }
                </div>
              </div>
              <div className='button-wrapper flex-box flex-1 items-center content-end'>
                <div className='button inverted' onClick={ handleComment }>
                  { translations.lxp.contribute_discussion }
                </div>
              </div>
            </div>
          }

        </div>
      </div>

      <div className='task-content-bottom-container'>
        <EventTabs
          appProps={ appProps }
          tabIndex={ tabIndex }
          setTabIndex={ setTabIndex }
          user={ user }
          event={ event }
          currentTask={ currentTask }
          fetchNotePath={ fetchNotePath }
          commentsPath={ commentsPath }
          courseLocale={ courseLocale }
          resourceProps={ resourceProps }
          translation={ translation }
        />

        {/* event.instructors.length > 0 &&
          <div style={{ marginTop: 40 }}>
            <div className='show instructor-title' style={{ marginBottom: 20, marginTop: 20 }}>
              { translations.general.instructors }
            </div>
            <Instructors
              instructors={ event.instructors }
              screenClass={ 'lxp-show' }
              appProps={ appProps }
            />
          </div>
        */}
      </div>
    </div>
  )
}
