import React, { useState, useEffect, useRef } from 'react'
import { detectBrowser, detectOs } from '../../helpers/utils'
import cookie from 'react-cookies'

const lrsMediaStep = 10000

export default function AppLayout(props) {
  const { appProps, pageName, Component } = props

  const [subButton, setSubButton] = useState(true)
  const [showSubButton, setShowSubButton] = useState(false)
  const [renderKey, setRenderKey] = useState(0)
  const [deviceToken, setDeviceToken] = useState(null)
  const [isSubscribed, setIsSubscribed] = useState(null)

  // const Notification = window.Notification || window.mozNotification || window.webkitNotification
  const deviceCookie = cookie.load('device_cookie')
  const prev = usePrevious(isSubscribed) //previous state of isSubscribed
  const { flashMessage, translations, setCurrentAppProps } = appProps

  const notificationsProps = {
    subButton: subButton,
    setSubButton: setSubButton,
    showSubButton: showSubButton
  }

  const updatedAppProps = {
    ...appProps,
    ...notificationsProps
  }

  useEffect( () => {
    setCurrentAppProps({
      ...notificationsProps,
      lrsMediaStep: lrsMediaStep,
      bookmarks: []
    })

    if (appProps.user && !appProps.isMobileApp) {
      Rails.ajax({
        type: 'GET',
        url: `${ appProps.fetchBookmarksPath }?is_index=${ !!props.isBookmarksIndex }`,
        dataType: 'json',
        success: res => {
          setCurrentAppProps({ bookmarks: res.bookmarks })
        }
      })

    }
  }, [])

  useEffect(() => {
    if (appProps.user && !appProps.isMobileApp) {
      if(!deviceCookie) {
        WonderPush.push(function() {
          WonderPush.getDeviceId().then(res => {
            setDeviceToken(res)
            let fd = new FormData()
            fd.append('device_token', res)

            Rails.ajax({
             type: 'POST',
             url: appProps.createDeviceCookiePath,
             dataType: 'json',
             data: fd,
             success: res => {}
            })
          })
        })
      }

      const params = new URLSearchParams(window.location.search)

      if (params.has('notification_id')) {
        let id = params.get('notification_id')
        let fd = new FormData()
        fd.append('id', id)
        Rails.ajax({
         type: 'PATCH',
         url: appProps.markNotificationAsReadPath,
         dataType: 'json',
         data: fd,
         success: res => {}
        })
      }

      window.WonderPush = window.WonderPush || []
      WonderPush.push(['setUserConsent', true])

      var previousWonderPushSubscriptionState

      window.addEventListener('WonderPushEvent', function(event) {
        if (!event.detail || event.detail.name !== 'subscription') return
        if (previousWonderPushSubscriptionState === WonderPush.SubscriptionState.NOT_SUBSCRIBED && event.detail.state === WonderPush.SubscriptionState.SUBSCRIBED) {
          //User just subscribed
          WonderPush.push(function() {
            WonderPush.getDeviceId().then(res => {
              setDeviceToken(res)
              flashMessage.show(translations.notifications.successfully_subscribed, 'success')
              setParameters(true,false)
              enableNotifications()
            })
          })
        } else if (previousWonderPushSubscriptionState === WonderPush.SubscriptionState.UNSUBSCRIBED && event.detail.state === WonderPush.SubscriptionState.SUBSCRIBED) {
          //User resubscribed
          WonderPush.push(function() {
            WonderPush.getDeviceId().then(res => {
              setDeviceToken(res)
              flashMessage.show(translations.notifications.successfully_subscribed, 'success')
              setParameters(true,false)
            })
          })
        } else if (previousWonderPushSubscriptionState === WonderPush.SubscriptionState.SUBSCRIBED && event.detail.state === WonderPush.SubscriptionState.UNSUBSCRIBED) {
          // Transitioning from subscribed to unsubscribed
          WonderPush.push(function() {
            WonderPush.getDeviceId().then(res => {
              setDeviceToken(res)
              setParameters(false,true)
            })
          })
        } else if (previousWonderPushSubscriptionState === undefined && event.detail.state === WonderPush.SubscriptionState.SUBSCRIBED) {
          // Initial state is subscribed
          if (pageName === 'notification_settings') {
            WonderPush.push(function() {
              WonderPush.getDeviceId().then(res => {
                Rails.ajax({
                  type: 'GET',
                  url: appProps.checkSubscriptionPath + `?device_token=${res}`,
                  dataType: 'json',
                  success: result => {
                    if(result.hasSubscribed) {
                      setParameters(true,false)
                    } else {
                      if(setShowSubButton) {
                        setShowSubButton(true)
                      }
                      if(setSubButton) {
                        setSubButton(true)
                      }
                    }
                  }
                })
              })
            })
          }
        } else if (previousWonderPushSubscriptionState === undefined && event.detail.state === WonderPush.SubscriptionState.NOT_SUBSCRIBED) {
          // Initial state is not subscribed
          if(setShowSubButton) {
            setShowSubButton(true)
          }
          if(setSubButton) {
            setSubButton(true)
          }
        } else if (previousWonderPushSubscriptionState === undefined && event.detail.state === WonderPush.SubscriptionState.UNSUBSCRIBED) {
          // Initial state is unsubscribed
          if(setShowSubButton) {
            setShowSubButton(true)
          }
          if(setSubButton) {
            setSubButton(true)
          }
        }
        previousWonderPushSubscriptionState = event.detail.state
      })
    }
  }, [])

  useEffect(() => {
    if (appProps.user && !appProps.isMobileApp) {
      WonderPush.push(function() {
        WonderPush.getDeviceId().then(token => {
          setDeviceToken(token)
          if(isSubscribed && (prev != isSubscribed)) {
            WonderPush.push(function() {
              WonderPush.getInitializationPromise().then(result => {
                WonderPush.getInstallationId().then(res => {
                  // WonderPush.setUserId(hashOfCurrentUserUid)
                  subscribe(token, res)
                })
              })
            })
          } else if(!isSubscribed && (prev != isSubscribed)) {
            unsubscribe(token)
          }
        })
      })
    }
  }, [isSubscribed])

  useEffect( () => {
    if (window.location.href.includes('?trustCookies=yes')) {
      cookie.save('cookieConsent', 'YES', { path: '/', secure: true })
      cookie.save('cookieControl', true, { path: '/', secure: true })
    }
  }, [])

  function enableNotifications() {
    let fd = new FormData()
    fd.append('enabled_notifications', true)
    Rails.ajax({
      type: 'PATCH',
      url: appProps.enableNotificationsPath,
      dataType: 'json',
      data: fd,
      success: res => {}
    })
  }

  function subscribe(token, id) {
    let fd = new FormData()
    let browser = detectBrowser()
    let os = detectOs()
    fd.append('device_token', token)
    fd.append('installation_id', id)
    fd.append('browser', browser)
    fd.append('os', os)

    Rails.ajax({
      type: 'POST',
      url: appProps.createDevicePath,
      dataType: 'json',
      data: fd,
      success: res => {}
    })
  }

  function unsubscribe(token) {
    let fd = new FormData()
    fd.append('device_token', token)

    Rails.ajax({
      type: 'DELETE',
      url: appProps.deleteSubscriptionPath,
      dataType: 'json',
      data: fd,
      success: res => {}
    })
  }

  function setParameters(isSubscribedBoolean, subButtonTextBoolean) {
    setIsSubscribed(isSubscribedBoolean)
    if(setSubButton) {
      setSubButton(subButtonTextBoolean)
    }
    if (setShowSubButton) {
      setShowSubButton(true)
    }
  }

  function usePrevious(value) {
    const ref = useRef()
    useEffect(() => {
      ref.current = value
    })
    return ref.current
  }

  return (
    <Component
      { ...props }
      appProps={ updatedAppProps }
    />
  )
}
