import React from 'react'
import { Loader } from '@reactiveonline/frontend_shared_components'
import { truncateString, getTranslation } from '@reactiveonline/frontend_shared_components/utils'

export default function Summary({
  appProps, order, setOrder, loading, setLoading, tax, showActions, accountScreen,
  showActionsButtons
}) {
  const {
    translations, destroyLineItemPath, cataloguePath, flashMessage, currentLocale,
    defaultLocale
  } = appProps

  function navigateToItem(path) {
    if (path) {
      window.open(path, '_blank')
    }
  }

  function onRemoveItem(lineItem) {
    setLoading(true)

    let formData = new FormData()
    formData.append('line_item_id', lineItem.id)
    formData.append('checkout', true)

    Rails.ajax({
      url: destroyLineItemPath,
      type: 'DELETE',
      dataType: 'json',
      data: formData,
      success: res => {
        flashMessage.show(translations.checkout.removeItemSuccess)

        if (res.order) {
          setOrder(res.order)

        } else {
          window.location.href = cataloguePath
        }
      },
      complete: res => {
        setLoading(false)
      }
    })
  }

  function renderField(title, value, highlight = false) {
    return (
      <div className='field-wrapper flex-box content-space-between'>
        <div className={ `field-title ${ highlight ? 'highlight' : '' }` }>
          { title }:
        </div>
        <div className={ `field-value ${ highlight ? 'highlight' : '' }` }>
          { value }
        </div>
      </div>
    )
  }

  function printOrder() {
    window.print()
    return false
  }

  return (
    <div className='flex-box flex-column summary-wrapper'>
      <div className='summary'>
        <div className='summary-title'>
          { translations.checkout.orderSummary }
        </div>

        { order.lineItems.map( lineItem => {
          const translation = getTranslation(lineItem.item.translations, currentLocale, defaultLocale)

          return (
            <div key={ lineItem.id } className='item-wrapper'>
              <div className='item-title-wrapper flex-box content-space-between'>
                <div className='item-title' onClick={ () => navigateToItem(lineItem.item.path) }>
                  { truncateString(translation.title, 15) }
                </div>

                { showActions &&
                  <>
                    { loading ?
                      <Loader size='xsmall'/>
                    :
                      <i className='trash_icon color-scheme' onClick={ () => onRemoveItem(lineItem) }/>
                    }
                  </>
                }
              </div>

              { renderField(translations.checkout.price, lineItem.item.price) }
            </div>
          )
        })}

        <div className='section-divider'/>

        { renderField(translations.checkout.subtotal, order.netTotal) }
        { renderField(tax.title, order.subtotalVat) }
        { renderField(translations.checkout.total, order.total, true) }
      </div>

      { showActionsButtons &&
        <div className='checkout-actions'>
          <div className=''>
            <button className='button' onClick={ ()=> window.location.href = appProps.coursesPath }>{ translations.checkout.goToCourses }</button>
          </div>
          <div className='flex-box content-space-between'>
            <button className='button inverted' onClick={ ()=> printOrder() }>{ translations.checkout.printReceipt }</button>
            { !accountScreen &&
              <button className='button inverted' style={{ marginLeft: 5 }} onClick={ ()=> window.location.href = `${appProps.accountPath}/orders` }>{ translations.checkout.viewOrdersHistory }</button>
            }
          </div>
        </div>
      }
    </div>
  )
}
