import React from 'react'
import ListItem from '../shared/ListItem'
import { getTaskType } from '../task/utils'
import { NO_IMAGE_SOURCE } from '../GlobalConstants'

export default function Bookmarks({ appProps, screenClass }) {
  const { translations, bookmarks, setCurrentAppProps, currentLocale } = appProps

  function onBookmark(added, bookmark) {
    if (!added) {
      setCurrentAppProps({ bookmarks: bookmarks.filter( item => item.id !== bookmark.id )  })
    }
  }

  return (
    <div className={ `lxp-wrapper ${ screenClass }` }>
      <div style={{ padding: '50px 0px 0px 0px' }}>
        <div className='main-container'>
          <div className='section-title' style={{ marginBottom: 40 }}>
            <h1>{ translations.general.bookmarks }</h1>
          </div>
        </div>
      </div>

      <div className='section body-bg'>
        <div className='main-container'>
          <div className='list-item-wrapper flex-wrap'>
            { bookmarks.map( bookmark => {
              let bookmarkImageSource = bookmark.image
              if (['MindseedLms::Course', 'MindseedLms::Event', 'MindseedLms::Bundle'].includes(bookmark.type)) {
                bookmarkImageSource = bookmark.image && (bookmark.image.isTranslatable ? bookmark.image[currentLocale] : bookmark.image.source)
                if (!bookmarkImageSource) {
                  bookmarkImageSource = NO_IMAGE_SOURCE
                }
              }

              return (
                <ListItem
                  key={ bookmark.id }
                  appProps={ appProps }
                  image={ bookmarkImageSource }
                  icon={ !bookmarkImageSource && bookmark.taskType && getTaskType(bookmark.taskType) }
                  title={ bookmark.title }
                  actionPath={ bookmark.path }
                  actionExtra={ () => {} }
                  quickMenu
                  bookmarkId={ bookmark.id }
                  onBookmark={ onBookmark }
                />
              )
            })}
          </div>
        </div>
      </div>
    </div>
  )
}
