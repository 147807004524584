import React from 'react'

export default function Events({ pageName, pageTitle }) {

  return (
    <div className={ pageName }>
      <div className='section-title'>
        <h3>{ pageTitle }</h3>
      </div>
    </div>
  )
}
