import React from 'react'

export default function Sidebar({ appProps }) {
  const { translations } = appProps

  return (
    <>
      <div className='sidebar'>
        <div  className={ `sidebar-element body-text ${window.location.pathname === appProps.accountPath ? 'active' : ''} ` }
              onClick={ ()=> window.open('/account/manage', '_blank') }>
            { translations.account.manage_account }
            <i style={{ marginLeft: 5 }} className='pointer clickable-link fa-light fa-pen-to-square'/>
        </div>
        <div  className={ `sidebar-element body-text ${window.location.pathname === appProps.ordersPath ? 'active' : ''} ` }
              onClick={ () => { window.location.href = appProps.ordersPath } }>
            { translations.account.orders }
        </div>
        <div  className={ `sidebar-element ${window.location.pathname === appProps.notificationsSettingsPath ? 'active' : ''} ` }
              onClick={ () => { window.location.href = appProps.notificationsSettingsPath } }>
            { translations.account.notification_settings }
        </div>
        <div  className={ `sidebar-element ${window.location.pathname === appProps.remindersPath ? 'active' : ''} ` }
              onClick={ () => { window.location.href = appProps.remindersPath } }>
            { translations.account.reminders }
        </div>
        <div  className={ `sidebar-element ${window.location.pathname === appProps.practiceLogPath ? 'active' : ''} ` }
              onClick={ () => { window.location.href = appProps.practiceLogPath } }>
            { translations.account.practice_log }
        </div>
        <div  className={ `sidebar-element ${window.location.pathname === appProps.languagesPath ? 'active' : ''} ` }
              onClick={ () => { window.location.href = appProps.languagesPath } }>
            { translations.account.languages }
        </div>
        <div  className={ `sidebar-element ${window.location.pathname === appProps.privacyPath ? 'active' : ''} ` }
              onClick={ () => { window.location.href = appProps.privacyPath } }>
            { translations.account.privacy }
        </div>
        <div  className='sidebar-element delete'
              onClick={ () => { window.location.href = appProps.deleteAccountPath } }>
            { translations.account.delete_account }
        </div>
      </div>
    </>
  )
}
