import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react'
import { Loader, RadioInput } from '@reactiveonline/frontend_shared_components'
import { detectBrowser, formatDate, getMediaIcon } from '../../helpers/utils'
import Modal from '../shared/Modal'
import Webcam from 'react-webcam'
import Dropzone from 'react-dropzone'
import ReactPlayer from 'react-player'
import UploadChat from './uploads/UploadChat'

const placeholderAvatar = 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ2nNUXQ8TRIbctxR4bGhWkQd5mrIeu1okIyte3_rz0HSVf6hctJAXHOwrUidaXMlOtCFA&usqp=CAU'

const mediaTypes = ['image', 'audio', 'video', 'pdf', 'powerpoint', 'doc', 'excel', 'csv']

const mimeTypes = {
  image: ['image/*'],
  audio: ['audio/mpeg'],
  video: ['video/mp4', 'video/quicktime'],
  pdf: ['application/pdf'],
  powerpoint: ['application/vnd.ms-powerpoint', 'application/vnd.openxmlformats-officedocument.presentationml.presentation'],
  doc: ['application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'],
  excel: ['application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'],
  csv: ['text/csv']
}

export default function UserUpload({
  appProps, taskId, courseUid, courseId, uploadUserFilePath, fetchUserUploadsPath,
  sendCommentReplyPath, fetchRepliesPath, deleteUploadPath, settingsTranslation,
  userUploadInfo, setUserUploadInfo, downloadUserFilePath, validateFilePath
}) {
  const [uploadModalOpen, setUploadModalOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [cameraOpening, setCameraOpening] = useState(false)
  const [webcamOpened, setWebcamOpened] = useState(false)
  const [fileForUpload, setFileForUpload] = useState(null)
  const [fileForUploadSize, setFileForUploadSize] = useState(0)
  const [capturing, setCapturing] = useState(false)
  const [recordedChunks, setRecordedChunks] = useState([])
  const [capturedEntity, setCapturedEntity] = useState(null)
  const [fileComment, setFileComment] = useState('')
  const [fileName, setFileName] = useState(null)
  const [initialUploads, setInitialUploads] = useState([])
  const [chatModalOpen, setChatModalOpen] = useState(false)
  const [selectedUpload, setSelectedUpload] = useState(null)
  const [deleteModalOpen, setDeleteModalOpen] = useState(false)
  const [itemToDelete, setItemToDelete] = useState(null)
  const [isPublic, setIspublic] = useState(false)

  const reactPlayerRef = useRef(null)
  const webcamRef = useRef(null)
  const mediaRecorderRef = useRef(null)

  const { translations, flashMessage, user } = appProps
  const browser = detectBrowser()
  const publicityOptions = [
    { key: 'private', title: translations.user_upload.private },
    { key: 'public', title: translations.user_upload.public }
  ]

  useEffect(() => {
    setInitialUploads([])
    fetchUserUploads()
  }, [taskId])

  useEffect(()=>{
    if (recordedChunks.length > 0) {
      if (webcamOpened) {
        const blob = new Blob(recordedChunks, {
          type: `video/${browser === 'safari' ? 'mp4' : 'webm'}`
        })
        setCapturedEntity(blob)
      }

      setRecordedChunks([])
    }
  }, [recordedChunks, webcamOpened])

  useEffect( () => {
    if(fileForUpload){
      setFileName(handleFilename())
    }
  }, [fileForUpload])

  const renderCapturedEntity = useMemo( () => {
    if (capturedEntity) {
      setFileName('blob_' + Date.now())

      return (
        <div className='record-tile'>
          { (capturedEntity.type === 'image/webp' || capturedEntity.type === 'image/jpeg') &&
            <img
              className='recorded-image'
              src={ URL.createObjectURL(capturedEntity) }
            />
          }

          { (capturedEntity.type === 'video/webm' || capturedEntity.type === 'video/mp4') &&
          <ReactPlayer
            ref={ reactPlayerRef }
            url={ URL.createObjectURL(capturedEntity) }
            width='100%'
            height='100%'
            controls
            volume={ 1 }
            muted
          />
          }
        </div>
      )
    }
  }, [capturedEntity])

  const handleStartCaptureClick = useCallback(() => {
    setCapturing(true)
    mediaRecorderRef.current = new MediaRecorder(webcamRef.current.stream, {
      mimeType: `video/${browser === 'safari' ? 'mp4' : 'webm'}`
    })
    mediaRecorderRef.current.addEventListener(
      "dataavailable",
      handleDataAvailable
    )
    mediaRecorderRef.current.start()
  }, [webcamRef, mediaRecorderRef])

  const handleDataAvailable = useCallback( ({ data }) => {
    if (data.size > 0) {
      setRecordedChunks((prev) => prev.concat(data))
    }
  }, [])

  const handleStopCaptureClick = useCallback(() => {
    mediaRecorderRef.current.stop()
    setCapturing(false)
  }, [mediaRecorderRef, webcamRef])

  const capture = useCallback(() => {
    fetch(webcamRef.current.getScreenshot()).then( res => res.blob()).then(res => {
      setCapturedEntity(res)
    })
  }, [webcamRef, capturedEntity])

  function fetchUserUploads(){
    Rails.ajax({
      type: 'GET',
      url: fetchUserUploadsPath + `?task_id=${ taskId }&entity_type=MindseedLms::Course`,
      dataType: 'json',
      success: res => {
        setInitialUploads(res.userUploads)
      }
    })
  }

  function openWebCam() {
    setCameraOpening(true)

    navigator.mediaDevices.getUserMedia({ video: true, audio: true }).then( res => {
      setCameraOpening(false)
      setWebcamOpened(true)
      setUploadModalOpen(false)

    }).catch( res => {
      setCameraOpening(false)
      flashMessage.show(translations.user_upload.webcamError, 'error')
    })
  }

  function calculateFileSize(bytes){
    const length = String(bytes).length

    if (length < 4) {
      return bytes + ' B'

    } else if (length < 7) {
      return (bytes / 1024.0).toFixed(2) + ' KB'

    } else if(length < 10) {
      return (bytes / 1048576.0).toFixed(2) + ' MB'

    } else {
      return (bytes / 1073741824.0).toFixed(2) + ' GB'
    }
  }

  function validateUpload(){
    setLoading(true)
    const fd = new FormData()
    fd.append('file_size', fileForUploadSize)
    fd.append('file_path', fileForUpload.path || fileName)
    fd.append('entity_type', 'MindseedLms::Course')
    fd.append('task_id', taskId)

    Rails.ajax({
      type: 'POST',
      url: validateFilePath,
      dataType: 'json',
      data: fd,
      success: result => {
        uploadFileToBucket(result)
      },
      error: result => {
        setLoading(false)
        flashMessage.show(translations.user_upload[result.error], 'error')
      }
    })
  }

  function uploadFileToBucket(object) {
    const { accessToken, bucket, uploadPath, fileName } = object
    const reader = new FileReader()
    reader.readAsArrayBuffer(fileForUpload)

    reader.addEventListener("load", async (event) => {
      const bytes = event.target.result
      const cloudResponse = await fetch(
        `https://storage.googleapis.com/upload/storage/v1/b/${ bucket }/o?uploadType=media&name=${ encodeURI(uploadPath + fileName) }&predefinedAcl=private`,
        {
          method: 'POST',
          headers: {
            'Content-Type': fileForUpload.type,
            'Authorization': `Bearer ${ accessToken }`
          },
          body: bytes
        }
      )

      let result = await cloudResponse.json()

      if(result.mediaLink) {
        const sourceUrl = `https://storage.googleapis.com/${bucket}/${result.name}`

        upload(sourceUrl, result.name)
      } else if(result.error && result.error.code === 401) {
          flashMessage.show(translations.user_upload.uploadError, 'error')
      }
    })
  }

  function upload(sourceUrl, fullFileName){
    let distinctName = fullFileName.split('/')[fullFileName.split('/').length - 1]

    let formData = new FormData()
    formData.append('task_id', taskId)
    formData.append('file_size', fileForUploadSize)
    formData.append('entity_type', 'MindseedLms::Course')
    formData.append('file_name', distinctName)
    formData.append('source_url', sourceUrl)
    formData.append('publicity', isPublic)

    if(fileComment){
      formData.append('file_comment', fileComment)
    }

    Rails.ajax({
      url: uploadUserFilePath,
      type: 'POST',
      dataType: 'json',
      data: formData,
      success: res => {
        flashMessage.show(translations.user_upload.successfullyUploaded, 'success')
        setInitialUploads([res.createdUpload, ...initialUploads])
        let updatedUserInfo = [...userUploadInfo]
        let currentUserInfo = updatedUserInfo.find(info => info.id == taskId)

        if(currentUserInfo && !currentUserInfo.hasUploaded){
          currentUserInfo.hasUploaded = true
          setUserUploadInfo(updatedUserInfo)
        }
      },
      error: res => {
        flashMessage.show(translations.user_upload.uploadError, 'error')
      },
      complete: res =>{
        setFileForUpload(null)
        setFileForUploadSize(0)
        setFileName(null)
        setFileComment('')
        setUploadModalOpen(false)
        setIspublic(false)
        setLoading(false)
      }
    })
  }

  function deleteUpload(upload){
    let fd = new FormData()

    fd.append('upload_id', upload.id)

    Rails.ajax({
      type: 'DELETE',
      url: deleteUploadPath,
      dataType: 'json',
      data: fd,
      success: res => {
        setInitialUploads(initialUploads.filter(u => u.id != upload.id ))
        flashMessage.show(translations.user_upload.deletedSuccessfully, 'success')
      },
      error: res => {
        flashMessage.show(translations.user_upload.deleteError, 'error')
      },
      complete: res => {
        closeDeleteModal()
      }
    })
  }

  function onDropFile(droppedFile) {
    setFileForUpload(droppedFile[0])
    setFileForUploadSize(droppedFile[0].size)
  }

  function closeUploadModal(){
    if (!loading) {
      setUploadModalOpen(false)

      if(isPublic){
        setIspublic(false)
      }

      if (fileForUpload) {
        setFileForUpload(null)
        setFileForUploadSize(0)
      }
    }
  }

  function closeWebCamModal(userHasCaptured = false){
   stop()
   setWebcamOpened(false)
   setCapturedEntity(null)
   setUploadModalOpen(true)
   setCapturing(false)
   if(!userHasCaptured){
     setFileName(null)
     setFileComment('')
   }
  }

  function handleKeepCaptured(){
    setFileForUpload(capturedEntity)
    setFileForUploadSize(capturedEntity.size)
    closeWebCamModal(true)
  }

  function handleDiscard(){
    setCapturedEntity(null)
    setFileComment('')
    setFileName(null)
  }

  function handleDiscardSelected(){
    if (!loading) {
      setFileForUpload(null)
      setFileComment('')
      setFileName(null)
    }
  }

  function stop() {
    if (webcamRef.current) {
      const stream = webcamRef.current.video.srcObject
      const tracks = stream.getTracks()

      tracks.forEach(track => track.stop())
      webcamRef.current.video.srcObject = null

      if (mediaRecorderRef.current && mediaRecorderRef.current.state === 'recording') {
        mediaRecorderRef.current.stop()
      }
    }
 }

  function handleFilename(){
    let fname = ''

    if (fileForUpload.path) {
      fname = fileForUpload.path
    }

    else {
      let type = fileForUpload.type.slice(fileForUpload.type.indexOf('/') + 1)

      fname = fileName + '.' + type
    }

    fname = fname.trim()

    return fname
  }

  function updateFileName(value) {
    if (!value.includes('.')) {
      setFileName(value)
    }
  }

  function openDeleteModal(upload){
    setItemToDelete(upload)
    setDeleteModalOpen(true)
  }

  function closeDeleteModal(){
    setItemToDelete(null)
    setDeleteModalOpen(false)
  }

  function openChatModal(upload) {
    setSelectedUpload(upload)
    setChatModalOpen(true)
  }

  function closeChatModal(){
    setChatModalOpen(false)
    setSelectedUpload(null)
  }

  function downloadFile(id, event) {
    event.preventDefault()
    setLoading(true)
    const fd = new FormData()

    fd.append('file_id', id)

    Rails.ajax({
      type: 'POST',
      url: downloadUserFilePath,
      dataType: 'json',
      data: fd,
      success: result => {
        window.open(result.url)
      },
      error: result => {
        flashMessage.show(translations.user_upload.upload_not_found, 'error')
      },
      complete: result => {
        setLoading(false)
      }
    })
  }

  return (
    <>
      <div className='user-uploads-wrapper'>
        <div className='user-uploads-title'>
          { translations.user_upload.yourFiles }
        </div>

        { settingsTranslation.userUploadInstructions &&
          <div
            className='user-uploads-info'
            dangerouslySetInnerHTML={{ __html: settingsTranslation.userUploadInstructions.replaceAll('\n', '<br/>') }}
          />
        }

        <div className='flex-box'>
          <div
            className='button user-uploads-button'
            onClick={ () => setUploadModalOpen(true) }
          >
            <i className='icon_plus user-uploads-button-icon'/>
            { translations.user_upload.uploadFiles }
          </div>
        </div>

        { initialUploads.length > 0 &&
          <div className='flex-box flex-column'>
            { initialUploads.map( item => (
              <div
                key={ item.id }
                className='user-uploads-tile flex-box'
              >
                <div className='avatar-wrapper'>
                  <img
                    className='avatar'
                    src={ item.user && item.user.image ? item.user.image : placeholderAvatar }
                  />
                </div>

                <div className='content-wrapper flex-box flex-column'>
                  <div className='content-title'>
                    { (item.user && item.user.fullName) || translations.user_upload.user }
                  </div>

                  <div
                    className='content-info'
                    dangerouslySetInnerHTML={{ __html: item.rootComment && item.rootComment.content.replaceAll('\n', '<br/>') }}
                  />

                  <div className='flex-box'>
                    <div
                      className='content-file-wrapper flex-box'
                      onClick={ event => downloadFile(item.id, event) }
                    >
                      <i className={ `content-file-icon ${ getMediaIcon(item.fileName) }` }/>
                      <div className='content-file-info-wrapper flex-box flex-column content-space-between'>
                        <div className='content-file-name'>
                          { item.fileName.length > 45 ? item.fileName.substring(0,45) + '...' : item.fileName }
                        </div>

                        <div className='content-file-size'>
                          { item.fileSize }
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className='actions-wrapper flex-box content-space-between items-center'>
                    { (item.totalComments > 0 || item.unreadComments > 0) ?
                      <div
                        className='flex-box items-center pointer'
                        onClick={ () => openChatModal(item) }
                      >
                        <div className='action'>
                          { `${ translations.general.show_replies } (${ item.totalComments })` }
                        </div>

                        { item.unreadComments > 0 &&
                          <div className='action color-scheme'>
                            &nbsp;{ `(${ item.unreadComments } ${ translations.general.new })` }
                          </div>
                        }

                        <i className='fa-solid fa-play'/>
                      </div>

                    :
                      <div/>
                    }

                    <div className='flex-box items-center'>
                      <div className='action'>
                        { formatDate(item.createdAt) }
                      </div>

                      <div
                        className='action-button'
                        onClick={ () => openChatModal(item) }
                      >
                        { translations.general.reply }
                      </div>
                    </div>
                  </div>
                </div>

                { item.userId == user.id &&
                  <i
                    className='user-uploads-delete-icon fa-light fa-trash'
                    onClick={ () => openDeleteModal(item) }
                  />
                }
              </div>
            ))}
          </div>
        }
      </div>

      { uploadModalOpen &&
        <Modal
          visible
          mode='medium'
          acceptAction={ validateUpload }
          saveText={ translations.general.submit }
          disabledAction={ !fileForUpload || loading }
          closeModal={ closeUploadModal }
          acceptActionClass='user-uploads-button-action'
        >
          <div className='user-uploads-modal-wrapper flex-box flex-column items-center'>
            <div className='modal-title'>
              { translations.user_upload.uploadFiles }
            </div>

            <div className='modal-info'>
              { translations.user_upload.uploadToThisTask }
            </div>

            { !fileForUpload &&
              <>
                <Dropzone
                  onDrop={ onDropFile }
                  accept={ mediaTypes.map( mediaType => mimeTypes[mediaType] ).flat() }
                >
                  { ({ getRootProps, getInputProps }) => (
                    <div { ...getRootProps() } className='modal-dropzone-wrapper modal-dropzone flex-box flex-column items-center content-center'>
                      <input { ...getInputProps() }/>

                      <i className='fa-light fa-folder-arrow-up color-scheme dropzone-icon'/>

                      <div className='dropzone-main-text'>
                        { translations.user_upload.dragFiles }
                      </div>

                      <div className='dropzone-text'>
                        { translations.user_upload.clickToUpload }
                      </div>
                    </div>
                  )}
                </Dropzone>

                { !window.location.href.includes('isMobileApp=true') &&
                  <>
                    <div className='modal-text'>
                      { translations.user_upload.captureOwn }
                    </div>

                    { cameraOpening &&
                      <div className='modal-button'>
                        <Loader
                          size='small'
                          position='center'
                        />
                      </div>
                    }

                    { !cameraOpening &&
                      <div
                        className='modal-button button inverted'
                        onClick={ openWebCam }
                      >
                        { translations.user_upload.captureNow }
                      </div>
                    }
                  </>
                }
              </>
            }

            <div className='section-divider' style={{ marginTop: '50px' }}/>

            { fileForUpload &&
              <>
                <div className='modal-dropzone-wrapper modal-dropzone-selected border-color-scheme flex-box items-center conten-space-between'>
                  <div className='flex-box items-center flex-1'>
                    <i
                      className={ `selected-file-icon ${ getMediaIcon(handleFilename() || fileForUpload.type.replace('/', '.')) }` }
                    />
                    <div className='selected-file-name'>
                      { fileName && fileName.length > 30 ? fileName.substring(0,30) + '...' : fileName }
                    </div>
                  </div>

                  <div className='flex-box items-center content-end flex-1'>
                    <div className='selected-file-size'>
                      { calculateFileSize(fileForUploadSize) }
                    </div>

                    <i
                      className='selected-file-discard fa-light fa-times'
                      onClick={ handleDiscardSelected }
                    />
                  </div>
                </div>

                <div className='flex-box flex-column' style={{ marginBottom: 15 }}>
                  <RadioInput
                    options={ publicityOptions }
                    currentOption={ isPublic ? 'public' : 'private' }
                    setCurrentOption={ option => setIspublic(option.key == 'public') }
                    optionStyles={{ marginRight: '10px' }}
                  />
                </div>
              </>
            }

            { !loading &&
              <div className='modal-comment-wrapper flex-box flex-column'>
                <div className='modal-comment-title'>
                  { translations.user_upload.note }
                </div>

                <textarea
                  className='comment-area'
                  value={ fileComment }
                  onChange={ event => setFileComment(event.target.value) }
                />
              </div>
            }

            { loading &&
              <div className='modal-loader-wrapper'>
                <Loader
                  size='medium'
                  position='center'
                />
              </div>
            }
          </div>
        </Modal>
      }

      { webcamOpened &&
        <Modal
          visible
          mode='large'
          saveText={ translations.general.back }
          acceptAction={ () => closeWebCamModal() }
          closeModal={ () => closeWebCamModal() }
        >
          <div className='user-uploads-record-wrapper flex-box flex-column items-center'>
            { !capturedEntity &&
              <>
                <Webcam
                  ref={ webcamRef }
                  className={ `record-tile ${ capturing ? 'recording' : '' }` }
                  screenshotFormat={ `image/${ browser === 'safari' ? 'jpeg' : 'webp' }` }
                  audio
                  muted
                />

                <div className='record-actions-wrapper flex-box content-center'>
                  <div
                    className={ `record-action-button button inverted ${ capturing ? 'disabled' : '' }` }
                    onClick={ !capturing ? capture : () => {} }
                  >
                    { translations.user_upload.capturePhoto }
                  </div>

                  <div
                    className='record-action-button button'
                    onClick={ capturing ? handleStopCaptureClick : handleStartCaptureClick }
                  >
                    { translations.user_upload[capturing ? 'stopCapture' : 'startCapture'] }
                  </div>
                </div>
              </>
            }

            { capturedEntity &&
              <>
                { renderCapturedEntity }

                <div className='recorded-file-name-wrapper'>
                  <div className='recorded-file-name-title'>
                    { translations.user_upload.fileName }
                  </div>

                  <input
                    type='text'
                    value={ fileName || '' }
                    onChange={ event => updateFileName(event.target.value) }
                  />

                  <div className='recorded-file-name-help'>
                    { translations.user_upload.fileNameHelpText }
                  </div>
                </div>

                <div className='record-actions-wrapper flex-box content-center'>
                  <div
                    className='record-action-button button inverted'
                    onClick={ handleDiscard }
                  >
                    { translations.user_upload.discard }
                  </div>

                  <div
                    className='record-action-button button'
                    onClick={ handleKeepCaptured }
                  >
                    { translations.user_upload.keep }
                  </div>
                </div>
              </>
            }
          </div>
        </Modal>
      }

      { chatModalOpen && selectedUpload &&
        <UploadChat
          appProps={ appProps }
          selectedUpload={ selectedUpload }
          courseUid={ courseUid }
          fetchRepliesPath={ fetchRepliesPath }
          setInitialUploads={ setInitialUploads }
          onClose={ closeChatModal }
          taskTitle={ settingsTranslation.title }
          sendCommentReplyPath={ sendCommentReplyPath }
        />
      }

      { deleteModalOpen && itemToDelete &&
        <Modal
          visible={  deleteModalOpen }
          mode='small'
          acceptAction={ () => deleteUpload(itemToDelete) }
          saveText={ translations.general.delete }
          abortText={ translations.general.cancel }
          abortAction={ closeDeleteModal }
          closeModal={ closeDeleteModal }
        >
          <div>
            { translations.user_upload.deleteConfirmation }
          </div>
        </Modal>
      }

      { loading &&
        <Loader
          fullPage
        />
      }
    </>
  )
}
