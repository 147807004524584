import React from 'react'
import Select from '../../components/shared/Select'
import { getTranslation } from '@reactiveonline/frontend_shared_components/utils'

export default function Table({ appProps, heading, filters, headers, rows, courseLocale, screenClass }) {
  const { translations, currentLocale } = appProps

  return (
    <div className={`table-wrapper ${screenClass}`}>
      <div className={`flex-box items-center ${ heading ? 'content-space-between' : 'content-end'} `} style={ heading && { marginBottom: 40 } }>
        { heading &&
          <div className='heading'>
            { heading }
          </div>
        }
        { filters &&
          <div className='filters'>
            <Select
              appProps={appProps}
              selectName={ 'selectName' }
              selectorHeading={ 'Filter' }
              options={ filters }
              includeNone={ true }
              initialItemUid={ null }
            />
          </div>
        }
      </div>
      { rows && rows.length > 0 &&
        <div className='table flex-box flex-column'>
          { headers &&
            <div className='table-head flex-box'>
              { headers.map( (item, index)=> {
                  return (
                    <div key={index} className='table-cell flex-1'>
                      { item }
                    </div>
                  )
                })
              }
            </div>
          }
          <div className='table-body'>
            { rows.map( (item, index)=> {
              const translation = getTranslation(item.translations, courseLocale, currentLocale)

              return (
                <div key={ index } className='table-row flex-box' onClick={ ()=> window.location.href = item.showPath }>
                  <div className='table-cell flex-1'>{ item.date }</div>
                  <div className='table-cell flex-1 flex-box flex-column items-start'>
                    <div>{ translation.title }</div>
                    { item.path &&
                      <div>
                        <a href={ item.path } target="_blank" className='button' style={{ width: 'auto', fontSize: 12, padding: '3px 10px' }}>
                          { translations.event.join_now }
                        </a>
                      </div>
                    }
                  </div>
                  <div className='table-cell flex-1'>
                    <i className={`fa-thin ${item.type === 'onsiteEvent' ? 'fa-people-group' : 'fa-camera-web' }`} style={{ marginRight: 5 }}></i>
                    { translations.event[item.type] }
                  </div>
                  <div className='table-cell flex-1'>{ item.venue || '-' }</div>
                  <div className='table-cell flex-1'>{ `${ item.duration } ${ translations.general.minutes }` }</div>
                  <div className='table-cell flex-1'>{ item.instructors.map( instructor => instructor.fullName ).join(', ') }</div>
                </div>
              )
            })}
          </div>
        </div>
      }
    </div>
  )
}
