import React, { useState, useEffect } from 'react'
import Steps from './Steps'
import Summary from './Summary'

export default function Checkout({
  appProps, currentOrder, screenClass, tax, errors, warnings, legalTerms,
  phoneRequired, mobileRequired
}) {
  const [order, setOrder] = useState(currentOrder)
  const [loading, setLoading] = useState(false)

  const { translations, setCurrentAppProps, flashMessage, cataloguePath } = appProps

  useEffect( () => {
    setCurrentAppProps({ order: null }) // hide cart from header
    handleErrors({ errors: errors, warnings: warnings, order: order })
  }, [])

  function handleErrors(result) {
    if (result.errors) {
      result.errors.forEach( (error, index) => {
        setTimeout( () => flashMessage.show(error, 'error'), 100 * index)
      })
    }

    if (result.warnings) {
      result.warnings.forEach( (warning, index) => {
        setTimeout( () => flashMessage.show(warning, 'notice'), 100 * index * (result.errors ? result.errors.length : 1))
      })
    }

    if (result.order === null || order.lineItems.length === 0) {
      setTimeout( () => {
        window.location.href = cataloguePath
      }, 1000)
    } else if (result.order) {
      setOrder(result.order)
    }
  }

  return (
    <div className={ `lxp-wrapper ${ screenClass }` }>
      <div className='section heading'>
        <div className='main-container'>
          <div className={ `section-title ${appProps.currentDevice != 'desktop' ? 'mobile' : '' }` }>
            <h1>{ translations.checkout.checkout }</h1>
          </div>
        </div>
      </div>

      <div className='section body-bg'>
        <div className='main-container checkout-wrapper'>
          <div className='flex-box flex-wrap checkout-container'>
            <Steps
              appProps={ appProps }
              order={ order }
              setOrder={ setOrder }
              loading={ loading }
              setLoading={ setLoading }
              handleErrors={ handleErrors }
              legalTerms={ legalTerms }
              phoneRequired={ phoneRequired }
              mobileRequired={ mobileRequired }
            />

            <Summary
              appProps={ appProps }
              order={ order }
              setOrder={ setOrder }
              loading={ loading }
              setLoading={ setLoading }
              tax={ tax }
              showActionsButtons={ false }
              showActions
            />
          </div>
        </div>
      </div>
    </div>
  )
}
