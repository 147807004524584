import React from 'react'
import { Loader } from '@reactiveonline/frontend_shared_components'
import { formatDate } from '../../helpers/utils'

export default function PracticeLogTable( { userPractices, loading, translations } ) {

  function renderItem(userPractice) {
    return(
      <div key={userPractice.id} className= 'flex-box items-center table-row'>
        <div className='table-cell flex-1'>{ userPractice.courseTitle }</div>
        <div className='table-cell flex-1'>{ userPractice.taskTitle }</div>
        <div className='table-cell flex-1'>{ formatDate(userPractice.updatedAt) }</div>
      </div>
    )
  }
  const logColums =
    <div className= 'flex-box item-center table-head'>
      <div className='table-cell head flex-1'>{ translations.course.course }</div>
      <div className='table-cell head flex-1'>{ translations.course.task }</div>
      <div className='table-cell head flex-1'>{ translations.general.completed_at }</div>
    </div>

  const noLogs = <p> { translations.account.no_practice_logs } </p>

  return(
    <div className='table'>
      { userPractices.length == 0 ? noLogs : logColums }
      { userPractices.map(u => renderItem(u)) }
      <div>
        { loading &&
          <Loader
            position={ 'center' }
            size={ 'small' }
          />
        }
      </div>
    </div>
  )
}
