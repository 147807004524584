import React from 'react'

export default function ProgressBar({ percentage }) {

  return (
    <>
      <div className="task-progress">
        <div className="progress-percentage secondary-text-color">
          { percentage }
        </div>
        <div className="progress-bar-wrapper">
          <div className="progress-bar-back"></div>
          <div className="progress-bar-front" style={{ width: percentage }}></div>
        </div>
      </div>
    </>
  )
}
