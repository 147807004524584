import React from 'react'
import TabItem from '../shared/TabItem'

export default function TabsIndex({ appProps, pageTitle, transparentHeader, color }) {
  const { translations } = appProps
  const tabData = [
    { title: translations.general.learnings, path: appProps.coursesPath },
    { title: translations.general.notes,     path: appProps.notesPath },
    // { title: translations.general.dashboard, path: appProps.dashboardPath },
    { title: translations.account.events,    path: appProps.eventsPath }
  ]

  return (
    <div className={`section heading ${transparentHeader ? 'transparent tabbed' : ''}`}>
      <div className='main-container' style={{ height: '100%' }}>
        { pageTitle &&
          <div className={ `section-title flex-box items-center ${appProps.currentDevice != 'desktop' ? 'mobile' : '' }` } style={{ height: '100%' }}>
            <h1>{ pageTitle }</h1>
          </div>
        }
        <div className='flex-box items-center content-start tabs-index'>
          { tabData.map( (data, index) => {
              return (
                <TabItem
                  key={ index }
                  title={ data.title }
                  path={ data.path }
                  index={ index }
                  color={ color }
                />
              )
            })
          }
        </div>
      </div>
    </div>
  )
}
