import React, { useState } from 'react'
import { Loader } from '@reactiveonline/frontend_shared_components'
import ListItem from '../shared/ListItem'
import Description from '../shared/Description'
import Modal from '../shared/Modal'
import ProgressBar from '../shared/ProgressBar'
import AddToCart from '../shared/AddToCart'
import LoginPrompt from '../shared/LoginPrompt'
import { NO_IMAGE_SOURCE } from '../GlobalConstants'
import { lrsEvent } from '../../helpers/utils'
import { getTranslation } from '@reactiveonline/frontend_shared_components/utils'
import { formatDate } from '../../helpers/utils'
import moment from 'moment'

export default function CourseListItem({
  appProps, itemClass, item, description, actionPath, actionMethod, quickMenu,
  showExtraInfo, entityType, courseLocale, isPreview, tenantUrl, entityShowPath, isBundleShow
}) {
  const { translations, currentLocale, order, user, colorScheme, generateEnrolmentTokenPath } = appProps
  const { accessibilityProps, showPath } = item
  const {
    userEnroled, enableUnenrol, enableLxp, showEnrol, enrolmentDate, startDate,
    userAction, lxpPath, showTerms, isPurchased, endDate
  } = accessibilityProps

  const queryParams = new URLSearchParams(location.search)
  const accessCodeParam = queryParams.has('access_code') ? '&access_code=' + queryParams.get('access_code') : ''

  const [prerequisitesLoading, setPrerequisitesLoading] = useState(false)
  const [coursePrerequisites, setCoursePrerequisites] = useState([])
  const [prerequisiteModalOpen, setPrerequisiteModalOpen] = useState(false)
  const [acceptedCustomTerms, setAcceptedCustomTerms] = useState(!showTerms)
  const [termsModalOpen, setTermsModalOpen] = useState(false)
  const [showLoginPrompt, setShowLoginPrompt] = useState(false)
  const [enrolmentToken, setEnrolmentToken] = useState(null)

  const isInCart = !!(order && order.lineItems.find( lineItem => lineItem.item.entityType === entityType && lineItem.item.uid === item.uid ))
  const divTranslations = entityType === 'MindseedLms::Event' ? translations.event : (entityType === 'MindseedLms::Bundle' ? translations.bundle : translations.course)
  const translation = getTranslation(item.translations, courseLocale, currentLocale)
  const imageSource = item.image.isTranslatable ? item.image[currentLocale] : item.image.source

  function stringToDate(s) {
    return moment(s,'DD/MM/YYYY HH:mm:ss').toDate()
  }

  function handleCourseStart() {
    if (item.getPrerequisitesPath) {
      checkPrerequisites(() => {
        if (item.completionRate) {
          lrsEvent(appProps, { en: { title: translation.title } }, item.uid, 'course', 'resumed')
        }
        window.location.href = lxpPath
      })
    } else if(entityType === 'MindseedLms::Bundle') {
      if (item.completionRate) {
        lrsEvent(appProps, { en: { title: translation.title } }, item.uid, 'bundle', 'resumed')
      }
      handleResumeBundle()
    } else {
      lrsEvent(appProps, { en: { title: translation.title } }, item.uid, 'event', 'resumed')
      window.location.href = lxpPath
    }
  }

  function handleResumeBundle() {
    Rails.ajax({
      url: lxpPath,
      type: 'GET',
      dataType: 'json',
      success: res => {
        if(res.redirectTo){
          window.location.href = res.redirectTo
        }
      },
      error: err => {}
    })
  }

  function checkPrerequisites(actionCallback) {
    const { getPrerequisitesPath } = item
    setPrerequisitesLoading(true)

    Rails.ajax({
      url: getPrerequisitesPath,
      type: 'POST',
      dataType: 'json',
      success: res => {
        if(res.coursePrerequisites && res.coursePrerequisites.length > 0) {
          setCoursePrerequisites(res.coursePrerequisites)
          setPrerequisiteModalOpen(true)
        } else {
          actionCallback()
        }
        setPrerequisitesLoading(false)
      },
      error: err => {
        setCoursePrerequisites([])
        setPrerequisiteModalOpen(false)
        setPrerequisitesLoading(false)
      }
    })
  }

  function handleAction() {
    if (user) {
      if (item.getPrerequisitesPath) {
        checkPrerequisites(actionMethod)
      } else {
        actionMethod()
      }
    } else {
        Rails.ajax({
          url: generateEnrolmentTokenPath,
          type: 'GET',
          dataType: 'json',
          success: res => {
            setEnrolmentToken(res.generatedToken)
            setShowLoginPrompt(true)
          },
        })
      }
  }

  function closeLoginPrompt(){
    setShowLoginPrompt(false)
    setEnrolmentToken(null)
  }

  function renderActionButton() {
    if (userEnroled) {
      return (
        <>
          { item.completionRate > 0 &&
            <ProgressBar percentage={ `${ item.completionRate }%` }/>
          }

          { enableLxp && !prerequisitesLoading && !isBundleShow &&
              <div className='button-wrapper' style={{ marginTop: 10 }}>
                <div className='button' onClick={ handleCourseStart }>
                  { divTranslations[item.completionRate ? 'resume_course' : 'start_course'] }
                </div>
              </div>
          }

          { enableLxp && prerequisitesLoading &&
            <Loader
              size='small'
              position='center'
            />
          }
        </>
      )

    } else if (showEnrol) {
      return renderEnrolAction()

    } else if (showPath) {
      return (
        <div className='button-wrapper' style={{ marginTop: 10 }}>
          {
            !isPreview ?
            <div className='button' onClick={  () => window.location.href = showPath }>
              { translations.general.show_details }
            </div>
            :
            <a className='button' href={ tenantUrl + showPath } target='_blank'>
              { translations.general.show_details }
            </a>
          }
        </div>
      )
    }
  }

  function renderEnrolAction() {
    return (
      <div>
        { prerequisitesLoading &&
          <div style={{ marginTop: 10 }}>
            <Loader
              size='small'
              position='center'
            />
          </div>
        }

        { !prerequisitesLoading &&
          <>
            { userAction && userAction === 'enrolment' &&
              <div className='button-wrapper flex-box flex-column' style={{ marginTop: 10 }}>
                { item.price && !isPurchased &&
                  <AddToCart
                    appProps={ appProps }
                    buyNow={ !userEnroled }
                    entityType={ entityType }
                    entityUid={ item.uid }
                    isInCart={ isInCart }
                    disableActions={ !acceptedCustomTerms }
                  />
                }

                { (!item.price || isPurchased) &&
                  <div
                    className={ `button ${ acceptedCustomTerms ? '' : 'disabled' }` }
                    onClick={ () => acceptedCustomTerms && handleAction() }
                  >
                    { translations.course.enrol_now }
                  </div>
                }

                { showTerms && !isInCart &&
                  <div className='flex-box items-start'>
                    <div
                      style={{ cursor: 'pointer', fontSize: 25 }}
                      className={ `fa-light ${ acceptedCustomTerms ? 'fa-square-check' : 'fa-square' }` }
                      onClick={ ()=> setAcceptedCustomTerms(!acceptedCustomTerms) }
                    />
                    <div className='custom-terms' onClick={ () => setTermsModalOpen(true) }>
                      { divTranslations.accept_custom_terms }
                    </div>
                  </div>
                }
              </div>
            }

            { userAction && userAction === 'request' &&
              <div
                className='button'
                style={{ marginTop: 10 }}
                onClick={ handleAction }
              >
                { translations.course.request_access }
              </div>
            }
          </>
        }

        { userAction && userAction === 'pending' &&
          <div className='button-wrapper' style={{ marginTop: 10 }}>
            { translations.course.access_requested }
          </div>
        }

        { !userAction && enrolmentDate != null  && (stringToDate(enrolmentDate ) > new Date()) &&
          <div style={{ marginTop: 10 }}>
            { translations.course.enrolment_starts } { formatDate(enrolmentDate) }
          </div>
        }

        { !userAction && endDate != null &&
          ( stringToDate(endDate) < new Date() ) &&
          <div style={{ marginTop: 10 }}>
            { divTranslations.course_ended } { formatDate(endDate) }
          </div>
        }
      </div>
    )
  }

  function renderCourseInfo(icon, content) {
    return (
      <div className='course-information-item flex-box items-center'>
        <div className='flex-box'>
          <div
            className={ `${ icon } icon` }
            style={{ color: colorScheme }}
          />
          <div style={{ textTransform: 'capitalize' }}>
            { content }
          </div>
        </div>
      </div>
    )
  }

  return (
    <>
      <ListItem
        appProps={ appProps }
        itemClass={ itemClass }
        image={ imageSource || NO_IMAGE_SOURCE }
        title={ translation.title }
        description={ description }
        instructors={ item.instructors }
        price={ (userEnroled || isPurchased) ? null : item.price }
        entityType={ entityType }
        actionPath={ actionPath }
        quickMenu={ quickMenu }
        entityUid={ item.uid }
        startDate={ startDate }
        durationType={ item.durationType }
        durationAmount={ item.durationAmount }
      >
        { renderActionButton() }

        { showExtraInfo &&
          <>
            <div className='flex-box flex-column'>
              <div className='section-divider'/>
              <div className='course-info-title'>
                { entityType && entityType === 'MindseedLms::Course' ? translations.course.about : translations.event.about }:
              </div>

              <div className='course-information flex-box flex-column'>
                { startDate && renderCourseInfo('calendar_icon_from', `${ translations.general.from_date } ${ formatDate(startDate) }`) }

                { endDate && renderCourseInfo('calendar_icon_to', `${ translations.general.to_date } ${ formatDate(endDate) }`) }

                { item.difficulty && renderCourseInfo('course_icon', translations.course[`difficulty_${ item.difficulty }`]) }

                { item.timeInvestAmount > 0 && renderCourseInfo('time_invest_icon', `${ item.timeInvestAmount } ${ translations.general[item.timeInvestType] || '' }`) }

                { item.durationAmount > 0 && renderCourseInfo('learning_icon', `${ item.durationAmount } ${ translations.general[item.durationType] || '' }`) }

                { renderCourseInfo('globe_icon', item.availableLanguages.map( language => language.presentation ).join(', ')) }

                { enrolmentDate && renderCourseInfo('add_user_icon', `${ translations.course.enrol_from } ${ formatDate(enrolmentDate) }`) }

                { item.accessibilityProps.remainingEnrolments !== null && item.accessibilityProps.remainingEnrolments > 0 &&
                  renderCourseInfo('remaining_enrolments_icon', `${ translations.course.remaining_enrolments } ${ item.accessibilityProps.remainingEnrolments }`)
                }
              </div>
            </div>

            { actionMethod && enableUnenrol &&
              <div className='flex-box content-end' style={{ marginTop: 10 }}>
                <div
                  className='clickable-link pointer'
                  onClick={ actionMethod }
                >
                  { translations.course.unenrol }
                </div>
              </div>
            }
          </>
        }
      </ListItem>

      { prerequisiteModalOpen &&
        <Modal
          visible
          closeModal={ () => setPrerequisiteModalOpen(false) }
          icon='exclamation'
          mode='small'
          elementsClass='items-center content-center centered'
          acceptAction={ () => setPrerequisiteModalOpen(false) }
          saveText={ translations.general.ok }
        >
          <div>
            { translations.lxp.prerequisites_applied_prompt }
            <div className='prerequisites-items flex-box flex-column items-center'>
              { coursePrerequisites.map( prereq => (
                <div key={ prereq.prerequisiteId } className='prerequisites-item flex-box'>
                  <a href={prereq.prerequisiteShowPath}>
                    { prereq.prerequisiteTitle }
                  </a>
                </div>
              ))}
            </div>
          </div>
        </Modal>
      }

      { termsModalOpen &&
        <Modal
          visible
          closeModal={ () => setTermsModalOpen(false) }
          mode='large'
          acceptAction={ () => setTermsModalOpen(false) }
          saveText={ translations.general.close }
        >
          <Description
            description={ translation.terms }
          />
        </Modal>
      }

      { showLoginPrompt && enrolmentToken &&
        <LoginPrompt
          appProps={ appProps }
          redirectAfterAuthPath={ entityType === 'MindseedLms::Bundle' ? `${entityShowPath}?enrt=${enrolmentToken}${accessCodeParam}` : (lxpPath ? `${lxpPath}?enrt=${enrolmentToken}` : `${entityShowPath}?enrt=${enrolmentToken}${accessCodeParam}`) }
          onClose={ () => closeLoginPrompt() }
        />
      }
    </>
  )
}
