import React, { useState, useEffect } from 'react'
import { Loader } from '@reactiveonline/frontend_shared_components'
import Modal from '../../shared/Modal'
import { loadStripe } from '@stripe/stripe-js'
import { Elements } from '@stripe/react-stripe-js'
import StripeCheckout from '../shared/StripeCheckout'

const step = 3

export default function PaymentStep({
  appProps, order, setOrder, currentStep, setCurrentStep, loading, setLoading,
  handleErrors, legalTerms, user, billingCountryCode
}) {
  const [acceptedTerms, setAcceptedTerms] = useState(false)
  const [paymentMethods, setPaymentMethods] = useState([])
  const [showTerms, setShowTerms] = useState(false)
  const [stripePromise, setStripePromise] = useState(null)
  const [submitStripeCard, setSubmitStripeCard] = useState(false) // calls completeOrder from stripe component
  const [stripePaymentIntentClientSecret, setStripePaymentIntentClientSecret] = useState(null)
  const [stripePaymentIntentActionType, setStripePaymentIntentActionType] = useState(null)

  const [showPaymentIframe, setShowPaymentIframe] = useState(false)

  const { translations, getPaymentMethodsPath, completeOrderPath, flashMessage } = appProps
  const currentPaymentMethod = paymentMethods.find( paymentMethod => paymentMethod.id === order.paymentMethodId )
  const disableComplete = (!order.paymentMethodId || !acceptedTerms)

  useEffect( () => {
    if (currentStep === step && paymentMethods.length === 0) {
      setLoading(true)

      Rails.ajax({
        url: getPaymentMethodsPath,
        type: 'GET',
        dataType: 'json',
        success: res => {
          if (res.paymentMethods) {
            setPaymentMethods(res.paymentMethods)
          }
        },
        complete: res => {
          setLoading(false)
        }
      })
    }
  }, [currentStep])

  useEffect( () => {
    let paymentMethod = paymentMethods.find( paymentMethod => paymentMethod.id === order.paymentMethodId )
    if (paymentMethod && paymentMethod.type === 'StripePayment' && !stripePromise) {
      setStripePromise(loadStripe(paymentMethod.settings.publishable_key))
    }
  }, [order.paymentMethodId, paymentMethods])

  function setPaymentMethod(paymentMethod) {
    if (!loading && (!currentPaymentMethod || paymentMethod.id !== currentPaymentMethod.id)) {
      setOrder( prevState => ({ ...prevState, paymentMethodId: paymentMethod.id }) )
    }
  }

  function setStripeError() {
    setLoading(false)
    setSubmitStripeCard(false)
    setStripePaymentIntentClientSecret(null)
    setStripePaymentIntentActionType(null)
  }

  function handleTriggerComplete() {
    if (currentPaymentMethod.type === 'StripePayment') {
      setSubmitStripeCard(true)
      setLoading(true)
    } else {
      completeOrder()
    }
  }

  function completeOrder(stripePaymentMethodId = null) {
    if (!disableComplete) {
      setLoading(true)

      let formData = new FormData()
      formData.append('order[payment_method_id]', order.paymentMethodId)
      if (currentPaymentMethod.type === 'StripePayment') {
        formData.append('stripe_payment_method_id', stripePaymentMethodId)
      }

      Rails.ajax({
        url: completeOrderPath,
        type: 'POST',
        dataType: 'json',
        data: formData,
        success: res => {
          if (res.redirect_iframe_url) {
            setShowPaymentIframe(true)
            document.getElementById('payment-iframe').src = res.redirect_iframe_url

          } else if (res.redirect_form) {
            document.body.innerHTML += res.redirect_form
            const form = document.getElementById('payment-redirect-form')
            form.submit()

          } else if (res.redirect_url) {
            window.location.href = res.redirect_url
          } else if((res.requires_action || res.requires_confirmation) && res.payment_intent_client_secret) {
            setStripePaymentIntentClientSecret(res.payment_intent_client_secret)
            setStripePaymentIntentActionType(res.requires_action ? 'requires_action' : 'requires_confirmation')
            setSubmitStripeCard(false)
          }
        },
        error: res => {
          setSubmitStripeCard(false)
          handleErrors(res)
        },
        complete: res => {
          setLoading(false)
        }
      })
    }
  }

  return (
    <div className='step-content'>
      { currentStep === step &&
        <>
          <div className='payment-providers-wrapper'>
            { !loading && paymentMethods.length === 0 &&
              <div>
                { translations.checkout.noPaymentMethods }
              </div>
            }
            { paymentMethods.map( paymentMethod => {
              const translation = paymentMethod.translations
              return (
                <div
                  key={ paymentMethod.id }
                  className={ `payment-provider ${ paymentMethod.id === order.paymentMethodId ? 'selected' : '' } flex-box items-center` }
                  onClick={ () => setPaymentMethod(paymentMethod) }
                >
                  <div className={ `payment-icon ${ paymentMethod.type } flex-box items-center` }/>
                  <div className='flex-box flex-column'>
                    <div className='payment-title'>
                      { paymentMethod.translation.title }
                    </div>
                    { paymentMethod.translation.description &&
                      <div className='payment-description' dangerouslySetInnerHTML={{ __html: paymentMethod.translation.description }}/>
                    }
                  </div>
                </div>
              )
            })}
          </div>
          { currentPaymentMethod && currentPaymentMethod.type === 'StripePayment' &&
            <Elements stripe={ stripePromise }>
              <StripeCheckout
                appProps={ appProps }
                submitStripeCard={ submitStripeCard }
                setStripeError={ setStripeError }
                completeOrder={ completeOrder }
                stripePaymentIntentClientSecret={ stripePaymentIntentClientSecret }
                stripePaymentIntentActionType={stripePaymentIntentActionType}
                orderId={ order.number }
                type={ 'Order' }
                billingDetails={{
                  email: user ? user.email : '',
                  name: order && order.address ? `${order.address.firstName} ${order.address.lastName}` : '',
                  phone: order && order.address ? (order.address.phone || order.address.mobile) : '',
                  address: order && order.address ? order.address.address : '',
                  city: order && order.address ? order.address.city : '',
                  country: order && order.address && billingCountryCode ? billingCountryCode : '',
                  state: order && order.address && order.address.state ? order.address.state.title : '',
                  postalCode: order && order.address ? order.address.postalCode : ''
                }}
              />
            </Elements>
          }

          <div className='complete-step-wrapper flex-box items-center flex-column'>
            { loading ?
              <Loader size='small'/>
            :
              <>
                <div className='flex-box items-center' style={{ margin: '20px 0' }}>
                  <div
                    className={ `fa-light ${ acceptedTerms ? 'fa-square-check' : 'fa-square' } pointer` }
                    style={{ fontSize: 25, marginRight: 10 }}
                    onClick={ () => setAcceptedTerms(!acceptedTerms) }
                  />
                  { translations.checkout.accept }
                  <div className='color-scheme pointer' onClick={ () => setShowTerms(true) }>
                    &nbsp;{ translations.checkout.terms }
                  </div>
                </div>

                <div
                  className={ `button ${ disableComplete ? 'disabled' : '' }` }
                  onClick={ handleTriggerComplete }
                >
                  { translations.checkout.placeOrder }
                </div>
              </>
            }
          </div>

          { showPaymentIframe &&
            <div className='payment-iframe-overlay'>
              <iframe id='payment-iframe' src='' width='500' height='500'/>
            </div>
          }

          { showTerms &&
            <Modal
              visible
              closeModal={ ()=> setShowTerms(false) }
              mode='medium'
              elementsClass='body-bg'
              theme='body-bg'
            >
              <div style={{ marginTop: 20 }} dangerouslySetInnerHTML={{__html: legalTerms }}></div>
            </Modal>
          }

        </>
      }
    </div>
  )
}
