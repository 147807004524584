import React, { useState, useEffect } from 'react'

const resourceIcons = {
  audio: 'fa-light fa-file-audio',
  video: 'fa-light fa-file-video',
  pdf: 'fa-light fa-file-pdf',
  powerpoint: 'fa-light fa-file-powerpoint',
  doc: 'fa-light fa-file-word',
  excel: 'fa-light fa-file-excel',
  csv: 'fa-light fa-file-csv',
  other: 'fa-light fa-file'
}

export default function TaskResources({ taskId, courseUid, courseLocale, resourceProps }) {
  const [resources, setResources] = useState([])
  const [loading, setLoading] = useState(false)

  useEffect( () => {
    setLoading(true)

    Rails.ajax({
      url: `${ resourceProps.fetchResourcesPath }?course_uid=${ courseUid }&task_id=${ taskId }&course_locale=${ courseLocale }`,
      type: 'GET',
      dataType: 'json',
      success: res => {
        setResources(res.resources)
      },
      error: err => {},
      complete: res => {
        setLoading(false)
      }
    })
  }, [taskId])

  function getResourceType(type) {
    switch (true) {
      case /^.*image.*$/.test(type):
        return 'image'

      case /^.*audio.*$/.test(type):
        return 'audio'

      case /^.*video.*$/.test(type):
        return 'video'

      case /^.*csv.*$/.test(type):
        return 'csv'
    }

    switch (type) {
      case 'application/pdf':
        return 'pdf'

      case 'application/vnd.ms-powerpoint':
      case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
        return 'powerpoint'

      case 'application/msword':
      case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
        return 'doc'

      case 'application/vnd.ms-excel':
      case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
        return 'excel'

      default:
        return 'other'
    }
  }

  return (
    <div className='resources-wrapper flex-box flex-wrap'>
      { resources.map( (resource, index) => {
        const type = getResourceType(resource.type)

        return (
          <div key={ index } className='resource flex-box flex-column items-center content-center'>
            <a href={ resource.source } className='download' target='_blank' download>
              <i className='cloud_download_icon'/>
            </a>

            { type === 'image' ?
              <img src={ resource.source } alt={ resource.fileName }/>

            :
              <>
                <i className={ resourceIcons[type] }/>
                <div className='title'>
                  { `${ resource.fileName.slice(0, 10) }${ resource.fileName.length > 10 ? '...' : '' }` }
                </div>
              </>
            }
          </div>
        )
      })}
    </div>
  )
}
