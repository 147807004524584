import React from 'react'
import Modal from '../../shared/Modal'

export default function WebContent({ appProps, task, contentUrl, fullScreen, setFullScreen }) {

  if (task.settings.urlOpenMethod === 'iframe') {
    return (
      <>
        { fullScreen &&
          <Modal
            mode='fullpage'
            visible
            closeModal={ () => setFullScreen(false) }
          >
            <iframe
              width='100%'
              height='100%'
              src={ contentUrl }
            />
          </Modal>
        }

        { !fullScreen &&
          <iframe
            width='100%'
            height='100%'
            src={ contentUrl }
          />
        }
      </>
    )

  } else {
    return (
      <div className='flex-box items-center content-center' style={{ width: '100%', height: '100%' }}>
        <div className='launch-content' style={{ backgroundImage: `url(${ task.settings.featuredImage ? task.settings.featuredImage.source : 'https://storage.googleapis.com/mindseed_files/lxp/component_web_link_placeholder.webp' })` }}/>
        <div className='button launch' onClick={ () => window.open(contentUrl) }>
          { appProps.translations.lxp.launch_content }
        </div>
      </div>
    )
  }
}
