import React, { useState, useEffect, useRef } from 'react'
import DropMenu from './DropMenu'

export default function NotificationsDropMenu({ appProps, selectedDropMenu, setSelectedDropMenu }) {
  const [notifications, setNotifications] = useState([])
  const [hasUnreadNotifications, setHasUnreadNotifications] = useState(false)

  const didFetchNotificationsRef = useRef(false)
  const { translations } = appProps

  useEffect( () => {
    if ((selectedDropMenu === 'notifications') && !didFetchNotificationsRef.current) {
      fetchNotifications()
    }
  }, [selectedDropMenu])

  function fetchNotifications() {
    Rails.ajax({
      url: appProps.latestNotificationsPath,
      type: 'GET',
      dataType: 'json',
      success: res => {
        setNotifications(res.notifications)

        if (res.notifications.length > 0 && res.notifications.find( notification => !notification.isRead )) {
          setHasUnreadNotifications(true)
        }

        didFetchNotificationsRef.current = true
      }
    })
  }

  function deleteNotification(uid) {
    Rails.ajax({
      type: 'DELETE',
      url: `${ appProps.notificationsPath }/${uid}`,
      dataType: 'json',
      success: res =>{
        setNotifications(notifications.filter(n => n.uid != uid ))
        appProps.flashMessage.show(translations.notifications.deleted_successfully, 'success')
      }
    })
  }

  function markAsRead(item = null) {
    let fd = new FormData()
    fd.append('id', item ? item.uid : null)
    fd.append('mark_all', !item)

    Rails.ajax({
      type: 'PATCH',
      url: appProps.markNotificationAsReadPath,
      dataType: 'json',
      data: fd,
      success: res => {
        if (item) {
          let updatedNotifications = [...notifications]
          const notificationIndex = updatedNotifications.findIndex( notification => notification.uid === item.uid )

          if (notificationIndex >= 0) {
            updatedNotifications.splice(notificationIndex, 1, { ...updatedNotifications[notificationIndex], isRead: true })
          }

          setNotifications(updatedNotifications)

        } else {
          setHasUnreadNotifications(false)
          setNotifications(notifications.map( notification => ({ ...notification, isRead: true }) ))
        }
      }
    })

    if (item) {
      window.location.href = item.path
    }
  }

  function itemRenderer(item, index) {
    return (
      <div key={ index } className='flex-box' style={{ marginBottom: 20 }}>
        <i className='fa-light fa-bell'/>

        <div className='flex-1 flex-box flex-column items-start pointer' onClick={ () => markAsRead(item) }>
          <a className='clickable-link title' href={ item.path }>
            { item.title }
          </a>

          { item.body &&
            <div className='description'>
              { item.body }
            </div>
          }

          <div className='info' style={{ fontWeight: (item.isRead ? '' : 'bold') }}>
            { item.sentBefore }
          </div>
        </div>

        <div>
          <i className='fa-light fa-trash' onClick={() => deleteNotification(item.uid)}/>
          { !item.isRead &&
            <i className="fa-sharp fa-solid fa-circle" style={{color: appProps.colorScheme, marginLeft:10}}/>
          }
        </div>
      </div>
    )
  }

  return (
    <li className='account drop-menu' id='drop-menu'>
      <div className='menu-handler notification_icon'/>

      <DropMenu
        dropMenuData={ notifications || [] }
        title={ translations.general.notifications }
        titleButtonText= { translations.general.mark_all_as_read }
        titleButtonPath={ null }
        titleAction={ () => markAsRead() }
        action={ appProps.notificationsPath  }
        actionText={ translations.general.show_all }
        menuPosition={ 'main-header' }
        selected={ selectedDropMenu === 'notifications' }
        setSelected={ setSelectedDropMenu }
        menuKey='notifications'
        itemRenderer={ itemRenderer }
        colorScheme={ appProps.colorScheme }
        hasUnreadNotifications={ hasUnreadNotifications }
      />
    </li>
  )
}
