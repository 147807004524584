import React, { useState, useEffect } from 'react'
import { Loader } from '@reactiveonline/frontend_shared_components'

export default function Profile({ pageName, appProps, pageTitle }) {
  const [biography, setBiography] = useState(appProps.user.biography || '')
  const [loading, setLoading] = useState(false)

  const { translations } = appProps

  function saveChanges() {
    if(biography === '') {
      return
    }

    setLoading(true)

    let formData = new FormData()
    formData.append('account[biography]', biography)

    Rails.ajax({
      type: 'PATCH',
      url: `/${appProps.currentLocale}/account`,
      data: formData,
      dataType: 'json',
      success: res => {
        appProps.flashMessage.show(res.message, 'success')
      },
      error: res => {
        appProps.flashMessage.show(res.error, 'error')
      },
      complete: res => {
        setLoading(false)
      }
    })
  }

  return (
    <>
      <div className='section-title'>
        <h3>{ pageTitle }</h3>
      </div>
      <div className={ `account ${pageName}` }>
        <div className='avatar-wrapper'>
          <div className='avatar pointer' onClick={ ()=> window.open('/account/manage', '_blank') }>
            <img src={appProps.user.avatar || 'https://storage.googleapis.com/mindseed_files/lxp/avatar_icon.webp'} />
          </div>
        </div>

        <div className='fields-wrapper'>
          <div className='field'>
            <label>{ translations.account.name }</label>
            <div className='flex-box items-center'>
              { appProps.user.fullname }
              <i style={{ marginLeft: 5 }} className='pointer clickable-link fa-light fa-pen-to-square' onClick={ ()=> window.open('/account/manage', '_blank') } />
            </div>
          </div>

          <div className='field'>
            <label>{ translations.account.email }</label>
            <div className='flex-box items-center'>
              { appProps.user.email }
              <i style={{ marginLeft: 5 }} className='pointer clickable-link fa-light fa-pen-to-square' onClick={ ()=> window.open('/account/manage', '_blank') } />
            </div>
          </div>

          <div className='field'>
            <a href="/account/manage" rel='nofollow noindex noreferrer' target="_blank">
              { translations.account.manage_account }
            </a>
          </div>

          <div className='field'>
            <label>{ translations.account.bio }</label>
            <textarea value={biography} onChange={e => setBiography(e.target.value)} />
          </div>
        </div>

        {loading && <Loader fullPage />}

        <div className='button-wrapper'>
          <button className='button' onClick={saveChanges}>
            { translations.general.save_changes }
          </button>
        </div>
      </div>
    </>
  )
}
