import React from 'react'

export default function NoteCard({ icon, textUp, textDown, additionalClass }) {
  return (
    <div className = 'flex-box flex-1 card-item'>
      <div className = {`icon-container flex-box content-center items-center ${additionalClass}`}>
        <div className={ `${ icon } icon` }/>
      </div>
      <div className = 'notecard_texts flex-box flex-column'>
        <div>{textUp}</div>
        <div className = 'text-down'> { textDown } </div>
      </div>
    </div>
  )
}
