import React from 'react'
import ListItem from '../shared/ListItem'
import TabsIndex from '../shared/TabsIndex'
import Table from '../shared/Table'
import { NO_IMAGE_SOURCE } from '../GlobalConstants'
import { formatDate } from '../../helpers/utils'

export default function Events({ appProps, screenClass, pageTitle, events, courseLocale }) {
  const { translations } = appProps

  const headers = [
    translations.general.date,
    translations.general.title,
    translations.general.type,
    translations.general.venue,
    translations.general.duration,
    translations.general.instructors
  ]

  const filters  = [
    // { uid: 1, text: 'Upcoming' },
    // { uid: 2, text: 'Past' }
  ]

  return (
    <div className={` lxp-wrapper ${ screenClass }`}>
      <TabsIndex
        appProps={ appProps }
        pageTitle={ pageTitle }
      />

      <div className='section body-bg'>
        <div className='main-container'>
          <Table
            appProps={ appProps }
            heading={ null }
            filters={ null }
            headers={ headers }
            rows={ events.map( event => ({ ...event, date: formatDate(event.date) }) ) }
            courseLocale={ courseLocale }
            screenClass={ screenClass }
          />
        </div>
      </div>

    </div>
  )
}
