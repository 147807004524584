import React, { useState, useEffect, useRef, lazy, Suspense } from 'react'
import TaskTabs from './TaskTabs'
// import Instructors from '../shared/Instructors'
import { getTaskType } from './utils'
import { lrsEvent } from '../../helpers/utils'
import { Loader } from '@reactiveonline/frontend_shared_components'

let Task = () => <div/>

const tasks = {
  youtube: 'MediaPlayer',
  vimeo: 'MediaPlayer',
  video: 'MediaPlayer',
  onsiteEvent: 'OnSiteEvent',
  audio: 'Audio',
  timer: 'Timer',
  post: 'Post',
  pdf: 'Pdf',
  pageBuilder: 'PageBuilder',
  breathing: 'Breathing',
  zoom: 'RemoteEvent',
  teams: 'RemoteEvent',
  scorm: 'Scorm',
  webContent: 'WebContent',
  mindfulnessVideo: 'MediaPlayer'
}

export default function TaskContent({
  appProps, user, course, showSidebar, setShowSidebar, currentTask, courseLocale,
  currentChapterId, rootTasks, fetchNotePath, saveBookmarkPath,
  commentsPath, resourceProps, completeTask, currentChapterInstructors, practices,
  changeTask, isDesktop, tabIndex, setTabIndex, fetchSettingsPath,
  fetchInstructorsPath, fetchScormContentPath, autoCompletedTasks, translation,
  disableChangeTask, setDisableChangeTask, handleChangeTab, uploadUserFilePath,
  fetchUserUploadsPath, sendCommentReplyPath, fetchRepliesPath, deleteUploadPath,
  settingsTranslation, userUploadInfo, setUserUploadInfo, downloadUserFilePath, validateFilePath,
  bundleShowPath, bundleTitle, showCloseIcon, taskIndex, navigate
}) {
  const [showNavigations, setShowNavigations] = useState(false)
  const [socketHeight, setSocketHeight] = useState(0)
  const [loading, setLoading] = useState(false)
  const [fullScreen, setFullScreen] = useState(false)
  const [loadingTask, setLoadingTask] = useState(false)

  const { translations, bookmarks, setCurrentAppProps } = appProps
  const currentBookmark = currentTask && bookmarks.find( bookmark => bookmark.markableId == currentTask.id && bookmark.markableType === 'Task')
  const previousTaskId = usePrevious(currentTask ? currentTask.id : null)

  function usePrevious(value) {
    const ref = useRef()
    useEffect(() => {
      ref.current = value
    })
    return ref.current
  }

  useEffect( () => {
    if (currentTask && previousTaskId !== currentTask.id) {
      setDisableChangeTask(true)
      setTimeout(() => {
        setDisableChangeTask(false)
      },1000)
      setLoadingTask(true)
      Task = lazy( () => import(`./tasks/${ tasks[currentTask.contentType] }`) )
      setTimeout( () => setLoadingTask(false), 100)
    }
  }, [currentTask])

  useEffect( () => {
    setSocketHeight(document.getElementsByClassName('content-socket')[0].clientHeight)
  }, [showSidebar])

  function getMediaPath() {
    if (['audio', 'video', 'timer'].includes(currentTask.contentType) || (currentTask.contentType == 'mindfulnessVideo' && currentTask.settings.videoType == 'video')) {
      return (
        settingsTranslation && settingsTranslation.media &&
        settingsTranslation.media.length && settingsTranslation.media[0].source
      )

    } else if (['youtube', 'vimeo'].includes(currentTask.contentType) || (currentTask.contentType == 'mindfulnessVideo' && ['youtube', 'vimeo'].includes(currentTask.settings.videoType))) {
      return settingsTranslation && settingsTranslation.video

    } else if (currentTask.contentType === 'scorm') {
      return (
        settingsTranslation && settingsTranslation.media &&
        settingsTranslation.media.length && settingsTranslation.media[0].source
      )
    }
  }

  function getMediaId() {
    return settingsTranslation && settingsTranslation.media &&
      settingsTranslation.media.length && settingsTranslation.media[0].mediaId || null
  }

  function renderTask() {
    if (currentTask) {
      let taskIsCompleted = practices.find( practice => practice.taskId == currentTask.id )
      if (['video', 'youtube', 'vimeo', 'mindfulnessVideo'].includes(currentTask.contentType)) {
        return (
          <Task
            appProps={ appProps }
            task={ currentTask }
            mediaUrl={ getMediaPath() }
            mediaId={ getMediaId() }
            courseLocale={ courseLocale }
            completeTask={ (sessionUid, lowerCompletionRate, duration) => completeTask(currentTask.id, {
              isCompleted: false,
              isRoot: !!currentTask.isRoot,
              sessionUid: sessionUid,
              lowerCompletionRate: lowerCompletionRate,
              duration: duration
            })}
            courseUid={ course.uid }
            taskIsCompleted={ taskIsCompleted }
          />
        )
      } else if (currentTask.contentType === 'post') {
        return (
          <Task
            task={ currentTask }
            courseLocale={ courseLocale }
            socketHeight={ socketHeight }
            fullScreen={ fullScreen }
            setFullScreen={ setFullScreen }
          />
        )
      } else if (currentTask.contentType === 'pageBuilder') {
        return (
          <Task
            task={ currentTask }
            courseLocale={ courseLocale }
            translations={ translations }
            appProps={ appProps }
          />
        )
      } else if (currentTask.contentType === 'pdf') {
        return (
          <Task
            task={ currentTask }
            courseLocale={ courseLocale }
          />
        )
      } else if (currentTask.contentType === 'timer') {
        return (
          <Task
            appProps={ appProps }
            courseUid={ course.uid }
            task={ currentTask }
            courseLocale={ courseLocale }
            mediaUrl={ getMediaPath() }
            mediaId={ getMediaId() }
            completeTask={ completeTask }
            translations={ translations }
            taskIsCompleted={ taskIsCompleted }
          />
        )
      } else if (currentTask.contentType === 'audio') {
        return (
          <Task
            appProps={ appProps }
            courseUid={ course.uid }
            task={ currentTask }
            courseLocale={ courseLocale }
            mediaUrl={ getMediaPath() }
            mediaId={ getMediaId() }
            completeTask={ completeTask }
            translations={ translations }
            taskIsCompleted={ taskIsCompleted }
          />
        )
      } else if (currentTask.contentType === 'onsiteEvent') {
        return (
          <Task
            task={ currentTask }
            courseLocale={ courseLocale }
            translations={ translations }
            fetchSettingsPath={ fetchSettingsPath }
            courseUid={ course.uid }
            fetchInstructorsPath={ fetchInstructorsPath }
          />
        )
      } else if (currentTask.contentType === 'breathing') {
        return (
          <Task
            task={ currentTask }
            courseUid={ course.uid }
            translations={ translations }
            appProps={ appProps }
            completeTask={ completeTask }
            taskIsCompleted={ taskIsCompleted }
          />
        )
      } else if (['zoom', 'teams'].includes(currentTask.contentType)) {
        return (
          <Task
            task={ currentTask }
            courseLocale={ courseLocale }
            translations={ translations }
            fetchSettingsPath={ fetchSettingsPath }
            courseUid={ course.uid }
            fetchInstructorsPath={ fetchInstructorsPath }
          />
        )
      } else if (currentTask.contentType === 'scorm') {
        return (
          <Task
            appProps={ appProps }
            task={ currentTask }
            courseLocale={ courseLocale }
            mediaUrl={ getMediaPath() }
            mediaId={ getMediaId() }
            fetchScormContentPath={ fetchScormContentPath }
            courseUid={ course.uid }
            completeTask={ completeTask }
            isCompleted={ practices.find( practice => practice.taskId == currentTask.id ) }
          />
        )
      } else if (currentTask.contentType === 'webContent') {
        return (
          <Task
            appProps={ appProps }
            task={ currentTask }
            contentUrl={ settingsTranslation.url }
            fullScreen={ fullScreen }
            setFullScreen={ setFullScreen }
          />
        )
      }
    }
  }

  function handleBookmark(){
    if (!loading) {
      setLoading(true)

      let formData = new FormData()

      if (currentBookmark) {
        formData.append('id', currentBookmark.id)
      } else {
        formData.append('markable_type', 'Task')
        formData.append('markable_id', currentTask.id)
        formData.append('course_uid', course.uid)
      }

      Rails.ajax({
        url: saveBookmarkPath,
        type: 'POST',
        data: formData,
        dataType: 'json',
        success: res => {
          if (currentBookmark) {
            setCurrentAppProps({ bookmarks: bookmarks.filter( bookmark => bookmark.id !== currentBookmark.id ) })
          } else if (res.bookmark) {
            setCurrentAppProps({ bookmarks: [ ...bookmarks, res.bookmark ] })
            lrsEvent(appProps, currentTask.settings.translations, res.bookmark.id, 'bookmark', 'created', {
              associableUid: course.uid
            })
          }
        },
        error: err => {},
        complete: res => {
          setLoading(false)
        }
      })
    }
  }

  function handleComment() {
    setTabIndex(2)
    const elements = document.getElementsByClassName('task-content-bottom-container')
    if (elements) {
      elements[0].scrollIntoView({ behavior: 'smooth' })
    }
  }

  return (
    <div className={ `task-content-container ${ showSidebar ? '' : 'fullscreen' }` }>
      <div className='content-socket-container'>
        <div
          className='content-socket'
          onMouseEnter={ () => setShowNavigations(true) }
          onMouseLeave={ () => setShowNavigations(false) }
        >
          { currentTask &&
            <Suspense fallback={ <div/> }>
              { (loadingTask || (currentTask.id !== previousTaskId)) ?
                <Loader
                  absolute
                  size='medium'
                  position='center'
                />
              :
                renderTask()
              }
            </Suspense>
          }

          { !showSidebar && showCloseIcon &&
            <div className='content-sidebar-icon back_icon' onClick={ () => setShowSidebar(true) }/>
          }

          { showNavigations && currentTask && currentTask.isRoot &&
            <>
              { taskIndex > 0 &&
                <div
                  className='content-navigation previous flex-box content-center items-center'
                  onClick={ () => navigate('previous') }
                >
                  <i className='fa-regular fa-chevron-left'/>
                </div>
              }

              { taskIndex < (rootTasks.length - 1) &&
                <div
                  className='content-navigation next flex-box content-center items-center'
                  onClick={ () => navigate('next') }
                >
                  <i className='fa-regular fa-chevron-right'/>
                </div>
              }
            </>
          }
        </div>

        <div className='content-socket-footer flex-box flex-column'>
          { currentTask &&
            <>
              <div className='content-user-options flex-box flex-1 content-space-between items-center'>
                <div className='left-options'>
                  <i className={ getTaskType(currentTask.contentType) }/>
                  { settingsTranslation.title }
                </div>

                <div className='right-options flex-box items-center'>
                  <div
                    className={ `option ${ currentBookmark ? 'bookmark_icon_filled' : 'bookmark_icon' }` }
                    onClick={ handleBookmark }
                  />
                  { (currentTask.contentType === 'post' || (currentTask.contentType === 'webContent' && currentTask.settings.urlOpenMethod === 'iframe')) &&
                    <div className='option fullscreen_icon' style={{ marginLeft: 15 }} onClick={ () => setFullScreen(true) }></div>
                  }
                </div>
              </div>
            </>
          }
        </div>
      </div>

      <div className='task-content-bottom-container'>
        <TaskTabs
          appProps={ appProps }
          tabIndex={ tabIndex }
          setTabIndex={ setTabIndex }
          user={ user }
          course={ course }
          currentTask={ currentTask }
          fetchNotePath={ fetchNotePath }
          commentsPath={ commentsPath }
          courseLocale={ courseLocale }
          resourceProps={ resourceProps }
          showSidebar={ showSidebar }
          practices={ practices }
          completeTask={ completeTask }
          currentChapterId ={ currentChapterId }
          changeTask={ changeTask }
          isDesktop={ isDesktop }
          autoCompletedTasks={ autoCompletedTasks }
          translation={ translation }
          handleChangeTab={ handleChangeTab }
          uploadUserFilePath={ uploadUserFilePath }
          fetchUserUploadsPath={ fetchUserUploadsPath }
          sendCommentReplyPath={ sendCommentReplyPath }
          fetchRepliesPath={ fetchRepliesPath }
          deleteUploadPath={ deleteUploadPath }
          settingsTranslation={ settingsTranslation }
          userUploadInfo={ userUploadInfo }
          setUserUploadInfo={ setUserUploadInfo }
          downloadUserFilePath={ downloadUserFilePath }
          validateFilePath={ validateFilePath }
          bundleShowPath={ bundleShowPath }
          bundleTitle={ bundleTitle }
          showCloseIcon={ showCloseIcon }
          showSidebar={ showSidebar }
          setShowSidebar={ setShowSidebar }
        />

      {/* (currentChapterInstructors.length > 0 || course.instructors.length > 0) &&
        <div style={{ marginTop: 40 }}>
          <div className='show instructor-title' style={{ marginBottom: 20, marginTop: 20 }}>
            { translations.general.instructors }
          </div>
          <Instructors
            instructors={ currentChapterInstructors.length > 0 ? currentChapterInstructors : course.instructors }
            screenClass={ 'lxp-show' }
            appProps={ appProps }
          />
        </div>
      */}
      </div>
    </div>
  )
}
