import React from 'react'
import { Loader, InputField } from '@reactiveonline/frontend_shared_components'

const step = 1

export default function ContactStep({
  appProps, order, setOrder, currentStep, setCurrentStep, loading, setLoading,
  renderStepOverview, handleErrors, phoneRequired, mobileRequired
}) {
  const { translations, updateCheckoutContactPath, flashMessage } = appProps
  const { address } = order

  const disableContinue = !(validatePhoneNumber(address.mobile || '') && validatePhoneNumber(address.phone || ''))

  const stepOverview = [
    { title: translations.checkout.email,     value: order.email },
    { title: translations.checkout.firstName, value: address.firstName },
    { title: translations.checkout.lastName,  value: address.lastName },
    { title: translations.checkout.mobile,    value: address.mobile },
    { title: translations.checkout.phone,     value: address.phone }
  ]

  function setAddress(state) {
    if (!loading) {
      setOrder( prevState => ({ ...prevState, address: { ...prevState.address, ...state } }) )
    }
  }

  function handleChangePhone(event, phoneType) {
    if(phoneType == 'mobile') {
      setAddress({ mobile: event.target.value })
      if(event.target.value == ''){
        setMobileError(false)
      }
      else{
        if(validatePhoneNumber(event.target.value)) {
          if(mobileError) {
            setMobileError(false)
          }
        }
        else{
          setMobileError(true)
        }
      }
    }
    else if(phoneType == 'phone') {
      setAddress({ phone: event.target.value })
      if(event.target.value == ''){
        phoneError(false)
      }
      else{
        if(validatePhoneNumber(event.target.value)) {
          if(phoneError) {
            setPhoneError(false)
          }
        }
        else{
          setPhoneError(true)
        }
      }
    }
  }

  function validatePhoneNumber(phoneFieldValue) {
    const pattern = /^(\+?\d{4,16})?$/g
    return pattern.test(phoneFieldValue)
  }

  function validateAndProceed() {
    if (!disableContinue) {
      setLoading(true)

      let formData = new FormData()
      formData.append('address[first_name]', address.firstName ? address.firstName.trim() : '')
      formData.append('address[last_name]', address.lastName ? address.lastName.trim() : '')
      formData.append('address[mobile]', address.mobile ? address.mobile.trim() : '')
      formData.append('address[phone]', address.phone ? address.phone.trim() : '')

      Rails.ajax({
        url: updateCheckoutContactPath,
        type: 'POST',
        dataType: 'json',
        data: formData,
        success: res => {
          if (res.order) {
            setOrder(res.order)
            setCurrentStep(2)
          }
        },
        error: res => {
          handleErrors(res)
        },
        complete: res => {
          setLoading(false)
        }
      })
    }
  }

  return (
    <div className='step-content'>
      { currentStep > step && renderStepOverview(stepOverview) }

      { currentStep === step &&
        <>
          <InputField
            label={ `<div class='flex-box'>${ translations.checkout.email }&nbsp;<div class='color-scheme'>*</div></div>` }
            parseLabel
          >
            <input
              type='text'
              value={ order.email }
              disabled
            />
          </InputField>

          <InputField
            label={ `<div class='flex-box'>${ translations.checkout.firstName }&nbsp;<div class='color-scheme'>*</div></div>` }
            parseLabel
          >
            <input
              type='text'
              value={ address.firstName || '' }
              onChange={ event => setAddress({ firstName: event.target.value }) }
            />
          </InputField>

          <InputField
            label={ `<div class='flex-box'>${ translations.checkout.lastName }&nbsp;<div class='color-scheme'>*</div></div>` }
            parseLabel
          >
            <input
              type='text'
              value={ address.lastName || '' }
              onChange={ event => setAddress({ lastName: event.target.value }) }
            />
          </InputField>

          <InputField
            label={ `<div class='flex-box'>${ translations.checkout.mobile }&nbsp;${ mobileRequired ? '<div class="color-scheme">*</div>' : '' } </div>` }
            parseLabel
          >
            <input
              type='text'
              value={ address.mobile || '' }
              onChange={ event => setAddress({ mobile: event.target.value }) }
            />

            { !validatePhoneNumber(address.mobile || '') &&
              <div style={{ color: '#e40046' }}>
                { translations.checkout.mobileError }
              </div>
            }
          </InputField>

          <InputField
            label={ `<div class='flex-box'>${ translations.checkout.phone }&nbsp;${ phoneRequired ? '<div class="color-scheme">*</div>' : '' } </div>` }
            parseLabel
          >
            <input
              type='text'
              value={ address.phone || '' }
              onChange={ event => setAddress({ phone: event.target.value }) }
            />

            { !validatePhoneNumber(address.phone || '') &&
              <div style={{ color: '#e40046' }}>
                { translations.checkout.phoneError }
              </div>
            }
          </InputField>

          <div className='complete-step-wrapper flex-box content-center'>
            { loading ?
              <Loader size='small'/>
            :
              <div
                className={ `button ${ disableContinue ? 'disabled' : '' }` }
                onClick={ validateAndProceed }
              >
                { translations.checkout.continue }
              </div>
            }
          </div>
        </>
      }
    </div>
  )
}
