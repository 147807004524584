export function getTaskType(taskType) {
  const taskTypeIcons = {
    youtube: 'video_icon',
    vimeo: 'video_icon',
    video: 'video_icon',
    breathing: 'wind_icon',
    onsiteEvent: 'events_icon',
    timer: 'meditation_timer_icon',
    post: 'post_icon',
    pdf: 'pdf_icon',
    pageBuilder: 'builder_icon',
    audio: 'audio_icon',
    zoom: 'webcam_icon',
    teams: 'webcam_icon',
    scorm: 'zip_icon',
    webContent: 'webContent_icon',
    mindfulnessVideo: 'mindfulnessVideo_icon'
  }

  return taskTypeIcons[taskType]
}
