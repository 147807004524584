import React, { useState, useEffect, useCallback } from "react"

export default function Select({ appProps, selectName, selectorHeading, options, includeNone, initialItemUid, selectCallback }) {
  const [dropMenuOpen, setDropMenuOpen] = useState(null)
  const [selectedItem, setSelectedItem] = useState(initialItemUid ? options.find( (option) => initialItemUid == option.uid ) : null)

  const escFunction = useCallback((event) => {
    if (event.key === "Escape") {
      setDropMenuOpen(false)
    }
  }, []);

  useEffect(() => {
    document.addEventListener("keydown", escFunction, false);
    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
  }, []);

  return (
    <>
      <div className='select-drop-menu-wrapper flex-box'>
        <div className='select-drop-menu-wrapper'>
          <div className={`select-label flex-box flex-row-reverse items-center content-end ${dropMenuOpen ? 'up_icon' : 'down_icon'}`}
               onClick={ ()=> {
                 if (dropMenuOpen) {
                  setDropMenuOpen(null)
                } else {
                  setDropMenuOpen(selectName)
                }
               }}>
            <div style={{ marginRight: 10 }}>
              { selectedItem && (selectedItem.text || selectedItem.title) ?
                <div className='flex-box items-center'>
                  { selectedItem && selectedItem.icon &&
                    <i className={ selectedItem && selectedItem.icon } style={{ width: 20 }}></i>
                  }
                  <div>{ selectedItem.text || selectedItem.title }</div>
                </div>
                :
                selectorHeading }
            </div>
          </div>
          { dropMenuOpen &&
            <div className='select-drop-menu flex-box flex-column'>
              <div className='select-drop-menu-options'>
                <>
                  { includeNone &&
                    <div className='select-drop-menu-option flex-box items-center'
                         onClick={ () => {
                             setSelectedItem(null)
                             setDropMenuOpen(null)
                           }
                         }>
                      <div>
                        -
                      </div>
                    </div>
                  }
                  { options.map( (option, index) => {
                    return (
                      <div key={ index }
                           className='select-drop-menu-option flex-box items-center'
                           style={ selectedItem && selectedItem.uid == option.uid ? { color: appProps.colorScheme } : {} }
                           onClick={ () => {
                               setSelectedItem(option)
                               setDropMenuOpen(null)
                               selectCallback(option)
                             }
                           }>
                        { option.icon &&
                          <i className={ option.icon } style={{ width: 20 }}></i>
                        }
                        <div>
                          { option.text || option.title }
                        </div>
                      </div>
                    )
                  })}
                </>
              </div>
            </div>
          }
        </div>
      </div>
    </>
  )
}
