import React, { useState, useEffect } from 'react'
import LoginStep from './steps/LoginStep'
import ContactStep from './steps/ContactStep'
import BillingStep from './steps/BillingStep'
import PaymentStep from './steps/PaymentStep'

export default function Steps({
  appProps, order, setOrder, loading, setLoading, handleErrors, legalTerms,
  phoneRequired, mobileRequired
}) {
  const [currentStep, setCurrentStep] = useState(null)
  const [billingCountryCode, setBillingCountryCode] = useState(null)

  const { user, translations } = appProps

  const stepTitles = [
    translations.checkout.loginStep,
    translations.checkout.contactStep,
    translations.checkout.billingStep,
    translations.checkout.paymentStep
  ]

  useEffect( () => {
    if (!user) {
      setCurrentStep(0)
    } else {
      if (!order.address.firstName || !order.address.lastName || (phoneRequired && !order.address.phone) || (mobileRequired && !order.address.mobile) ) {
        setCurrentStep(1)
      } else if (
        !order.address.address || !order.address.city || !order.address.postalCode ||
        !order.address.country.id || (order.address.country.statesRequired && !order.address.state.id) ||
        (order.address.isCompany && !order.address.companyName)
      ) {
        setCurrentStep(2)
      } else {
        setCurrentStep(3)
      }
    }
  }, [])

  function changeDetails(step) {
    if (!loading) {
      setCurrentStep(step)
    }
  }

  function renderStepOverview(fields) {
    return (
      <div className='step-overview flex-box'>
        <div display='flex-box flex-1 flex-column'>
          { fields.map( (field, index) => {
            if (field.value) {
              return (
                <div key={ index } className='field-title'>
                  { field.title }:
                </div>
              )
            }
          })}
        </div>

        <div display='flex-box flex-1 flex-column'>
          { fields.map( (field, index) => {
            if (field.value) {
              return (
                <div key={ index } className='field-value'>
                  { field.value }
                </div>
              )
            }
          })}
        </div>
      </div>
    )
  }

  function renderStep(step) {
    return (
      <div className='step flex-box flex-column'>
        <div className='step-header flex-box items-center'>
          <div className='status-wrapper'>
            <div className={ `status flex-box content-center items-center ${ step === currentStep ? 'active' : '' } ${ currentStep > step ? 'completed' : '' }` }>
              { currentStep > step &&
                <i className='check_icon'/>
              }
            </div>
          </div>

          <div className='step-title-wrapper flex-box flex-1 content-space-between'>
            <div className={ `step-title ${ step === currentStep ? 'active' : '' }` }>
              { stepTitles[step] }
            </div>

            { currentStep > step &&
              <div className='step-edit color-scheme' onClick={ () => changeDetails(step) }>
                { translations.checkout.changeDetails }
              </div>
            }
          </div>
        </div>

        { currentStep === 0 && currentStep === step &&
          <LoginStep
            appProps={ appProps }
          />
        }

        { step === 1 &&
          <ContactStep
            appProps={ appProps }
            order={ order }
            setOrder={ setOrder }
            currentStep={ currentStep }
            setCurrentStep={ setCurrentStep }
            loading={ loading }
            setLoading={ setLoading }
            renderStepOverview={ renderStepOverview }
            handleErrors={ handleErrors }
            phoneRequired={ phoneRequired }
            mobileRequired={ mobileRequired }
          />
        }

        { step === 2 &&
          <BillingStep
            appProps={ appProps }
            order={ order }
            setOrder={ setOrder }
            currentStep={ currentStep }
            setCurrentStep={ setCurrentStep }
            loading={ loading }
            setLoading={ setLoading }
            renderStepOverview={ renderStepOverview }
            handleErrors={ handleErrors }
            setBillingCountryCode={ setBillingCountryCode }
          />
        }

        { step === 3 &&
          <PaymentStep
            appProps={ appProps }
            order={ order }
            setOrder={ setOrder }
            currentStep={ currentStep }
            setCurrentStep={ setCurrentStep }
            loading={ loading }
            setLoading={ setLoading }
            handleErrors={ handleErrors }
            legalTerms={ legalTerms }
            user={ user }
            billingCountryCode={ billingCountryCode }
          />
        }

        { step !== 3 &&
          <div className='section-divider'/>
        }
      </div>
    )
  }

  return (
    <div className='steps'>
      { !user && renderStep(0) }
      { renderStep(1) }
      { renderStep(2) }
      { renderStep(3) }
    </div>
  )
}
