import React, { useState, useEffect } from 'react'
import TabItem from '../shared/TabItem'
import ListItem from '../shared/ListItem'
import Modal from '../shared/Modal'
import MediaPlayer from '../task/tasks/MediaPlayer'
import { NO_IMAGE_SOURCE } from '../GlobalConstants'

export default function VideoPractices({
  appProps, screenClass, videoPractices, courseLocale, defaultLocale, requestedEntity,
  timerEnabled, breathings
}) {
  const [openVideoModal, setOpenVideoModal] = useState(false)
  const [openDescriptionModal, setOpenDescriptionModal] = useState(false)
  const [selectedVideo, setSelectedVideo] = useState(null)
  const [mediaId, setMediaId] = useState(null)
  const [mediaUrl, setMediaUrl] = useState(null)
  const { translations } = appProps

  const tabData = timerEnabled ?
    [
      { title: translations.mindfulness.guided_meditations, path: appProps.mindfulnessPath },
      { title: translations.mindfulness.meditation_timer, path: appProps.silentTimerPath }
    ]
  :
    []

  if (breathings.length > 0) {
    tabData.push({ title: translations.breathing.breathing_guide, path: appProps.breathingsPath })
  }

  tabData.push({ title: translations.mindfulness.mindfulness_videos, path: appProps.videoPracticesPath })

  useEffect( () => {
    if (requestedEntity) {
      playVideo(requestedEntity)
    }
  }, [])

  useEffect(()=>{
    if(selectedVideo){
      let currentMediaId = selectedVideo.content.settings.translations[appProps.currentLocale] &&
        selectedVideo.content.settings.translations[appProps.currentLocale].media &&
        selectedVideo.content.settings.translations[appProps.currentLocale].media.length > 0 &&
        selectedVideo.content.settings.translations[appProps.currentLocale].media[0].mediaId || null

      setMediaId(currentMediaId)
      setMediaUrl(getMediaPath())
    }
  },[selectedVideo])

  function playVideo(video, event = null) {
    if (event) {
      event.preventDefault()
    }
    if(openDescriptionModal){
      setOpenDescriptionModal(false)
    }
    setSelectedVideo(video)
    setOpenVideoModal(true)
  }

  function showDescription(video, event = null) {
    if (event) {
      event.preventDefault()
    }
    setSelectedVideo(video)
    setOpenDescriptionModal(true)
  }

  function closeVideoModal(){
    setSelectedVideo(null)
    setOpenVideoModal(false)
  }

  function closeDescriptionModal(){
    setSelectedVideo(null)
    setOpenDescriptionModal(false)
  }

  function getTranslation(translations) {
    if (courseLocale && translations[courseLocale]) {
      return translations[courseLocale]
    } else if (appProps.currentLocale && translations[appProps.currentLocale]) {
      return translations[appProps.currentLocale]
    } else if (defaultLocale && translations[defaultLocale]) {
      return translations[defaultLocale]
    } else {
      return translations[Object.keys(translations)[0]]
    }
  }

  function getMediaPath() {
    if (selectedVideo && selectedVideo.content.settings.videoType == 'video') {
      return (
        selectedVideo.content.settings.translations[appProps.currentLocale] &&
        selectedVideo.content.settings.translations[appProps.currentLocale].media &&
        selectedVideo.content.settings.translations[appProps.currentLocale].media.length &&
        selectedVideo.content.settings.translations[appProps.currentLocale].media[0].source
      )

    } else if (selectedVideo && ['youtube', 'vimeo'].includes(selectedVideo.content.settings.videoType)) {
      return selectedVideo.content.settings.translations[appProps.currentLocale] && selectedVideo.content.settings.translations[appProps.currentLocale].video
    }
  }

  return (
    <div className={ `lxp-wrapper ${ screenClass }` }>
      <div className='section-hero'>
        <div className='section heading' style={{ height: '100%' }}>
          <div className='main-container flex-box items-center' style={{ height: '100%' }}>
            <h1>{ translations.general.mindfulness }</h1>
          </div>
        </div>
        <div className='main-container'>
          <div className='flex-box flex-wrap items-center content-start tabs-index' style={{ zIndex: 2 }}>
            { tabData.map( (data, index) => (
              <TabItem
                key={ index }
                title={ data.title }
                path={ data.path }
              />
            ))}
          </div>
        </div>
      </div>

      <div style={{ paddingTop: 80 }}>
        <div className='main-container'>
          { videoPractices.recent.length > 0 &&
            <>
              <div className='section-title flex-box content-space-between'>
                <h2>{ translations.mindfulness.recent_practices }</h2>
              </div>
              <div className='section-divider'></div>
              <div className='list-item-wrapper'>
                { videoPractices.recent.map( (video, index) => {
                  const vSettings = video.content.settings
                  const stripedHtml = `${ (getTranslation(vSettings.translations).description || '').replace(/<[^>]+>/g, '').substring(0, 150) } ...`
                  return (
                    <ListItem
                      key={ index }
                      appProps={ appProps }
                      title={ getTranslation(vSettings.translations).title || '' }
                      description={ stripedHtml }
                      actionPath={ '#' }
                      quickMenu
                      entityUid={ video.id }
                      entityType='MindseedLms::Library'
                      actionExtra={ event => showDescription(video, event) }
                      titleActionExtra={ event => showDescription(video, event) }
                      image={ vSettings.featuredImage ? vSettings.featuredImage.source : NO_IMAGE_SOURCE }
                    />
                  )})
                }
              </div>
            </>
          }

          { videoPractices.courses.length > 0 &&
            <>
              <div className='section-title flex-box content-space-between'>
                <h2>{ translations.mindfulness.course_timers }</h2>
              </div>
              <div className='section-divider'></div>
              <div className='list-item-wrapper'>
                { videoPractices.courses.map( (video, index) => {
                  const vSettings = video.content.settings
                  const stripedHtml = `${ (getTranslation(vSettings.translations).description || '').replace(/<[^>]+>/g, '').substring(0, 150) } ...`
                  return (
                    <ListItem
                      key={ index }
                      appProps={ appProps }
                      title={ getTranslation(vSettings.translations).title || '' }
                      description={ stripedHtml }
                      actionPath={ '#' }
                      quickMenu
                      entityUid={ video.id }
                      entityType='MindseedLms::Library'
                      actionExtra={ event => showDescription(video, event) }
                      titleActionExtra={ event => showDescription(video, event) }
                      image={ vSettings.featuredImage ? vSettings.featuredImage.source : NO_IMAGE_SOURCE }
                    />
                  )})
                }
              </div>
            </>
          }

          { videoPractices.practices.length > 0 &&
            <>
              <div className='section-title flex-box content-space-between'>
                <h2>{ translations.mindfulness.mindfulness_videos }</h2>
              </div>
              <div className='section-divider'></div>
              <div className='list-item-wrapper'>
                { videoPractices.practices.map( (video, index) => {
                  const vSettings = video.content.settings
                  const stripedHtml = `${ (getTranslation(vSettings.translations).description || '').replace(/<[^>]+>/g, '').substring(0, 150) } ...`
                  return (
                    <ListItem
                      key={ index }
                      appProps={ appProps }
                      title={ getTranslation(vSettings.translations).title || '' }
                      description={ stripedHtml }
                      actionPath={ '#' }
                      quickMenu
                      entityUid={ video.id }
                      entityType='MindseedLms::Library'
                      actionExtra={ event => showDescription(video, event) }
                      titleActionExtra={ event => showDescription(video, event) }
                      image={ vSettings.featuredImage ? vSettings.featuredImage.source : NO_IMAGE_SOURCE }
                    />
                  )})
                }
              </div>
            </>
          }
        </div>

        { openVideoModal && selectedVideo && ((selectedVideo.content.settings.videoType === 'video' && mediaId) || selectedVideo.content.settings.videoType !== 'video') && mediaUrl &&
          <Modal
            visible={ openVideoModal }
            closeModal={ closeVideoModal }
            mode='fullpage'
            theme='dark'
          >

            <MediaPlayer
              key={ selectedVideo.id }
              appProps={ appProps }
              mediaUrl={ mediaUrl }
              mediaId={ mediaId }
              video={ selectedVideo }
            />

          </Modal>
        }

        { openDescriptionModal && selectedVideo && ((selectedVideo.content.settings.videoType === 'video' && mediaId) || selectedVideo.content.settings.videoType !== 'video') && mediaUrl &&
          <Modal
            visible={ openDescriptionModal }
            closeModal={ closeDescriptionModal }
            mode='medium'
          >
            <>
              <img style={{ width: '100%', height: '275px', objectFit: 'cover' }} src={ selectedVideo.content.settings.featuredImage ? selectedVideo.content.settings.featuredImage.source : NO_IMAGE_SOURCE }/>

              { getTranslation(selectedVideo.content.settings.translations).title &&
                <div className='section-title' style={{ marginTop: '20px', textAlign: 'center' }}>
                  <h3>
                    { getTranslation(selectedVideo.content.settings.translations).title }
                  </h3>
                </div>
              }

              { getTranslation(selectedVideo.content.settings.translations).description &&
                <div style={{ marginTop: '20px', textAlign: 'center' }}>
                  <div dangerouslySetInnerHTML={{__html: getTranslation(selectedVideo.content.settings.translations).description.replace(/<[^>]+>/g, '') }}></div>
                </div>
              }

              <div className='flex-box content-center' style={{ marginTop: '25px' }}>
                <button
                  className='button'
                  style={{ width: '65%' }}
                  onClick={ () => playVideo(selectedVideo) }
                >
                  { translations.mindfulness.play_video }
                </button>
              </div>
            </>
          </Modal>
        }

      </div>
    </div>
  )
}
