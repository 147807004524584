import React from 'react'
import { getTaskType } from './utils'

export default function TaskDeepenings({
  deepenings, chapterId, practices, changeTask, completeTask, courseLocale, showSidebar, setShowSidebar,
  currentTask, setTabIndex, translations, autoCompletedTasks, handleChangeTab, handleSocialIconClick
}) {

  return (
    <div className='flex-box flex-wrap'>
      { deepenings.map( (deepening, index) => {
        const isCompleted = practices.find( practice => practice.taskId == deepening.id )
        const isDisabled = autoCompletedTasks.includes(deepening.contentType)
        const duration = deepening.settings.translations[courseLocale].duration || ''

        return (
          <div
            key={ deepening.id }
            className={ `deepening-task ${ deepening.id == currentTask.id ? 'current' : '' }` }
            onClick={ () => changeTask(deepening, chapterId) }
          >
            <div className='flex-box'>
              <div
                className={ `check-complete icon ${ isCompleted ? 'filter_icon_circle_filled' : 'filter_icon_circle' } ${(isDisabled && !isCompleted) ? 'disabled' : ''}`}
                onClick={ () => {
                  if(!isDisabled) {
                    completeTask(deepening.id, {
                      isCompleted: isCompleted,
                      isRoot: deepening.isRoot,
                      completedTask: deepening,
                      completedChapterId: chapterId
                    })
                  }
                }}
              />

              <div className="task-details flex-1">
                <div className={ `task-title ${ deepening.id == currentTask.id ? 'current' : '' }` }>
                  { deepening.settings.translations[courseLocale].title }
                </div>

                <div className="task-type-duration flex-box">
                  <div className={ `icon ${ getTaskType(deepening.contentType) }` }/>

                  { duration !== '' && duration !== '0' &&
                    `${ duration } ${ translations.general.min }`
                  }
                </div>
              </div>

              { !!deepening.settings.translations[courseLocale].seedQuestion &&
                <div
                  className='social_icon'
                  style={{ alignSelf: 'center', margin: '0 5px' }}
                  onClick={ () => handleSocialIconClick(deepening, chapterId) }
                />
              }

              { !!deepening.settings.enableUploads &&
                <div
                  className='upload_icon'
                  style={{ alignSelf: 'center', margin: '0 5px' }}
                  onClick={ () => handleChangeTab(3, deepening, chapterId) }
                />
              }
            </div>

            { (index < (deepenings.length - 1)) &&
              <div style={{ height: '1px', width: '100%', marginTop: 10, backgroundColor: '#D9D9D9' }}/>
            }
          </div>
        )
      })}
    </div>
  )
}
