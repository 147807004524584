import React, { useState, useEffect } from 'react'

import Progress from '../shared/Progress'

import { getTranslatableField } from '../../helpers/utils'
import { getTaskType } from './utils'
import TaskDeepenings from './TaskDeepenings'

export default function ChaptersOverview({
  chapters, courseLocale, practices, completeTask, currentTask, currentChapterId,
  changeTask, setTabIndex, translations, autoCompletedTasks, handleChangeTab, showSidebar,
  setShowSidebar, isDesktop, course, currentLocale
}) {
  const [openedChapterId, setOpenedChapterId] = useState(null)
  const [currentTaskWrapperId, setCurrentTaskWrapperId] = useState(null)

  const placeholderAvatar = 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ2nNUXQ8TRIbctxR4bGhWkQd5mrIeu1okIyte3_rz0HSVf6hctJAXHOwrUidaXMlOtCFA&usqp=CAU'

  useEffect(() => setOpenedChapterId(currentChapterId), [currentChapterId])

  useEffect( () => {
    if (currentTask && openedChapterId && ((!currentTask.isRoot && currentTask.parentId !== currentTaskWrapperId) || currentTask.isRoot)) {
      setCurrentTaskWrapperId(currentTask.parentId)
    }
  }, [currentTask, openedChapterId])

  function renderChapter(chapter) {
    const isOpen = openedChapterId === chapter.id
    const rootTasks = chapter.items.map(tw => tw.items).map(ts => ts.find(t => t.isRoot).id.toString())
    const completedTasksCount = practices.filter(p => rootTasks.includes(p.taskId.toString())).length

    const currentDate = new Date()
    const isLocked = new Date(chapter.settings.startDate) > new Date()

    return (
      <div className="chapter-item" key={ chapter.id }>
        <div
          className={ `chapter-details flex-box items-center ${ isLocked ? 'locked' : '' }` }
          onClick={ () => { if (!isLocked) { setOpenedChapterId(chapter.id !== openedChapterId ? chapter.id : null) } } }
        >

          <div className={ `chapter-title flex-1 ${ currentChapterId == chapter.id ? 'current' : '' }` }>
            { getTranslatableField(chapter, courseLocale, 'title') }
          </div>

          <Progress
            circleSize={ 38 }
            percentage={ chapter.items.length ? (completedTasksCount / chapter.items.length) * 100 : 0 }
            textValue={ `${ completedTasksCount }/${ chapter.items.length }` }
            textClass='chapter-progress-text'
          />

          { isLocked &&
            <div className="toggle-chapter icon protected-icon" style={{ marginLeft: '10px' }}/>
          }

          { !isLocked && chapter.items.length > 0 &&
            <div className={ `toggle-chapter icon angle_${ isOpen ? 'up' : 'down' }_icon` } style={{ marginLeft: '10px' }}/>
          }
        </div>

        { isOpen && !isLocked &&
          <div className="chapter-tasks flex-box flex-column">
            { renderChapterTasks(chapter.items, chapter.id) }
          </div>
        }
      </div>
    )
  }

  function renderChapterTasks(taskWrappers, chapterId) {
    return taskWrappers.map( taskWrapper => {

      const rootTask = taskWrapper.items.filter(t => t.isRoot)[0]
      const isCompleted = practices.find( practice => practice.taskId == rootTask.id )
      const isDisabled = autoCompletedTasks.includes(rootTask.contentType)
      const duration = getTranslatableField(rootTask, courseLocale, 'duration')
      const deepenings = taskWrapper.items.slice(1)
      const currentTaskId = currentTask && currentTask.id

      return (
        <div key={ taskWrapper.id }>
          <div
            className="chapter-task flex-box items-start"
            onClick={ () => changeTask(rootTask, chapterId) }
          >
            <div
              className={ `check-complete icon ${ isCompleted ? 'filter_icon_square_filled' : 'filter_icon_square' } ${(isDisabled && !isCompleted) ? 'disabled' : ''}`}
              onClick={ () => {
                if(!isDisabled) {
                  completeTask(rootTask.id, {
                    isCompleted: isCompleted,
                    isRoot: rootTask.isRoot,
                    completedTask: rootTask,
                    completedChapterId: chapterId
                  })
                }
              }}
            />

            <div className="task-details flex-box flex-column">
              <div className={ `task-title ${ rootTask.id === currentTaskId ? 'current' : '' }` }>
                { getTranslatableField(rootTask, courseLocale, 'title') }
              </div>

              <div className="task-type-duration flex-box items-center content-space-between">
                <div className='flex-1 flex-box items-center'>
                  <div className={ `icon ${ getTaskType(rootTask.contentType) }` }/>
                  { duration !== '' && duration !== '0' &&
                    <div className="duration-text">
                      { duration } { translations.general.min }
                    </div>
                  }
                </div>
              </div>
            </div>
            <div className='flex-box' style={{ alignSelf: 'center' }}>
              { !!rootTask.settings.translations[courseLocale].seedQuestion &&
                <div
                  className='icon social_icon'
                  style={{ margin: '0 5px' }}
                  onClick={ () => handleSocialIconClick(rootTask, currentChapterId) }
                />
              }

              { !!rootTask.settings.enableUploads &&
                <div
                  className='icon upload_icon'
                  style={{ margin: '0 5px' }}
                  onClick={ () => handleChangeTab(3, rootTask, chapterId) }
                />
              }
            </div>
          </div>

          { currentTaskWrapperId === taskWrapper.id &&
            <div className='deepenings-wrapper'>

              <TaskDeepenings
                deepenings={ deepenings }
                chapterId={ chapterId }
                practices={ practices }
                changeTask={ changeTask }
                completeTask={ completeTask }
                courseLocale={ courseLocale }
                currentTask={ currentTask }
                setTabIndex={ setTabIndex }
                translations={ translations }
                autoCompletedTasks={ autoCompletedTasks }
                handleChangeTab={ handleChangeTab }
                showSidebar={ showSidebar }
                setShowSidebar={ setShowSidebar }
                handleSocialIconClick={ handleSocialIconClick }
              />
            </div>
          }
        </div>
      )
    })
  }

  function handleSocialIconClick(task, chapterId){
    if(isDesktop){
      if(!showSidebar){
        setShowSidebar(true)
      }

      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth"
      })
    }

    else {
      handleChangeTab(2, task, chapterId)
    }
  }

  return (
    <>
      <div className={`task-sidebar-container tab flex-box flex-column`}>
        <div className="chapters-overview-container">
          { chapters.map( chapter => renderChapter(chapter) ) }
        </div>
      </div>
      { course.instructors && course.instructors.length > 0 &&
        <div className='task-sidebar-container content-socket-container tab' style={{ minHeight: 0, marginBottom: '30px' }}>
          <div className='content-socket-footer' style={{ borderTop: 'none' }}>
            <div>
              <div style={{marginBottom: '20px'}}>
                { translations.general.instructors }
              </div>
              <div className='flex-box flex-wrap'>
                { course.instructors.map( (instructor, index) => (
                  <a
                    key={ instructor.id }
                    style={{ marginRight: 5 }}
                    className='clickable-link'
                    href={ `/${ currentLocale }/instructors/${ instructor.id }` }
                    rel='nofollow noindex noreferrer'
                  >
                    <img
                      className='instructor-image'
                      src={ instructor.image || placeholderAvatar }
                    />
                  </a>
                ))}
              </div>
            </div>
          </div>
        </div>
      }
    </>
  )
}
