import React, { useState, useEffect, useRef } from 'react'

import TaskContent from './TaskContent'
import Discussion from './sidebar/Discussion'
import Modal from '../shared/Modal'
import CourseLanguageSelector from '../shared/CourseLanguageSelector'
import Progress from '../shared/Progress'
import { lrsEvent } from '../../helpers/utils'
import { getTranslation } from '@reactiveonline/frontend_shared_components/utils'

const autoCompletedTasks = ['timer', 'breathing', 'audio', 'video', 'vimeo', 'youtube', 'scorm', 'mindfulnessVideo']

export default function Show({
  appProps, course, courseLanguage, setLocalePath, userPractices, completeTaskPath,
  fetchContentPath, fetchNotePath, commentsPath, fetchResourcesPath,
  completeCoursePath, fetchInstructorsPath, fetchSettingsPath, completeBundlePath,
  fetchScormContentPath, uploadUserFilePath, fetchUserUploadsPath, sendCommentReplyPath,
  fetchRepliesPath, deleteUploadPath, userUploadInfoPath, downloadUserFilePath, validateFilePath, bundleShowPath, bundleTitle
}) {
  const {
    translations, currentDevice, currentLocale, flashMessage,
    user, saveBookmarkPath, colorScheme
  } = appProps

  const [currentCourse, setCurrentCourse] = useState(course)
  const [practices, setPractices] = useState(userPractices)
  const [langModalOpen, setLangModalOpen] = useState(!currentCourse.localeSupported)
  const [contentLoading, setContentLoading] = useState(!currentCourse.localeSupported)
  const [loading, setLoading] = useState(false)
  const [showSidebar, setShowSidebar] = useState(false)
  const [showCloseIcon, setShowCloseIcon] = useState(false)
  const [currentTask, setCurrentTask] = useState(null)
  const [currentChapterId, setCurrentChapterId] = useState(null)
  // const [currentChapterInstructors, setCurrentChapterInstructors] = useState([])
  const [rootTasks, setRootTasks] = useState([]) // root task with chapter id
  const [showChangedCourseAlert, setShowChangedCourseAlert] = useState(false)
  const [showCompleteCourseNotice, setShowCompleteCourseNotice] = useState((parseInt(course.completionRate) == 100) && !course.completed)
  const [showCompleteBundleNotice, setShowCompleteBundleNotice] = useState(!course.bundleCompleted && course.completedAllCourses)
  const [tabIndex, setTabIndex] = useState(0)
  const [disableChangeTask, setDisableChangeTask] = useState(false)
  const [userUploadInfo, setUserUploadInfo] = useState([])

  const completeAfterChangeRef = useRef(false)

  const translation = getTranslation(course.translations, courseLanguage.code, currentLocale)
  const settingsTranslation = currentTask && currentTask.settings.translations[courseLanguage.code]
  const taskIndex = currentTask && rootTasks.findIndex( task => task.id == currentTask.id )

  const resourceProps = {
    fetchResourcesPath: fetchResourcesPath
  }

  useEffect( () => {
    if (currentCourse.content.length) {
      let task = null
      let chapterId = null
      let firstTask = null
      let firstChapterId = null
      let foundLatestCompleted = false
      let tasks = []
      let deepening = null
      let firstDeepening = null
      const urlParams = new URLSearchParams(window.location.search)
      const requestedTaskId = urlParams.get('task')

      fetchUserUploadInfo()

      currentCourse.content.forEach( (chapter, chapterIndex) => {
        chapter.items.forEach( (taskWrapper, taskWrapperIndex) => {
          tasks.push({ id: taskWrapper.items[0].id, chapterId: chapter.id })

          if (requestedTaskId) {
            const requestedTask = taskWrapper.items.find(t => t.id == requestedTaskId)
            if (requestedTask) {
              task = requestedTask
              chapterId = chapter.id
              if (taskWrapper.items.length > 1) {
                deepening = taskWrapper.items[1]
              }
            }
          }

          if (!firstTask && !firstChapterId) {
            firstTask = taskWrapper.items[0]
            firstChapterId = chapter.id
            if (taskWrapper.items.length > 1) {
              firstDeepening = taskWrapper.items[1]
            }
          }

          // if (!task && foundLatestCompleted) {
          //   task = taskWrapper.items[0]
          //   chapterId = chapter.id
          //   if (taskWrapper.items.length > 1) {
          //     deepening = taskWrapper.items[1]
          //   }
          // }
          //
          // if (practices.length && !task && taskWrapper.items[0].id == practices[0].taskId) {
          //   foundLatestCompleted = true
          //   if ((chapterIndex === (currentCourse.content.length - 1)) && (taskWrapperIndex === (chapter.items.length - 1))) {
          //     task = taskWrapper.items[0]
          //     chapterId = chapter.id
          //     if (taskWrapper.items.length > 1) {
          //       deepening = taskWrapper.items[1]
          //     }
          //   }
          // }
        })
      })

      let chapter = currentCourse.content.find( chapter => chapter.id === chapterId )
      if (!chapter) {
        chapter = currentCourse.content.find( chapter => chapter.id === firstChapterId )
        if (!chapter) {
          chapter = currentCourse.content.find( chapter => chapter.id === currentCourse.content[0].id )
        }
      }
      if ((chapter.settings.startDate && new Date(chapter.settings.startDate) <= new Date()) || !chapter.settings.startDate) {
        let t = task || firstTask
        setCurrentTask(t)
        if (t) {
          setShowSidebar(!t.settings.disableComments)
        }
        setCurrentChapterId(chapter.id)
      }

      setRootTasks(tasks)
    }
  }, [])

  useEffect( () => {
    if(currentDevice === 'desktop' && currentTask){
      setShowCloseIcon(!currentTask.settings.disableComments)
      setShowSidebar(!currentTask.settings.disableComments)
    }

    if (currentTask && currentTask.settings.assignedContentId) {
      setLoading(true)

      Rails.ajax({
        type: 'GET',
        url: `${ fetchContentPath }?id=${ currentTask.settings.assignedContentId }`,
        dataType: 'json',
        success: result => {
          if (result.content) {
            setCurrentTask( prevState => ({
              ...prevState,
              assignedContentId: prevState.settings.assignedContentId,
              settings: result.content.content.settings
            }))
          }
        },
        error: result => {
          window.location.reload()
        },
        complete: result => {
          setLoading(false)
        }
      })
    } else if (completeAfterChangeRef.current) {
      completeAfterChangeRef.current = false
      completeTask(currentTask.id, {
        isCompleted: false,
        isRoot: currentTask.isRoot
      })
    }
  }, [currentTask])

  useEffect( ()=> {
    if (window.location.href.includes('isMobileApp=true')) {
      setInterval( ()=> {
        if (document.getElementsByClassName('top_header')[0] != undefined) {
          document.getElementsByClassName('top_header')[0].style.display = 'none';
        }
        if (document.getElementsByClassName('header_wrapper')[0] != undefined) {
          document.getElementsByClassName('header_wrapper')[0].style.display = 'none';
        }
        if (document.getElementsByClassName('breadcrumb-wrapper')[0] != undefined) {
          document.getElementsByClassName('breadcrumb-wrapper')[0].style.display = 'none';
        }
        if (document.getElementsByTagName('footer')[0] != undefined) {
          document.getElementsByTagName('footer')[0].style.display = 'none';
        }
        if (document.getElementById('Body_7712465398')) {
          document.getElementById('Body_7712465398').style.display = 'none';
        }
      }, 1000)
    }
  },[])

  // useEffect(() => {
  //   const currentChapter = currentCourse.content.find(chapter => chapter.id == currentChapterId)
  //
  //   if (currentChapter) {
  //     setLoading(true)
  //
  //     Rails.ajax({
  //       url: `${ fetchSettingsPath }?course_uid=${ currentCourse.uid }&activity_type=chapter&activity_id=${ currentChapter.id }`,
  //       type: 'GET',
  //       dataType: 'json',
  //       success: result => {
  //         if (result.settings) {
  //           if (result.settings.instructors && result.settings.instructors.length) {
  //             Rails.ajax({
  //               type: 'GET',
  //               url: `${ fetchInstructorsPath }?course_uid=${ currentCourse.uid }&instructor_ids=${ JSON.stringify(result.settings.instructors) }`,
  //               dataType: 'json',
  //               success: result => {
  //                 if (result.instructors) {
  //                   setCurrentChapterInstructors(result.instructors)
  //                 }
  //               }
  //             })
  //
  //           } else {
  //             setCurrentChapterInstructors([])
  //           }
  //         }
  //       },
  //       complete: result => {
  //         setLoading(false)
  //       }
  //     })
  //   } else {
  //     setCurrentChapterInstructors([])
  //   }
  // }, [currentChapterId])

  function fetchUserUploadInfo(){
    Rails.ajax({
      type: 'GET',
      url: `${ userUploadInfoPath }?entity_type=MindseedLms::Course`,
      dataType: 'json',
      success: result => {
        setUserUploadInfo(result.userUploadInfo)
      },
      error: result => {}
    })
  }

  function fetchTask(task, chapterId, disabled = false) {
    const isCompleted = !!practices.find( practice => practice.taskId == currentTask.id )

    if (!isCompleted && !disabled) {
      completeTask(currentTask.id, {
        isCompleted: false,
        isRoot: currentTask.isRoot
      })
    }

    changeTask(task, chapterId)
  }

  function navigate(direction) {
    if (!disableChangeTask) {
      let requestedTask = null
      let breakLoop = false
      let isDisabled = autoCompletedTasks.includes(currentTask.contentType)

      if (direction === 'previous' && taskIndex > 0) {
        requestedTask = rootTasks[taskIndex - 1]
      } else if (direction === 'next' && taskIndex < (rootTasks.length - 1)) {
        requestedTask = rootTasks[taskIndex + 1]
      }

      if (requestedTask) {
        for (let i = 0; i < currentCourse.content.length; i++) {
          if (breakLoop) { break }
          const chapter = currentCourse.content[i]

          if ((chapter.settings.startDate && new Date(chapter.settings.startDate) <= new Date()) || !chapter.settings.startDate) {
            for (let j = 0; j < chapter.items.length; j++) {
              const taskWrapper = chapter.items[j]
              if (taskWrapper.items[0].id == requestedTask.id) {
                fetchTask(taskWrapper.items[0], requestedTask.chapterId, isDisabled)
                breakLoop = true
                break
              }
            }
          } else {
            break
          }
        }
      }
    }
  }

  function changeTask(task, chapterId) {
    if (!disableChangeTask && (!currentTask || task.id !== currentTask.id)) {
      setCurrentTask(task)
      setCurrentChapterId(chapterId)

      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth"
      })
    }
  }

  function completeTask(taskId, taskAdditionalData = {}) {
    const {
      isCompleted = null,
      isRoot = false,
      completedTask = null,
      completedChapterId = null,
      sessionUid = null,
      lowerCompletionRate = false,
      duration = null
    } = taskAdditionalData

    const taskIsCompleted = isCompleted !== null ? practices.find( practice => practice.taskId == taskId ) : isCompleted

    if (!taskIsCompleted) {
      if (currentTask.id != taskId) {
        completeAfterChangeRef.current = true
        changeTask(completedTask, completedChapterId)
      }
      if(!completeAfterChangeRef.current) {
        setLoading(true)
        let formData = new FormData()
        formData.append('task_id', taskId)
        formData.append('is_root', isRoot)

        Rails.ajax({
          type: 'POST',
          url: completeTaskPath,
          data: formData,
          dataType: 'json',
          success: result => {
            if (result.userPractice) {
              setPractices([result.userPractice, ...practices])
            }

            if (result.newCompletionProps) {
              setCurrentCourse({ ...currentCourse, ...result.newCompletionProps })
              if (parseInt(result.newCompletionProps.completionRate) == 100 && !result.newCompletionProps.completed) {
                setShowCompleteCourseNotice(true)
              }
            }

            if (!lowerCompletionRate) { //if completion rate equals 100
              logPracticeToLrs(sessionUid, duration)
            }
          },
          error: result => {
            setShowChangedCourseAlert(true)
          },
          complete: result => {
            setLoading(false)
          }
        })
      }

    // REPEATABLE PRACTICES ONLY
    } else if (['video', 'youtube', 'vimeo', 'audio', 'timer', 'breathing', 'mindfulnessVideo'].includes(currentTask.contentType)) {
      logPracticeToLrs(sessionUid, duration)
    }
  }

  function logPracticeToLrs(sessionUid, duration = null) {
    lrsEvent(appProps, currentTask.settings.translations, currentTask.id, currentTask.contentType, 'completed', {
      associableUid: course.uid,
      assignableUid: currentTask.assignedContentId,
      sessionUid: sessionUid,
      mediaId: getMediaId(),
      locale: courseLanguage.code,
      duration: duration
    })
  }

  function getMediaId() {
    return (currentTask.settings.translations[courseLanguage.code] &&
      currentTask.settings.translations[courseLanguage.code].media &&
      currentTask.settings.translations[courseLanguage.code].media.length &&
      currentTask.settings.translations[courseLanguage.code].media[0].mediaId || null
    )
  }

  function completeCourse() {
    setLoading(true)
    Rails.ajax({
      type: 'POST',
      url: completeCoursePath,
      dataType: 'json',
      success: result => {
        if (result.completed) {
          setCurrentCourse({ ...currentCourse, completionRate: 100, completed: true })
          setShowCompleteCourseNotice(false)
          flashMessage.show(translations.course.completed_alert, "success")
          lrsEvent(appProps, currentTask.settings.translations, currentCourse.uid, 'course', 'completed')

          if (result.bundleCompletionRate && result.bundleCompletionRate === 100) {
            setShowCompleteBundleNotice(true)
          }
        }
      },
      complete: result => {
        setLoading(false)
      }
    })
  }

  function completeBundle() {
    setLoading(true)
    Rails.ajax({
      type: 'POST',
      url: completeBundlePath,
      dataType: 'json',
      success: result => {
        if (result.completed) {
          setCurrentCourse({ ...currentCourse, bundleCompletionRate: 100, bundleCompleted: true })
          setShowCompleteBundleNotice(false)
          flashMessage.show(translations.bundle.completed_alert, 'success')
          lrsEvent(appProps, currentTask.settings.translations, currentCourse.bundleUid, 'bundle', 'completed')
        }
      },
      complete: result => {
        setLoading(false)
      }
    })
  }

  function handleChangeTab(index, task, chapterId) {
    setTabIndex(index)
    changeTask(task, chapterId)

    const element = document.getElementsByClassName('task-content-bottom-container')[0]
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' })
    }
  }

  return (
    <>
      { langModalOpen &&
        <Modal
          visible={ langModalOpen }
          mode='medium centered'
          elementsClass='items-center content-center'
          icon='course'
        >
          <div style={{ marginTop: 20 }}>
            { translations.lxp.preferred_course_language_message }
            <b style={{ marginLeft: 5, color: colorScheme || '#000000' }}>{ courseLanguage.presentation }</b>.
          </div>

          <div style={{ marginTop: 20, width: '70%' }}>
            { translations.lxp.course_language_not_supported }
          </div>

          <div style={{ marginTop: 20 }}>
            <CourseLanguageSelector
              languages={ currentCourse.availableLanguages }
              setLocalePath={ setLocalePath }
              translations={ translations }
            />
          </div>
        </Modal>
      }
      <div className='section breadcrumb-container' style={{ paddingTop: 20 }}>
        <div className="main-container">
          <div className='flex-box content-space-between breadcrumb-box'>
            <div className='flex-box breadcrumbs'>
              <div className='inner-breadcrumb flex-box items-center content-center'>
                { bundleShowPath ?
                  <a href={ bundleShowPath } className='bundle-title clickable-link chevron_icon_right_after'>
                    { bundleTitle }
                  </a>
                  :
                  <a href={ course.showPath } className={ `course-title chevron_icon_right_after ${ bundleShowPath ? 'bundle' : '' } clickable-link` }>
                    { translation.title }
                  </a>
                }
              </div>
              <div className={`inner-breadcrumb last-breadcrumb ${bundleShowPath ? 'is-bundle' : ''}  flex-box items-center content-center`}>
                { settingsTranslation && settingsTranslation.title }
              </div>
            </div>
            <div className='flex-box flex-1 content-end items-center breadcrumbs-progress'>

              <Progress
                circleSize={ 38 }
                percentage={ currentCourse.completionRate ? currentCourse.completionRate : 0 }
                textValue={ `${ currentCourse.completionRate ? currentCourse.completionRate : 0 }%` }
                textClass='chapter-progress-text'
              />

              { rootTasks.length > 1 && taskIndex >= 0 && taskIndex <= (rootTasks.length - 1) &&
                <div className='items-center' style={{ height: '80%', width: '1px', borderLeft: '1px solid lightgray', marginLeft: '20px' }}/>
              }

              { currentTask && currentTask.isRoot &&
                <div className='flex-box'>
                  { taskIndex > 0 &&
                    <button
                      className='navigation-button'
                      style={{ marginLeft: '20px' }}
                      onClick={ () => navigate('previous') }
                    >
                      { translations.general.previous }
                    </button>
                  }

                  { taskIndex < (rootTasks.length - 1) &&
                    <button
                      className='navigation-button'
                      style={{ marginLeft: '20px' }}
                      onClick={ () => navigate('next') }
                    >
                      { translations.general.next }
                    </button>
                  }
                </div>
              }
            </div>
          </div>
        </div>
      </div>
      <div className='section body-bg' style={{ paddingTop: 20 }}>
        <div className="main-container">
          {!contentLoading &&
            <div className="task-container flex-box items-start">
              <TaskContent
                appProps={ appProps }
                user={ user }
                course={ currentCourse }
                showSidebar={ showSidebar }
                setShowSidebar={ setShowSidebar }
                currentTask={ currentTask }
                courseLocale={ courseLanguage.code }
                currentChapterId={ currentChapterId }
                rootTasks={ rootTasks }
                fetchNotePath={ fetchNotePath }
                saveBookmarkPath={ saveBookmarkPath }
                commentsPath={ commentsPath }
                resourceProps={ resourceProps }
                completeTask={ completeTask }
                // currentChapterInstructors={ currentChapterInstructors }
                practices={ practices }
                changeTask={ changeTask }
                isDesktop={ currentDevice == "desktop" }
                tabIndex={ tabIndex }
                setTabIndex={ setTabIndex }
                fetchSettingsPath={ fetchSettingsPath }
                fetchInstructorsPath={ fetchInstructorsPath }
                fetchScormContentPath={ fetchScormContentPath }
                autoCompletedTasks={ autoCompletedTasks }
                translation={ translation }
                disableChangeTask={ disableChangeTask }
                setDisableChangeTask={ setDisableChangeTask }
                handleChangeTab={ handleChangeTab }
                uploadUserFilePath={ uploadUserFilePath }
                fetchUserUploadsPath={ fetchUserUploadsPath }
                sendCommentReplyPath={ sendCommentReplyPath }
                fetchRepliesPath={ fetchRepliesPath }
                deleteUploadPath={ deleteUploadPath }
                settingsTranslation={ settingsTranslation }
                userUploadInfo={ userUploadInfo }
                setUserUploadInfo={ setUserUploadInfo }
                downloadUserFilePath={ downloadUserFilePath }
                validateFilePath={ validateFilePath }
                bundleShowPath={ bundleShowPath }
                bundleTitle={ bundleTitle }
                showCloseIcon={ showCloseIcon }
                taskIndex={ taskIndex }
                navigate={ navigate }
              />

              { showSidebar && currentDevice === 'desktop' &&
                <div className={`task-sidebar-container flex-box flex-column`}>
                  <div className="sidebar-close-button flex-box content-space-between">
                    <div style={{ fontSize: '30px', fontWeight: 600 }}>
                      { translations.lxp.discussion }
                    </div>
                    <div className="icon collapse_icon" onClick={ () => setShowSidebar(false) }/>
                  </div>

                  <Discussion
                    settingsTranslation={ settingsTranslation }
                    userId={ user.id }
                    entityType={ 'MindseedLms::Course' }
                    entityUid={ course.uid }
                    taskId={ currentTask && currentTask.id }
                    commentsPath={ commentsPath }
                    translations={ translations }
                  />
                </div>
              }
            </div>
          }
        </div>
      </div>

      { showChangedCourseAlert &&
        <Modal
          visible={ showChangedCourseAlert }
          mode='small'
        >
          <div className='flex-box flex-column content-space-around' style={{ height: '100%' }}>
            { translations.lxp.course_change }

            <button
              type='button'
              className='button'
              onClick={ () => window.location.reload() }
            >
              { translations.general.reload }
            </button>
          </div>
        </Modal>
      }

      { showCompleteCourseNotice &&
        <div className="complete-course-wrapper" style={{ position: "fixed", bottom: 0, left: 0, zIndex: 10 }}>
          <div className="complete-course-text">
            { translations.lxp.course_congrats }
          </div>
          <div className="button-wrapper">
            <div className="button" onClick={ completeCourse }>
              { translations.lxp.course_complete }
            </div>
          </div>
        </div>
      }

      { showCompleteBundleNotice &&
        <div className='complete-course-wrapper' style={{ position: 'fixed', bottom: 0, left: 0, zIndex: 9 }}>
          <div className='complete-course-text'>
            { translations.lxp.bundle_congrats }
          </div>
          <div className='button-wrapper'>
            <div className='button' onClick={ completeBundle }>
              { translations.lxp.bundle_complete }
            </div>
          </div>
        </div>
      }
    </>
  )
}
