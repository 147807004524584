import React, { useState, useEffect } from 'react'
import { Loader } from '@reactiveonline/frontend_shared_components'
import Select from '../../shared/Select'

export default function Languages({ appProps, pageName, pageTitle }) {
  const [currentUser, setCurrentUser] = useState(appProps.user)
  const [uiLocale, setUiLocale] = useState(currentUser.uiLocale)
  const [courseLocale, setCourseLocale] = useState(currentUser.courseLocale)
  const [loading, setLoading] = useState(false)

  const { translations } = appProps

  function constructLangOptions(collection) {
    return [{uid: '0', text: translations.account.select_language, value: null}].concat(collection.map(l => {return {uid: l.code, text: l.presentation, value: l.code}}))
  }

  const uiLanguages = constructLangOptions(appProps.languages)
  const courseLanguages = constructLangOptions(appProps.courseLanguages)

  useEffect(() => {
    if(uiLocale !== currentUser.uiLocale || courseLocale !== currentUser.courseLocale) {
      saveChanges()
    }
  }, [uiLocale, courseLocale])

  function saveChanges() {
    setLoading(true)

    let formData = new FormData()
    if(uiLocale) {
      formData.append('account[ui_locale]', uiLocale === '0' ? null : uiLocale)
    }
    if(courseLocale) {
      formData.append('account[course_locale]', courseLocale === '0' ? null : courseLocale)
    }

    Rails.ajax({
      type: 'PATCH',
      url: `/${appProps.currentLocale}/account`,
      data: formData,
      dataType: 'json',
      success: res => {
        if(res.redirectUrl) {
          window.location.href = res.redirectUrl
        } else {
          setCurrentUser(res.user)
          appProps.flashMessage.show(res.message, 'success')
        }
      },
      error: res => {
        appProps.flashMessage.show(res.error, 'error')
      },
      complete: res => {
        setLoading(false)
      }
    })
  }

  return (
    <div className={ pageName }>
      <div className='section-title'>
        <h3>{ pageTitle }</h3>
        { translations.account.languages_description }
      </div>
      <div className='' style={{ position: 'relative' }}>
        { loading &&
          <Loader
            absolute
            size={ 'small' }
            position={ 'right' }
          />
        }
      </div>

      <div className='flex-box items-center content-space-between card'>
        <div>{ translations.account.language_website }</div>
        <Select
          appProps={ appProps }
          selectName={ 'ui_locale' }
          options={ uiLanguages }
          initialItemUid={ uiLocale || '0' }
          selectCallback={ (option) => setUiLocale(option.uid) }
        />
      </div>

      <div className='flex-box items-center content-space-between card'>
        <div>{ translations.account.language_course }</div>
        <Select
          appProps={ appProps }
          selectName={ 'course_locale' }
          options={ courseLanguages }
          initialItemUid={ courseLocale || '0' }
          selectCallback={ (option) => setCourseLocale(option.uid) }
        />
      </div>

    </div>
  )
}
