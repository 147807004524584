import React from 'react'
import ListItem from '../shared/ListItem'
import Description from '../shared/Description'
import { NO_IMAGE_SOURCE } from '../GlobalConstants'
import { getTranslation } from '@reactiveonline/frontend_shared_components/utils'

export default function Show({ appProps, screenClass, instructor, courses }) {
  const { translations, currentLocale, defaultLocale } = appProps
  const translation = getTranslation(instructor.translations, currentLocale, defaultLocale)

  return (
    <div className={` lxp-wrapper ${ screenClass }`}>
      <div className='section heading'>
        <div className='main-container'>
          <div className='section-title'>
            <h5>{ translation.title }</h5>
            <h1>{ instructor.fullName }</h1>
            <h5>{ `${ courses.length } ${ translations.course.courses }` }</h5>
          </div>
        </div>
      </div>

      <div>
        <div className='main-container'>
          <h3>{ translations.account.bio }</h3>
          <div className='section-divider'></div>
          <div className='instructors-wrapper'>
            <div className='instructor-wrapper flex-box'>
              <div className='instructor-image'>
                <img src={ instructor.image || NO_IMAGE_SOURCE } />
              </div>
              <div dangerouslySetInnerHTML={{__html: translation.bio }} className="instructor-details editor"></div>
            </div>
          </div>
        </div>
      </div>

      <div className='section body-bg' style={{ marginTop: 80 }}>
        <div className='main-container'>
          <h3>{ translations.course.courses }</h3>
          <div className='section-divider'></div>
          <div className='list-item-wrapper'>
            { courses.map( course => {
              const imageSource = course.image.isTranslatable ? course.image[currentLocale] : course.image.source
              const courseTranslation = getTranslation(course.translations, currentLocale, defaultLocale)
              const stripedHtml = courseTranslation.shortDescription ? courseTranslation.shortDescription.replace(/<[^>]+>/g, '').substring(0, 150) : ''

              return (
                <ListItem
                  key={ course.id }
                  appProps={ appProps }
                  course={ course }
                  itemClass={ screenClass }
                  image={ imageSource || NO_IMAGE_SOURCE }
                  title={ courseTranslation.title }
                  description={ stripedHtml ? `${ stripedHtml } ...` : '' }
                  instructors={ course.instructors }
                  price={ course.price }
                  actionPath={ course.showPath }
                  entityType={ course.entityType }
                  entityUid={ course.uid }
                  quickMenu
                  accessibilityProps={ course.accessibilityProps }
                />
              )
            })}
          </div>
        </div>
      </div>
    </div>
  )
}
