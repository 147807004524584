import React from 'react'
import CourseListItem from '../courses/CourseListItem'
import { NO_IMAGE_SOURCE } from '../GlobalConstants'
import { getTranslation } from '@reactiveonline/frontend_shared_components/utils'

export default function Courses({ appProps, coursesPath, screenClass, courses, courseLocale }) {
  const { translations, currentLocale } = appProps

  return (
    <div className={` lxp-wrapper ${ screenClass }`}>
      <div className='section-hero'>
        <div className='section heading' style={{ height: '100%' }}>
          <div className='main-container flex-box items-center' style={{ height: '100%' }}>
            <h1>{ translations.general.catalogue }</h1>
          </div>
        </div>
      </div>

      <div className='section body-bg'>
        <div className='main-container'>
          { courses.length > 0 ?
            <div className='list-filters-wrapper'>
              <div className='list-filters'>
                <div className='main-content'>
                  <div className='list-item-wrapper'>
                    { courses.map( (course, index) => {
                      const translation = getTranslation(course.translations, courseLocale, currentLocale)
                      const stripedHtml = translation.shortDescription ? translation.shortDescription.replace(/<[^>]+>/g, '').substring(0, 150) : ''

                      return (
                        <CourseListItem
                          key={ index }
                          appProps={ appProps }
                          itemClass={ screenClass }
                          item={ course }
                          description={ stripedHtml ? `${ stripedHtml } ...` : '' }
                          actionPath={ course.showPath }
                          quickMenu
                          entityType={ course.entityType }
                          courseLocale={ courseLocale }
                        />
                      )
                    })
                    }
                  </div>
                </div>
              </div>
            </div>
            :
            <div className='login-message'>
              <h3>
                <a href={ coursesPath } rel='nofollow noindex noreferrer'>
                  { translations.general.login_courses_message }
                </a>
              </h3>
            </div>
          }
        </div>
      </div>

    </div>
  )
}
