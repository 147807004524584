import React, { useState, useEffect, useRef } from 'react'
import DropMenu from './DropMenu'
import { getTaskType } from '../task/utils'
import { NO_IMAGE_SOURCE } from '../GlobalConstants'

export default function BookmarksDropMenu({ appProps, selectedDropMenu, setSelectedDropMenu }) {
  const [bookmarks, setBookmarks] = useState([])
  const [loading, setLoading] = useState(false)

  const bookmarksRef = useRef(bookmarks)
  const {
    translations, latestBookmarksPath, saveBookmarkPath, setCurrentAppProps,
    currentLocale, bookmarksPath
  } = appProps

  useEffect( () => {
    if (selectedDropMenu === 'bookmarks') {
      fetchBookmarks()
    }
  }, [selectedDropMenu])

  function fetchBookmarks() {
    Rails.ajax({
      url: latestBookmarksPath,
      type: 'GET',
      dataType: 'json',
      success: res => {
        const updatedBookmarks = res.bookmarks
        bookmarksRef.current = updatedBookmarks
        setBookmarks(updatedBookmarks)
      },
      error: err => {}
    })
  }

  function handleBoomark(id){
    if (!loading){
      setLoading(true)
      let formData = new FormData()
      formData.append('id', id)

      Rails.ajax({
        url: saveBookmarkPath,
        type: 'POST',
        data: formData,
        dataType: 'json',
        success: res => {
          const updatedBookmarks = bookmarksRef.current.filter( bookmark => bookmark.id !== id )
          bookmarksRef.current = updatedBookmarks
          setCurrentAppProps({ bookmarks: appProps.bookmarks.filter( bookmark => bookmark.id !== id ) })
          setBookmarks(updatedBookmarks)
        },
        error: err => {},
        complete: res => setLoading(false)
      })
    }
  }

  function itemRenderer(item, index) {
    let itemImageSource = item.image
    if (['MindseedLms::Course', 'MindseedLms::Event', 'MindseedLms::Bundle'].includes(item.type)) {
      itemImageSource = item.image && (item.image.isTranslatable ? item.image[currentLocale] : item.image.source)
      if (!itemImageSource) {
        itemImageSource = NO_IMAGE_SOURCE
      }
    }

    return (
      <div key={ index } className='flex-box header-bookmark-item'>
        <div className='bookmark-action' onClick={ () => handleBoomark(item.id) }>
          <i className='bookmark_icon_filled'/>
        </div>

        <div className='bookmark-item flex-box items-center content-center' onClick={ () => window.location.href = item.path }>
          { itemImageSource &&
            <img src={ itemImageSource || NO_IMAGE_SOURCE } />
          }

          { !itemImageSource && item.taskType &&
            <div className='icon-wrapper flex-box items-center content-center'>
              <i className={ getTaskType(item.taskType) }/>
            </div>
          }

          <div className='flex-box flex-column'>
            <div className='title'>
              { item.title }
            </div>

            { item.instructors && item.instructors.length > 0 &&
              <div className='description'>
                { translations.general.with } { item.instructors.map( i => i.fullName ).join(', ') }
              </div>
            }
          </div>
        </div>
      </div>
    )
  }

  return (
    <li className='account drop-menu' id='drop-menu'>
      <div className='menu-handler bookmark_icon'/>

      <DropMenu
        dropMenuData={ bookmarksRef ? bookmarksRef.current : [] }
        dataLength={ appProps.bookmarks ? appProps.bookmarks.length : 0 }
        title={ translations.general.bookmarks }
        titleButtonText={ translations.general.show_all }
        titleButtonPath={ bookmarksPath }
        action={ null }
        actionText={ null }
        menuPosition={ 'main-header bookmarks' }
        selected={ selectedDropMenu === 'bookmarks' }
        setSelected={ setSelectedDropMenu }
        menuKey='bookmarks'
        itemRenderer={ itemRenderer }
      />
    </li>
  )
}
