import React, { useState } from 'react'
import Modal from '../../shared/Modal'
import BreathingExercise from '../../mindfulness/breathing/BreathingExercise'

export default function Breathing({ task, courseUid, translations, appProps, completeTask, taskIsCompleted }) {
  const [breathingModalOpen, setBreathingModalOpen] = useState(false)
  const { settings } = task

  return (
    <>
      { settings.breathingType &&
        <div className='flex-box content-center items-center' style={{ width: '100%', height: '100%' }}>
          { task.settings.featuredImage &&
            <div className='launch-content' style={{ backgroundImage: `url(${ task.settings.featuredImage.source })` }}/>
          }
          <div className='button launch' onClick={ () => setBreathingModalOpen(true) }>
            { translations.lxp.launch_breathing }
          </div>
        </div>
      }

      { breathingModalOpen &&
        <Modal
          visible
          closeModal={ () => setBreathingModalOpen(false) }
          mode='fullpage'
          theme='dark'
        >
          <BreathingExercise
            appProps={ appProps }
            courseUid={ courseUid }
            taskId={ task.id }
            pauseAfterExhale={ settings.pauseAfterExhale }
            pauseAfterInhale={ settings.pauseAfterInhale }
            exhaleDuration={ settings.exhaleDuration }
            inhaleDuration={ settings.inhaleDuration }
            duration={ settings.duration * 60 }
            breathingId={ task.assignedContentId }
            breathingTranslations={ task.settings.translations }
            completeBreathing={ (sessionId, lowerCompletionRate, duration) => completeTask(task.id, {
                isCompleted: false,
                isRoot: !!task.isRoot,
                sessionUid: sessionId,
                lowerCompletionRate: lowerCompletionRate,
                duration: duration
              })
            }
            taskIsCompleted={ taskIsCompleted }
            taskCompletionRate={ task.settings.completionRate }
          />
        </Modal>
      }
    </>
  )
}
