import React, { useState, useEffect } from 'react'
import { Loader } from '@reactiveonline/frontend_shared_components'
import CourseListItem from '../courses/CourseListItem'
import Instructors from '../shared/Instructors'
import Description from '../shared/Description'
import Modal from '../shared/Modal'
import { downloadIcs } from '../shared/utils'
import { lrsEvent } from '../../helpers/utils'
import { getTranslation } from '@reactiveonline/frontend_shared_components/utils'
import { formatDate } from '../../helpers/utils'

export default function Event({ appProps, screenClass, event, courseLocale, entityShowPath }) {
  const [loading, setLoading] = useState(false)
  const [unenrolModalOpen, setUnenrolModalOpen] = useState(false)
  const [accessibleModalOpen, setAccessibleModalOpen] = useState(false)

  const { settings, content } = event
  const { instructors, accessibilityProps } = event
  const { translations, currentLocale } = appProps
  const eventTranslation = getTranslation(event.translations, courseLocale, currentLocale)

  function handleEnrolment() {
    if (!accessibilityProps) return;
    setLoading(true)
    const { userEnroled, enrolPath, unenrolPath } = accessibilityProps

    Rails.ajax({
      type: 'POST',
      url: userEnroled ? unenrolPath : enrolPath,
      dataType: 'json',
      success: res => {
        if (unenrolModalOpen) {
          setUnenrolModalOpen(false)
          lrsEvent(appProps, { en: { title: eventTranslation.title } }, event.uid, 'event', 'unenroled')
          window.location.href = res.redirectTo
        }

        if (res.redirectTo && res.accessible) {
          lrsEvent(appProps, { en: { title: eventTranslation.title } }, event.uid, 'event', 'enroled')
          window.location.href = res.redirectTo
        } else if (!unenrolModalOpen && !res.accessible && typeof(res) == 'object') {
          lrsEvent(appProps, { en: { title: eventTranslation.title } }, event.uid, 'event', 'enroled')
          setAccessibleModalOpen(true)
        }
      },
      error: res => {
        setLoading(false)
      }
    })
  }

  function openUnenrolModal() {
    setUnenrolModalOpen(true)
  }

  useEffect( ()=> {
    if (window.location.href.includes('isMobileApp=true')) {
      setInterval( ()=> {
        if (document.getElementsByClassName('top_header')[0] != undefined) {
          document.getElementsByClassName('top_header')[0].style.display = 'none';
        }
        if (document.getElementsByClassName('header_wrapper')[0] != undefined) {
          document.getElementsByClassName('header_wrapper')[0].style.display = 'none';
        }
        if (document.getElementsByClassName('breadcrumb-wrapper')[0] != undefined) {
          document.getElementsByClassName('breadcrumb-wrapper')[0].style.display = 'none';
        }
        if (document.getElementsByTagName('footer')[0] != undefined) {
          document.getElementsByTagName('footer')[0].style.display = 'none';
        }
        if (document.getElementById('Body_7712465398')) {
          document.getElementById('Body_7712465398').style.display = 'none';
        }
      }, 1000)
    }

    const queryParams = new URLSearchParams(location.search)

    if (queryParams.has('token_expired')) {
      appProps.flashMessage.show(translations.course.automatic_enrolment_error, 'error')
      queryParams.delete('token_expired')
      // window.location.href = window.location.href.replace('?token_expired=true','')
    }

    if (queryParams.has('accessibility_modal')) {
      queryParams.delete('accessibility_modal')
      setAccessibleModalOpen(true)
    }
  },[])

  return (
    <>
      { unenrolModalOpen &&
        <Modal
          visible
          closeModal={ () => setUnenrolModalOpen(false) }
          icon='exclamation'
          mode='small'
          elementsClass='items-center content-center centered'
          abortText={ translations.general.cancel }
          abortAction={ () => setUnenrolModalOpen(false) }
          acceptAction={ handleEnrolment }
          saveText={ translations.general.button_accept }
        >
          <div>
            { translations.event.unenrol_message }
          </div>
        </Modal>
      }

      { accessibleModalOpen &&
        <Modal
          visible={ accessibleModalOpen }
          icon='exclamation'
          mode='small'
          elementsClass='items-center content-center centered'
          acceptAction={ () => window.location.href.includes('accessibility_modal=true') ? (window.location.href = window.location.href.replace('?accessibility_modal=true','')) : (window.location.href = window.location.href.replace('?token_expired=true','')) }
          saveText={ translations.general.ok }
        >
          <>
            <div>
              { translations.event.enrolment_success }
            </div>
            <div>
              { translations.event.event } { translations.event.starts_at.toLowerCase() } { formatDate(event.accessibilityProps.startDate) }
            </div>
          </>
        </Modal>
      }

      <div className={` lxp-wrapper ${ screenClass }`}>
        <div className='section heading' style={{ overflow: 'initial' }}>
          <div className='main-container' style={{ position: 'relative' }}>
            <div className='flex-box flex-wrap'>
              <div className='section-title'>
                <h1>{ eventTranslation.title }</h1>
                { instructors.length > 0 &&
                  <div className='body-text'>
                    { translations.general.with }
                    { instructors.map( (instructor, index) => (
                      <a
                        key={ instructor.id }
                        className='clickable-link'
                        style={{ paddingLeft: 5 }}
                        href={ `/${ currentLocale }/instructors/${ instructor.id }` }
                        rel='nofollow noindex noreferrer'
                       >
                         { `${ instructor.fullName }${ index < (instructors.length - 1) ? ',' : '' }` }
                      </a>
                    ))}
                  </div>
                }
                { eventTranslation.shortDescription &&
                  <Description
                    description={ eventTranslation.shortDescription }
                  />
                }
              </div>

              <CourseListItem
                appProps={ appProps }
                itemClass={ screenClass }
                item={ event }
                entityShowPath={ entityShowPath }
                actionMethod={ accessibilityProps.userEnroled ? openUnenrolModal : handleEnrolment }
                showExtraInfo
                entityType='MindseedLms::Event'
                courseLocale={ courseLocale }
              />
            </div>
          </div>
        </div>

        <div className='section body-bg'>
          <div className='main-container'>
            <div className='course-overview-details'>
              { accessibilityProps.userEnroled && event.type === 'onsiteEvent' &&
                <div className='button-wrapper flex-box flex-6 content-end' style={{ marginBottom: '30px' }}>
                  <div
                    className='button flex-1'
                    onClick={ () => downloadIcs(settings.startDate, settings.endDate, event.id, content.location, eventTranslation.title) }
                  >
                    { translations.general.download_ics }
                  </div>
                  <div className='flex-5'/>
                </div>
              }

              { accessibilityProps.userEnroled && eventTranslation.additionalInfo && eventTranslation.additionalInfo != "<p></p>" &&
                <div className='card'>
                  <div dangerouslySetInnerHTML={{__html: eventTranslation.additionalInfo }} className='editor'></div>
                </div>
              }

              { eventTranslation.description &&
                <>
                  <div className='section-title'>
                    <h3>{ translations.course.description }</h3>
                  </div>
                  <div dangerouslySetInnerHTML={{__html: eventTranslation.description }} className='editor'></div>
                </>
              }

              <div className='section-divider large'></div>

              { instructors.length > 0 &&
                <Instructors
                  instructors={ instructors }
                  appProps={ appProps }
                />
              }

            </div>
          </div>
        </div>

      </div>

      { loading && <Loader fullPage /> }
    </>
  )
}
