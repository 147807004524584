import React, { useState, useEffect } from 'react'
import CourseListItem from '../../courses/CourseListItem'
import { getTranslation } from '@reactiveonline/frontend_shared_components/utils'

export default function Bundle({ appProps, item }) {
  const [loading, setLoading] = useState([])
  const [sellable, setSellable] = useState([])
  const [courseLocale, setCourseLocale] = useState(null)

  const settings = item.contentSettings

  useEffect( () => {
    if (settings.selectedProducts.length > 0) {
      setLoading(true)

      Rails.ajax({
        type: 'GET',
        url: `${ appProps.selectedSellablesPath }?sellable_ids=${ JSON.stringify(settings.selectedProducts.map( item => item.id )) }&sellable_type=MindseedLms::Bundle`,
        dataType: 'json',
        success: res => {
          setSellable(res.items)
          setCourseLocale(res.courseLocale)
          setLoading(false)
        },
        error: res => {
          setLoading(false)
        },
      })
    }
  }, [])

  return (
    <>
      { sellable.map((s, index) => {
        const translation = getTranslation(s.translations, courseLocale, appProps.currentLocale)
        const stripedHtml = translation.shortDescription ? translation.shortDescription.replace(/<[^>]+>/g, '').substring(0, 150) : ''

        return (
          <CourseListItem
            key={ index }
            appProps={ appProps }
            itemClass={ 'lxp' }
            item={ s }
            description={ stripedHtml ? `${ stripedHtml } ...` : '' }
            actionPath={ s.showPath }
            quickMenu
            entityType={ s.entityType }
            courseLocale={ courseLocale }
          />
        )
      })}
    </>
  )
}
