import React from 'react'
import Summary from './Summary'

export default function CompleteCheckout({ appProps, screenClass, order, tax, accountScreen }) {
  const { translations } = appProps

  function renderStepOverview(fields) {
    return (
      <div className='step-overview flex-box' style={{ marginLeft: 0 }}>
        <div display='flex-box flex-1 flex-column'>
          { fields.map( (field, index) => {
            if (field.value) {
              return (
                <div key={ index } className='field-title'>
                  { field.title }:
                </div>
              )
            }
          })}
        </div>

        <div display='flex-box flex-1 flex-column'>
          { fields.map( (field, index) => {
            if (field.value) {
              return (
                <div key={ index } className='field-value'>
                  { field.value }
                </div>
              )
            }
          })}
        </div>
      </div>
    )
  }

  return (
    <div className={ `lxp-wrapper ${ screenClass }` }>
      { !accountScreen &&
        <div className='section heading'>
          <div className='main-container'>
            <div className={ `section-title ${appProps.currentDevice != 'desktop' ? 'mobile' : '' }` }>
              <h1>{ translations.checkout.completeScreenHeading }</h1>
              <h4>{ translations.orders.order_no } #{ order.number }</h4>
            </div>
          </div>
        </div>
      }

      <div className='section body-bg'>
        <div className={`${accountScreen ? '' : 'main-container' } checkout-wrapper`}>
          { accountScreen &&
            <div style={{ fontSize: 17, fontWeight: 700, marginBottom: 10 }}>{ translations.orders.order_no } #{ order.number }</div>
          }
          <div className='flex-box flex-wrap checkout-container'>
            <div className='checkout-details'>
              <div className='checkout-detail'>
                <div className='checkout-detail-title'>
                  { translations.checkout.contactStep }
                </div>

                { renderStepOverview([
                  { title: translations.checkout.email, value: order.email },
                  { title: translations.checkout.firstName, value: order.address.firstName },
                  { title: translations.checkout.lastName, value: order.address.lastName },
                  { title: translations.checkout.mobile, value: order.address.mobile },
                  { title: translations.checkout.phone, value: order.address.phone }
                ])}
              </div>
              <div className='checkout-detail'>
                <div className='checkout-detail-title'>
                  { translations.checkout.billingStep }
                </div>

                { renderStepOverview([
                  { title: translations.checkout.address, value: order.address.address },
                  { title: translations.checkout.city, value: order.address.city },
                  { title: translations.checkout.postalCode, value: order.address.postalCode },
                  { title: translations.checkout.country, value: order.address.country.title },
                  { title: translations.checkout.notes, value: order.notes },
                  { title: translations.checkout.companyName, value: order.address.companyName },
                  { title: translations.checkout.vatNumber, value: order.address.vat },
                  { title: translations.checkout.additionalInfo, value: order.address.additionalInfo },
                ])}
              </div>
              <div className='checkout-detail'>
                <div className='checkout-detail-title'>
                  { translations.checkout.paymentStep }
                </div>

                { renderStepOverview([
                  { title: translations.checkout.paymentMethod, value: order.paymentMethod.translation.title }
                ])}
              </div>
            </div>

            <Summary
              appProps={ appProps }
              order={ order }
              tax={ tax }
              accountScreen={ accountScreen }
              showActionsButtons={ true }
            />

          </div>
        </div>
      </div>
    </div>
  )
}
