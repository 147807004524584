import React, { useState, useEffect } from 'react'
import { MuiPickersUtilsProvider, DateTimePicker, Calendar } from '@material-ui/pickers'
import MomentUtils from '@date-io/moment'
import Select from '../shared/Select'
import DropMenu from '../shared/DropMenu'
import moment from 'moment'
import Modal from '../shared/Modal'
import { NumberInput, RadioInput } from '@reactiveonline/frontend_shared_components'
import { formatDate } from '../../helpers/utils'

const frequencies = ['once', 'daily', 'weekly', 'monthly']
const weekdays = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday']

export default function TaskReminders({ appProps, taskTitle, courseUid, taskId }) {
  const [reminderModalOpen, setReminderModalOpen] = useState(false)
  const [reminderToEdit, setReminderToEdit] = useState(null)
  const [title, setTitle] = useState('')
  const [pushEnabled, setPushEnabled] = useState(false)
  const [selectedStartDateTime, setSelectedStartDateTime] = useState(new Date())
  const [selectedEndDateTime, setSelectedEndDateTime] = useState(null)
  const [notificationType, setNotificationType] = useState('email')
  const [repeatEvery, setRepeatEvery] = useState(1)
  const [selectedFrequency, setSelectedFrequency] = useState('once')
  const [notificationTypesArray, setNotificationTypesArray] = useState(['email'])
  const [taskReminders, setTaskReminders] = useState([])
  const [repeatOn, setRepeatOn] = useState([])
  const [step, setStep] = useState('edit')
  const [endOption, setEndOption] = useState('never')

  const { translations } = appProps
  const endDateOptions = [{ key: 'date', title: translations.general.on, itemRenderer: renderEndDate }, { key: 'never', title: translations.general.never }]

  useEffect(() => {
    Rails.ajax({
      type: 'GET',
      url: appProps.checkUserSubscriptionsPath,
      dataType: 'json',
      success: res => {
        if(res.userHasSubscribed) {
          setPushEnabled(true)
          setNotificationType('push')
          setNotificationTypesArray(['push', 'email'])
        }
      }
    })
  }, [])

  useEffect(() => {
    setTaskReminders([])
    setTitle(`${ taskTitle } - ${ translations.reminders.reminder }`)
    fetchReminders()
  }, [taskId])

  useEffect( () => {
    if (selectedFrequency === 'once') {
      setSelectedEndDateTime(null)
    }
  }, [selectedFrequency])

  function fetchReminders() {
    Rails.ajax({
      type: 'GET',
      url: appProps.fetchRemindersPath + `?task_id=${taskId}&course_id=${courseUid}`,
      dataType: 'json',
      success: res => {
        setTitle(`${ taskTitle } - ${ translations.reminders.reminder }`)
        setTaskReminders(res.taskReminders)
      }
    })
  }

  function removeReminder(objectId) {
    let fd = new FormData()
    fd.append('object_id', objectId)

    Rails.ajax({
      type: 'DELETE',
      url: appProps.deleteReminderPath,
      dataType: 'json',
      data: fd,
      success: res => {
        setTaskReminders(taskReminders.filter(r => r._id.$oid != objectId ))
        appProps.flashMessage.show(translations.reminders.deleted_successfully, 'success')
      }
    })
  }

  function saveReminder() {
    let repeatAmount = repeatEvery
    if (selectedFrequency === 'once') {
      repeatAmount = null
    } else if (selectedFrequency !== 'weekly') {
      repeatAmount = 1
    } else if (!repeatEvery) {
      repeatAmount = 1
    }

    let fd = new FormData()
    fd.append('title', title)
    fd.append('type', notificationType)
    fd.append('start_time', selectedStartDateTime)
    fd.append('end_time', endOption === 'date' ? selectedEndDateTime : null)
    fd.append('repeat_type', selectedFrequency)
    fd.append('repeat_amount', repeatAmount)
    fd.append('repeat_on', selectedFrequency === 'weekly' ? JSON.stringify(repeatOn) : null)
    fd.append('course_id', courseUid)
    fd.append('task_id', taskId)

    if (reminderToEdit) {
      fd.append('object_id', reminderToEdit._id.$oid)
    }

    Rails.ajax({
      type: reminderToEdit ? 'PATCH' : 'POST',
      url: appProps[reminderToEdit ? 'updateReminderPath' : 'createReminderPath'],
      dataType: 'json',
      data: fd,
      success: res => {
        if (reminderToEdit) {
          let updatedReminders = [...taskReminders]
          const reminderIndex = updatedReminders.findIndex( reminder => reminder._id.$oid === reminderToEdit._id.$oid )

          if (reminderIndex >= 0) {
            updatedReminders.splice(reminderIndex, 1, res.reminder)
          }

          setTaskReminders(updatedReminders)

        } else {
          setTaskReminders([res.reminder, ...taskReminders])
        }
      }
    })

    closeModal()
  }

  function editReminder(reminder) {
    setReminderToEdit(reminder)
    setTitle(reminder.title)
    setNotificationType(reminder.reminderType)
    setSelectedStartDateTime(null)
    setSelectedEndDateTime(reminder.reminderEndTime)
    setSelectedFrequency(reminder.repeatType)
    setRepeatEvery(reminder.repeatAmount)
    setRepeatOn(reminder.repeatOn)
    setReminderModalOpen(true)
  }

  function closeModal() {
    setReminderToEdit(null)
    setTitle(`${ taskTitle } - ${ translations.reminders.reminder }`)
    setSelectedStartDateTime(new Date())
    setSelectedEndDateTime(null)
    setNotificationType(pushEnabled ? 'push' : 'email')
    setRepeatEvery(1)
    setSelectedFrequency('once')
    setRepeatOn([])
    setStep('edit')
    setReminderModalOpen(false)
  }

  function handleRepeatOn(weekday) {
    if (repeatOn.includes(weekday)) {
      setRepeatOn( prevState => prevState.filter( item => item !== weekday ) )
    } else {
      setRepeatOn( prevState => [...prevState, weekday] )
    }
  }

  function handleNextStep() {
    if (step === 'save') {
      saveReminder()
    } else {
      if (endOption != 'never' && selectedEndDateTime && moment(selectedEndDateTime) < moment(selectedStartDateTime)) {
        appProps.flashMessage.show(translations.reminders.date_range_error, 'error')
      } else {
        setStep('save')
      }
    }
  }

  function renderItem(reminder, index) {
    let startLocalTime = moment(reminder.originalStartTime).local().format('YYYY-MM-DD HH:mm')
    let endLocalTime = null
    if(reminder.reminderEndTime) {
      endLocalTime = moment(reminder.reminderEndTime).local().format('YYYY-MM-DD HH:mm')
    }

    return (
      <div key={ index } className='card reminder flex-box content-space-between'>
        <div>
          <h2 style={{ fontWeight: 'bold' }}>
            { reminder.title }
          </h2>

          <div>
            { renderEventInfo('arrow-rotate-right', translations.reminders[reminder.repeatType]) }
          </div>

          <div>
            { renderEventInfo('calendar-clock', formatDate(startLocalTime)) }
          </div>

          { endLocalTime &&
            <div>
              { renderEventInfo('calendar-xmark', formatDate(endLocalTime)) }
            </div>
          }
        </div>

        <DropMenu
          dropMenuData={[
            { title: translations.notifications.edit, icon: 'edit', onClick: () => editReminder(reminder) },
            { title: translations.notifications.remove, icon: 'trash', onClick: () => removeReminder(reminder._id.$oid) }
          ]}
        />
      </div>
    )
  }

  function renderEventInfo(icon, text) {
    return (
      <div className='flex-box items-center' style={{ marginTop: 10 }}>
        <div className='content-center items-center'>
          <i className={ `fa-light fa-${ icon }` } style={{ color: appProps.colorScheme }}></i>
        </div>

        <div className='flex-box flex-wrap' style={{ marginLeft: 10 }}>
          { text }
        </div>
      </div>
    )
  }

  function renderEndDate(option) {
    return (
      <div className='flex-box items-center'>
        <div style={{ marginRight: 10 }}>
          { option.title }
        </div>

        <MuiPickersUtilsProvider utils={ MomentUtils } locale={ appProps.currentLocale || 'en' }>
          <DateTimePicker
            autoOk
            ampm={ false }
            disablePast
            disableToolbar
            locale={ appProps.currentLocale || 'en' }
            format={ 'DD/MM/YYYY HH:mm' }
            minutesStep={ 5 }
            okLabel={ translations.general.confirm }
            cancelLabel={ translations.general.cancel }
            clearLabel={ translations.general.clear }
            className='calendar-input to'
            value={ selectedEndDateTime }
            minDate={ selectedStartDateTime }
            onChange={ setSelectedEndDateTime }
          />
        </MuiPickersUtilsProvider>
      </div>
    )
  }

  return (
    <>
      <div className='flex-box items-center' style={{ minHeight: 120 }}>
        <div className="button pointer" onClick={() => setReminderModalOpen(true) }>
          <i className='icon_plus' style={{ marginRight: 10 }}/>
          { translations.reminders.new_reminder }
        </div>
      </div>

      <div className='flex-box flex-wrap'>
        { taskReminders.map( (reminder, index) => renderItem(reminder, index) ) }
      </div>

      { reminderModalOpen &&
        <Modal
          visible
          closeModal={ closeModal }
          mode='medium'
          acceptAction={ handleNextStep }
          saveText={ step === 'save' ? translations.general.apply : translations.reminders.set_reminder }
          abortText={ translations.general.back }
          abortAction={ step === 'save' ? () => setStep('edit') : null }
          disabledAction={ !selectedStartDateTime }
        >
          { step === 'edit' &&
            <>
              <div style={{ marginBottom: 15 }}>
                <label>
                  { translations.general.title }
                </label>
                <input type='text' value={ title } onChange={ event => setTitle(event.target.value)} />
              </div>

              <div style={{ marginBottom: 15 }}>
                <label>
                  { translations.reminders.frequency }
                </label>
                <div style={{ marginTop: 10 }} className="flex-box items-center contents-space-between flex-wrap">
                  { frequencies.map( frequency => (
                    <div
                      key={ frequency }
                      className={`button pointer ${ selectedFrequency !== frequency ? 'secondary' : ''}`}
                      style={{ marginRight: 10 }}
                      onClick={ () => setSelectedFrequency(frequency) }
                    >
                      { translations.reminders[frequency] }
                    </div>
                  ))}
                </div>
              </div>

              <div className='flex-box flex-column' style={{ marginBottom: 15 }}>
                <label>
                  { translations.reminders.start_date }
                </label>
                <MuiPickersUtilsProvider utils={ MomentUtils } locale={ appProps.currentLocale || 'en' }>
                  <DateTimePicker
                    autoOk
                    ampm={false}
                    disablePast
                    disableToolbar
                    locale={ appProps.currentLocale || 'en' }
                    format={ 'DD/MM/YYYY HH:mm' }
                    minutesStep={ 5 }
                    okLabel={ translations.general.confirm }
                    cancelLabel={ translations.general.cancel }
                    className={'calendar-input from'}
                    value={ selectedStartDateTime }
                    maxDate={ selectedEndDateTime || undefined }
                    onChange={ setSelectedStartDateTime }
                  />
                </MuiPickersUtilsProvider>
                {
                  !selectedStartDateTime &&
                  <div style={{ color: 'red', fontSize: '12px' }}>{ translations.reminders.set_new_start_date }</div>
                }
              </div>

              { selectedFrequency === 'weekly' &&
                <>
                  <div style={{ marginBottom: 15 }}>
                    <label>
                      { translations.reminders.repeat_on }
                    </label>
                    <div className="flex-box items-center contents-space-between" style={{ marginTop: 10 }}>
                      { weekdays.map( weekday => (
                        <div
                          key={ weekday }
                          className={ `button pointer ${ repeatOn.includes(weekday) ? '' : 'secondary' }` }
                          style={{ marginRight: 10 }}
                          onClick={ () => handleRepeatOn(weekday)}
                        >
                          { translations.general[weekday][0] }
                        </div>
                      ))}
                    </div>
                  </div>

                  <div style={{ marginBottom: 15 }}>
                    <label>
                      { translations.reminders.repeat_every }
                    </label>

                    <div className='flex-box items-center'>
                      <NumberInput
                        number={ repeatEvery }
                        setNumber={ setRepeatEvery }
                        min={ 1 }
                      />
                      <div style={{ marginLeft: 15 }}>
                        { translations.general.weeks }
                      </div>
                    </div>
                  </div>
                </>
              }

              { selectedFrequency !== 'once' &&
                <div className='flex-box flex-column' style={{ marginBottom: 15 }}>
                  <label>
                    { translations.reminders.end_date }
                  </label>

                  <RadioInput
                    options={ endDateOptions }
                    currentOption={ endOption }
                    setCurrentOption={ option => setEndOption(option.key) }
                  />
                </div>
              }
            </>
          }

          { step === 'save' &&
            <div className='flex-box content-space-between flex-column' style={{ height: '100%' }}>
              <div style={{ background: '#F9F9F9', padding: 20 }}>
                <div style={{ fontWeight: 'bold' }}>
                  { title }
                </div>

                <div>
                  { renderEventInfo('arrow-rotate-right', translations.reminders[selectedFrequency]) }
                </div>

                <div>
                  { renderEventInfo('calendar-clock', formatDate(selectedStartDateTime)) }
                </div>

                { selectedFrequency !== 'once' &&
                  <div>
                    { renderEventInfo('calendar-xmark', endOption === 'never' ? translations.general.never : formatDate(selectedEndDateTime)) }
                  </div>
                }
              </div>

              <div className='flex-box flex-column' style={{ marginBottom: 15 }}>
                <label>
                  { translations.reminders.receive_via }
                </label>
                <RadioInput
                  options={ notificationTypesArray.map( option => ({ key: option, title: translations.reminders[option] }) ) }
                  currentOption={ notificationType }
                  setCurrentOption={ option => setNotificationType(option.key) }
                />
              </div>
            </div>
          }
        </Modal>
      }
    </>
  )
}
