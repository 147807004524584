import React,{useEffect, useState, useRef} from 'react'
import { Switch } from '@material-ui/core'
import { detectBrowser, detectOs } from '../../../helpers/utils'
import moment from 'moment'

export default function NotificationSettings({ appProps, pageName, pageTitle }) {
  const Notification = window.Notification || window.mozNotification || window.webkitNotification

  const [browserAllowsNotifications, setBrowserAllowsNotifications] = useState(Notification.permission !== 'denied')
  const [deviceToken, setDeviceToken] = useState(null)
  const [userDevices, setUserDevices] = useState(appProps.userDevices)
  const [enabledNotifications, setEnabledNotifications] = useState(appProps.hasEnabled)
  const isFirstRef = useRef(true)
  const browser = detectBrowser()
  const os = detectOs()

  const { translations, subButton, setSubButton, showSubButton } = appProps

  useEffect( () => {
    if(appProps.user) {
      if ('permissions' in navigator) {
        navigator.permissions.query({name:'notifications'}).then(function(notificationPerm) {
          notificationPerm.onchange = function() {
             if (notificationPerm.state === 'denied') {
              setBrowserAllowsNotifications(false)
            }
          }
        })
      }
    }
  }, [appProps])

  function handleToggleNotifications() {
    setEnabledNotifications( prevState => !prevState )

    let fd = new FormData()
    fd.append('enabled_notifications', !enabledNotifications)

    Rails.ajax({
      type: 'PATCH',
      url: appProps.enableNotificationsPath,
      dataType: 'json',
      data: fd,
      success: res => {}
    })
  }

  function checkToken(action) {
    WonderPush.push(function() {
      WonderPush.getDeviceId().then(res => {
        Rails.ajax({
          type: 'GET',
          url: appProps.checkDevicesCountPath + `?device_token=${res}`,
          dataType: 'json',
          success: result => {
            if(action === 'subscribe') {
              if(result.devicesCount >= 1) {
                WonderPush.getInstallationId().then(id => {
                  let fd = new FormData()

                  fd.append('device_token', res)
                  fd.append('installation_id', id)
                  fd.append('browser', browser)
                  fd.append('os', os)

                  Rails.ajax({
                    type: 'POST',
                    url: appProps.createDevicePath,
                    dataType: 'json',
                    data: fd,
                    success: res => {}
                  })
                  appProps.flashMessage.show(translations.notifications.successfully_subscribed, 'success')
                  setSubButton(false)
                })
              }
              else {
                WonderPush.setNotificationEnabled(true)
              }
            }
            else if(action === 'unsubscribe') {
              if(result.devicesCount >= 2) {
                let fd = new FormData()
                fd.append('device_token', res)

                Rails.ajax({
                  type: 'DELETE',
                  url: appProps.deleteSubscriptionPath,
                  dataType: 'json',
                  data: fd,
                  success: res => {}
                })
                setSubButton(true)
              }
              else {
                WonderPush.setNotificationEnabled(false)
              }
            }
          }
        })
      })
    })
  }

  function removeDevice(device) {
    setUserDevices(userDevices.filter(d => d.device_token != device.device_token))

    let fd = new FormData()
    fd.append('device_token', device.device_token)

    Rails.ajax({
      type: 'DELETE',
      url: appProps.deleteSubscriptionPath,
      dataType: 'json',
      data: fd,
      success: res => {}
    })
  }

  function renderItem(device) {
    return (
      <div key={ device.device_token } className= 'flex-box item-center table-row'>
        <div className='table-cell flex-1'>
          <i className={ `${ device.device_type }_icon` }/>
        </div>

        <div className='table-cell flex-1'>
          <i className={ `fa-brands fa-${ ['mac', 'ios'].includes(device.device_os) ? 'apple' : device.device_os }` }/>
        </div>

        <div className='table-cell flex-1'>
          { device.device_browser &&
            <i className={ `fa-brands fa-${ device.device_browser }` }/>
          }
        </div>

        <div className='table-cell flex-1'>
          { moment(device.created_at).format('DD/MM/YY HH:mm') }
        </div>

        <div className='table-cell head flex-1'>
          <button
            className='button'
            onClick={ () => removeDevice(device) }
          >
            { translations.notifications.remove }
          </button>
        </div>
      </div>
    )
  }

  return (
    <div className={ pageName }>
      <div className='section-title'>
        <h3>{ pageTitle }</h3>
      </div>

      { browser == 'safari' ?
        <h3 style={{ marginTop: 20 }}>
          { translations.notifications.safari }
        </h3>
      :
        <>
          <div className='flex-box items-center card'>
            <div className='label'>
              { translations.notifications.enable }
            </div>
            <Switch
              checked={ enabledNotifications }
              onChange={ handleToggleNotifications }
            />
          </div>

          { enabledNotifications &&
            <>
              { browserAllowsNotifications && showSubButton &&
                <button
                  className='button'
                  onClick={ () => checkToken(subButton ? 'subscribe' : 'unsubscribe') }
                >
                  { translations.notifications[subButton ? 'subscribe' : 'unsubscribe'] }
                </button>
              }

              { !browserAllowsNotifications &&
                <div style={{ marginTop: 20 }}>
                  { translations.notifications.reset_notification_settings }
                </div>
              }

              { userDevices.length > 0 ?
                <div className='card table-wrapper'>
                  <div className='table' style={{ marginTop: 20 }}>
                    <div className= 'flex-box item-center table-head'>
                      <div className='table-cell head flex-1'>{ translations.notifications.device_type }</div>
                      <div className='table-cell head flex-1'>{ translations.notifications.os }</div>
                      <div className='table-cell head flex-1'>{ translations.notifications.browser }</div>
                      <div className='table-cell head flex-1'>{ translations.notifications.subscribed_at }</div>
                      <div className='table-cell head flex-1'>{ translations.notifications.delete_subscription }</div>
                    </div>

                    { userDevices.filter( device => device.device_token != deviceToken ).map( device => renderItem(device) ) }
                  </div>
                </div>

              :
                <div style={{ marginTop: 20 }}>
                  { translations.notifications.no_subscriptions }
                </div>
              }
            </>
          }
        </>
      }
    </div>
  )
}
