import React from 'react'

export default function Pdf({ task, courseLocale }) {
  const file = task.settings.translations[courseLocale] &&
  task.settings.translations[courseLocale].media &&
  task.settings.translations[courseLocale].media.length &&
  task.settings.translations[courseLocale].media[0].source

  if (file) {
    return (
      <>
        <iframe
          type='application/pdf'
          src={ file }
          height='100%'
          width='100%'
        />
      </>
    )
  } else {
    return null
  }
}
