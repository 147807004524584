import React, { useState, useEffect } from 'react'

import { Content } from '@reactiveonline/frontend_shared_components/builder'
import { additionalElements } from '../builder/additionalElements'

export default function ShowTaskBuilderContent({
  appProps, task, courseLocale
}) {
  return (
    <div className='page-builder'>
      { !!task.settings.translations[courseLocale].html &&
        <Content
          appProps={{
            ...appProps,
            pageBuilderAdditionalElements: additionalElements({ appProps: appProps })
          }}
          content={ task.settings.translations[courseLocale].html }
        />
      }
    </div>
  )
}
