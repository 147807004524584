export function downloadIcs(startDate, endDate, uid, location, title) {
  const eventStartDate = icsDateFormat(startDate)
  const eventEndDate = icsDateFormat(endDate)
  // const reoccurUntilDate = icsDateFormat(settings.recurringUntil)

  // if both end date and duration => duration prevails
  // if both recurring times and recurring until =>  recurring times prevail
  const icsMSG = "BEGIN:VCALENDAR\nVERSION:2.0\nBEGIN:VEVENT"
    + "\nUID:"
    +  uid
    + "\nDTSTART:"
    +  eventStartDate
    + "\nDTEND:"
    +  eventEndDate
    + "\nLOCATION:"
    + location
    // + "\nATTENDEE;CN=My Self ;RSVP=TRUE:MAILTO:me@gmail.com"
    // + "\nORGANIZER;CN=Me:MAILTO::me@gmail.com"
    // + "\nDURATION:"
    // + "PT" + settings.translations[courseLocale].duration + "M"
    // + "\nRRULE:FREQ="
    // + getReoccurrence(settings.repeat, settings.reccuring)
    // + ";INTERVAL="
    // + settings.repeat
    // + ";COUNT="
    // + settings.recurringTimes
    // + ";UNTIL="
    // + reoccurUntilDate
    + "\nSUMMARY:"
    + title
    + "\nEND:VEVENT\nEND:VCALENDAR"

    window.open('data:text/calendar;charset=utf8,' + escape(icsMSG))
}

function icsDateFormat(date){
  if ( date != null ) {
    let icsDate = splitMulti(date,['-',':','.'])
    icsDate[icsDate.length - 1] = 'Z'
    return icsDate.join("")
  }
}

// function getReoccurrence(repeat, recurring) {
//   if (!repeat || repeat === '0') {
//     return null
//   } else {
//     switch(recurring) {
//       case 'days':
//         return 'DAILY'
//       case 'weeks':
//         return 'WEEKLY'
//       case 'months':
//         return 'MONTHLY'
//     }
//   }
// }

function splitMulti(str, tokens){
  let tempChar = tokens[0]
  for (let i = 1; i < tokens.length; i++) {
    str = str.split(tokens[i]).join(tempChar)
  }
  str = str.split(tempChar)

  return str
}
