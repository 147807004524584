import React, { useState, useMemo } from 'react'
import { Content } from '@reactiveonline/frontend_shared_components/builder'
import { additionalElements } from '../../builder/additionalElements'

export default function PageBuilder({ task, courseLocale, translations, appProps }) {
  const [loadBuilder, setLoadBuilder] = useState(false)

  const pageContent = useMemo( () => {
    return (
      <div className='page-builder'>
        <div className='close_icon flex-box items-center content-center' onClick={ () => { setLoadBuilder(false) } }></div>
        { !!task.settings.translations[courseLocale].html &&
          <Content
            appProps={{
              ...appProps,
              pageBuilderAdditionalElements: additionalElements({ appProps: appProps })
            }}
            content={ task.settings.translations[courseLocale].html }
          />
        }
      </div>
    )
  }, [task, appProps])

  return (
    <>
      <div className='flex-box items-center content-center' style={{ width: '100%', height: '100%' }} onClick={ () => setLoadBuilder(true) }>
        <div className='launch-content' style={{ backgroundImage: `url(${ task.settings.featuredImage ? task.settings.featuredImage.source : 'https://storage.googleapis.com/mindseed_global_assets/hero_image_mindfulness' })` }}></div>
        <div className='button launch'>
          { translations.lxp.launch_content }
        </div>
      </div>
      { loadBuilder && pageContent }
    </>
  )
}
