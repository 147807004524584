import React from 'react'
import TaskComments from '../../comments/TaskComments'

export default function Discussion({ settingsTranslation, userId, entityType, entityUid, taskId, commentsPath, translations }) {
  return (
      <>
        { settingsTranslation && settingsTranslation.seedQuestion && settingsTranslation.seedQuestion !== true &&
          <div className='task-content-container' style={{ marginTop: '40px', width: '100%' }}>
            <div className='content-socket-container' style={{ minHeight: 0 }}>
              <div>
                <div className='seed-question-wrapper'>
                  <div className='seed-question flex-box flex-1 items-start'>
                    <i className='fa-thin fa-comments-question'/>
                    <div
                      className='text'
                      style={{ lineHeight: '1.2em' }}
                      dangerouslySetInnerHTML={{ __html: settingsTranslation.seedQuestion.replaceAll('\n', '<br/>') }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        }

        <div style={{ marginTop: '30px' }}>
          <TaskComments
            userId = { userId }
            entityType={ entityType }
            entityUid={ entityUid }
            taskId={ taskId }
            commentsPath={ commentsPath }
            translations={ translations }
          />
        </div>
      </>
  )
}
