import React from 'react'

export default function Description({description}) {

  return (
    <>
      <div dangerouslySetInnerHTML={{__html: description }} className='body-text description editor'></div>
    </>
  )
}
