import React, { useState, useEffect } from 'react'
import TabItem from '../shared/TabItem'
import ListItem from '../shared/ListItem'
import Modal from '../shared/Modal'
import BreathingSettings from './breathing/BreathingSettings'
import { NO_IMAGE_SOURCE } from '../GlobalConstants'

export default function Breathings({
  appProps, screenClass, breathings, courseLocale, defaultLocale, requestedEntity,
  timerEnabled, videoPractices
}) {
  const [breathingSettingsOpen, setBreathingSettingsOpen] = useState(false)
  const [selectedBreathing, setSelectedBreathing] = useState(null)
  const { translations } = appProps

  const tabData = timerEnabled ?
    [
      { title: translations.mindfulness.guided_meditations, path: appProps.mindfulnessPath },
      { title: translations.mindfulness.meditation_timer, path: appProps.silentTimerPath },
      { title: translations.breathing.breathing_guide, path: appProps.breathingsPath }
    ]
  :
    [{ title: translations.breathing.breathing_guide, path: appProps.breathingsPath }]

  if (videoPractices.length > 0) {
    tabData.push({ title: translations.mindfulness.mindfulness_videos, path: appProps.videoPracticesPath })
  }

  useEffect( () => {
    if (requestedEntity) {
      openBreathingModalFromTile(requestedEntity)
    }
  }, [])

  function openBreathingModalFromTile(breathing, event = null) {
    if (event) {
      event.preventDefault()
    }
    setSelectedBreathing(breathing)
    setBreathingSettingsOpen(true)
  }

  function getTranslation(translations) {
    if (courseLocale && translations[courseLocale]) {
      return translations[courseLocale]
    } else if (appProps.currentLocale && translations[appProps.currentLocale]) {
      return translations[appProps.currentLocale]
    } else if (defaultLocale && translations[defaultLocale]) {
      return translations[defaultLocale]
    } else {
      return translations[Object.keys(translations)[0]]
    }
  }

  function closeModal() {
    setBreathingSettingsOpen(false)
    setSelectedBreathing(null)
  }

  return(
    <div className={ `lxp-wrapper ${ screenClass }` }>
      <div className='section-hero'>
        <div className='section heading' style={{ height: '100%' }}>
          <div className='main-container flex-box items-center' style={{ height: '100%' }}>
            <h1>{ translations.general.mindfulness }</h1>
          </div>
        </div>
        <div className='main-container'>
          <div className='flex-box flex-wrap items-center content-start tabs-index' style={{ zIndex: 2 }}>
            { tabData.map( (data, index) => (
              <TabItem
                key={ index }
                title={ data.title }
                path={ data.path }
              />
            ))}
          </div>
        </div>
      </div>

      <div style={{ paddingTop: 80 }}>
        { breathings.length > 0 &&
          <div className='main-container'>
            <div className='section-title flex-box content-space-between'>
              <h2>{ translations.breathing.breathing_guide }</h2>
            </div>
            <div className='section-divider'></div>
            <div className='list-item-wrapper'>
              { breathings.map( (breathing, index) => {
                const bSettings = breathing.content.settings
                const stripedHtml = `${ (getTranslation(bSettings.translations).description || '').replace(/<[^>]+>/g, '').substring(0, 150) } ...`
                return (
                  <ListItem
                    key={ index }
                    appProps={ appProps }
                    title={ getTranslation(bSettings.translations).title || '' }
                    description={ stripedHtml }
                    actionPath={ '#' }
                    quickMenu
                    entityUid={ breathing.id }
                    entityType='MindseedLms::Library'
                    actionExtra={ event => openBreathingModalFromTile(breathing, event) }
                    image={ bSettings.featuredImage ? bSettings.featuredImage.source : NO_IMAGE_SOURCE }
                  />
                )})
              }
            </div>
          </div>
        }

        { breathingSettingsOpen &&
          <BreathingSettings
            appProps={ appProps }
            title={ getTranslation(selectedBreathing.content.settings.translations).title }
            description={ getTranslation(selectedBreathing.content.settings.translations).description }
            selectedBreathing={ selectedBreathing }
            onClose={ closeModal }
          />
        }

      </div>
    </div>
  )
}
