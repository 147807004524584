import React, { useState } from "react"

export default function Delete({ appProps, pageName, pageTitle }) {
  const [loading, setLoading] = useState(false)
  const { translations } = appProps

  function deleteAccount() {
    setLoading(true)

    Rails.ajax({
      type: 'POST',
      url: '/account/delete',
      dataType: 'json',
      success: res => {
        window.location.href = `/`
      },
      error: res => {
        setLoading(false)
      }
    })
  }

  return (
    <>
      <div className='section-title'>
        <h3>{ pageTitle }</h3>
      </div>
      <div className={ `flex-box content-start ${pageName}` }>
        <div className='delete-box'>
          <div className='image-wrapper'>
            <img src='https://images.unsplash.com/photo-1499946981954-e7f4b234d7fa?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80'/>
          </div>
          <div className='notice-wrapper'>
            { appProps.user.fullname &&
              <h2> { appProps.user.fullname } </h2>
            }
            <h2> { translations.account.delete_alert_title } </h2>
            <div>
              { translations.account.delete_alert_description }
            </div>
            <div className='button-wrapper flex-box content-end' style={{ marginTop: 20 }}>
              { loading ?
                <div>
                  { translations.account.deleting }
                </div>
                :
                <button type='submit' id='delete-account' className='button' onClick={ deleteAccount }>
                  { translations.account.confirm_delete }
                </button>
              }
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
