import React, { useState, useEffect } from 'react'
import Comment from './Comment'
import consumer from 'channels/consumer'
import Modal from '../shared/Modal'
import { useLoadMore } from '../../helpers/useLoadMore'

const itemsPerPage = 3

export default function TaskComments({ userId, entityType, entityUid, taskId, commentsPath, translations }) {
  const [comments, setComments] = useState([])
  const [fetchComments, totalComments, setTotalComments] = useLoadMore(comments, setComments, itemsPerPage, null)
  const [newComments, setNewComments] = useState([])
  const [input, setInput] = useState('')
  const [deleteModalOpen, setDeleteModalOpen] = useState(false)
  const [dataToDelete, setDataToDelete] = useState(null)
  const [userHasCommented, setUserHasCommented] = useState(false)
  const inputChangeHandler = (e) => setInput(e.target.value)

  useEffect(() => {
    fetchComments(`${ commentsPath }?commentable_type=${ entityType }&commentable_uid=${ entityUid }&task_id=${ taskId }`, true)
    setNewComments([])
    setInput('')
  }, [taskId])

  useEffect(() => {
    const cable = consumer.subscriptions.create(
      {
        channel: 'CommentsChannel',
        commentable_type: entityType,
        commentable_uid: entityUid,
        task_id: taskId
      },
      {
        received: data => {
          receivedComment(data)
        },
      }
    )

    return () => {
      consumer.subscriptions.remove(cable)
    }
  }, [taskId])

  useEffect(() => {
    if(comments.length){
      setUserHasCommented(!!comments.find(comment => comment.user.id === userId || (comment.replies && !!comment.replies.find(reply => reply.user.id == userId))))
    }
    if (newComments.length) {
      setNewComments((newStateNewComments) => {
        return [...newStateNewComments.filter(item => comments.find(c => c.id == item.id) == null)]
      })
    }
  }, [comments])

  useEffect( () => {
    if(newComments.length){
      setUserHasCommented(!!newComments.find(comment => comment.user.id === userId || (comment.newReplies && !!comment.newReplies.find(reply => reply.user.id == userId))))
    }
  }, [newComments])

  const channelActionHandler = (state, data) => {
    let newState = [...state]
    const { action } = data
    const { parent, comment, comment_id } = data.data

    let parentComment = newState.find(element => element.id == parent)
    if (!parentComment) {
      return state
    }

    const { replies, newReplies, totalReplies } = parentComment
    switch (action) {
      case "Create":
        newReplies ? parentComment.newReplies = [...newReplies, comment] : parentComment.newReplies = [comment]
        break
      case "Update":
        if (!replies) break

        let reply = replies.find(element => element.id == comment.id)
        if (reply) {
          reply.content = comment.content
        }
        else if (newReplies) {
          reply = newReplies.find(element => element.id == comment.id)
          if (reply) {
            reply.content = comment.content
          }
        }
        break
      case "Delete":
        let deletedComment = replies.find(rep => rep.id == comment_id)

        if (deletedComment) {
          parentComment.replies = replies.filter((element => element.id != comment_id))
          parentComment.totalReplies -= 1
        }
        else if (newReplies) {
          deletedComment = newReplies.find(rep => rep.id == comment_id)
          if (deletedComment) {
            parentComment.newReplies = newReplies.filter((element => element.id != comment_id))
          } else {
            parentComment.totalReplies -= 1
          }
        } else {
          parentComment.totalReplies -= 1
        }
        break
    }

    return newState
  }

  function receivedComment(data) {
    const { action } = data
    const { parent, comment, comment_id } = data.data

    if (parent === null) {
      switch (action) {
        case "Create":
          setNewComments((prevComments) => {
            return [...prevComments, comment]
          })
          break
        case "Update":
          setComments((prevComments) => {
            let newStateComments = [...prevComments]
            let editedComment = newStateComments.find((element => element.id == comment.id))
            if (editedComment) {
              editedComment.content = comment.content
              editedComment.user = comment.user
            }

            return newStateComments
          })

          setNewComments((prevNewComments) => {
            let newStateNewComments = [...prevNewComments]
            let editedComment = newStateNewComments.find((element => element.id == comment.id))
            if (editedComment) {
              editedComment.content = comment.content
              editedComment.user = comment.user
            }

            return newStateNewComments
          })
          break
        case "Delete":
          setComments((newStateComments) => {
            let array = newStateComments.filter((element => element.id != comment_id))
            setTotalComments(array.length)
            return array
          })

          setNewComments((newStateNewComments) => {
            let array = newStateNewComments.filter((element => element.id != comment_id))
            return array
          })
          break
      }
    } else {
      setComments((newStateComments) => { return channelActionHandler(newStateComments, data) })
      setNewComments((newStateNewComments) => { return channelActionHandler(newStateNewComments, data) })
    }
  }

  function fetchCommentsHandler() {
    fetchComments(`${ commentsPath }?commentable_type=${ entityType }&commentable_uid=${ entityUid }&task_id=${ taskId }`)
  }

  function submitHandler() {
    if (input.trim() === "") {
      return
    }

    let fd = new FormData()
    fd.append('comment[content]', input)

    Rails.ajax({
      type: 'POST',
      url: `${ commentsPath }?commentable_type=${ entityType }&commentable_uid=${ entityUid }&task_id=${ taskId }`,
      dataType: 'json',
      data: fd,
      success: res => {
        setInput("")
      },
      error: res => {
      }
    })
  }

  function setComment(commentId, key, value) {
    let newStateComments = [...comments]
    let newStateNewComments = [...newComments]
    let comment = newStateComments.find(c => c.id == commentId) || newStateNewComments.find(c => c.id == commentId)

    if (!comment) {
      return
    }
    comment[key] = value
    setComments(newStateComments)
    setNewComments(newStateNewComments)
  }

  function likeCommentHandler(parentId, commentId) {
    let fd = new FormData()
    fd.append('reply_id', parentId ? commentId: '')

    Rails.ajax({
      type: 'POST',
      url: `${ commentsPath }/${ parentId ? parentId : commentId }/like?commentable_type=${ entityType }&commentable_uid=${ entityUid }&task_id=${ taskId }`,
      dataType: 'json',
      data: fd,
      success: res => {
        let newStateComments = [...comments]
        let newStateNewComments = [...newComments]
        let comment = null

        if (parentId) {
          let parentComment = newStateComments.find(c => c.id == parentId) || newStateNewComments.find(c => c.id == parentId)
          if (parentComment) {
            comment = parentComment.replies.find(c => c.id == commentId) || parentComment.newReplies.find(c => c.id == commentId)
          }
        } else {
          comment = newStateComments.find(c => c.id == commentId) || newStateNewComments.find(c => c.id == commentId)
        }

        if (comment) {
          comment.likeByUser = !res.action
          comment.totalLikes = res.action ? comment.totalLikes - 1 : comment.totalLikes + 1
        }

        setComments(newStateComments)
        setNewComments(newStateNewComments)
      },
      error: res => {
      }
    })
  }

  function editCommentHandler(parentId, commentId, content) {
    let fd = new FormData()
    fd.append('comment[content]', content)
    fd.append('reply_id', parentId ? commentId : '')

    Rails.ajax({
      type: 'PATCH',
      url: `${ commentsPath }/${ parentId ? parentId : commentId }?commentable_type=${ entityType }&commentable_uid=${ entityUid }&task_id=${ taskId }`,
      dataType: 'json',
      data: fd,
      success: res => {
      },
      error: res => {
      }
    })
  }

  function deleteCommentHandler() {
    const { parentId, commentId } = dataToDelete
    let fd = new FormData()
    fd.append('reply_id', parentId ? commentId : '')

    Rails.ajax({
      type: 'DELETE',
      url: `${ commentsPath }/${ parentId ? parentId : commentId }?commentable_type=${ entityType }&commentable_uid=${ entityUid }&task_id=${ taskId }`,
      dataType: 'json',
      data: fd,
      success: res => {},
      error: res => {},
      complete: res => {
        closeDeleteModal()
      }
    })
  }

  function openDeleteModal(parentId, commentId) {
    setDeleteModalOpen(true)
    setDataToDelete({ parentId: parentId, commentId: commentId })
  }

  function closeDeleteModal() {
    setDeleteModalOpen(false)
    setDataToDelete(null)
  }

  return (
    <>
      <div className='flex-box flex-column items-start'>
        { !userHasCommented &&
          <div className='warning-wrapper flex-box items-center'>
            <div className='warning_icon clickable-link' style={{ marginRight: 10, fontSize: 20 }}></div>
            <div>{ translations.lxp.no_contribution }</div>
          </div>
        }

        <textarea
          className='comment-area'
          placeholder={ `${translations.lxp.leave_comment}...`} //&#10;
          value={ input }
          onChange={ inputChangeHandler }
        />
        <div className='flex-box content-end' style={{ width: '100%' }}>
          <button
            className='button'
            style={{ width: 'auto', marginTop: 30 }}
            onClick={ submitHandler }
          >
            { translations.comments.submit }
          </button>
        </div>
      </div>

      <div className='section-divider' />

      <div className='comment-items-wrapper'>
        { comments.length > 0 && comments.map((comment) => (
          <Comment
            key={ comment.id }
            parent={ null }
            comment={ comment }
            userId={ userId }
            entityType={ entityType }
            entityUid={ entityUid }
            taskId={ taskId }
            level={ 1 }
            commentsPath={ commentsPath }
            setComment={ setComment }
            likeCommentHandler={ likeCommentHandler }
            editCommentHandler={ editCommentHandler }
            deleteCommentHandler={ openDeleteModal }
            translations={ translations }
          />))
        }
      </div>

      <div className='comment-items-wrapper'>
        { newComments.length > 0 && newComments.map((comment) => (
          <Comment
            key={ comment.id }
            parent={ null }
            comment={ comment }
            userId={ userId }
            entityType={ entityType }
            entityUid={ entityUid }
            taskId={ taskId }
            level={ 1 }
            commentsPath={ commentsPath }
            setComment={ setComment }
            likeCommentHandler={ likeCommentHandler }
            editCommentHandler={ editCommentHandler }
            deleteCommentHandler={ openDeleteModal }
            translations={ translations }
          />))
        }
      </div>

      { comments.length < totalComments &&
        <div className="flex-box content-start">
          <div
            className="clickable-link pointer"
            style={{ paddingTop: 30 }}
            onClick={ () => fetchComments(`${ commentsPath }?commentable_type=${ entityType }&commentable_uid=${ entityUid }&task_id=${ taskId }`) }>
              { `${ translations.comments.load_more } (${ totalComments - comments.length })` }
          </div>
        </div>
      }

      { deleteModalOpen &&
        <Modal
          visible={ deleteModalOpen }
          closeModal={ closeDeleteModal }
          mode='small'
          elementsClass='items-center content-center centered'
          icon='trash'
          abortText={ translations.general.cancel }
          abortAction={ closeDeleteModal }
          saveText={ translations.general.button_accept }
          acceptAction={ deleteCommentHandler }
        >
          <div>
            { translations.comments.delete_comment_message }
          </div>
        </Modal>
      }
    </>
  )
}
