import React, { useState, useEffect } from 'react'
import Modal from '../shared/Modal'
import ListItem from '../shared/ListItem'
import TabItem from '../shared/TabItem'
import { NO_IMAGE_SOURCE } from '../GlobalConstants'
import cookie from 'react-cookies'
import { getCookieExpirationDate } from '../../helpers/utils'
import Settings from './timer/Settings'
import Timer from './timer/Timer'
import { getTranslation } from '@reactiveonline/frontend_shared_components/utils'

const timerDefaultSettings = {
  preparationTime: 0,
  silenceEnabled: true,
  silence: 1,
  startGongEnabled: true,
  startingGong: 1,
  startGongVolume: 0.7,
  endGongEnabled: true,
  endingGong: 1,
  endGongVolume: 0.7
}

export default function Mindfulness({
  appProps, screenClass, timers, breathings, userCourseTimers, recentPractices,
  requestedEntity, videoPractices
}) {
  const [loading, setLoading]                     = useState(false)
  const [settingsModalOpen, setSettingsModalOpen] = useState(false)
  const [exerciseModalOpen, setExerciseModalOpen] = useState(false)
  const [exerciseTimer, setExerciseTimer]         = useState(null)
  const [exerciseCookie, setExerciseCookie]       = useState( () => cookie.load('exerciseCookie') )

  const timerSettings = exerciseCookie || timerDefaultSettings

  const [preparationTime, setPreparationTime]     = useState(timerSettings.preparationTime)
  const [silenceEnabled, setSilenceEnabled]       = useState(timerSettings.silenceEnabled)
  const [silence, setSilence]                     = useState(timerSettings.silence)
  const [startGongEnabled, setStartGongEnabled]   = useState(timerSettings.startGongEnabled)
  const [endGongEnabled, setEndGongEnabled]       = useState(timerSettings.endGongEnabled)
  const [startingGong, setStartingGong]           = useState(timerSettings.startingGong)
  const [endingGong, setEndingGong]               = useState(timerSettings.endingGong)
  const [startGongVolume, setStartGongVolume]     = useState(timerSettings.startGongVolume)
  const [endGongVolume, setEndGongVolume]         = useState(timerSettings.endGongVolume)

  const {
    translations, mindfulnessPath, silentTimerPath, breathingsPath, videoPracticesPath,
    currentLocale, defaultLocale
  } = appProps

  let tabData = [
    { title: translations.mindfulness.guided_meditations, path: mindfulnessPath },
    { title: translations.mindfulness.meditation_timer, path: silentTimerPath }
  ]

  if (breathings.length > 0) {
    tabData.push({ title: translations.breathing.breathing_guide, path: breathingsPath })
  }

  if (videoPractices.length > 0) {
    tabData.push({ title: translations.mindfulness.mindfulness_videos, path: videoPracticesPath })
  }

  useEffect(() => {
    if (requestedEntity) {
      let timerTranslation = getTranslation(requestedEntity.translations, currentLocale, defaultLocale)
      openSettingsModal(requestedEntity, timerTranslation.title)
    }
  }, [])

  useEffect( () => {
    setPreparationTime(timerSettings.preparationTime)
    setSilenceEnabled(timerSettings.silenceEnabled)
    setSilence(timerSettings.silence)
    setStartGongEnabled(timerSettings.startGongEnabled)
    setEndGongEnabled(timerSettings.endGongEnabled)
    setStartingGong(timerSettings.startingGong)
    setEndingGong(timerSettings.endingGong)
    setStartGongVolume(timerSettings.startGongVolume)
    setEndGongVolume(timerSettings.endGongVolume)
  }, [exerciseCookie])

  function saveExerciseSettings() {
    cookie.save('exerciseCookie',
      {
        preparationTime: preparationTime,
        silenceEnabled: silenceEnabled,
        silence: silence,
        startGongEnabled: startGongEnabled,
        startingGong: startingGong,
        startGongVolume: startGongVolume,
        endGongEnabled: endGongEnabled,
        endingGong: endingGong,
        endGongVolume: endGongVolume
      },
      { expires: getCookieExpirationDate(30), path: '/' }
    )

    setSettingsModalOpen(false)
    setExerciseCookie(cookie.load('exerciseCookie'))
    startTimer()
  }

  function resetSettings() {
    const settings = exerciseTimer ? exerciseTimer.settings : timerDefaultSettings

    setPreparationTime(settings.preparationTime)
    setSilenceEnabled(exerciseTimer ? exerciseTimer.settings.silence > 0 : timerDefaultSettings.silenceEnabled)
    setSilence(settings.silence)
    setStartGongEnabled(exerciseTimer ? !!exerciseTimer.settings.startingGong : timerDefaultSettings.startGongEnabled)
    setEndGongEnabled(exerciseTimer ? !!exerciseTimer.settings.endingGong : timerDefaultSettings.endGongEnabled)
    setStartingGong(settings.startingGong)
    setEndingGong(settings.endingGong)
    setStartGongVolume(timerDefaultSettings.startGongVolume)
    setEndGongVolume(timerDefaultSettings.endGongVolume)
  }

  function openSettingsModal(timer, title, event = null) {
    if(event){
      event.preventDefault()
    }
    setExerciseTimer({ id: timer.id, settings: { ...timer.content.settings }, title: title })
    setSettingsModalOpen(true)
  }

  function closeSettingsModal() {
    setSettingsModalOpen(false)
  }

  function startTimer(timer = null) {
    if (!exerciseTimer && timer) {
      const settings = timer.content.settings

      setPreparationTime(settings.preparationTime)
      setSilenceEnabled(!!settings.silence)
      setSilence(settings.silence)
      setStartGongEnabled(!!settings.startingGong)
      setEndGongEnabled(!!settings.endingGong)
      setStartingGong(settings.startingGong)
      setEndingGong(settings.endingGong)
      setExerciseTimer({ id: timer.id, settings: { ...settings }, title: getTranslation(timer.translations, currentLocale, defaultLocale) })
    }

    setTimeout( () => setExerciseModalOpen(true), 100 )
  }

  function closeExerciseModal() {
    setExerciseModalOpen(false)
    setExerciseTimer(null)
  }

  return (
    <>
      <div className={`lxp-wrapper ${ screenClass }`}>
        <div className='section-hero'>
          <div className='section heading' style={{ height: '100%' }}>
            <div className='main-container flex-box items-center' style={{ height: '100%' }}>
              <h1>{ translations.general.mindfulness }</h1>
            </div>
          </div>
          <div className='main-container'>
            <div className='flex-box flex-wrap items-center content-start tabs-index' style={{ zIndex: 2 }}>
              { tabData.map( (data, index) => (
                <TabItem
                  key={ index }
                  title={ data.title }
                  path={ data.path }
                />
              ))}
            </div>
          </div>
        </div>

        <div style={{ paddingTop: 70 }}>
          <div className='main-container'>
            { recentPractices.length > 0 &&
              <>
                <h2>{ translations.mindfulness.recent_practices }</h2>
                <div className='section-divider'/>
                <div className='list-item-wrapper'>
                  { recentPractices.map( (timer, index) => {
                    const hasTranslation = timer.availableLanguages.map( locale => locale.code ).includes(currentLocale)

                    if (hasTranslation) {
                      const timerTranslation = getTranslation(timer.translations, currentLocale, defaultLocale)
                      const stripedHtml = timerTranslation.description ? `${ timerTranslation.description.replace(/<[^>]+>/g, '').substring(0, 150) } ...` : ''

                      return (
                        <ListItem
                          key={ index }
                          appProps={ appProps }
                          image={ timer.content.settings.featuredImage ? timer.content.settings.featuredImage.source : NO_IMAGE_SOURCE }
                          title={ timerTranslation.title }
                          description={ stripedHtml }
                          actionPath={ '#' }
                          quickMenu
                          entityUid={ timer.id }
                          entityType='MindseedLms::Library'
                          actionExtra={ event => openSettingsModal(timer, timerTranslation.title, event) }
                        />
                      )
                    } else {
                      return null
                    }
                  })}
                </div>
              </>
            }

            { userCourseTimers.length > 0 &&
              <>
                <div className='section-title flex-box content-space-between'>
                  <h2>{ translations.mindfulness.course_timers }</h2>
                </div>
                <div className='section-divider'/>
                <div className='list-item-wrapper'>
                  { userCourseTimers.map( (timer, index) => {
                    const hasTranslation = timer.availableLanguages.map( locale => locale.code ).includes(currentLocale)
                    const timerTranslation = getTranslation(timer.translations, currentLocale, defaultLocale)
                    const stripedHtml = timerTranslation.description ? `${ timerTranslation.description.replace(/<[^>]+>/g, '').substring(0, 150) } ...` : ''

                    if (hasTranslation) {
                      const timerTranslation = getTranslation(timer.translations, currentLocale, defaultLocale)
                      const stripedHtml = timerTranslation.description ? `${ timerTranslation.description.replace(/<[^>]+>/g, '').substring(0, 150) } ...` : ''

                      return (
                        <ListItem
                          key={ index }
                          appProps={ appProps }
                          image={ timer.content.settings.featuredImage ? timer.content.settings.featuredImage.source : NO_IMAGE_SOURCE }
                          title={ timerTranslation.title }
                          description={ stripedHtml }
                          actionPath={ '#' }
                          quickMenu
                          entityUid={ timer.id }
                          entityType='MindseedLms::Library'
                          actionExtra={ event => openSettingsModal(timer, timerTranslation.title, event) }
                        />
                      )
                    } else {
                      return null
                    }
                  })}
                </div>
              </>
            }
            { timers.length > 0 && timers.find(timer => timer.availableLanguages.map( locale => locale.code ).includes(currentLocale)) &&
              <>
                <div className='section-title flex-box content-space-between'>
                  <h2>{ translations.mindfulness.guided_practices }</h2>
                </div>
                <div className='section-divider'/>
                <div className='list-item-wrapper'>
                  { timers.map( (timer, index) => {
                    const hasTranslation = timer.availableLanguages.map( locale => locale.code ).includes(currentLocale)

                    if (hasTranslation) {
                      const timerTranslation = getTranslation(timer.translations, currentLocale, defaultLocale)
                      const stripedHtml = timerTranslation.description ? `${ timerTranslation.description.replace(/<[^>]+>/g, '').substring(0, 150) } ...` : ''

                      return (
                        <ListItem
                          key={ index }
                          appProps={ appProps }
                          image={ timer.content.settings.featuredImage ? timer.content.settings.featuredImage.source : NO_IMAGE_SOURCE }
                          title={ timerTranslation.title }
                          description={ stripedHtml }
                          actionPath={ '#' }
                          quickMenu
                          entityUid={ timer.id }
                          entityType='MindseedLms::Library'
                          actionExtra={ event => openSettingsModal(timer, timerTranslation.title, event) }
                        />
                      )
                    } else {
                      return null
                    }
                  })}
                </div>
              </>
            }
          </div>
        </div>
      </div>

      { settingsModalOpen &&
        <Modal
          visible={ settingsModalOpen }
          closeModal={ closeSettingsModal }
          mode='medium'
          saveText={ translations.mindfulness.start_meditating }
          acceptAction={ saveExerciseSettings }
        >
          <Settings
            appProps={ appProps }
            preparationTime={ preparationTime }
            setPreparationTime={ setPreparationTime }
            silenceEnabled={ silenceEnabled }
            setSilenceEnabled={ setSilenceEnabled }
            silenceTime={ silence }
            setSilenceTime={ setSilence }
            startGongEnabled={ startGongEnabled }
            setStartGongEnabled={ setStartGongEnabled }
            endGongEnabled={ endGongEnabled }
            setEndGongEnabled={ setEndGongEnabled }
            startGongUid={ startingGong }
            setStartGongUid={ option => setStartingGong(option.uid) }
            endGongUid={ endingGong }
            setEndGongUid={ option => setEndingGong(option.uid) }
            startGongVolume={ startGongVolume }
            setStartGongVolume={ setStartGongVolume }
            endGongVolume={ endGongVolume }
            setEndGongVolume={ setEndGongVolume }
            resetState={ resetSettings }
            timerType='guided'
            exerciseTimer= { exerciseTimer }
          />
        </Modal>
      }

      { exerciseModalOpen &&
        <Timer
          appProps={ appProps }
          onCloseModal={ closeExerciseModal }
          translations={ translations }
          prepEnabled={ preparationTime > 0 }
          preparationTime={ preparationTime }
          silenceEnabled={ silenceEnabled }
          silenceMinutes={ silence }
          startGongEnabled={ startGongEnabled }
          endGongEnabled={ endGongEnabled }
          startGongUid={ startingGong }
          endGongUid={ endingGong }
          startGongVolume={ startGongVolume }
          endGongVolume={ endGongVolume }
          timerAudioFile={ exerciseTimer.settings.translations[currentLocale] &&
            exerciseTimer.settings.translations[currentLocale].media &&
            exerciseTimer.settings.translations[currentLocale].media.length &&
            exerciseTimer.settings.translations[currentLocale].media[0].source ||
            null
          }
          timerType='timer'
          timerTitle={ exerciseTimer.title }
          timerId={ exerciseTimer.id }
          timerTranslations={ exerciseTimer.settings.translations }
          mediaId={ exerciseTimer.settings.translations[currentLocale] &&
            exerciseTimer.settings.translations[currentLocale].media &&
            exerciseTimer.settings.translations[currentLocale].media.length &&
            exerciseTimer.settings.translations[currentLocale].media[0].mediaId ||
            null
          }
          courseLocale={ currentLocale }
        />
      }
    </>
  )
}
