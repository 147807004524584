import React, { useState, useEffect } from 'react'
import moment from 'moment'


export default function RemoteEvent({
  task, courseLocale, translations, fetchSettingsPath, courseUid, fetchInstructorsPath
}) {
  const [settings, setSettings] = useState(task.settings)
  const [instructors, setInstructors] = useState([])

  useEffect( () => {
    Rails.ajax({
      url: `${ fetchSettingsPath }?course_uid=${ courseUid }&activity_type=task&activity_id=${ task.id }`,
      type: 'GET',
      dataType: 'json',
      success: result => {
        if (result.settings) {
          setSettings( prevState => ({ ...prevState, ...result.settings }) )
        }
      }
    })
  }, [])

  useEffect( () => {
    if (settings && settings.instructors) {
      Rails.ajax({
        type: 'GET',
        url: `${ fetchInstructorsPath }?course_uid=${ courseUid }&instructor_ids=${ JSON.stringify(settings.instructors) }`,
        dataType: 'json',
        success: result => {
          if (result.instructors) {
            setInstructors(result.instructors)
          }
        }
      })
    }
  }, [settings])

  function renderEventInfo(icon, text) {
    return (
      <div className='flex-box items-center' style={{ marginTop: 10 }}>
        <div className='content-center items-center'>
          <div className={ `${ icon } color-scheme` }/>
        </div>

        <div className='flex-box flex-wrap' style={{ marginLeft: 10 }}>
          { text }
        </div>
      </div>
    )
  }

  return (
    <div className = 'onsite-event-container' style={{ backgroundImage: `url(${ settings.featuredImage ? settings.featuredImage.source : 'https://storage.googleapis.com/mindseed_files/lxp/component_events_placeholder.webp' })` }}>
      <div className='card'>
        <div style={{ marginTop: 10 , marginLeft: 10  }}>
          <h1>
            { settings.translations[courseLocale].title }
          </h1>

          { instructors.length > 0 &&
            <h2>
              { translations.general.with } { instructors.map( instructor => instructor.fullName ).join(', ') }
            </h2>
          }

          { settings.startDate &&
            renderEventInfo('calendar_icon', `${ moment(settings.startDate).format('DD/MM/YY HH:mm') }${ settings.endDate ? ' - ' : '' }${ settings.endDate ? moment(settings.endDate).format('DD/MM/YY HH:mm') : '' }`)
          }

          { settings.eventDuration &&
            renderEventInfo('duration_icon', `${ settings.eventDuration } ${ translations.general.minutes }`)
          }

          { settings.maxParticipants > 0 &&
            renderEventInfo('participants_icon', settings.maxParticipants)
          }
        </div>

        { settings.eventJoinUrl &&
          <div style = {{ position: 'absolute' , bottom: 15 , right: 15 }}>
            <a
              target='_blank'
              href={ settings.eventJoinUrl }
              className='button'
            >
              { translations.event.join_now }
            </a>
          </div>
        }
      </div>
    </div>
  )
}
