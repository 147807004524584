import React, { useState, useEffect, useRef } from 'react'
import image from './bubbles-small'
import { useInterval } from '../../../helpers/useInterval'
import { lrsEvent } from '../../../helpers/utils'

export default function BreathingExercise({
  appProps, courseUid, taskId, duration, pauseAfterExhale, pauseAfterInhale,
  exhaleDuration, inhaleDuration, breathingId, completeBreathing,
  breathingTranslations, taskIsCompleted, taskCompletionRate
}) {
  const [currentTime, setCurrentTime] = useState(0)
  const [animationProgress, _setAnimationProgress] = useState(1)
  const [increase, _setIncrease] = useState(true)
  const [action, setAction] = useState('breathe_in')
  const [durationRemaining, setRemainingDuration] = useState(duration)
  const [sessionUid, setSessionUid] = useState(String(Math.random()).substring(2, 12))

  const durationRemainingRef = useRef(durationRemaining)
  const increaseRef = useRef(true)
  const completedRef = useRef(false)
  const completionRateRef = useRef(taskIsCompleted)
  const animationProgressRef = useRef(1)
  const inhaleStep = 1 / inhaleDuration
  const exhaleStep = 1 / exhaleDuration

  const { translations } = appProps

  useEffect( () => {
    lrsEvent(appProps, breathingTranslations, taskId || breathingId, 'breathing', 'started', {
      associableUid: courseUid,
      sessionUid: sessionUid,
      assignableUid: taskId ? breathingId : null
    })
    setInterval( () => setCurrentTime( prev => prev + 1 ), 1000)

    setAnimationProgress(animationProgressRef.current + inhaleStep)

    setInterval( () => {
      if (animationProgressRef.current >= 101 && increaseRef.current) {
        setIncrease(false)
        if (pauseAfterInhale) {
          setAction('hold')
        }
        setTimeout( () => {
          setAnimationProgress(animationProgressRef.current - inhaleStep)
          setAction('breathe_out')
        }, pauseAfterInhale * 1000)

      } else if (animationProgressRef.current <= 1 && !increaseRef.current) {
        setIncrease(true)
        if (pauseAfterExhale) {
          setAction('hold')
        }
        setTimeout( () => {
          setAnimationProgress(animationProgressRef.current + exhaleStep)
          setAction('breathe_in')
        }, pauseAfterExhale * 1000)

      } else if (animationProgressRef.current < 101 && animationProgressRef.current > 1) {
        setAnimationProgress(animationProgressRef.current + (increaseRef.current ? inhaleStep : (-exhaleStep)) )
      }
    }, 10)

    setInterval( updateRemainingDuration, 1000 )
  }, [])

  useEffect( () => {
    if (!completedRef.current && ((currentTime >= duration) || (taskCompletionRate && (currentTime/duration)*100 >= taskCompletionRate && !completionRateRef.current)) ) {
      if(completeBreathing){
        completeBreathing(sessionUid, !completionRateRef.current, duration)
        if(!completionRateRef.current) {
          completionRateRef.current = true
        }
        else{
          completedRef.current = true
        }
      } else {
        lrsEvent(appProps, breathingTranslations, breathingId, 'breathing', 'completed', {
          associableUid: courseUid,
          sessionUid: sessionUid,
          assignableUid: taskId ? breathingId : null,
          duration: duration
        })
        completedRef.current = true
      }
    }
  }, [currentTime])

  useInterval(() => {
    lrsEvent(appProps, breathingTranslations, taskId || breathingId, 'breathing', 'consumed', {
      associableUid: courseUid,
      timestamp: duration - durationRemainingRef.current,
      sessionUid: sessionUid,
      logTimestamp: true,
      assignableUid: taskId ? breathingId : null,
      duration: duration
    })
  }, ( currentTime < (duration - 1) ) ? appProps.lrsMediaStep : null )

  function setAnimationProgress(state) {
    animationProgressRef.current = state
    _setAnimationProgress(state)
  }

  function setIncrease(state) {
    increaseRef.current = state
    _setIncrease(state)
  }

  function updateRemainingDuration() {
    setRemainingDuration( prevState => {
      const state = prevState - 1
      durationRemainingRef.current = state

      return state
    })
  }

  function secTominutes(duration) {
    let sec, min
    let mins = Math.floor(duration / 60)
    let secs = duration % 60

    if (secs < 10) {
      sec = '0' + secs
    } else {
      sec = secs
    }

    if (mins < 10) {
      min = '0' + mins
    } else {
      min = mins
    }

    return min + ':' + sec
  }

  return (
    <div className='timer-container flex-box flex-column content-center items-center'>
      { currentTime < duration &&
        <>
          <img src={ image } style={{ transform: `scale(${ animationProgress / 100 })` }}/>
          <div className='breathing-text' style={{ color: 'white', fontSize: '2em', textAlign: 'center' }}>
            { action === 'breathe_in' && translations.breathing.breathe_in }
            { action === 'breathe_out' && translations.breathing.breathe_out }
            { action === 'hold' && translations.breathing.hold }
          </div>
          <div style={{ position: 'absolute', bottom: 0, right: 0, color: 'white' }}>
            { secTominutes(durationRemaining) }
          </div>
        </>
      }

      { currentTime >= duration &&
        <div className='player-wrapper'>
          <div className='completed-wrapper flex-box items-center content-center'>
            <div className='completed-heading'>
              <div className='title' style={{ color: appProps.colorScheme || '#000000' }}>
                { translations.mindfulness.completed_title }
              </div>
              <div className='subtitle' style={{ color: appProps.colorScheme || '#000000' }}>
                { translations.mindfulness.completed_subtitle }
              </div>
            </div>
            <div className='completed-stats'>
            </div>
          </div>
        </div>
      }
    </div>
  )
}
