import React, { useState, useEffect } from 'react'
import { Loader } from '@reactiveonline/frontend_shared_components'
import { truncateString } from '@reactiveonline/frontend_shared_components/utils'
import { NO_IMAGE_SOURCE } from '../GlobalConstants'
import { getTaskType } from '../task/utils'
import { formatDate } from '../../helpers/utils'

export default function Chapters({ appProps, fetchCourseContentPath, courseLocale, courseLxpPath, userEnroled }) {
  const [content, setContent] = useState([])
  const [totalItems, setTotalItems] = useState({ chapters: 0, tasks: 0, deepenings: 0 })
  const [toggledChapterIds, setTogglesChapterIds] = useState([])
  const [loading, setLoading] = useState(false)

  const { translations, currentLocale } = appProps

  useEffect( () => {
    setLoading(true)

    Rails.ajax({
      type: 'GET',
      url: fetchCourseContentPath,
      dataType: 'json',
      success: res => {
        let chaptersCount = 0
        let tasksCount = 0
        let deepeningsCount = 0
        res.courseContent.forEach( chapter => {
          chaptersCount += 1
          tasksCount += chapter.items.length
          chapter.items.forEach( task => {
            deepeningsCount += task.items.length - 1
          })
        })

        setContent(res.courseContent)
        setTotalItems({ chapters: chaptersCount, tasks: tasksCount, deepenings: deepeningsCount })
      },
      complete: res => {
        setLoading(false)
      }
    })
  }, [])

  function handleToggleChapter(chapterId, isLocked) {
    if (!isLocked) {
      if (toggledChapterIds.includes(chapterId)) {
        setTogglesChapterIds( prevState => prevState.filter( chapter => chapter !== chapterId ) )
      } else {
        setTogglesChapterIds( prevState => [...prevState, chapterId] )
      }
    }
  }

  return (
    <>
      { !loading && content && content.length > 0 &&
        <div className='chapters-preview-wrapper'>
          <div className='chapters-preview-title'>
            { translations.course.course_content }
          </div>

          <div className='chapters-preview-info'>
            { totalItems.chapters } { translations.course.chapters }, { totalItems.tasks } { translations.course.tasks }
            { totalItems.deepenings > 0 &&
              <>
                , { totalItems.deepenings } { translations.lxp.deepenings }
              </>
            }
          </div>

          { content.map( (chapter, index) => {
            const chapterDate = chapter.settings.startDate ? new Date(chapter.settings.startDate) : null
            const isLocked = !!(chapterDate && chapterDate > new Date())
            const chapterTranslations = chapter.settings.translations
            const chapterTranslation = chapterTranslations[courseLocale] || chapterTranslations[currentLocale] || chapterTranslations[Object.keys(chapterTranslations)[0]]
            const isToggled = toggledChapterIds.includes(chapter.id)

            return (
              <div
                key={ chapter.id }
                className='chapter-tile-wrapper flex-box flex-column'
              >
                <div
                  className={ `chapter-tile ${ isLocked ? 'locked' : 'pointer' } flex-box items-center` }
                  onClick={ () => handleToggleChapter(chapter.id, isLocked) }
                >
                  <div className={ `chapter-title ${ isLocked ? 'locked' : '' }` }>
                    { index + 1 }. { truncateString(chapterTranslation.title, 30) }
                  </div>

                  <div className='chapter-date flex-box content-end'>
                    { isLocked &&
                      <>
                        { translations.general.releases_on } { formatDate(chapterDate) }
                      </>
                    }
                  </div>

                  <div className={ `chapter-tasks ${ isLocked ? 'locked' : '' } flex-box content-end` }>
                    { chapter.items.length } { translations.course.tasks }
                  </div>

                  <div className='chapter-toggle flex-box content-end'>
                    <i className={ `${ isToggled ? 'up_icon' : 'down_icon' } ${ isLocked ? 'locked' : 'color-scheme' }` }/>
                  </div>
                </div>

                { isToggled &&
                  <>
                    <div className='chapter-divider'/>
                    { (chapterTranslation.description || chapter.settings.featuredImage) &&
                      <>
                        <div className='chapter-info flex-box items-start flex-wrap'>
                          <img className='chapter-image' src={ chapter.settings.featuredImage ? chapter.settings.featuredImage.source : NO_IMAGE_SOURCE }/>
                          <div className='chapter-description-wrapper'>
                            <div className='chapter-description'>
                              <div dangerouslySetInnerHTML={{ __html: chapterTranslation.description }}/>
                            </div>
                          </div>
                        </div>

                        <div className='chapter-divider'/>
                      </>
                    }

                    <div className='chapter-tasks-wrapper'>
                      { chapter.items.map( (taskWrapper, index) => {
                        const task = taskWrapper.items[0]
                        const taskTranslations = task.settings.translations
                        const taskTranslation = taskTranslations[courseLocale] || taskTranslations[currentLocale] || taskTranslations[Object.keys(taskTranslations)[0]]

                        return (
                          <div key={ taskWrapper.id }>
                            <div className='chapter-task flex-box items-center'>
                              <div className='task-icon'>
                                <i className={ getTaskType(task.contentType) }/>
                              </div>

                              <div
                                className={`task-title ${(courseLxpPath && userEnroled) ? "pointer" : ""}`}
                                onClick={ () => {
                                  if(courseLxpPath && userEnroled){
                                    window.location.href = `${courseLxpPath}?task=${task.id}`}
                                  }
                                }
                              >
                                { index + 1 }. { taskTranslation.title }
                              </div>

                              <div className='task-deepenings flex-box content-end'>
                                { taskWrapper.items.length > 1 &&
                                  <>
                                    { taskWrapper.items.length - 1 } { translations.lxp.deepenings }
                                  </>
                                }
                              </div>
                            </div>

                            { index < (chapter.items.length - 1) &&
                              <div className='chapter-divider'/>
                            }
                          </div>
                        )
                      })}
                    </div>
                  </>
                }
              </div>
            )
          })}
        </div>
      }

      { loading &&
        <Loader
          size='medium'
          position='center'
        />
      }
    </>
  )
}
