import React, { useState, useEffect, useRef } from 'react'
import image from '../breathing/bubbles-small'

export default function StartGong({ duration }) {
  const [animationProgress, _setAnimationProgress] = useState(101)
  const animationProgressRef = useRef(101)
  const decreaseStep = 1 / duration

  useEffect( () => {
    if (duration > 0) {
      setInterval( () => {
        setAnimationProgress(animationProgressRef.current - decreaseStep)
      }, 10)
    }
  }, [duration])

  function setAnimationProgress(state) {
    animationProgressRef.current = state
    _setAnimationProgress(state)
  }

  return(
    <img src={ image } style={{ transform: `scale(${ animationProgress / 100 })` }}/>
  )
}
