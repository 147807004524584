import React from 'react'

export default function Analytics({ appProps, screenClass }) {
  return (
    <div className='main-container' style={{ paddingTop: `${appProps.headerHeight}px` }}>
      <div className={ screenClass }>

      </div>
    </div>
  )
}
