import React, { useState, useEffect } from 'react'
import TabItem from '../shared/TabItem'
import cookie from 'react-cookies'
import { getCookieExpirationDate } from '../../helpers/utils'
import Settings from './timer/Settings'
import Timer from './timer/Timer'

const timerDefaultSettings = {
  preparationTime: 10,
  silenceEnabled: true,
  silenceTime: 1,
  startGongEnabled: true,
  startGongUid: 1,
  startGongVolume: 0.7,
  endGongEnabled: true,
  endGongUid: 1,
  endGongVolume: 0.7,
}

export default function Mindfulness({ appProps, screenClass, timers, breathings, videoPractices }) {
  const [loading, setLoading]                     = useState(false)
  const [exerciseModalOpen, setExerciseModalOpen] = useState(false)
  const [exerciseTimer, setExerciseTimer]         = useState(null)
  const [silentTimerSettings, _setSilentTimerSettings] = useState({ ...timerDefaultSettings })

  const { translations } = appProps

  let tabData = [
    { title: translations.mindfulness.guided_meditations, path: appProps.mindfulnessPath },
    { title: translations.mindfulness.meditation_timer, path: appProps.silentTimerPath }
  ]

  if (breathings.length > 0) {
    tabData.push({ title: translations.breathing.breathing_guide, path: appProps.breathingsPath })
  }

  if (videoPractices.length > 0) {
    tabData.push({ title: translations.mindfulness.mindfulness_videos, path: appProps.videoPracticesPath })
  }

  function resetSilentSettings() {
    _setSilentTimerSettings({ ...timerDefaultSettings, silenceEnabled: true })
  }

  function setSilentTimerSettings(key, value) {
    _setSilentTimerSettings((prevSettings) => ({ ...prevSettings, [key]: value }))
  }

  function startSilentTimer() {
    setExerciseModalOpen(true)
    setExerciseTimer({contentType: "silent"})
  }

  function closeExerciseModal() {
    setExerciseModalOpen(false)
    setExerciseTimer(null)
  }

  return (
    <>
      <div className={` lxp-wrapper ${ screenClass }`}>
        <div className='section-hero'>
          <div className='section heading' style={{ height: '100%' }}>
            <div className='main-container flex-box items-center' style={{ height: '100%' }}>
              <h1>{ translations.general.mindfulness }</h1>
            </div>
          </div>
          <div className='main-container'>
            <div className='flex-box flex-wrap items-center content-start tabs-index' style={{ zIndex: 2 }}>
              { tabData.map( (data, index) => (
                <TabItem
                  key={ index }
                  title={ data.title }
                  path={ data.path }
                />
              ))}
            </div>
          </div>
        </div>

        <div style={{ paddingTop: 80 }}>
          <div className='main-container'>
            <div className='section-title flex-box items-center content-space-between'>
              <h2>{ translations.mindfulness.meditation_timer }</h2>
              <div className='button' onClick={ ()=> startSilentTimer() }>
                { translations.mindfulness.start_meditating }
              </div>
            </div>

            <div className='section-divider'></div>

            <div className='silent-timer-wrapper'>
              <Settings
                appProps={ appProps }
                preparationTime={ silentTimerSettings.preparationTime }
                setPreparationTime={ (value) => setSilentTimerSettings('preparationTime', value) }
                silenceEnabled={ silentTimerSettings.silenceEnabled }
                setSilenceEnabled={ (value) => setSilentTimerSettings('silenceEnabled', value) }
                silenceTime={ silentTimerSettings.silenceTime }
                setSilenceTime={ (value) => setSilentTimerSettings('silenceTime', value) }
                startGongEnabled={ silentTimerSettings.startGongEnabled }
                setStartGongEnabled={ (value) => setSilentTimerSettings('startGongEnabled', value) }
                endGongEnabled={ silentTimerSettings.endGongEnabled }
                setEndGongEnabled={ (value) => setSilentTimerSettings('endGongEnabled', value) }
                startGongUid={ silentTimerSettings.startGongUid }
                setStartGongUid={ (option) => setSilentTimerSettings('startGongUid', option.uid) }
                endGongUid={ silentTimerSettings.endGongUid }
                setEndGongUid={ (option) => setSilentTimerSettings('endGongUid', option.uid) }
                startGongVolume={ silentTimerSettings.startGongVolume }
                setStartGongVolume={ (value) => setSilentTimerSettings('startGongVolume', value) }
                endGongVolume={ silentTimerSettings.endGongVolume }
                setEndGongVolume={ (value) => setSilentTimerSettings('endGongVolume', value) }
                resetState={ resetSilentSettings }
                timerType="silent"
              />
            </div>

          </div>
        </div>
     </div>

     { exerciseModalOpen &&
       <Timer
         appProps={ appProps }
         onCloseModal={ closeExerciseModal }
         prepEnabled={ silentTimerSettings.preparationTime > 0 }
         preparationTime={ silentTimerSettings.preparationTime }
         silenceEnabled
         silenceMinutes={ silentTimerSettings.silenceTime }
         startGongEnabled={ silentTimerSettings.startGongEnabled }
         endGongEnabled={ silentTimerSettings.endGongEnabled }
         startGongUid={ silentTimerSettings.startGongUid }
         endGongUid={ silentTimerSettings.endGongUid }
         startGongVolume={ silentTimerSettings.startGongVolume }
         endGongVolume={ silentTimerSettings.endGongVolume }
         timerAudioFile={ null }
         timerType={ "timerSilent" }
         timerTitle={ translations.mindfulness.silent_meditation }
         translations={ translations }
         timerId={ String(Math.random()).substring(2, 12) }
         timerTranslations={ { en: { title: translations.mindfulness.meditation_timer }} }
       />
     }
    </>
  )
}
