import React, { useState, useMemo } from 'react'
import cookie from 'react-cookies'
import { default as TaskTimer } from '../../mindfulness/timer/Timer'

export default function Timer({
  appProps, task, courseUid, courseLocale, mediaUrl, mediaId, completeTask,
  translations, taskIsCompleted
}) {
  const [exerciseCookie, setExerciseCookie] = useState(cookie.load('exerciseCookie'))
  const [timerModalOpen, setTimerModalOpen] = useState(false)

  const taskTitle = task.settings.translations[courseLocale] && task.settings.translations[courseLocale].title

  function onFinish(sessionId, lowerCompletionRate, duration) {
    completeTask(task.id, {
      isCompleted: false,
      isRoot: !!task.isRoot,
      sessionUid: sessionId,
      lowerCompletionRate: lowerCompletionRate,
      duration: duration
    })
  }

  const pageContent = useMemo( () => {
    return (
      <TaskTimer
        appProps={ appProps }
        onCloseModal={ () => setTimerModalOpen(false) }
        prepEnabled={ task.settings.preparationTime && task.settings.preparationTime > 0 }
        preparationTime={ task.settings.preparationTime || 0 }
        silenceEnabled={ !!task.settings.silence }
        silenceMinutes={ task.settings.silence || 0 }
        startGongEnabled={ !!task.settings.startingGong }
        endGongEnabled={ !!task.settings.endingGong }
        startGongUid={ task.settings.startingGong }
        endGongUid={ task.settings.endingGong }
        startGongVolume={ exerciseCookie ? exerciseCookie.startGongVolume : 0.8 }
        endGongVolume={ exerciseCookie ? exerciseCookie.endGongVolume : 0.8 }
        timerAudioFile={ mediaUrl }
        timerType='timer'
        timerTitle={ taskTitle }
        timerId={ task.id }
        timerTranslations={ { en: { title: taskTitle } } }
        courseUid={ courseUid }
        onFinish={ onFinish }
        translations={ translations }
        mediaId={ mediaId }
        courseLocale={ courseLocale }
        assignableUid={ task.assignedContentId }
        taskIsCompleted={ taskIsCompleted }
        taskCompletionRate={ task.settings.completionRate }
      />
    )
  }, [task])

  return (
    <>
      <div className='timer-container flex-box content-center items-center'>
        <div  className='launch-content' style={ task.settings.featuredImage ? { backgroundImage: `url(${task.settings.featuredImage.source})`} : {} }/>
        <div className='button launch' onClick={ () => setTimerModalOpen(true) }>
          { translations.lxp.launch_timer }
        </div>
      </div>

      { timerModalOpen && task.assignedContentId && pageContent }
    </>
  )
}
