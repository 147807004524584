import React, { useEffect } from 'react'

export default function TabItem(props) {

  useEffect(() => {
    document.getElementById(`tab_${props.index}`).scrollIntoView({
      inline: "nearest",
      behavior: "smooth",
    })
  }, [])

  return (
    <div
      id={ `tab_${props.index}` }
      onClick={ ()=> window.open(`${props.path}`, "_self") }
      className={ `tab-item ${props.color ? props.color : ''} ${ props.path.includes(window.location.pathname) ? 'active' : '' }` }
    >
      { props.title }
    </div>
  )
}
