import React, { useEffect } from 'react'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import { NO_IMAGE_SOURCE } from '../GlobalConstants'
import TaskNote from '../notes/TaskNote'
import Description from '../shared/Description'
import TaskResources from './TaskResources'
import ChaptersOverview from './ChaptersOverview'
import TaskReminders from './TaskReminders'
import UserUpload from './UserUpload'
import Discussion from './sidebar/Discussion'
import { getTranslatableField } from '../../helpers/utils'

export default function TaskTabs({
  appProps, tabIndex, setTabIndex, user, course, currentTask, fetchNotePath,
  commentsPath, courseLocale, resourceProps, showSidebar, setShowSidebar, practices,
  completeTask, currentChapterId, changeTask, isDesktop, autoCompletedTasks,
  translation, handleChangeTab, uploadUserFilePath, fetchUserUploadsPath,
  sendCommentReplyPath, fetchRepliesPath, deleteUploadPath, settingsTranslation,
  userUploadInfo, setUserUploadInfo, downloadUserFilePath, validateFilePath, bundleShowPath, bundleTitle
}) {
  const taskDescription = currentTask && currentTask.settings.translations[courseLocale].description
  const resourcesCount = currentTask && course.resourcesCount[currentTask.id] ? `(${ course.resourcesCount[currentTask.id] })` : ''
  const commentsDisabled = currentTask && currentTask.settings.disableComments
  const taskUploadsInfo = currentTask && userUploadInfo.find( uploadInfo => uploadInfo.id === currentTask.id )

  const { translations } = appProps

  return (
    <Tabs selectedIndex={ tabIndex } onSelect={ index => setTabIndex(index) }>
      <TabList>
        <Tab>
          { translations.lxp.course_overview }
        </Tab>

        { currentTask &&
          <>
            <Tab disabled={ !taskDescription }>
              { translations.course.description }
            </Tab>

            <Tab disabled={ isDesktop || commentsDisabled }>
              { translations.lxp.discussion }
            </Tab>

            <Tab disabled={ !currentTask.settings.enableUploads }>
              { translations.user_upload.uploads }
              { taskUploadsInfo && !taskUploadsInfo.hasUploaded &&
                <div className='tab-notice background-color-scheme'/>
              }
            </Tab>

            <Tab disabled={ !resourcesCount }>
              { translations.lxp.downloads } { resourcesCount }
            </Tab>

            <Tab>
              { translations.general.notes }
            </Tab>

            <Tab>
              { translations.lxp.reminders}
            </Tab>
          </>
        }
      </TabList>


      <TabPanel>
        <ChaptersOverview
          chapters={ course.content }
          courseLocale={ courseLocale }
          practices={ practices }
          completeTask={ completeTask }
          currentTask={ currentTask }
          currentChapterId={ currentChapterId }
          changeTask={ changeTask }
          setTabIndex={ setTabIndex }
          translations={ translations }
          autoCompletedTasks={ autoCompletedTasks }
          handleChangeTab={ handleChangeTab }
          showSidebar={ showSidebar }
          setShowSidebar={ setShowSidebar }
          isDesktop={ isDesktop }
          course={ course }
          currentLocale={ appProps.currentLocale }
        />
      </TabPanel>

      { currentTask &&
        <>
          <TabPanel>
            { taskDescription &&
              <Description
                description={ taskDescription }
              />
            }
          </TabPanel>

          <TabPanel>
            { !commentsDisabled && !isDesktop &&
              <Discussion
                settingsTranslation={ settingsTranslation }
                userId={ user.id }
                entityType={ 'MindseedLms::Course' }
                entityUid={ course.uid }
                taskId={ currentTask && currentTask.id }
                commentsPath={ commentsPath }
                translations={ translations }
              />
            }
          </TabPanel>

          <TabPanel>
            { currentTask.settings.enableUploads &&
              <UserUpload
                appProps={ appProps }
                taskId={ currentTask.id }
                courseUid={ course.uid }
                courseId={ course.id }
                uploadUserFilePath={ uploadUserFilePath }
                fetchUserUploadsPath={ fetchUserUploadsPath }
                sendCommentReplyPath={ sendCommentReplyPath }
                fetchRepliesPath = { fetchRepliesPath }
                deleteUploadPath={ deleteUploadPath }
                settingsTranslation={ settingsTranslation }
                userUploadInfo={ userUploadInfo }
                setUserUploadInfo={ setUserUploadInfo }
                downloadUserFilePath={ downloadUserFilePath }
                validateFilePath={ validateFilePath }
              />
            }
          </TabPanel>

          <TabPanel>
            { resourcesCount &&
              <TaskResources
                taskId={ currentTask && currentTask.id }
                courseUid={ course.uid }
                courseLocale={ courseLocale }
                resourceProps={ resourceProps }
              />
            }
          </TabPanel>

          <TabPanel>
            <TaskNote
              key={ currentTask && currentTask.id }
              appProps={ appProps }
              entityType={ 'MindseedLms::Course' }
              entityUid={ course.uid }
              taskId={ currentTask && currentTask.id }
              fetchNotePath={ fetchNotePath }
              taskTitle={ currentTask && getTranslatableField(currentTask, courseLocale, 'title') }
              entityTitle={ translation.title }
              translations={ translations }
            />
          </TabPanel>

          <TabPanel>
            <TaskReminders
              appProps={ appProps }
              taskTitle={ currentTask && getTranslatableField(currentTask, courseLocale, 'title') }
              courseUid={ course.uid }
              taskId={ currentTask && currentTask.id }
            />
          </TabPanel>
        </>
      }
    </Tabs>
  )
}
