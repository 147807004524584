import React, { useState, useEffect } from 'react'
import ScormAgain from 'scorm-again'
import { useInterval } from '../../../helpers/useInterval'
import { Loader } from '@reactiveonline/frontend_shared_components'

export default function Scorm({
  appProps, task, courseLocale, mediaUrl, mediaId, fetchScormContentPath,
  courseUid, completeTask, isCompleted, isForMobile
}) {
  const [apiLoaded, setApiLoaded] = useState(false)
  const [showModal, setShowModal] = useState(isForMobile)
  const [saveDataInterval, setSaveDataInterval] = useState(null)
  const [loading, setLoading] = useState(false)
  const [contentPath, setContentPath] = useState(null)
  const [errorFound, setErrorFound] = useState(false)
  const [progress, setProgress] = useState(null)
  const [didFetchProgress, setDidFetchProgress] = useState(false)

  const {
    translations, lrsMediaStep, flashMessage, lrsServerUrl, userUid, tenantUid,
    isPreview
  } = appProps

  useEffect( () => {
    if (showModal && !isPreview) {
      Rails.ajax({
        type: 'GET',
        url: `${ lrsServerUrl }/get_scorm_progress?accountUid=${ tenantUid }&userUid=${ userUid }&mediaId=${ mediaId }&courseUid=${ courseUid }&taskId=${ task.id }`,
        dataType: 'json',
        success: res => {
          if (res.progress) {
            setProgress(res.progress)
            setDidFetchProgress(true)
          }
        },
        error: res => {
          setProgress({})
          setDidFetchProgress(true)
        },
      })

    } else if (isPreview) {
      setProgress({})
      setDidFetchProgress(true)
    }
  }, [showModal])

  useEffect( () => {
    if (didFetchProgress) {
      initializeApi()
      setApiLoaded(true)

      if (!contentPath) {
        setLoading(true)

        Rails.ajax({
          url: `${ fetchScormContentPath }?uid=${ courseUid }&media_id=${ mediaId }`,
          type: 'GET',
          dataType: 'json',
          success: res => {
            setContentPath(res.contentPath)
            setSaveDataInterval(setInterval(saveData, !isForMobile ? lrsMediaStep : 3000))
          },
          error: res => {
            if (res.error) {
              flashMessage.show(res.error, 'error')
              setErrorFound(true)
              setShowModal(false)
            }
          },
          complete: res => {
            setLoading(false)
          }
        })

      } else {
        setSaveDataInterval(setInterval(saveData, !isForMobile ? lrsMediaStep : 3000))
      }
    }
  }, [didFetchProgress])

  function initializeApi() {
    window.API = new Scorm12API({})

    window.API.loadFromJSON(progress)

    window.API.on('LMSSetValue.cmi.*', (CMIElement, value) => {
      if (CMIElement === 'cmi.suspend_data') {
        window.API.cmi.suspend_data = value
      }
    })
  }

  function saveData() {
    if (!isCompleted && !isPreview) {
      const progressData = window.API.cmi.toJSON()

      if (progressData.core.lesson_status === 'completed' || progressData.core.lesson_status === 'passed') {
        completeTask(task.id, { isCompleted: isCompleted, isRoot: task.isRoot })
      }

      Rails.ajax({
        type: 'POST',
        url: `${ lrsServerUrl }/save_scorm_progress`,
        dataType: 'json',
        data: JSON.stringify({
          accountUid: tenantUid,
          userUid: userUid,
          mediaId: mediaId,
          courseUid: courseUid,
          taskId: task.id,
          progress: progressData
        }),
        success: res => {},
        error: res => {}
      })
    }
  }

  function onClose() {
    clearInterval(saveDataInterval)
    saveData()
    setApiLoaded(false)
    setDidFetchProgress(false)
    setProgress(null)
    setShowModal(false)
  }

  return (
    <>
      <div className='flex-box content-center items-center' style={{ width: '100%', height: '100%' }}>
        { errorFound ?
          <div>
            { translations.lxp.content_not_found }
          </div>

        :
          <>
            <div className='launch-content' style={!isForMobile ? { backgroundImage: `url(${ task.settings.featuredImage ? task.settings.featuredImage.source : 'https://storage.googleapis.com/mindseed_files/lxp/component_web_link_placeholder.webp' })` } : {}}></div>
            <div className='button launch' onClick={ () => setShowModal(true) }>
              { translations.lxp.launch_content }
            </div>
          </>
        }
      </div>

      { showModal &&
        <div className='page-builder scorm'>
          { !isForMobile &&
            <div
              className='close_icon flex-box items-center content-center'
              onClick={ onClose }
            />
          }

          { loading &&
            <Loader
              absolute
              size='large'
              position='center'
            />
          }

          { apiLoaded && !loading &&
            <iframe
              width='100%'
              height='100%'
              src={ contentPath }
            />
          }
        </div>
      }
    </>
  )
}
