import React, { useState, useEffect, useRef } from 'react'
import ReactPlayer from 'react-player'
import { lrsEvent, getLrsTimestamp } from  '../../../helpers/utils'
import { useInterval } from  '../../../helpers/useInterval'

export default function MediaPlayer({
  appProps, task, mediaUrl, mediaId, courseLocale, completeTask, courseUid,
  taskIsCompleted, video
}) {
  const contentSettings = (task && task.settings) || (video && video.content.settings) || null
  const contentType = (task && task.contentType) || (video && video.content.contentType)
  const contentId = (task && task.id) || (video && String(video.id))

  const [playingState, setPlayingState] = useState((contentSettings && contentSettings.autoplay) ? 'playing' : null )
  const [duration, setDuration] = useState(0)
  const [startTime, setStartTime] = useState(0)
  const [playedSeconds, setPlayedSeconds] = useState(0)
  const [sessionUid, setSessionUid] = useState(String(Math.random()).substring(2, 12))
  const [loadedTimestamp, setLoadedTimestamp] = useState(!!appProps.isPreview)

  const reactPlayerRef = useRef(null)
  const completionRateRef = useRef(taskIsCompleted)

  useEffect(() => {
    if(((contentType !== "video" && (contentType !== "mindfulnessVideo" || (contentType === "mindfulnessVideo" && contentSettings.videoType !== "video"))) || ((contentType === "video" || ( contentType === "mindfulnessVideo" && contentSettings.videoType === "video")) && mediaId)) && !appProps.isPreview) {
      if(!!(contentSettings && contentSettings.autoplay)) {
        lrsEvent(appProps, contentSettings.translations, contentId, contentType, 'started', {
          associableUid: courseUid,
          sessionUid: sessionUid,
          mediaId: mediaId,
          locale: courseLocale || appProps.currentLocale,
          assignableUid: task ? task.assignedContentId : null
        })
      }
      getLrsTimestamp(appProps.lrsServerUrl, appProps.userUid, contentId, courseUid, mediaId || '', courseLocale || appProps.currentLocale, setStartTime, setLoadedTimestamp)
    }
    else if(appProps.isPreview){
      setLoadedTimestamp(true)
    }
  }, [mediaId])

  useEffect(() => {
    if (mediaUrl && ((playedSeconds / duration) * 100) >= contentSettings.completionRate && !completionRateRef.current) {
      if (completeTask) {
        completeTask(sessionUid, !!mediaUrl, duration)

      } else {
        lrsEvent(appProps, contentSettings.translations, contentId, contentType, 'completed', {
          associableUid: courseUid,
          sessionUid: sessionUid,
          assignableUid: task ? task.assignedContentId : null,
          locale: courseLocale || appProps.currentLocale,
          duration: duration
        })
      }
      completionRateRef.current = true
    }
  },[playedSeconds])

  const interval = useInterval(() => {
    fireConsumeEvent()
  }, (playingState=='playing') ? appProps.lrsMediaStep : null)

  function resetInterval(intervalRef) {
    clearInterval(intervalRef.current)
    intervalRef.current = 0
  }

  function fireConsumeEvent() {
    if(Math.floor(reactPlayerRef.current.getCurrentTime()) < (duration - 1)) {
      lrsEvent(appProps, contentSettings.translations, contentId, contentType, 'consumed', {
        associableUid: courseUid,
        timestamp: Math.floor(reactPlayerRef.current.getCurrentTime()),
        sessionUid: sessionUid,
        mediaId: mediaId,
        locale: courseLocale || appProps.currentLocale,
        logTimestamp: true,
        assignableUid: task ? task.assignedContentId : null,
        duration: duration
      })
    }
  }

  return (
    <div style={{ height: '100%', background: '#000000' }}>
      { loadedTimestamp &&
        <ReactPlayer
          url={ `${ (mediaUrl && mediaUrl.indexOf('list=') > 0) ? mediaUrl.slice(0, mediaUrl.indexOf('list=') - 1) : mediaUrl }#t=${ startTime },${ contentType !== 'vimeo' && (contentType !== 'mindfulnessVideo' || contentSettings.videoType !== 'vimeo') ? duration : '' }` }
          ref={ reactPlayerRef }
          config={{
            file: {
              attributes: {
                autoPlay: contentSettings ? contentSettings.autoplay : false,
                controlsList: `${ contentSettings && !contentSettings.allowDownload ? 'nodownload' : '' }`
              }
            },
            youtube: {
              playerVars: {
                start: startTime,
                fs: contentSettings && contentSettings.showFullscreen ? 1 : 0,
                hd: 1,
                modestBranding: contentSettings && contentSettings.showLogo ? 0 : 1,
                cc_load_policy: contentSettings && contentSettings.showCaptions ? 1 : 0,
                cc_lang_pref: contentSettings && contentSettings.showCaptions ? (courseLocale || appProps.currentLocale) : 'en',
                rel: contentSettings && contentSettings.showRelated ? 1 : 0
              }

            },
            vimeo: {
              playerOptions: {
                autoplay: !!(contentSettings && contentSettings.autoplay),
                portrait: !!(contentSettings && contentSettings.showLogo),
                texttrack: contentSettings && contentSettings.showCaptions && (courseLocale || appProps.currentLocale)
              }
            }
          }}
          controls={ contentSettings && contentSettings.controls ? contentSettings.controls : true }
          playing={ (contentType === "youtube" || (contentType === "mindfulnessVideo" && contentSettings.videoType === "youtube")) ? !!(contentSettings && contentSettings.autoplay) : false }
          muted={ (contentType === 'video' || (contentType === "mindfulnessVideo" && contentSettings.videoType === "video")) ? !!(contentSettings && contentSettings.mute) : (!!(contentSettings && contentSettings.autoplay) ? true : !!(contentSettings && contentSettings.mute))
          }
          volume={ 1 }
          onStart={ () => {
            if(contentType === "vimeo" || (contentType === "mindfulnessVideo" && contentSettings.videoType === "vimeo")) {
              reactPlayerRef.current.seekTo(startTime)
            }
          }}
          onDuration={ d => setDuration(d)}
          onPlay={ () => {
            if(!playingState  || playingState == 'completed') {
              let newSessionUid =  String(Math.random()).substring(2, 12)
              setSessionUid(newSessionUid)
              lrsEvent(appProps, contentSettings.translations, contentId, contentType, 'started', {
                associableUid: courseUid,
                sessionUid: newSessionUid,
                mediaId: mediaId,
                locale: courseLocale || appProps.currentLocale,
                assignableUid: task ? task.assignedContentId : null
              })
            }
            setPlayingState('playing')
          }}
          onPause={ () => {
            fireConsumeEvent()
            resetInterval(interval)
            setPlayingState('paused')
          }}
          onProgress={ (currentProgress) => {
            setPlayedSeconds(Math.floor(currentProgress.playedSeconds))
          }}
          onEnded={ () => {
            setPlayingState('completed')
            if (completeTask) {
              completeTask(sessionUid, false, duration)
            } else {
              lrsEvent(appProps, contentSettings.translations, contentId, contentType, 'completed', {
                associableUid: courseUid,
                sessionUid: sessionUid,
                assignableUid: task ? task.assignedContentId : null,
                locale: courseLocale || appProps.currentLocale,
                mediaId: mediaId,
                duration: duration
              })
            }
          }}
          width='100%'
          height='100%'
        />
      }
    </div>
  )
}
