import React, { useState, useEffect } from 'react'
import Scorm from './Scorm'

export default function ScormMobile({
  appProps, courseLocale, taskId, isRoot, mediaUrl, mediaId, fetchScormContentPath,
  courseUid, isCompleted, completeTaskPath
}) {
  const [taskIsCompleted, setTaskIsCompleted] = useState(isCompleted)

  const task = {
    id: taskId,
    isRoot: isRoot
  }

  function completeTask(taskId, taskAdditionalData = {}) {
    const {
      isCompleted = null,
      isRoot = false
    } = taskAdditionalData

    if (!isCompleted) {
      let formData = new FormData()
      formData.append('task_id', taskId)
      formData.append('is_root', isRoot)

      Rails.ajax({
        type: 'POST',
        url: completeTaskPath,
        data: formData,
        dataType: 'json',
        success: result => {
          setTaskIsCompleted(true)
        },
        error: result => {},
        complete: result => {}
      })
    }
  }

  return(
    <Scorm
      appProps={ appProps }
      task={ task }
      courseLocale={ courseLocale }
      mediaId={ mediaId }
      mediaUrl={ mediaUrl }
      fetchScormContentPath={ fetchScormContentPath }
      courseUid={ courseUid }
      completeTask={ completeTask }
      isCompleted={ taskIsCompleted }
      isForMobile
    />
  )
}
