import debounce from "lodash.debounce"
import moment from 'moment'
import moment_timezone from 'moment-timezone'

export function detectBrowser() {
  let userAgent = navigator.userAgent
  let browserName
  if(userAgent.match(/brave\//i)){
    browserName = "brave"
   }else if(userAgent.match(/opr\//i)){
    browserName = "opera"
   }else if(userAgent.match(/chrome|chromium|crios/i)){
     browserName = "chrome"
   }else if(userAgent.match(/firefox|fxios/i)){
     browserName = "firefox"
   }else if(userAgent.match(/safari/i)){
     browserName = "safari"
   }else if(userAgent.match(/edg/i)){
     browserName = "edge"
   }else{
     browserName="No browser detection"
   }
  return browserName
}

export function formatAdminDate(date, timezone){
  return moment_timezone(date).tz(timezone)
}

export function detectOs() {
  let userAgent = navigator.userAgent
  let OS="Unknown"
  if (userAgent.indexOf("BlackBerry")!=-1) OS="blackberry"
  if (userAgent.indexOf("Win")!=-1) OS="windows"
  if (userAgent.indexOf("Mac")!=-1) OS="mac"
  if (userAgent.indexOf("X11")!=-1) OS="UNIX"
  if (userAgent.indexOf("Linux")!=-1) OS="linux"
  return OS
}

export function getLrsTimestamp(lrsServerUrl, userId, taskId, courseId, mediaUid, locale, setStartTime, setLoadedTimestamp = null) {
  Rails.ajax({
    type: 'GET',
    url: lrsServerUrl + `/get_timestamp?userId=${userId}&taskId=${taskId}&associableUid=${courseId}&mediaId=${mediaUid}&locale=${locale}`,
    dataType: 'json',
    success: res => {
      if (res) {
        setStartTime(isNaN(parseInt(res)) ? 0 : res)
      }
      if(setLoadedTimestamp) {
        setLoadedTimestamp(true)
      }
    },
    error: result => {
      if(setLoadedTimestamp) {
        setLoadedTimestamp(true)
      }
    }
  })
}

export function lrsEvent(props, translations, objUid, objType, verb, data = {}) {
  const {
    actorType = 'Agent', associableUid = null, timestamp = null,
    sessionUid = null, mediaId = null, locale = null, logTimestamp = false,
    assignableUid = null, duration = null
  } = data

  if (props.userUid && !props.isPreview) {
    let info = {}
    if (translations['en']) {
      info = translations['en']
    } else {
      info = translations[Object.keys(translations)[0]] || {}
    }
    const actor = { userUid: props.userUid, appUrl: props.appUrl }
    const obj = { title: info.title || '', uid: objUid, objType: objType }
    const tenant = { uid: props.tenantUid, url: props.tenantUrl }

    const result = {

      // https://github.com/adlnet/xAPI-Spec/blob/master/xAPI-Data.md#242-actor
      actor: { // the Agent or Group that performed the action
        objectType: actorType, // actorType (Agent, Group)
        account: {
          homePage: actor.appUrl, // the home page for the system the account is on
          name: actor.userUid // the unique id or name used to log in to this account
        }
      },

      // https://github.com/adlnet/xAPI-Spec/blob/master/xAPI-Data.md#243-verb
      verb: { // the Verb defines the action between an Actor and an Activity
        id: actor.appUrl +  '/xapi/verbs#' + verb , // corresponds to the Verb definition
        display: { // the human readable representation of the Verb in one or more languages
          en: verb
        }
      },

      // https://github.com/adlnet/xAPI-Spec/blob/master/xAPI-Data.md#244-object
      object: { // the thing that was acted on
        id: obj.uid,
        objectType: 'Activity', // (optional) Activity, Agent, Group, SubStatement, StatementRef
        definition: {
          name: {
            en: obj.title
          },
          type: obj.objType // the type of the Activity
        }
      },

      // https://github.com/adlnet/xAPI-Spec/blob/master/xAPI-Data.md#249-authority
      authority: { // who or what has asserted that this Statement is true
        objectType: 'Agent',
        account: {
          homePage: tenant.url,
          name: tenant.uid
        }
      },

      result: {
        timestamp: timestamp // a timestamp for the progress of a practice
      },

      additionalData: { // any additional helpful data that we might need
        associableUid: associableUid, // uid of associated course
        assignableUid: assignableUid, // uid of the assignable library
        sessionUid: sessionUid, // uid of the practice's session
        mediaId: mediaId, // id of a media that is playing
        locale: locale, // locale of the practice
        logTimestamp: logTimestamp, // mark if the event is supposed to log a timestamp
        duration: duration // media's duration
      }
    }

    Rails.ajax({
      type: 'POST',
      url: props.lrsServerUrl + '/post_event',
      data: JSON.stringify(result),
      dataType: 'json',
      success: res => {},
      error: result => {},
    })
  }
}

export function getCookieExpirationDate(days) {
  const expiration = new Date()
  expiration.setDate(expiration.getDate() + days)
  return expiration
}

export const customReactSelectStyles = {
  option: (provided, state) => {
    let color = '#222222'
    let background = '#ffffff'
    if(state.isSelected) {
      color = '#ffffff'
      background = '#666666'
    } else if(state.isFocused) {
      background = '#f7f7f7'
    }

    return {
      ...provided,
      color: color,
      background: background
    }
  },
  control: (provided, state) => ({
    ...provided,
    height: 45,
    minHeight: 45,
    border: '1px solid hsl(0,0%,80%)',
    boxShadow: state.isFocused ? 0 : provided.boxShadow,
    '&:hover': {
       border: '1px solid hsl(0,0%,80%)'
    }
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    height: 45
  })
}

export function customReactSelectTheme(theme) {
  return {
    ...theme,
    colors: {
    ...theme.colors,
      primary50: '#f7f7f7'
    }
  }
}

export function debounceEventHandler(...args) {
  const debounced = debounce(...args)
  return (e) => {
    if(typeof e.persist === 'function') e.persist()
    return debounced(e)
  }
}

export function urlParamsAsObject(queryString) {
  const urlParams = new URLSearchParams(queryString)
  const entries = urlParams.entries()

  let params = {}
  for(const entry of entries) {
    params[entry[0]] = entry[1]
  }

  return params
}

function rangeNumbers(from, to) {
  let result = []
  for (let i = from; i <= to; i++) {
    result.push(i)
  }
  return result
}

export function toURLEncoded(element) {
  var key, list;
  list = [];
  if ('object' === typeof element) {
    for (key in element) {
      key = key;
      if (element.hasOwnProperty(key)) {
        list.push(key + '=' + encodeURIComponent(element[key]));
      }
    }
  }
  return list.join('&');
};

export function getTranslatableField(entity, locale, field) {
  if(entity.settings && entity.settings.translations && entity.settings.translations[locale]) {
    return entity.settings.translations[locale][field] || ''
  }
  return ''
}

export const gongsList = [
  { uid: 1, title: 'Dobacci', value: 'https://storage.googleapis.com/mindseed_files/gongs/dobaci.mp3' },
  { uid: 2, title: 'Mallet Hard', value: 'https://storage.googleapis.com/mindseed_files/gongs/mallet_hard.mp3' },
  { uid: 3, title: 'Mallet Smooth', value: 'https://storage.googleapis.com/mindseed_files/gongs/mallet_smooth.mp3' },
  { uid: 4, title: 'Swaha', value: 'https://storage.googleapis.com/mindseed_files/gongs/swaha.mp3' },
  { uid: 5, title: 'Tibetan', value: 'https://storage.googleapis.com/mindseed_files/gongs/tibetan.mp3' }
]

export function formatDate(date, splitDateAndTime = false) {
  if (!date) return '-'

  let utcDate = moment.utc(date, 'DD/MM/YYYY HH:mm:ss')
  if(utcDate.local().format('DD/MM/YYYY HH:mm') === 'Invalid date'){
    utcDate = moment.utc(moment(date), 'DD/MM/YYYY HH:mm:ss')
  }

  if (splitDateAndTime) {
    return {
      date: utcDate.local().format('DD/MM/YYYY'),
      time: utcDate.local().format('HH:mm')
    }
  }

  return utcDate.local().format('DD/MM/YYYY HH:mm')
}

const mediaIcons = {
  audio: 'fa-light fa-file-audio',
  video: 'fa-light fa-file-video',
  image: 'fa-light fa-image',
  pdf: 'fa-light fa-file-pdf',
  powerpoint: 'fa-light fa-file-powerpoint',
  doc: 'fa-light fa-file-word',
  excel: 'fa-light fa-file-excel',
  csv: 'fa-light fa-file-csv',
  zip: 'fa-light fa-file-zipper',
  other: 'fa-light fa-file'
}

export function getMediaIcon(name) {
  const fileName = name.toLowerCase()

  if (fileName.endsWith('.mp4') || fileName.endsWith('.webm') || fileName.endsWith('.mov')) {
    return mediaIcons['video']

  } else if (fileName.endsWith('.mp3') || fileName.endsWith('.wav')) {
    return mediaIcons['audio']

  } else if (fileName.endsWith('.jpeg') || fileName.endsWith('.jpg') || fileName.endsWith('.png') || fileName.endsWith('.webp')) {
    return mediaIcons['image']

  } else if (fileName.endsWith('.pdf')) {
    return mediaIcons['pdf']

  } else if (fileName.endsWith('.ppt') || fileName.endsWith('.pptx')) {
    return mediaIcons['powerpoint']

  } else if (fileName.endsWith('.doc') || fileName.endsWith('.docx') || fileName.endsWith('.odt') || fileName.endsWith('.rtf') || fileName.endsWith('.txt')) {
    return mediaIcons['doc']

  } else if (fileName.endsWith('.xls')) {
    return mediaIcons['excel']

  } else if (fileName.endsWith('.csv')) {
    return mediaIcons['csv']
  }

  return mediaIcons['other']
}
