import React from 'react'
import { AuthorizableLink } from '@reactiveonline/frontend_shared_components'

export default function LoginStep({ appProps }) {
  const { translations, accountPath } = appProps

  return (
    <div className='step-content'>
      <div className='login-step'>
        <div className='description'>
          { translations.checkout.loginDescription }
        </div>

        <div>
          { appProps.userFormLoginType ?
            <AuthorizableLink
              appProps={ appProps }
              linkClass={ 'button' }
              authorizableScreen='register'
              linkContent={ translations.checkout.createAccount }
              linkRef={ appProps.checkoutPath }
            /> :
            <a className='button' href={ appProps.checkoutPath }>
              { translations.account.login }
            </a>
          }
        </div>

        <div className='action-info-wrapper flex-box content-center'>
          <div className='action-info-main'>
            { translations.checkout.existingAccount }
          </div>

          <div className='action-info-secondary color-scheme'>
            { appProps.userFormLoginType ?
              <AuthorizableLink
                appProps={ appProps }
                authorizableScreen='login'
                linkContent={ translations.checkout.signIn }
                linkRef={ appProps.checkoutPath }
              /> :
              <a href={ appProps.checkoutPath }>
                { translations.checkout.signIn }
              </a>
            }
          </div>
        </div>
      </div>
    </div>
  )
}
