import React,{ useState } from 'react'
import { Loader } from '@reactiveonline/frontend_shared_components'
import Modal from '../shared/Modal'

export default function Notifications({ appProps, screenClass, initialNotifications, initialTotalPages }) {
  const [notifications, setNotifications] = useState(initialNotifications)
  const [currentPage, setCurrentPage] = useState(1)
  const [loading, setLoading] = useState(false)
  const [clearAllModalOpen, setClearAllModalOpen] = useState(false)

  const {
    translations, notificationsPath, clearAllNotificationsPath, flashMessage,
    markNotificationAsReadPath, colorScheme, notificationsSettingsPath
  } = appProps

  function loadMore(){
    setLoading(true)
    Rails.ajax({
      type: 'GET',
      url: `${ notificationsPath }?page=${ currentPage + 1 }`,
      dataType: 'json',
      success: res =>{
        setLoading(false)
        setCurrentPage(currentPage + 1)
        setNotifications(notifications.concat(res.additionalNotifications))
      }
    })
  }

  function clearNotifications() {
    setNotifications([])
    setClearAllModalOpen(false)

    Rails.ajax({
      type: 'POST',
      url: clearAllNotificationsPath,
      dataType: 'json',
      success: res => {}
    })
  }

  function deleteNotification(uid) {
    Rails.ajax({
      type: 'DELETE',
      url: `${ notificationsPath }/${ uid }`,
      dataType: 'json',
      success: res =>{
        setNotifications(notifications.filter(n => n.uid != uid ))
        flashMessage.show(translations.notifications.deleted_successfully, 'success')
      }
    })
  }

  function markAsRead(id = null) {
    let fd = new FormData()
    fd.append('id', id)
    fd.append('mark_all', !id)

    Rails.ajax({
      type: 'PATCH',
      url: markNotificationAsReadPath,
      dataType: 'json',
      data: fd,
      success: res => {
        if (id) {
          let updatedNotifications = [...notifications]
          const notificationIndex = updatedNotifications.findIndex( notification => notification.uid === id )

          if (notificationIndex >= 0) {
            updatedNotifications.splice(notificationIndex, 1, { ...updatedNotifications[notificationIndex], isRead: true })
          }

          setNotifications(updatedNotifications)

        } else {
          setNotifications(notifications.map( notification => ({ ...notification, isRead: true }) ))
        }
      }
    })
  }

  function navigateToNotification(notification) {
    markAsRead(notification.uid)
    window.location.href = notification.path
  }

  function renderItem(notification) {
    return (
      <div
        key={ notification.uid }
        className={ `notifications-wrapper flex-box items-center ${ !notification.isRead ? 'unread' : '' }` }
      >
        <div className='notification-icon flex-box items-center content-center'>
          <i className='fa-light fa-spa' style={{ color: colorScheme }}/>
        </div>

        <div
          className='notification-info flex-1 flex-box flex-column pointer'
          onClick={ () => navigateToNotification(notification) }
        >
          <div className='notification-title'>
            { notification.title }
          </div>
          <div>
            { notification.body }
          </div>
        </div>

        <div className='notification-actions flex-box content-space-between items-center'>
          <div
            className='action remove_icon pointer'
            onClick={ () => deleteNotification(notification.uid)}
          />
          { !notification.isRead &&
            <i className='action fa-solid fa-circle color-scheme' style={{ marginLeft: 10 }}/>
          }
        </div>
      </div>
    )
  }

  return (
    <div className={ `lxp-wrapper ${ screenClass }` }>

      <div style={{ padding: '50px 0px 0px 0px' }}>
        <div className='main-container'>
          <div className='section-title' style={{ marginBottom: 40 }}>
            <h1>{ translations.notifications.title }</h1>
          </div>
        </div>
      </div>

      <div className='section'>
        <div className='main-container'>
          <div className='flex-box items-center notifications-actions' style={{ marginBottom: 30 }}>
            <div
              className='button plain flex-box items-center'
              style={{ color: colorScheme }}
              onClick={ () => markAsRead() }
            >
              <i className="fa-solid fa-check-double" style={{ marginRight: 8 }}></i>
              <div>{ translations.general.mark_all_as_read }</div>
            </div>

            <div
              className='button plain flex-box items-center'
              style={{ color: '#838283' }}
              onClick={ () => setClearAllModalOpen(true) }
            >
              <i className="fa-solid fa-eraser" style={{ marginRight: 8 }}></i>
              <div>{ translations.notifications.clear_all }</div>
            </div>
          </div>

          <div className='flex-box flex-wrap notifications'>
            <div className='flex-box flex-3 flex-wrap'>
              { notifications.length == 0 &&
                <h4>
                  { translations.notifications.no_notifications }
                </h4>
              }
              { notifications.length > 0 &&
                <>
                  { notifications.map(n => renderItem(n)) }

                  <div>
                    { loading &&
                      <Loader
                        position={ 'center' }
                        size={ 'small' }
                      />
                    }

                    { currentPage < initialTotalPages && !loading && notifications.length > 0 &&
                      <div className='flex-box content-center'>
                        <a className='button' onClick={ loadMore }>
                          { translations.general.load_more }
                        </a>
                      </div>
                    }
                  </div>
                </>
              }
            </div>
            <div className='flex-1 notifications-sidebar'>
              <div className='sidebar'>
                <div
                  className='button'
                  onClick={ () => window.location.href = notificationsSettingsPath }
                >
                  { translations.account.notification_settings }
                </div>
              </div>
            </div>
          </div>

          { clearAllModalOpen &&
            <Modal
              visible
              closeModal={ () => setClearAllModalOpen(false) }
              icon='exclamation'
              mode='small'
              elementsClass='items-center content-center centered'
              abortText={ translations.general.cancel }
              abortAction={ () => setClearAllModalOpen(false) }
              acceptAction={ () => clearNotifications() }
              saveText={ translations.general.ok }
            >
              <div>
                { translations.notifications.clear_all_confirmation }
              </div>
            </Modal>
          }

        </div>
      </div>
    </div>
  )
}
