import React, { useState, useEffect } from 'react'

export default function Billing({ pageName, pageTitle }) {
  return (
    <>
      <div className='section-title'>
        <h3>{ pageTitle }</h3>
      </div>
    </>
  )
}
