import React, { useState, useEffect, lazy } from 'react'
import { App, AuthorizableModal } from '@reactiveonline/frontend_shared_components'
import HeaderIcons from './header/HeaderIcons'
import InvitationExpiredModal from './shared/InvitationExpiredModal'
import { additionalElements } from './builder/additionalElements'
import AppLayout from './shared/AppLayout'

// pass 'packageComponent' if the component should be imported inside the package
// pass 'includePageBuilder' if you want to include the additionalElements of the page builder

let Component = () => <div style={{ minHeight: '50vh' }}/>

export default function AppWrapper(props) {
  const [renderKey, setRenderKey] = useState(0)

  const {
    appProps, includePageBuilder, packageComponent, componentPath,
    renderThroughLayout
  } = props

  useEffect( () => {
    if (!packageComponent) {
      Component = lazy( () => import(`./${ componentPath }`) )
      setTimeout( () => setRenderKey(Math.random()), 100)
    }
  }, [])

  return (
    <>
      <InvitationExpiredModal
        appProps={ appProps }
      />

      <App
        { ...props }
        packageComponent={ packageComponent && !(appProps.userFormLoginType && appProps.notAuthorized) }
        PageContent={ appProps.userFormLoginType && appProps.notAuthorized ? AuthorizableModal : (packageComponent && !renderThroughLayout) ? null : AppLayout }
        appProps={{
          ...appProps,
          HeaderIcons: HeaderIcons,
          isMobileApp: window.location.href.includes('is_mobile_app=true'),
          pageBuilderAdditionalElements: includePageBuilder ? additionalElements({ appProps: appProps }) : {}
        }}
        Component={ Component }
      />
    </>
  )
}
