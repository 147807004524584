import React from 'react'

export default function AddToCart({ appProps, buyNow, entityType, entityUid, isInCart, disableActions }) {
  const { translations, flashMessage } = appProps

  function handleAddToCart(goToCheckout = false) {
    if (!disableActions) {
      let formData = new FormData()

      formData.append('sellable_type', entityType)
      formData.append('sellable_uid', entityUid)

      Rails.ajax({
        url: appProps.createLineItemPath,
        type: 'POST',
        dataType: 'json',
        data: formData,
        success: res => {
          if (res.order) {
            appProps.setCurrentAppProps({ order: res.order })
            flashMessage.show(translations.checkout.addItemSuccess)
          }

          if (goToCheckout) {
            window.location.href = appProps.checkoutPath
          }
        }
      })
    }
  }

  return (
    <>
      { isInCart ?
        <div>
          { translations.checkout.inCart }
        </div>

      :
        <>
          <div
            className={ `button ${ disableActions ? 'disabled' : '' } secondary flex-box items-center content-center` }
            onClick={ () => handleAddToCart() }
          >
            <i className='fa-thin fa-cart-circle-plus' style={ disableActions ? { color: 'white' } : {} }/>
            <div style={ disableActions ? { color: 'white', marginLeft: 10 } : { marginLeft: 10 } }>
              { translations.course.add_to_cart }
            </div>
          </div>

          { buyNow &&
            <div
              className={ `button ${ disableActions ? 'disabled' : '' }` }
              onClick={ () => handleAddToCart(true) }
            >
              { translations.course.buy_now }
            </div>
          }
        </>
      }
    </>
  )
}
