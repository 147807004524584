import React, { useState } from 'react'
import SelectDate from './SelectDate'
import ShowLatestLogs from './ShowLatestLogs'
import TabsIndex from '../shared/TabsIndex'
import NoteCard from '../shared/NoteCard'

export default function Index({ appProps, screenClass, pageTitle, initialHighlightedDates }) {
  const [selectedDate, setSelectedDate] = useState(new Date())
  const [highlightedDates, setHighlightedDates] = useState(initialHighlightedDates)

  const { translations } = appProps

  return (
    <div className={` lxp-wrapper ${ screenClass }`}>
      <TabsIndex
        appProps={ appProps }
        pageTitle={ pageTitle }
      />

      <div className='section body-bg'>
        <div className="main-container" >
        {
          // <div className='card'>
          //   <div className='flex-box flex-wrap'>
          //     <NoteCard
          //       icon='longest_streak_icon'
          //       textUp = { translations.general.longest_streak }
          //       textDown={ `${ '3' } ${ translations.general.days }` }
          //       additionalClass={ 'light' }
          //     />
          //
          //     <NoteCard
          //       icon='current_streak_icon'
          //       textUp = { translations.general.current_streak }
          //       textDown={ `${ '0' } ${ translations.general.days }` }
          //       additionalClass={ 'light' }
          //     />
          //
          //     <NoteCard
          //       icon='total_sessions_icon'
          //       textUp = { translations.general.total_sessions }
          //       textDown = {'3'}
          //       additionalClass={ 'light' }
          //     />
          //
          //     <NoteCard
          //       icon='total_minutes_icon'
          //       textUp = { translations.general.total_minutes }
          //       textDown = {'140'}
          //       additionalClass={ 'light' }
          //     />
          //   </div>
          // </div>
        }

          <div className='flex-box flex-wrap'>
            <div className='flex-2'>
              <SelectDate
                appProps={ appProps }
                selectedDate = { selectedDate }
                setSelectedDate = { setSelectedDate }
                highlightedDates = { highlightedDates }
                setHighlightedDates = { setHighlightedDates }
              />
            </div>
            <div className='dashboard_log flex-1'>
              <ShowLatestLogs
                appProps={ appProps }
                selectedDate={ selectedDate }
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
