import React, { useState } from 'react'
import DropMenu from '../shared/DropMenu'
import BookmarksDropMenu from '../shared/BookmarksDropMenu'
import NotificationsDropMenu from '../shared/NotificationsDropMenu'
import CartDropMenu from '../shared/CartDropMenu'
import { AuthorizableLink } from '@reactiveonline/frontend_shared_components'

export default function HeaderIcons({ appProps }) {
  const localePrefix = appProps.currentLocale ? `/${appProps.currentLocale}` : ''
  const [selectedDropMenu, setSelectedDropMenu] = useState(null)

  const { translations, registrationEnabled } = appProps

  let profileData = [
    { title: translations.account.manage_account, icon: null, read: false, timestamp: null, path: '/account/manage', target: '_blank' },
    { title: translations.account.orders, icon: null, read: false, timestamp: null, path: appProps.ordersPath },
    { title: translations.account.notification_settings, icon: null, read: false, timestamp: null, path: appProps.notificationsSettingsPath },
    { title: translations.account.reminders, icon: null, read: false, timestamp: null, path: appProps.remindersPath },
    { title: translations.account.practice_log, icon: null, read: false, timestamp: null, path: appProps.practiceLogPath },
    { title: translations.account.languages, icon: null, read: false, timestamp: null, path: appProps.languagesPath },
    { title: translations.account.privacy, icon: null, read: false, timestamp: null, path: appProps.privacyPath },
    { title: translations.general.logout, icon: null, read: false, timestamp: null, path: appProps.logoutPath, requestType: 'delete' },
  ]

  if(appProps.mssoProviderId) {
    profileData = profileData.filter((_, index) => index !== 0)
  }

  let loginData = [{ title: translations.account.login, icon: null, read: false, timestamp: null, path: `${localePrefix}/account` }]
  if(registrationEnabled) {
    loginData.push({ title: translations.account.register, icon: null, read: false, timestamp: null, path: `${localePrefix}/account` })
  }

  return (
    <div className="header-icons-container">
      <ul>
        { appProps.userLoggedIn &&
          <>
            <BookmarksDropMenu
              appProps={ appProps }
              selectedDropMenu={ selectedDropMenu }
              setSelectedDropMenu={ setSelectedDropMenu }
            />

            <NotificationsDropMenu
              appProps={ appProps }
              selectedDropMenu={ selectedDropMenu }
              setSelectedDropMenu={ setSelectedDropMenu }
            />
          </>
        }

        { appProps.order &&
          <CartDropMenu
            appProps={ appProps }
            selectedDropMenu={ selectedDropMenu }
            setSelectedDropMenu={ setSelectedDropMenu }
          />
        }

        { appProps.userLoggedIn ?
          <li className="account drop-menu" id='drop-menu'>
            <div className={`menu-handler ${appProps.user.avatar ? '' : 'account_icon my-account'}` }>
              { appProps.user.avatar && <img className='avatar' src={ appProps.user.avatar}/> }
            </div>
            <DropMenu
              dropMenuData={ profileData }
              title={ null }
              titleButtonText={ null }
              titleButtonPath={ appProps.accountPath }
              action={ null }
              actionText={ null }
              menuPosition={ 'main-header profile' }
              selected={ selectedDropMenu === 'account' }
              setSelected={ setSelectedDropMenu }
              menuKey='account'
            />
          </li>
        :
          <li className="account drop-menu">
            { appProps.userFormLoginType ?
              <AuthorizableLink
                appProps={ appProps }
                linkClass="login_icon my-account"
                linkRef={ appProps.redirectAfterAuthPath }
              />
            :
              <a
                href={ appProps.practiceLogPath }
                className={`menu-handler ${ appProps.user && appProps.user.avatar ? '' : 'account_icon my-account' }` }
              >
                { appProps.user && appProps.user.avatar &&
                  <img className='avatar' src={ appProps.user.avatar}/>
                }
              </a>
            }
          </li>
        }
      </ul>
    </div>
  )
}
