import React, { useState, useEffect } from 'react'
import Modal from '../../shared/Modal'
import { Loader } from '@reactiveonline/frontend_shared_components'
import { formatDate, getMediaIcon } from '../../../helpers/utils'
import moment from 'moment'

const placeholderAvatar = 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ2nNUXQ8TRIbctxR4bGhWkQd5mrIeu1okIyte3_rz0HSVf6hctJAXHOwrUidaXMlOtCFA&usqp=CAU'

export default function UploadChat({
  appProps, selectedUpload, courseUid, fetchRepliesPath,
  setInitialUploads, onClose, taskTitle, sendCommentReplyPath
}) {
  const [fetching, setFetching] = useState(false)
  const [loading, setLoading] = useState(false)
  const [comments, setComments] = useState([])
  const [reply, setReply] = useState('')

  const { translations, flashMessage, user } = appProps

  useEffect( () => {
    fetchReplies()
  }, [])

  function fetchReplies() {
    setFetching(true)

    Rails.ajax({
      type: 'GET',
      url: `${ fetchRepliesPath }?uid=${ courseUid }&upload_id=${ selectedUpload.id }`,
      dataType: 'json',
      success: res => {
        setComments(res.comments.map( comment => ({
          ...comment,
          ...formatDate(comment.createdAt, true)
        })))

        if (res.userUpload) {
          setInitialUploads( prevState => {
            let updatedUploads = [ ...prevState ]
            const uploadIndex = updatedUploads.findIndex( upload => upload.id === selectedUpload.id )

            if (uploadIndex >= 0) {
              updatedUploads.splice(uploadIndex, 1, { ...updatedUploads[uploadIndex], ...res.userUpload })
            }

            return updatedUploads
          })
        }
      },
      complete: res => {
        setFetching(false)
      }
    })
  }

  function sendReply() {
    setLoading(true)

    let formData = new FormData()
    formData.append('referenced_upload_id', selectedUpload.id)
    formData.append('reply_content', reply)

    Rails.ajax({
      url: sendCommentReplyPath,
      type: 'POST',
      dataType: 'json',
      data: formData,
      success: res => {
        setComments( prevState => [ ...prevState, { ...res.comment, ...formatDate(res.comment.createdAt, true) } ] )

        setInitialUploads( prevState => {
          let updatedUploads = [ ...prevState ]
          const uploadIndex = updatedUploads.findIndex( upload => upload.id === selectedUpload.id )

          if (uploadIndex >= 0) {
            updatedUploads.splice(uploadIndex, 1, { ...updatedUploads[uploadIndex], ...res.userUpload })
          }

          return updatedUploads
        })
      },
      error: res => {
        flashMessage.show(translations.user_upload.replyError, 'error')
      },
      complete: res => {
        setLoading(false)
        setReply('')
      }
    })
  }

  return (
    <Modal
      visible
      mode='medium'
      theme='chat-background'
      acceptAction={ sendReply }
      disabledAction={ !reply || loading }
      saveText={ translations.general.reply }
      closeModal={ onClose }
    >
      <div className='user-uploads-chat-modal-wrapper flex-box content-center'>
        <div className='user-uploads-chat-modal'>
          <div className='chat-header flex-box items-center content-center'>
            <i className={ `header-icon ${ getMediaIcon(selectedUpload.fileName) }` }/>

            <div className='flex-box flex-column'>
              <div className='header-title'>
                { selectedUpload.fileName }
              </div>

              <div className='header-subtitle color-scheme'>
                { taskTitle }
              </div>
            </div>
          </div>

          <div className='header-divider'/>

          { fetching &&
            <div className='comments-loader-wrapper'>
              <Loader
                size='small'
                position='center'
              />
            </div>
          }

          <div className='comments-wrapper'>
            { comments.map( (comment, index) => {
              const previousComment = index >= 0 && comments[index - 1]
              const nextComment = index < (comments.length - 1) && comments[index + 1]
              const commentCreator = comment.user || comment.administrator
              const isCurrentUser = comment.user && user && comment.user.id === user.id
              const commentCreatorName = commentCreator.fullName ||
                (comment.administrator && translations.user_upload.instructor) ||
                (comment.user && translations.user_upload.user)

              const showDate = !previousComment || previousComment.date !== comment.date
              const showUser = showDate || !previousComment ||
                (previousComment.user && comment.administrator) || (previousComment.administrator && comment.user) ||
                (previousComment.user && comment.user && previousComment.user.id !== comment.user.id) ||
                (previousComment.administrator && comment.administrator && previousComment.administrator.id !== comment.administrator.id)

              const showTime = !nextComment || nextComment.date !== comment.date ||
                (nextComment.user && comment.administrator) || (nextComment.administrator && comment.user) ||
                (nextComment.user && comment.user && nextComment.user.id !== comment.user.id) ||
                (nextComment.administrator && comment.administrator && nextComment.administrator.id !== comment.administrator.id) ||
                ((moment(nextComment.time, 'HH:mm') - moment(comment.time, 'HH:mm')) > 60000)

              return (
                <div
                  key={ comment.id }
                  className='comment-wrapper'
                >
                  { showDate &&
                    <div className='comment-date flex-box content-center'>
                      { comment.date }
                    </div>
                  }

                  { showUser &&
                    <div className={ `comment-header flex-box ${ isCurrentUser ? 'content-end' : '' }` }>
                      { isCurrentUser &&
                        <>
                          <div className='comment-user reversed'>
                            { commentCreatorName }
                          </div>

                          <img
                            className='comment-avatar'
                            src={ commentCreator.image || placeholderAvatar }
                          />
                        </>
                      }

                      { !isCurrentUser &&
                        <>
                          <img
                            className='comment-avatar'
                            src={ commentCreator.image || placeholderAvatar }
                          />

                          <div className='comment-user'>
                            { commentCreatorName }
                          </div>
                        </>
                      }
                    </div>
                  }

                  <div className={ `flex-box ${ isCurrentUser ? 'content-end' : '' }` }>
                    <div className={ `comment-content-wrapper ${ isCurrentUser ? 'reversed' : '' } ${ showTime ? 'time' : '' }` }>
                      <div
                        className={ `comment-content ${ showTime ? 'time' : '' }` }
                        dangerouslySetInnerHTML={{ __html: comment.content.replaceAll('\n', '<br/>') + (showTime ? `<span class='comment-info flex-box items-end'>${ comment.time }</span>` : '') }}
                      />
                    </div>
                  </div>
                </div>
              )
            })}
          </div>

          { !fetching && !loading &&
            <textarea
              className='new-comment'
              value={ reply }
              onChange={ event => setReply(event.target.value) }
            />
          }
        </div>
      </div>
    </Modal>
  )
}
