import React from 'react'
import Timer from '../../mindfulness/timer/Timer'

export default function Audio({ appProps, task, courseUid, courseLocale, mediaUrl, mediaId, completeTask, translations, taskIsCompleted }) {
  const taskTitle = task.settings.translations[courseLocale] && task.settings.translations[courseLocale].title

  function onFinish(sessionUid, lowerCompletionRate, duration) {
    completeTask(task.id, {
      isCompleted: false,
      isRoot: !!task.isRoot,
      sessionUid: sessionUid,
      lowerCompletionRate: lowerCompletionRate,
      duration: duration
    })
  }

  return (
    <Timer
      appProps={ appProps }
      isNotModal
      translations={ translations }
      prepEnabled={ false }
      preparationTime={ 0 }
      silenceEnabled={ false }
      silenceMinutes={ 0 }
      startGongEnabled={ false }
      endGongEnabled={ false }
      startGongUid={ null }
      endGongUid={ null }
      startGongVolume={ 0 }
      endGongVolume={ 0 }
      timerAudioFile={ mediaUrl }
      timerId={ task.id }
      timerType='audio'
      timerTitle={ taskTitle }
      courseUid={ courseUid }
      timerTranslations={{ en: { title: taskTitle } } }
      onFinish={ onFinish }
      autoPlay={ task.settings.autoplay }
      disableControls={ !task.settings.controls }
      mediaId={ mediaId }
      courseLocale={ courseLocale }
      assignableUid={ task.assignedContentId }
      taskId={ task.id }
      taskIsCompleted={ taskIsCompleted }
      taskCompletionRate={ task.settings.completionRate }
    />
  )
}
