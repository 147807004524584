import React, { useMemo, useEffect } from "react"
import {
  useStripe,
  useElements,
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement
} from "@stripe/react-stripe-js"

const useOptions = () => {
  const options = useMemo(
    () => ({
      style: {
        base: {
          color: "#424770",
          letterSpacing: "0.025em",
          fontFamily: "Source Code Pro, monospace",
          "::placeholder": {
            color: "#aab7c4"
          }
        },
        invalid: {
          color: "#9e2146"
        }
      }
    }),
    // [fontSize]
  )

  return options
}

const StripeCheckoutForm = ({submitStripeCard, setStripeError, completeOrder, stripePaymentIntentClientSecret, stripePaymentIntentActionType, orderId, type, appProps, billingDetails}) => {
  const stripe = useStripe()
  const elements = useElements()
  const options = useOptions()

  const { translations, flashMessage } = appProps

  useEffect( () => {
    if(submitStripeCard) {
      if (!stripe || !elements) {
        // Stripe.js has not loaded yet. Make sure to disable
        // form submission until Stripe.js has loaded.
        return
      }

      stripe.createPaymentMethod({
        type: "card",
        card: elements.getElement(CardNumberElement),
        billing_details: {
          address: {
            city: billingDetails.city,
            country: billingDetails.country,
            line1: billingDetails.address,
            postal_code: billingDetails.postalCode,
            state: billingDetails.state
          },
          email: billingDetails.email,
          name: billingDetails.name,
          phone: billingDetails.phone
        }
      }).then(payload => {
        if(payload.error) {
          flashMessage.show(payload.error.message, 'error')
          setStripeError()
        } else {
          completeOrder(payload.paymentMethod.id)
        }
      })
    }
  }, [submitStripeCard])

  useEffect( () => {
    if(stripePaymentIntentClientSecret) {
      if(stripePaymentIntentActionType === "requires_action") {
        stripe.confirmCardPayment(stripePaymentIntentClientSecret).then(function(payload) {

          if(payload.error) {
            flashMessage.show(payload.error.message, 'error')
            setStripeError()
            window.location.reload()
          } else {
            let formData = new FormData()
            formData.append('payment_intent_id', payload.paymentIntent.id)
            formData.append('orderid', orderId)
            formData.append('type', type)

            Rails.ajax({
              url: '/stripe_payment/success',
              type: 'POST',
              dataType: 'json',
              accept: 'json',
              data: formData,
              success: res => {
                if(res.redirect_url) {
                  window.location.href = res.redirect_url
                } else {
                  setStripeError()
                }
              },
              error: res => {
                setStripeError()
              }
            })
          }
        })
      } else if(stripePaymentIntentActionType === "requires_confirmation") {
        stripe.handleCardAction(stripePaymentIntentClientSecret).then(function(payload) {

          if(payload.error) {
            flashMessage.show(payload.error.message, 'error')
            setStripeError()
            window.location.reload()
          } else {
            let formData = new FormData()
            formData.append('payment_intent_id', payload.paymentIntent.id)
            formData.append('orderid', orderId)
            formData.append('type', type)

            Rails.ajax({
              url: '/stripe_payment/confirm_payment_intent',
              type: 'POST',
              dataType: 'json',
              accept: 'json',
              data: formData,
              success: res => {
                if(res.redirect_url) {
                  window.location.href = res.redirect_url
                } else {
                  setStripeError()
                }
              },
              error: res => {
                setStripeError()
              }
            })
          }
        })
      }
    }
  }, [stripePaymentIntentClientSecret])

  return (
    <form className='stripe-elements-wrapper'>
      <label>
        { translations.checkout.cardNumber }
      </label>
      <CardNumberElement
        options={ options }
        onReady={ () => {} }
        onChange={ event => {} }
        onBlur={ () => {} }
        onFocus={ () => {} }
      />

      <label>
        { translations.checkout.expirationDate }
      </label>
      <CardExpiryElement
        options={ options }
        onReady={ () => {} }
        onChange={ event => {} }
        onBlur={ () => {} }
        onFocus={ () => {} }
      />

      <label>
        { translations.checkout.cvc }
      </label>
      <CardCvcElement
        options={ options }
        onReady={ () => {} }
        onChange={ event => {} }
        onBlur={ () => {} }
        onFocus={ () => {} }
      />

      <div className='stripe-copyright'>
        <div className='stripe-logo'>
          <img src='https://storage.googleapis.com/reactive_files_eu/payment_processors/stripe-logo.jpg' />
        </div>
        <div className='stripe-text' dangerouslySetInnerHTML={{ __html: translations.checkout.stripeCopyright }}/>
      </div>
    </form>
  )
}

export default StripeCheckoutForm
