import React from 'react'
import { SelectInput } from '@reactiveonline/frontend_shared_components'

export default function CourseLanguageSelector({ languages, setLocalePath, translations }) {

  function changeLocale(option) {
    if (option.value !== '') {
      let fd = new FormData()
      fd.append('selected_locale', option.value)

      Rails.ajax({
        type: 'POST',
        url: setLocalePath,
        dataType: 'json',
        data: fd,
        success: res => {
          window.location.reload()
        }
      })
    }
  }

  return (
    <SelectInput
      options={ [{ value: '', title: translations.account.select_language }, ...languages.map( language => ({ value: language.code, title: language.presentation })  )] }
      currentOption=''
      setCurrentOption={ changeLocale }
    />
  )
}
