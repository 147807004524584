import React, { useCallback } from 'react'
import { useEditor, EditorContent } from '@tiptap/react'

import StarterKit from '@tiptap/starter-kit'
import Underline from '@tiptap/extension-underline'
import TextAlign from '@tiptap/extension-text-align'
import Link from '@tiptap/extension-link'
import CharacterCount from '@tiptap/extension-character-count'
import TextStyle from '@tiptap/extension-text-style'
import { Color } from '@tiptap/extension-color'
import Image from '@tiptap/extension-image'
import Dropcursor from '@tiptap/extension-dropcursor'

const headings = [1,2,3,4,5,6]

function MenuBar({ editor }) {

  const setLink = useCallback(() => {
    const previousUrl = editor.getAttributes('link').href
    const url = window.prompt('URL', previousUrl)

    // cancelled
    if (url === null) {
      return
    }

    // empty
    if (url === '') {
      editor.chain().focus().extendMarkRange('link').unsetLink()
        .run()

      return
    }

    // update link
    editor.chain().focus().extendMarkRange('link').setLink({ href: url })
      .run()
  }, [editor])

  const addImage = useCallback(() => {
    const url = window.prompt('URL')

    if (url) {
      editor.chain().focus().setImage({ src: url }).run()
    }
  }, [editor])

  if (editor) {
    return (
      <div className='text-editor-menu'>
        <button
          onClick={ event => {
            event.preventDefault()
            editor.chain().focus().toggleBold().run()
          }}
          className={ `editor-button ${ editor.isActive('bold') ? 'is-active' : '' }` }
        >
          <i className="fa-light fa-bold"></i>
        </button>

        <button
          onClick={ event => {
            event.preventDefault()
            editor.chain().focus().toggleItalic().run()
          }}
          className={ `editor-button ${ editor.isActive('italic') ? 'is-active' : '' }` }
        >
          <i className="fa-light fa-italic"></i>
        </button>
        <button
          onClick={ event => {
            event.preventDefault()
            editor.chain().focus().toggleUnderline().run()
          }}
          className={ `editor-button ${ editor.isActive('underline') ? 'is-active' : '' }` }
        >
          <i className="fa-light fa-underline"></i>
        </button>

        <button
          onClick={ event => {
            event.preventDefault()
            editor.chain().focus().toggleBulletList().run()
          }}
          className={ `editor-button bullet-list ${ editor.isActive('bulletList') ? 'is-active' : '' }` }
        >
          <i className="fa-light fa-list"></i>
        </button>
        <button
          onClick={ event => {
            event.preventDefault()
            editor.chain().focus().toggleOrderedList().run()
          }}
          className={ `editor-button numbered-list ${ editor.isActive('orderedList') ? 'is-active' : '' }` }
        >
          <i className="fa-light fa-list-ol"></i>
        </button>

        <button className='editor-button spacer'></button>

        <button onClick={() => editor.chain().focus().undo().run()} disabled={!editor.can().undo()} className='editor-button'>
          <i className="fa-light fa-rotate-left"></i>
        </button>
        <button onClick={() => editor.chain().focus().redo().run()} disabled={!editor.can().redo()} className='editor-button'>
          <i className="fa-light fa-rotate-right"></i>
        </button>

      </div>
    )
  } else {
    return null
  }
}

export default function TextEditor({ content, setContent, disabled, translatable, translations_characters, translataions_words }) {
  const textEditor = useEditor({
    extensions: [
      StarterKit,
      Underline,
      CharacterCount,
      TextStyle,
      Color,
      Image,
      Dropcursor,
      TextAlign.configure({
        types: ['heading', 'paragraph', 'blockquote'],
      }),
      Link.configure({
        openOnClick: false,
      }),
    ],
    content: content,
    heading: {
      levels: headings,
    },
    onUpdate: ({ editor }) => {
      const html = editor.getHTML()
      setContent(html)
    },
    editable: !disabled
  })

  return (
    <div className={ `text-editor ${ translatable ? 'translatable' : '' }` }>
      { !disabled && <MenuBar editor={ textEditor }/> }
      <EditorContent
        editor={ textEditor }
        className='text-editor-content'
      />
      { textEditor &&
        <div className="character-count m-t-5">
          <div className='m-r-5'>{ textEditor.storage.characterCount.characters() } { translations_characters }</div>
          /
          <div className='m-l-5'>{ textEditor.storage.characterCount.words() } { translataions_words }</div>
        </div>
      }
    </div>
  )
}
