import React,{ useState } from 'react'
import { Slider } from '@material-ui/core'
import Modal from '../../shared/Modal'
import BreathingExercise from './BreathingExercise'

export default function BreathingSettings({ appProps, title, description, selectedBreathing, onClose }) {
  const initialSettings = selectedBreathing.content.settings

  const [showSettingsModal, setShowSettingsModal] = useState(true)
  const [breathingModalOpen, setBreathingModalOpen] = useState(false)
  const [inhaleDuration, setInhaleDuration] = useState(parseInt(initialSettings.inhaleDuration))
  const [pauseAfterInhale, setPauseAfterInhale] = useState(parseInt(initialSettings.pauseAfterInhale))
  const [exhaleDuration, setExhaleDuration] = useState(parseInt(initialSettings.exhaleDuration))
  const [pauseAfterExhale, setPauseAfterExhale] = useState(parseInt(initialSettings.pauseAfterExhale))
  const [duration, setDuration] = useState(parseInt(initialSettings.duration))

  const inhaleRatio = initialSettings.inhaleRatio
  const pauseAfterInhaleRatio = initialSettings.pauseAfterInhaleRatio
  const exhaleRatio = initialSettings.exhaleRatio
  const pauseAfterExhaleRatio = initialSettings.pauseAfterExhaleRatio

  const { translations } = appProps

  function startBreathing() {
    setShowSettingsModal(false)
    setBreathingModalOpen(true)
  }

  function ratioHandler(newVal, sourceRatioType) {
    if (sourceRatioType === 'inhaleRatio') {
      setInhaleDuration(newVal)
      setPauseAfterInhale(newVal * pauseAfterInhaleRatio / inhaleRatio)
      setExhaleDuration(newVal * exhaleRatio / inhaleRatio)
      setPauseAfterExhale(newVal * pauseAfterExhaleRatio / inhaleRatio)

    } else if (sourceRatioType === 'pauseAfterInhaleRatio') {
      setPauseAfterInhale(newVal)
      setInhaleDuration(newVal * inhaleRatio / pauseAfterInhaleRatio)
      setExhaleDuration(newVal * exhaleRatio / pauseAfterInhaleRatio)
      setPauseAfterExhale(newVal * pauseAfterExhaleRatio / pauseAfterInhaleRatio)

    } else if (sourceRatioType === 'exhaleRatio') {
      setExhaleDuration(newVal)
      setInhaleDuration(newVal * inhaleRatio / exhaleRatio)
      setPauseAfterInhale(newVal * pauseAfterInhaleRatio / exhaleRatio)
      setPauseAfterExhale(newVal * pauseAfterExhaleRatio / exhaleRatio)

    } else if (sourceRatioType === 'pauseAfterExhaleRatio') {
      setPauseAfterExhale(newVal)
      setInhaleDuration(newVal * inhaleRatio / pauseAfterExhaleRatio)
      setPauseAfterInhale(newVal * pauseAfterInhaleRatio / pauseAfterExhaleRatio)
      setExhaleDuration(newVal * exhaleRatio / pauseAfterExhaleRatio)
    }
  }

  return (
    <>
      { showSettingsModal &&
        <Modal
          visible
          appProps={ appProps }
          closeModal={ onClose }
          mode='medium'
          acceptAction={ startBreathing }
          saveText={ translations.breathing.save_and_start }
        >
          <div className='timer-settings'>
            <div className='timer-title'>
              { title }
            </div>

            { description &&
              <div className='timer-description' dangerouslySetInnerHTML={{ __html: description }}/>
            }

            <div className='setting-item'>
              <div className='flex-box content-space-between'>
                <div className='flex-box'>
                  <div className='label'>
                    { translations.breathing.inhale }:
                  </div>

                  <div style={{ color: appProps.colorScheme }} className='value'>
                    { Math.round(inhaleDuration) } { translations.general.sec }
                  </div>

                </div>
              </div>

              <div className='slider-wrapper'>
                <Slider
                  value={ inhaleDuration }
                  min={ 1 }
                  max={ 15 }
                  onChangeCommitted={ (event, newValue) => ratioHandler(newValue, 'inhaleRatio') }
                />
              </div>
            </div>

            <div className='section-divider'/>

            { pauseAfterInhaleRatio > 0  &&
            <>
              <div className='setting-item'>
                <div className='flex-box content-space-between'>
                  <div className='flex-box'>
                    <div className='label'>
                      { translations.breathing.pause_after }:
                    </div>

                    <div style={{ color: appProps.colorScheme }} className='value'>
                      { Math.round(pauseAfterInhale) } { translations.general.sec }
                    </div>

                  </div>
                </div>

                <div className='slider-wrapper'>
                  <Slider
                    value={ pauseAfterInhale }
                    min={ 1 }
                    max={ 15 }
                    onChangeCommitted={ (event, newValue) => ratioHandler(newValue, 'pauseAfterInhaleRatio') }
                  />
                </div>
              </div>

              <div className='section-divider'/>
            </>
          }

            <div className='setting-item'>
              <div className='flex-box content-space-between'>
                <div className='flex-box'>
                  <div className='label'>
                    { translations.breathing.exhale }:
                  </div>

                  <div style={{ color: appProps.colorScheme }} className='value'>
                    { Math.round(exhaleDuration) } { translations.general.sec }
                  </div>

                </div>
              </div>

              <div className='slider-wrapper'>
                <Slider
                  value={ exhaleDuration }
                  min={ 1 }
                  max={ 15 }
                  onChangeCommitted={ (event, newValue) => ratioHandler(newValue, 'exhaleRatio') }
                />
              </div>
            </div>

            <div className='section-divider'/>

            { pauseAfterExhaleRatio > 0 &&
            <>
              <div className='setting-item'>
                <div className='flex-box content-space-between'>
                  <div className='flex-box'>
                    <div className='label'>
                      { translations.breathing.pause_after }:
                    </div>
                    <div style={{ color: appProps.colorScheme }} className='value'>
                      { Math.round(pauseAfterExhale) } { translations.general.sec }
                    </div>
                  </div>
                </div>


                <div className='slider-wrapper'>
                  <Slider
                    value={ pauseAfterExhale }
                    min={ 1 }
                    max={ 15 }
                    onChangeCommitted={ (event, newValue) => ratioHandler(newValue, 'pauseAfterExhaleRatio') }
                  />
                </div>
              </div>

              <div className='section-divider'/>
            </>
            }

            <div className='setting-item'>
              <div className='flex-box content-space-between'>
                <div className='flex-box'>
                  <div className='label'>
                    { translations.general.duration }:
                  </div>

                  <div style={{ color: appProps.colorScheme }} className='value'>
                    { duration } { translations.general.min }
                  </div>

                </div>
              </div>

              <div className='slider-wrapper'>
                <Slider
                  value={ duration }
                  min={ 1 }
                  max={ 15 }
                  onChangeCommitted={ (event, newValue) => setDuration(newValue) }
                />
              </div>
            </div>
          </div>
        </Modal>
      }

      { breathingModalOpen &&
        <Modal
          visible
          appProps={ appProps }
          closeModal={ onClose }
          mode='fullpage'
          theme='dark'
        >
          <BreathingExercise
            appProps={ appProps }
            pauseAfterExhale={  pauseAfterExhale  }
            pauseAfterInhale={  pauseAfterInhale  }
            exhaleDuration={ exhaleDuration }
            inhaleDuration={ inhaleDuration }
            duration={ duration  * 60 }
            breathingId={ selectedBreathing.id }
            breathingTranslations={ selectedBreathing.content.settings.translations }
          />
        </Modal>
      }
    </>
  )
}
