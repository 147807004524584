import React from 'react'
import ListItem from '../shared/ListItem'
import Description from '../shared/Description'
import { NO_IMAGE_SOURCE } from '../GlobalConstants'
import { getTranslation } from '@reactiveonline/frontend_shared_components/utils'

export default function Instructors({ appProps, screenClass, instructors }) {
  const { translations, currentLocale, defaultLocale } = appProps

  return (
    <div className='main-container' style={{ paddingTop: `${appProps.headerHeight}px` }}>
      <div className={`flex-box flex-column ${screenClass}` }>
        <div className='section-title'>
          <h1>{ translations.general.instructors }</h1>
        </div>
        <div className='section-divider'></div>
        <div className='list-item-wrapper instructors-index'>
          { instructors.map( (instructor) => {
            const translation = getTranslation(instructor.translations, currentLocale, defaultLocale)
            const stripedHtml = translation.bio ? translation.bio.replace(/<[^>]+>/g, '').substring(0, 150) : ''
            return (
              <div key={ instructor.id } className='list-item'>
                <div className='list-item-image'>
                  <a href={ `/${appProps.currentLocale}/instructors/${instructor.id}` } rel='nofollow noindex'></a>
                  <img className='image-gallery-image' src={ instructor.image || NO_IMAGE_SOURCE } />
                </div>
                <div className='list-item-details content-space-between'>
                  <div>
                    <div className='title' style={{ marginTop: 10 }}>
                      <a href={ `/${appProps.currentLocale}/instructors/${instructor.id}` } rel='nofollow noindex'>
                        { instructor.fullName }
                      </a>
                      { translation.title &&
                        <div href=''>
                          { translation.title }
                        </div>
                      }
                    </div>

                    { stripedHtml &&
                      <Description
                        description={ stripedHtml }
                      />
                    }
                  </div>
                  <div className='button-wrapper' style={{ marginTop: 10 }}>
                    <a href={ `/${appProps.currentLocale}/instructors/${instructor.id}` } rel='nofollow noindex' className='button'>
                      { translations.general.view_contributions }
                    </a>
                  </div>
                </div>
              </div>
              )
            })
          }
        </div>
      </div>
    </div>
  )
}
