import React from 'react'
import { downloadIcs } from '../../shared/utils'
import moment from 'moment'

export default function OnSiteEvent({
  task, translation, image, translations, eventUid, instructors
}) {
  const { settings } = task

  function renderEventInfo(icon, text) {
    return (
      <div className='flex-box items-center' style={{ marginTop: 10 }}>
        <div className='content-center items-center'>
          <div className={ `${ icon } color-scheme` }/>
        </div>

        <div className='flex-box flex-wrap' style={{ marginLeft: 10 }}>
          { text }
        </div>
      </div>
    )
  }

  return (
    <div
      className='onsite-event-container'
      style={{ backgroundImage: `url(${ image ? image : 'https://storage.googleapis.com/mindseed_files/lxp/component_events_placeholder.webp' })` }}
    >
      <div className='card'>
        <div style={{ marginTop: 10 , marginLeft: 10  }}>
          <h1>
            { translation.title }
          </h1>

          { instructors.length > 0 &&
            <h2>
              { translations.general.with } { instructors.map( instructor => instructor.fullName ).join(', ') }
            </h2>
          }

          { settings.startDate &&
            renderEventInfo('calendar_icon', `${ moment(settings.startDate).format('DD/MM/YY HH:mm') }${ settings.endDate ? ' - ' : '' }${ settings.endDate ? moment(settings.endDate).format('DD/MM/YY HH:mm') : '' }`)
          }

          { settings.eventDuration !== undefined && settings.eventDuration > 0 &&
            renderEventInfo('duration_icon', `${ settings.eventDuration } ${ translations.general.minutes }`)
          }

          { settings.location &&
            renderEventInfo('location_icon', settings.location)
          }

          { settings.maxParticipants > 0 &&
            renderEventInfo('participants_icon', settings.maxParticipants)
          }
        </div>

        <div style = {{ position: 'absolute' , bottom: 15 , right: 15 }}>
          <button
            type='button'
            className='button'
            onClick={ () => downloadIcs(settings.startDate, settings.endDate, task.id, settings.location, translation.title) }
          >
            { translations.general.download_ics }
          </button>
        </div>
      </div>
    </div>
  )
}
