import React from 'react'
import Modal from '../../components/shared/Modal'
import { AuthorizableLink } from '@reactiveonline/frontend_shared_components'

export default function LoginPrompt({ appProps, redirectAfterAuthPath, onClose }) {
  const { translations } = appProps

  function renderMssoAuthButton() {
    const csrfToken = document.querySelector("meta[name='csrf-token']").getAttribute("content")

    return (
      <div className="field" style={ {marginTop: 50} }>
        <form action={ `/auth/msso_${appProps.mssoProviderId}_auth` } method="post">
          <input type="hidden" name="authenticity_token" value={ csrfToken } />
          <input type="hidden" name="locale" value={ appProps.currentLocale } />
          { (redirectAfterAuthPath || appProps.redirectAfterAuthPath) && <input type="hidden" name="authorizable_redirection_link" value={ redirectAfterAuthPath || appProps.redirectAfterAuthPath } /> }
          <input type="submit" className="button" value={ appProps.translations.authentication.azureSignInButton } />
        </form>
      </div>
    )
  }

  return (
    <Modal
      visible
      closeModal={ onClose }
      mode='small'
      icon='account'
      abortAction={ null }
      abortText={ null }
      acceptAction={ null }
      saveText={ null }
    >
      <div className='flex-box flex-column items-center content-center centered'>
        <div style={{ marginTop: 20, marginBottom: 20 }}>{ translations.general.login_warning }</div>
        <div style={{ width: '100%' }}>
          { appProps.mssoProviderId && appProps.mssoProviderId !== "" && renderMssoAuthButton() }

          { !appProps.mssoProviderId && appProps.userFormLoginType &&
            <div className='flex-box items-center content-center' style={{ width: '100%' }}>
              <AuthorizableLink
                appProps={ appProps }
                linkRef={ redirectAfterAuthPath || appProps.redirectAfterAuthPath }
                authorizableScreen='register'
                linkContent={(
                  <button
                    type='button'
                    className='button inverted'
                    style={{ marginRight: 5 }}
                  >
                    { translations.account.register }
                  </button>
                )}
              />

              <AuthorizableLink
                appProps={ appProps }
                linkRef={ redirectAfterAuthPath || appProps.redirectAfterAuthPath }
                authorizableScreen='login'
                linkContent={(
                  <button
                    type='button'
                    className='button'
                    style={{ marginLeft: 5 }}
                  >
                    { translations.account.login }
                  </button>
                )}
              />
            </div>
          }

          { !appProps.mssoProviderId && !appProps.userFormLoginType &&
            <a href={ appProps.practiceLogPath }>
              { translations.account.login }
            </a>
          }
        </div>
      </div>
    </Modal>
  )
}
