import React, { useState, useEffect } from 'react'


export function useLoadMore(items, setItems, itemsPerPage, initialTotalItems) {
  const [currentPage, setCurrentPage] = useState(0)
  const [totalItems, setTotalItems] = useState(initialTotalItems)
  const [totalPages, setTotalPages] = useState(initialTotalItems ? Math.ceil(initialTotalItems / itemsPerPage) : null)

  useEffect(() => {
    let newTotalPages = Math.ceil(initialTotalItems ? Math.ceil(initialTotalItems / itemsPerPage) : null)
    setTotalPages(newTotalPages)
    if (newTotalPages && currentPage >= newTotalPages) {
      setCurrentPage(newTotalPages - 1)
    }
  }, [initialTotalItems])

  const fetchItems = (itemsPath, initial = false) => {
    if (totalPages && ((currentPage + 1) > totalPages)) return;

    Rails.ajax({
      type: 'GET',
      url: `${ itemsPath }&page=${ initial ? 1 : currentPage + 1 }`,
      dataType: 'json',
      success: res => {
        if (res.items) {
          let newItems = initial ? [...res.items] : [...items, ...res.items]
          newItems = newItems.filter((item, index, array) => array.findIndex(i => i.id == item.id) == index)
          setItems(newItems)
        }
        setCurrentPage((prevCurrentPage) => { return (initial ? 1 : prevCurrentPage + 1) })
        if (!totalItems || initial) setTotalItems(res.totalItems)
      },
      error: res => {}
    })
  }

  return [
    fetchItems,
    totalItems,
    setTotalItems
  ]
}
