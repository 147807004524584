import React from 'react'
import { CircularProgress } from '@material-ui/core'

export default function Progress({circleSize, percentage, textValue, textClass}) {

  const sizeRatio = circleSize / 100

  const styles = {
    circularProgress: {
      height: circleSize,
      width: circleSize,
      position: 'relative'
    },

    cInner: {
      position: 'absolute',
      zIndex: 6,
      top: '50%',
      left: '50%',
      height: sizeRatio * 80,
      width: sizeRatio * 80,
      marginTop: -(sizeRatio * 40),
      marginRight: 0,
      marginBottom: 0,
      marginLeft: -(sizeRatio * 40),
      borderRadius: '100%'
    },

    cValue: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      zIndex: 10,
      textAlign: 'center'
    },

    cOuter: {
     position: 'absolute',
     height: '100%',
     width: '100%',
     borderRadius: '100%',
     clip: `rect(0px, ${circleSize}px, ${circleSize}px, 0px)`
    },

  }

  return (
    <div style={styles.circularProgress}>
      <div className="circular-progress-inner" style={styles.cInner}></div>
      <div style={styles.cValue} className={textClass}>{textValue}</div>
      <div className="circular-progress-outer-bar" style={styles.cOuter}>
        <CircularProgress
          size= { circleSize }
          thickness= { 4.5 }
          variant= 'determinate'
          value= { percentage }
        />
      </div>
    </div>
  )
}
