import React, { useState, useEffect, useRef } from 'react'
import Modal from './Modal'
import { Loader } from '@reactiveonline/frontend_shared_components'
import { FlashMessage } from '@reactiveonline/frontend_shared_components'

export default function InvitationExpiredModal({ appProps }) {
  const [loading, setLoading]                     = useState(false)
  const [expiredInvitation, setExpiredInvitation] = useState(appProps.showReinvitationForm)

  const flashMessageRef = useRef(null)

  function resendInvitation() {
    setLoading(true)

    Rails.ajax({
      type: 'GET',
      url: appProps.userResendInvitationPath,
      dataType: 'json',
      success: res => {
        setExpiredInvitation(false)
        flashMessageRef.current.show(res.message, 'success')
      },
      complete: res => {
        setLoading(false)
      }
    })
  }

  return (
    <>
      { expiredInvitation &&
        <Modal
          visible
          closeModal={ ()=> setExpiredInvitation(false) }
          mode='medium'
          elementsClass='content-center body-bg'
          theme='body-bg'
        >
          <div className='auth-box' style={{ textAlign: 'center' }}>
            <h1 style={{ height: 'auto', textAlign: 'center', marginBottom: 20 }}>
              { appProps.translations.account.invitation_expired_title }
            </h1>
            <div style={{ textAlign: 'center', marginBottom: 20 }}>
              { appProps.translations.account.invitation_expired_description }
            </div>
            { loading ?
              <Loader
                position='center'
                size='medium'
              />
              :
              <div style={{ margin: '20px 0px' }} className='flex-box items-center content-center'>
                <div className='button' onClick={ ()=> resendInvitation() }>
                  { appProps.translations.account.invitation_expired_button }
                </div>
              </div>
            }
          </div>
        </Modal>
      }

      <FlashMessage ref={ flashMessageRef }/>
    </>
  )
}
