import React, { useState, useEffect } from 'react'
import TabsIndex from '../shared/TabsIndex'
import Listing from './Listing'
import { Loader } from '@reactiveonline/frontend_shared_components'

export default function Courses({
  appProps, screenClass, pageTitle, initialCourses, totalPages, coursesPath, cataloguePath,
  featuredCoursesPath, courseLocale
}) {
  const [featuredCourses, setFeaturedCourses] = useState([])
  const [loading, setLoading] = useState(false)
  const [loadingFeaturedCourses, setLoadingFeaturedCourses] = useState(false)
  const [courses, setCourses] = useState(initialCourses)
  const [currentPage, setCurrentPage] = useState(1)
  const [currentTotalPages, setCurrentTotalPages] = useState(totalPages)

  const { translations } = appProps
  const hasEnroledProducts = courses.length > 0 || featuredCourses.length > 0

  useEffect( () => {
    setLoadingFeaturedCourses(true)

    Rails.ajax({
      type: 'GET',
      url: featuredCoursesPath,
      dataType: 'json',
      success: res => {
        setFeaturedCourses(res.featuredCourses)
      },
      complete: res => {
        setLoadingFeaturedCourses(false)
      }
    })
  }, [])

  function handleLoadMore() {
    setLoading(true)

    Rails.ajax({
      type: 'GET',
      url: `${ coursesPath }?page=${ currentPage + 1 }`,
      dataType: 'json',
      success: res => {
        setCurrentPage( prevState => prevState + 1 )
        setCourses( prevState => [ ...prevState, ...res.courses ] )
        setCurrentTotalPages(res.totalPages)
      },
      complete: res => {
        setLoading(false)
      }
    })
  }

  return (
    <div className={ `lxp-wrapper ${ screenClass }` }>
      <div className='section-hero'>
        { appProps.userLoggedIn &&
          <TabsIndex
            appProps={ appProps }
            pageTitle={ translations.general.learnings }
          />
        }
      </div>

      { appProps.userLoggedIn ?
        <>
          { hasEnroledProducts ?
              <>
                { courses.length > 0 &&
                  <Listing
                    appProps={ appProps }
                    screenClass={ screenClass }
                    coursesType={ courses }
                    divisionTitle={ translations.course.continue_learning }
                    loadMore={ currentPage < currentTotalPages }
                    handleLoadMore={ handleLoadMore }
                    loading={ loading }
                    courseLocale={ courseLocale }
                  />
                }

                { featuredCourses.length > 0  &&
                  <Listing
                    appProps={ appProps }
                    screenClass={ screenClass }
                    coursesType={ featuredCourses }
                    divisionTitle={ translations.course.featured_courses }
                    courseLocale={ courseLocale }
                  />
                }
                <div>
                  { loadingFeaturedCourses &&
                    <Loader
                      position={ 'center' }
                      size={ 'medium' }
                    />
                  }
                </div>
              </>
            :
            <div className='section'>
              <div className='main-container'>
                <div className='login-message'>
                  { !loadingFeaturedCourses &&
                    <h3>
                      <a href={ cataloguePath } rel='nofollow noindex noreferrer'>
                        { translations.general.not_enrolled_message }
                      </a>
                    </h3>
                  }
                </div>
              </div>
            </div>
          }
        </>
        :
        <div className='section heading'>
          <div className='main-container'>
            <div className='section-title'>
              <h3>{ translations.general.login_warning }</h3>
            </div>
          </div>
        </div>
      }

    </div>
  )
}
