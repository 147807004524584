import React, { useState, useEffect } from 'react'
import { useLoadMore } from '../../helpers/useLoadMore'
import { formatDate } from '../../helpers/utils'

const itemsPerPage = 3
const placeholderAvatar = 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ2nNUXQ8TRIbctxR4bGhWkQd5mrIeu1okIyte3_rz0HSVf6hctJAXHOwrUidaXMlOtCFA&usqp=CAU'

export default function Comment({
  parent, comment, userId, entityType, entityUid, taskId, level, commentsPath, setComment,
  likeCommentHandler, editCommentHandler, deleteCommentHandler, translations
}) {
  const { content, replies, newReplies, totalReplies, totalLikes, likeByUser } = comment

  const [fetchReplies, totalPages, setTotalPages] = useLoadMore(comment.replies, (value) => setComment(comment.id, 'replies', value), itemsPerPage, comment.totalReplies)
  const [action, setAction] = useState(null)
  const [input, setInput] = useState("");
  const [inputFlag, setInputFlag] = useState(false)

  const inputChangeHandler = (e) => setInput(e.target.value)
  const onCancelChangeHandler = () => setInputFlag(false)

  useEffect(() => {
    if (comment.newReplies) {
      setComment(comment.id, 'newReplies', comment.newReplies.filter(item => comment.replies.find(r => r.id == item.id) == null))
    }
  }, [replies])

  const replyInputHandler = (e) => {
    setInputFlag(true)
    setAction("Reply")
    setInput("")
  }

  const editInputHandler = (e) => {
    setInputFlag(true)
    setAction("Edit")
    setInput(content)
  }

  const submitReplyHandler  = () => {
    if (input.trim() === "") {
      return;
    }

    let fd = new FormData()
    fd.append('parent', comment.id)
    fd.append('comment[content]', input)

    Rails.ajax({
      type: 'POST',
      url: `${ commentsPath }?commentable_type=${ entityType }&commentable_uid=${ entityUid }&task_id=${ taskId }`,
      dataType: 'json',
      data: fd,
      success: res => {
        setInputFlag(false)
        setAction(null)
        setInput("")
      },
      error: res => {
      }
    })
  }

  const submitEditHandler = () => {
    if (input.trim() === "") return;

    editCommentHandler(parent, comment.id, input)
    setInputFlag(false)
    setAction(null)
    setInput("")
  }

  return (
    <>
      { ((comment.user && comment.user.id) || (replies && replies.length > 0) || (newReplies && newReplies.length > 0)) &&
        <div className='comment-item-wrapper'>
          <div className='comment-item flex-box'>
            <div className='avatar'>
              <img src={ comment.user && comment.user.avatar ? comment.user.avatar : placeholderAvatar } />
            </div>
            <div className='comment flex-box flex-column'>
              <div className='comment-box'>
                { comment.user && comment.user.id &&
                  <div className='comment-header flex-box content-space-between'>
                    <div className='author'>
                      { comment.user.fullname }
                    </div>

                    { comment.hasBeenEdited &&
                      <div className='comment-edited'>
                        { translations.comments.edited }
                      </div>
                    }
                  </div>
                }

                <div className='comment-text'>
                  { comment.user && comment.user.id ? comment.content : translations.comments.deleted_comment_content }
                </div>
              </div>

              <div className='actions-info flex-box content-space-between items-center'>
                <div className='actions flex-box items-center'>
                  { !inputFlag && level <= 1 && comment.user && comment.user.id &&
                    <div className='clickable-link pointer' onClick={ replyInputHandler }>
                      { translations.comments.reply }
                    </div>
                  }

                  { !inputFlag &&
                    <>
                      <div className="button plain" style={{ cursor: `${(comment.user && comment.user.id && comment.user.id !== userId) ? 'pointer' : 'no-drop'}` }}
                        onClick={ () => {
                          if(comment.user && comment.user.id && comment.user.id !== userId) {
                            likeCommentHandler(parent, comment.id)}
                          }
                        }>
                          <i className={ `${ comment.likeByUser ? 'fa-solid' : 'fa-light'} fa-thumbs-up` } />
                      </div>

                      <div className='like-count'>
                        { comment.totalLikes }
                      </div>

                      { comment.user && comment.user.id === userId &&
                        <div className="button plain"
                          onClick={ editInputHandler }>
                            <i className="edit_icon" />
                        </div>
                      }

                      { comment.user && comment.user.id === userId &&
                        <div className="button plain"
                          onClick={ () => deleteCommentHandler(parent, comment.id) }>
                            <i className="trash_icon" />
                        </div>
                      }
                    </>
                  }
                </div>
                <div className='date-created'>
                  { formatDate(comment.createdAt) }
                </div>
              </div>

            </div>
          </div>
        </div>
      }

      <div className='nested-comment'>
        { replies && replies.length > 0 && replies.map((element) => (
            <Comment
              key={ element.id }
              parent={ comment.id }
              comment={ element }
              userId={ userId }
              entityType={ entityType }
              entityUid={ entityUid }
              taskId={ taskId }
              level={ level + 1 }
              commentsPath={ commentsPath }
              setComment={ setComment }
              likeCommentHandler={ likeCommentHandler }
              editCommentHandler={ editCommentHandler }
              deleteCommentHandler={ deleteCommentHandler }
              translations={ translations }
            />
          ))
        }

        { replies && totalReplies > 0 && replies.length < totalReplies &&
            <div className="flex-box content-center items-center load-more-button">
              <div
                className="clickable-link pointer"
                style={{ paddingTop: 30 }}
                onClick={ () => fetchReplies(`${ commentsPath }/${ comment.id }/replies?commentable_type=${ entityType }&commentable_uid=${ entityUid }&task_id=${ taskId }`) }
              >
                { translations.general.load_more } { `(${totalReplies - replies.length})` }
              </div>
            </div>
        }

        { newReplies && newReplies.length > 0 && newReplies.map((element) => (
            <Comment
              key={ element.id }
              parent={ comment.id }
              comment={ element }
              userId={ userId }
              entityType={ entityType }
              entityUid={ entityUid }
              taskId={ taskId }
              level={ level + 1 }
              commentsPath={ commentsPath }
              setComment={ setComment }
              likeCommentHandler={ likeCommentHandler }
              editCommentHandler={ editCommentHandler }
              deleteCommentHandler={ deleteCommentHandler }
              translations={ translations }
            />
          ))
        }

        { inputFlag &&
          <div className='flex-box flex-column new-comment-wrapper'>
            { action === "Reply" ?
              <div className='label'>{ translations.comments.add_comment }</div> : <div className='label'>{ translations.comments.edit_comment }</div>
            }
            <textarea className='comment-area' value={ input } onChange={ inputChangeHandler } />
            <div className='flex-box items-center'>
              <button
                className='button'
                onClick={ action === "Reply" ? submitReplyHandler : submitEditHandler }>
                  { translations.comments.submit }
              </button>
              <button
                style={{ marginLeft: 10 }}
                className='button inverted'
                onClick={ onCancelChangeHandler }>
                  { translations.general.cancel }
              </button>
            </div>
          </div>
        }
      </div>
    </>
  )
}
