import React, { useState, useEffect } from 'react'
import { Loader } from '@reactiveonline/frontend_shared_components'
import CourseListItem from './CourseListItem'
import Instructors from '../shared/Instructors'
import Description from '../shared/Description'
import Modal from '../shared/Modal'
import { NO_IMAGE_SOURCE } from '../GlobalConstants'
import { lrsEvent, formatDate } from '../../helpers/utils'
import Chapters from './Chapters'
import { getTranslation } from '@reactiveonline/frontend_shared_components/utils'

export default function Course({ appProps, screenClass, course, fetchCourseContentPath, courseLocale, entityShowPath }) {
  const [loading, setLoading] = useState(false)
  const [unenrolModalOpen, setUnenrolModalOpen] = useState(false)
  const [accessibleModalOpen, setAccessibleModalOpen] = useState(false)

  const { instructors, image, accessibilityProps } = course
  const { translations, currentLocale } = appProps
  const translation = getTranslation(course.translations, courseLocale, currentLocale)

  function handleEnrolment() {
    if (!accessibilityProps) return;
    setLoading(true)
    const { userEnroled, enrolPath, unenrolPath } = accessibilityProps

    Rails.ajax({
      type: 'POST',
      url: userEnroled ? unenrolPath : enrolPath,
      dataType: 'json',
      success: res => {
        if (unenrolModalOpen) {
          setUnenrolModalOpen(false)
          lrsEvent(appProps, { en: { title: translation.title } }, course.uid, 'course', 'unenroled')
          window.location.href = res.redirectTo
        }

        if (res.redirectTo && res.accessible) {
          lrsEvent(appProps, { en: { title: translation.title } }, course.uid, 'course', 'enroled')
          window.location.href = res.redirectTo
        } else if (!unenrolModalOpen && !res.accessible && typeof(res) == 'object') {
          lrsEvent(appProps, { en: { title: translation.title } }, course.uid, 'course', 'enroled')
          setAccessibleModalOpen(true)
        }
      },
      error: res => {
        setLoading(false)
      }
    })
  }

  function openUnenrolModal() {
    setUnenrolModalOpen(true)
  }

  useEffect( ()=> {
    if (window.location.href.includes('isMobileApp=true')) {
      setInterval( ()=> {
        if (document.getElementsByClassName('top_header')[0] != undefined) {
          document.getElementsByClassName('top_header')[0].style.display = 'none';
        }
        if (document.getElementsByClassName('header_wrapper')[0] != undefined) {
          document.getElementsByClassName('header_wrapper')[0].style.display = 'none';
        }
        if (document.getElementsByClassName('breadcrumb-wrapper')[0] != undefined) {
          document.getElementsByClassName('breadcrumb-wrapper')[0].style.display = 'none';
        }
        if (document.getElementsByTagName('footer')[0] != undefined) {
          document.getElementsByTagName('footer')[0].style.display = 'none';
        }
        if (document.getElementById('Body_7712465398')) {
          document.getElementById('Body_7712465398').style.display = 'none';
        }
      }, 1000)
    }

    const queryParams = new URLSearchParams(location.search)

    if (queryParams.has('token_expired')) {
      appProps.flashMessage.show(translations.course.automatic_enrolment_error, 'error')
      queryParams.delete('token_expired')
      // window.location.href = window.location.href.replace('?token_expired=true','')
    }

    if (queryParams.has('accessibility_modal')) {
      queryParams.delete('accessibility_modal')
      setAccessibleModalOpen(true)
    }
  },[])

  return (
    <>
      { unenrolModalOpen &&
        <Modal
          visible
          closeModal={ () => setUnenrolModalOpen(false) }
          icon='exclamation'
          mode='small'
          elementsClass='items-center content-center centered'
          abortText={ translations.general.cancel }
          abortAction={ () => setUnenrolModalOpen(false) }
          acceptAction={ handleEnrolment }
          saveText={ translations.general.button_accept }
        >
          <div>
            { translations.course.unenrol_message }
          </div>
        </Modal>
      }

      { accessibleModalOpen &&
        <Modal
          visible={ accessibleModalOpen }
          icon='exclamation'
          mode='small'
          elementsClass='items-center content-center centered'
          acceptAction={ () => window.location.href.includes('accessibility_modal=true') ? (window.location.href = window.location.href.replace('?accessibility_modal=true','')) : (window.location.href = window.location.href.replace('?token_expired=true','')) }
          saveText={ translations.general.ok }
        >
          <>
            <div>
              { translations.course.enrolment_success }
            </div>
            <div>
              { translations.course.course } { translations.course.starts_at.toLowerCase() } { formatDate(course.accessibilityProps.startDate) }
            </div>
          </>
        </Modal>
      }

      <div className={ `lxp-wrapper ${ screenClass }` }>
        <div className='section heading' style={{ overflow: 'initial' }}>
          <div className='main-container' style={{ position: 'relative' }}>
            <div className='flex-box flex-wrap'>
              <div className='section-title'>
                { course.bundlePath ?
                  <div className='flex-box items-center'>
                    <div
                      className='button inverted'
                      style={{ marginRight: 20, width: 40, height: 40, borderRadius: 20 }}
                      onClick={ () => window.location.href = course.bundlePath }
                    >
                      <div className='chevron_icon_left'/>
                    </div>
                    <h1>{ translation.title }</h1>
                  </div>

                :
                  <h1>{ translation.title }</h1>
                }

                { instructors.length > 0 &&
                  <div className='body-text'>
                    { translations.general.with }
                    { instructors.map( (instructor, index) => (
                      <a
                        key={ instructor.id }
                        className='clickable-link'
                        style={{ paddingLeft: 5 }}
                        href={ `/${ currentLocale }/instructors/${ instructor.id }` }
                        rel='nofollow noindex noreferrer'
                       >
                         { `${ instructor.fullName }${ index < (instructors.length - 1) ? ',' : '' }` }
                      </a>
                    ))}
                  </div>
                }
                { translation.shortDescription &&
                  <Description
                    description={ translation.shortDescription }
                  />
                }
              </div>

              <CourseListItem
                appProps={ appProps }
                itemClass={ screenClass }
                item={ course }
                actionMethod={ accessibilityProps.userEnroled ? openUnenrolModal : handleEnrolment }
                entityShowPath={ entityShowPath }
                showExtraInfo
                entityType='MindseedLms::Course'
                courseLocale={ courseLocale }
              />
            </div>
          </div>
        </div>

        <div className='section body-bg'>
          <div className='main-container'>
            <div className='course-overview-details'>

              { accessibilityProps.userEnroled && translation.additionalInfo && translation.additionalInfo != "<p></p>" &&
                <div className='card'>
                  <div dangerouslySetInnerHTML={{__html: translation.additionalInfo }} className='editor'></div>
                </div>
              }

              { translation.description &&
                <>
                  <div className='section-title'>
                    <h3>{ translations.course.description }</h3>
                  </div>
                  <div dangerouslySetInnerHTML={{__html: translation.description }} className='editor'></div>
                </>
              }

              <Chapters
                appProps={ appProps }
                fetchCourseContentPath={ fetchCourseContentPath }
                courseLocale={ courseLocale }
                courseLxpPath={ course.accessibilityProps.lxpPath }
                userEnroled={ course.accessibilityProps.userEnroled }
              />

              <div className='section-divider large'/>

              { instructors.length > 0 &&
                <Instructors
                  instructors={ instructors }
                  appProps={ appProps }
                />
              }

            </div>
          </div>
        </div>

      </div>

      { loading && <Loader fullPage/> }
    </>
  )
}
