import React, { useState, useEffect, useRef } from 'react'

import EventContent from './EventContent'
import Modal from '../shared/Modal'
import CourseLanguageSelector from '../shared/CourseLanguageSelector'

export default function Lxp({
  appProps, event, courseLanguage, setLocalePath, userPractices, completeTaskPath,
  fetchContentPath, fetchNotePath, commentsPath, fetchResourcesPath
}) {
  const [langModalOpen, setLangModalOpen] = useState(!event.localeSupported)
  const [contentLoading, setContentLoading] = useState(!event.localeSupported)
  const [loading, setLoading] = useState(false)
  const [currentTask, setCurrentTask] = useState({
    id: event.uid,
    type: event.type,
    settings: {
      ...event.settings,
      ...event.content
    }
  })
  const [showChangedCourseAlert, setShowChangedCourseAlert] = useState(false)
  const [tabIndex, setTabIndex] = useState(0)
  const completeAfterChangeRef= useRef(false)

  const { translations } = appProps

  const resourceProps = {
    fetchResourcesPath: fetchResourcesPath
  }

  useEffect( ()=> {
    if (window.location.href.includes('isMobileApp=true')) {
      setInterval( ()=> {
        if (document.getElementsByClassName('top_header')[0] != undefined) {
          document.getElementsByClassName('top_header')[0].style.display = 'none';
        }
        if (document.getElementsByClassName('header_wrapper')[0] != undefined) {
          document.getElementsByClassName('header_wrapper')[0].style.display = 'none';
        }
        if (document.getElementsByClassName('breadcrumb-wrapper')[0] != undefined) {
          document.getElementsByClassName('breadcrumb-wrapper')[0].style.display = 'none';
        }
        if (document.getElementsByTagName('footer')[0] != undefined) {
          document.getElementsByTagName('footer')[0].style.display = 'none';
        }
        if (document.getElementById('Body_7712465398')) {
          document.getElementById('Body_7712465398').style.display = 'none';
        }
      }, 1000)
    }
  },[])

  return (
    <>
      { langModalOpen &&
        <Modal
          visible={ langModalOpen }
          mode='small centered'
          elementsClass='items-center content-center'
          icon={ 'course' }
        >
          <div className='flex-box items-center content-space-between'>
            <div>
              { translations.lxp.preferred_course_language_message }
              <b style={{ marginLeft: 5 }}>{ courseLanguage.presentation }</b>
            </div>
            { courseLanguage.icon &&
              <div className="course-current-lang">
                {courseLanguage && <img src={ courseLanguage.icon } style={{"maxHeight": 30}} />}
              </div>
            }
          </div>
          <div style={{ marginTop: 20, marginBottom: 30 }}>
            { translations.lxp.course_language_not_supported }
          </div>
          <div className="languages_wrapper">
            <CourseLanguageSelector
              languages={ event.availableLanguages }
              setLocalePath={ setLocalePath }
              translations={ translations }
            />
          </div>
        </Modal>
      }

      <div className='section body-bg' style={{ paddingTop: 20 }}>
        <div className="main-container">
          {!contentLoading &&
            <div className="task-container flex-box items-start">
              <EventContent
                appProps={ appProps }
                user={ appProps.user }
                event={ event }
                currentTask={ currentTask }
                courseLocale={ courseLanguage.code }
                fetchNotePath={ fetchNotePath }
                saveBookmarkPath={ appProps.saveBookmarkPath }
                commentsPath={ commentsPath }
                resourceProps={ resourceProps }
                tabIndex={ tabIndex }
                setTabIndex={ setTabIndex }
              />
            </div>
          }
        </div>
      </div>

      { showChangedCourseAlert &&
        <Modal
          visible={ showChangedCourseAlert }
          mode='small'
        >
          <div className='flex-box flex-column content-space-around' style={{ height: '100%' }}>
            { translations.lxp.course_change }

            <button
              type='button'
              className='button'
              onClick={ () => window.location.reload() }
            >
              { translations.general.reload }
            </button>
          </div>
        </Modal>
      }
    </>
  )
}
