import React, { useState } from 'react'
import TextEditor from '../shared/TextEditor'
import { formatDate } from '../../helpers/utils'
import Modal from '../shared/Modal'

export default function Note({ appProps, note, isEditable, onDelete, onUpdate }) {
  const [isToggled, setIsToggled] = useState(false)
  const [content, setContent] = useState(note.content)
  const [showTextEditor, setShowTextEditor] = useState(false)
  const [editableContent, setEditableContent] = useState(content)
  const [showDeleteAlert, setShowDeleteAlert] = useState(false)
  const maxContentSize = 300
  const { translations } = appProps

  function handleToggle() {
    setIsToggled(!isToggled)
    setShowTextEditor(false)
  }

  function handleOpenEditor() {
    setIsToggled(true)
    setShowTextEditor(true)
  }

  function handleCancelEdit() {
    setEditableContent(content)
    setShowTextEditor(false)
    setIsToggled(false)
  }

  function deleteNote() {
    setShowDeleteAlert(false)

    Rails.ajax({
      url: `${ appProps.notesPath }/${ note.id }`,
      type: 'DELETE',
      dataType: 'json',
      success: res => {
        onDelete(note.id)
      },
      error: err => {}
    })
  }

  function save() {
    setContent(editableContent)
    setShowTextEditor(false)
    let formData = new FormData()
    formData.append('note[content]', editableContent)

    Rails.ajax({
      url: `${ appProps.notesPath }/${ note.id }`,
      type: 'PATCH',
      data: formData,
      dataType: 'json',
      success: res => {
        onUpdate(res.note)
        setIsToggled(false)
      },
      error: err => {}
    })
  }

  return(
    <div className='note-wrapper card flex-box items-center flex-column'>
      <div className='note-info flex-box items-center contents-space-between'>
        <div className='note-details'>
          <div className='note-title'>
            { note.taskTitle }
          </div>
          <div className='note-additional-details'>
            { formatDate(note.updatedAt) } <a href='#' rel='nofollow noidex noreferrer'> - { note.entityTitle }</a>
          </div>
        </div>

        { isEditable &&
          <div className='note-actions flex-box items-center content-end'>
            <div className='action edit_icon' onClick={ handleOpenEditor }/>
            <div className='action remove_icon' onClick={ () => setShowDeleteAlert(true) }/>
          </div>
        }
      </div>

      { showTextEditor ?
        <>
          <TextEditor
            content={ content }
            setContent={ updatedContent => setEditableContent(updatedContent) }
            translations_characters={ translations.general.characters }
            translataions_words={ translations.general.words }
          />
          <div className='actions-wrapper flex-box items-center content-end'>
            <button
              type='button'
              className='button action inverted'
              onClick={ handleCancelEdit }
            >
              { translations.general.cancel }
            </button>

            <button
              type='button'
              className='button action'
              onClick={ save }
            >
              { translations.general.save }
            </button>
          </div>
        </>
        :
        <>
          { content.length < maxContentSize ?
            <div dangerouslySetInnerHTML={{ __html: content }} className='note-description editor'/>
            :
            <div className='note-user-content-wrapper flex-box flex-column'>
              <div className='note-user-content flex-box flex-column'>
                <div className={` ${!isToggled ? 'note-content-overlay' : ''} `}></div>
                <div dangerouslySetInnerHTML={{ __html: isToggled ? content : content.substring(0, maxContentSize) }} className='note-description editor' />
                <div className='actions-wrapper'>
                  <div className={ `action flex-box items-center content-center ${ isToggled ? 'up_icon' : 'down_icon' }` } onClick={ handleToggle }/>
                </div>
              </div>
            </div>
          }
        </>
      }

      { showDeleteAlert &&
        <Modal
          visible={ showDeleteAlert }
          closeModal={ () => setShowDeleteAlert(false) }
          mode='small centered-content'
          icon={ 'trash' }
          abortText={ translations.general.cancel }
          abortAction={ () => setShowDeleteAlert(false) }
          saveText={ translations.general.delete }
          acceptAction={ deleteNote }
        >
          <div>
            { translations.general.note_delete_alert }
          </div>
        </Modal>
      }
    </div>
  )
}
