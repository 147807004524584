import React, { useState } from 'react'
import { Loader } from '@reactiveonline/frontend_shared_components'
import { formatDate } from '../../../helpers/utils'

export default function Reminders({ appProps, pageName, pageTitle }) {
  const [reminders, setReminders] = useState(appProps.initialReminders)
  const [currentPage, setCurrentPage] = useState(1)
  const [loading, setLoading] = useState(false)

  const { translations } = appProps

  function loadMore(){
    setLoading(true)
    Rails.ajax({
      type: 'GET',
      url: `${appProps.remindersPath}?page=${currentPage + 1}`,
      dataType: 'json',
      success: res =>{
        setLoading(false)
        setCurrentPage(currentPage + 1)
        setReminders(reminders.concat(res.reminders))
      }
    })
  }

  function removeReminder(objectId) {
    let fd = new FormData()
    fd.append('object_id', objectId)

    Rails.ajax({
      type: 'DELETE',
      url: appProps.deleteReminderPath,
      dataType: 'json',
      data: fd,
      success: res => {
        setReminders(reminders.filter(r => r._id.$oid != objectId ))
        appProps.flashMessage.show(translations.reminders.deleted_successfully, 'success')
      }
    })
  }

  function hasRepeatOn(item) {
    let returnText = `${ translations.general.every }${ item.repeatAmount > 1 ? (' ' + item.repeatAmount) : '' } `
    returnText += item.repeatOn.map( weekday => translations.general[`${ weekday }${ item.repeatAmount > 1 ? '_plural' : '' }`] ).join(', ')

    return returnText
  }

  function renderItem(reminder) {
    return(
      <div key={reminder._id.$oid} className= 'flex-box items-center table-row'>
        <div className='table-cell flex-1'>{ reminder.title }</div>
        <div className='table-cell flex-1'>{ reminder.repeatOn.length > 0 ? hasRepeatOn(reminder) : translations.reminders[reminder.repeatType] }</div>
        <div className='table-cell flex-1'>{ formatDate(reminder.originalStartTime) }</div>
        <div className='table-cell flex-1'>{ reminder.reminderEndTime ? formatDate(reminder.reminderEndTime) : translations.general.never }</div>
        <div className='action remove_icon justify-content-end pointer' onClick={ () => removeReminder(reminder._id.$oid)}/>
      </div>
    )
  }

  return (
    <>
      <div className='section-title'>
        <h3>{ pageTitle }</h3>
      </div>
      <div className='card table-wrapper'>
        <div className='table'>
          { reminders.length == 0 ?
            <div>
              { translations.reminders.no_reminders }
            </div>
          :
            <div className= 'flex-box item-center table-head' style={{marginRight:'12px'}}>
              <div className='table-cell head flex-1'>{ translations.general.title }</div>
              <div className='table-cell head flex-1'>{ translations.reminders.frequency }</div>
              <div className='table-cell head flex-1'>{ translations.reminders.start_date }</div>
              <div className='table-cell head flex-1'>{ translations.reminders.end_date }</div>
            </div>
          }

          { reminders.map(r => renderItem(r)) }

          { loading &&
            <Loader
              position={ 'center' }
              size={ 'small' }
            />
          }
        </div>
        { currentPage < appProps.initialTotalPages && !loading &&
          <div className='button-wrapper flex-box items-center content-start'>
            <a className="button" onClick={ loadMore }>
              { translations.general.load_more }
            </a>
          </div>
        }
      </div>
    </>
  )
}
