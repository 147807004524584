import React, { useState, useEffect } from 'react'
import Modal from '../../shared/Modal'
import { Loader } from '@reactiveonline/frontend_shared_components'
import CompleteCheckout from '../../checkout/CompleteCheckout'

export default function Orders({ appProps, pageName, pageTitle }) {
  const [orders, setOrders] = useState(appProps.orders)
  const [currentPage, setCurrentPage] = useState(1)
  const [loading, setLoading] = useState(false)
  const [orderNumber, setOrderNumber] = useState(null)
  const [currentOrder, setCurrentOrder] = useState(null)
  const [tax, setTax] = useState(null)

  const { translations, ordersPath, checkoutPath, initialTotalPages } = appProps

  useEffect( () => {
    if (orderNumber) {
      setLoading(true)

      Rails.ajax({
        type: 'GET',
        url: `${ checkoutPath }/complete/${ orderNumber }`,
        dataType: 'json',
        success: res =>{
          setCurrentOrder(res.order)
          setTax(res.tax)
        },
        complete: res => {
          setLoading(false)
        }
      })
    }
  }, [orderNumber])

  function loadMore() {
    setLoading(true)
    Rails.ajax({
      type: 'GET',
      url: `${ ordersPath }?page=${ currentPage + 1 }`,
      dataType: 'json',
      success: res => {
        setCurrentPage(currentPage + 1)
        setOrders(orders.concat(res.orders))
      },
      complete: res => {
        setLoading(false)
      }
    })
  }

  function closeModal() {
    setOrderNumber(null)
    setCurrentOrder(null)
    setTax(null)
  }

  function renderItem(order) {
    return(
      <div key={ order.id } className= 'flex-box items-center table-row'>
        <div className='table-cell flex-1'>{ order.number }</div>
        <div className='table-cell flex-1'>{ order.total }</div>
        <div className='table-cell flex-1'>{ order.completedAt }</div>
        <div className='action view_icon justify-content-end pointer' onClick={ () => setOrderNumber(order.number) }/>
      </div>
    )
  }

  return (
    <>
      <div className='section-title'>
        <h3>{ pageTitle }</h3>
      </div>
      <div className='card table-wrapper'>
        <div className='table'>
          { orders.length == 0 ?
            <div>
              { translations.orders.no_orders }
            </div>
          :
            <div className= 'flex-box item-center table-head' style={{marginRight:'12px'}}>
              <div className='table-cell head flex-1'>{ translations.orders.order_no }</div>
              <div className='table-cell head flex-1'>{ translations.checkout.total }</div>
              <div className='table-cell head flex-1'>{ translations.orders.order_date }</div>
            </div>
          }

          { orders.map(order => renderItem(order)) }

          { loading &&
            <Loader
              position='center'
              size='small'
            />
          }
        </div>
        { currentPage < initialTotalPages && !loading &&
          <div className='button-wrapper flex-box items-center content-start'>
            <a className="button" onClick={ loadMore }>
              { translations.general.load_more }
            </a>
          </div>
        }
      </div>

      { orderNumber &&
        <Modal
          visible
          closeModal={ closeModal }
          mode='medium'
          elementsClass='content-center body-bg'
          theme='body-bg'
        >
          { currentOrder && tax ?
            <CompleteCheckout
              appProps={ appProps }
              order={ currentOrder }
              tax={ tax }
              accountScreen={ true }
            />
          :
            <Loader
              position='center'
              size='medium'
            />
          }
        </Modal>
      }
    </>
  )
}
