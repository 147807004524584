import Course from './elements/Course'
import CourseList from './elements/CourseList'
import Event from './elements/Event'
import EventList from './elements/EventList'
import Bundle from './elements/Bundle'
import BundleList from './elements/BundleList'

export function additionalElements(props) {
  return {
    course: {
      component: Course,
      props: props
    },
    course_list: {
      component: CourseList,
      props: props
    },
    event: {
      component: Event,
      props: props
    },
    event_list: {
      component: EventList,
      props: props
    },
    bundle: {
      component: Bundle,
      props: props
    },
    bundle_list: {
      component: BundleList,
      props: props
    }
  }
}
