import React, { useState, useEffect, useRef } from 'react'
import { MuiPickersUtilsProvider, DatePicker, Calendar } from '@material-ui/pickers'
import MomentUtils from '@date-io/moment'
import moment from 'moment'
import { makeStyles } from '@material-ui/styles'

export default function SelectDate({ appProps, selectedDate, setSelectedDate, highlightedDates, setHighlightedDates }) {
  const [currentMonth, setCurrentMonth] = useState(new Date().getMonth() + 1)
  const didMountRef = useRef(false)

  const useStyles = makeStyles(() => ({
    dayWithLogContainer: {
      position: 'relative'
    },
    dayWithLog: {
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: '2px',
      right: '2px',
      borderRadius: '50%',
      border: `1px solid ${appProps.colorScheme}`,
      color: appProps.colorScheme,
      cursor: 'pointer'
    }
  }))
  const classes = useStyles()

  useEffect( () => {
    if (didMountRef.current) {
      Rails.ajax({
        type: 'GET',
        url: `${appProps.practiceLogPath}?current_month=${currentMonth}&practices_per_day=true`,
        dataType: 'json',
        success: res => {
          setHighlightedDates(res.highlightedDates)
        }
      })
    } else {
      didMountRef.current = true
    }
  }, [currentMonth])

  return (
    <div className='card'>
      <MuiPickersUtilsProvider utils={ MomentUtils } locale={ appProps.currentLocale || 'en' }>
        <DatePicker
          autoOk
          orientation='landscape'
          variant='static'
          openTo='date'
          locale={ appProps.currentLocale || 'en' }
          disableToolbar
          onMonthChange={ month => {
            let newMonth = moment(month._d).format('DD-M-YYYY').split('-')[1]
            setCurrentMonth(newMonth)
          }}
          value={ selectedDate }
          onChange={ newValue => setSelectedDate(newValue) }
          disableFuture
          renderDay={ (day, selected, dayInCurrentMonth, dayComponent) => {
            if (highlightedDates.flat().includes(moment(day).format('YYYY-MM-DD')) && dayInCurrentMonth ) {
              return (
                <div className = { classes.dayWithLogContainer }>
                  { dayComponent }
                  <div className = { classes.dayWithLog }/>
                </div>
              )
            }
            return dayComponent
          }}
        />
      </MuiPickersUtilsProvider>
    </div>
  )
}
