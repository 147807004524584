import React from "react"

export default function Privacy({ pageName, pageTitle, privacyPolicy }) {

  return (
    <>
      <div className={ pageName }>
        <div className='section-title'>
          <h3>{ pageTitle }</h3>
        </div>
        { privacyPolicy &&
          <div dangerouslySetInnerHTML={{__html: privacyPolicy}} className="card"></div>
        }
      </div>
    </>
  )
}
