import React, { useState, useEffect } from 'react'
import { Loader, InputField, SelectInput } from '@reactiveonline/frontend_shared_components'

const step = 2

export default function BillingStep({
  appProps, order, setOrder, currentStep, setCurrentStep, loading, setLoading,
  renderStepOverview, handleErrors, setBillingCountryCode
}) {
  const {
    translations, updateCheckoutBillingPath, flashMessage, getCountriesPath,
    getStatesPath
  } = appProps
  const { address } = order

  const [countries, setCountries] = useState([])
  const [states, setStates] = useState([])

  const stepOverview = [
    { title: translations.checkout.address,           value: address.address },
    { title: translations.checkout.city,              value: address.city },
    { title: translations.checkout.postalCode,        value: address.postalCode },
    { title: translations.checkout.country,           value: address.country ? address.country.title : '' },
    { title: translations.checkout.state,             value: address.state ? address.state.title : '' },
    { title: translations.checkout.companyName,       value: address.companyName },
    { title: translations.checkout.vatNumber,         value: address.vat },
    { title: translations.checkout.additionalInfo,    value: address.additionalInfo }
  ]

  useEffect( () => {
    if (currentStep === step && countries.length === 0) {
      setLoading(true)

      Rails.ajax({
        url: getCountriesPath,
        type: 'GET',
        dataType: 'json',
        success: res => {
          if (res.availableCountries) {
            setCountries(res.availableCountries)
          }
        },
        complete: res => {
          setLoading(false)
        }
      })
    }
  }, [currentStep])

  useEffect( () => {
    if (address.country && address.country.statesRequired) {
      setLoading(true)

      Rails.ajax({
        url: `${ getStatesPath }?country_id=${ address.country.id }`,
        type: 'GET',
        dataType: 'json',
        success: res => {
          if (res.states) {
            setStates(res.states)
          }
        },
        complete: res => {
          setLoading(false)
        }
      })
    }
  }, [address.country])

  function setAddress(state) {
    if (!loading) {
      setOrder( prevState => ({ ...prevState, address: { ...prevState.address, ...state } }) )
    }
  }

  function handleChangeBuyAsCompany(event) {
    const checked = event.target.checked
    if (checked) {
      setAddress({ isCompany: true })
    } else {
      setAddress({ isCompany: false, companyName: '', vat: '', additionalInfo: '' })
    }
  }

  function changeCountry(option) {
    if (!address.country || option.value !== address.country.id) {
      setAddress({ country: { id: option.value, title: option.title, statesRequired: option.statesRequired }, state: {} })
      setBillingCountryCode(option.code)
      setStates([])
    }
  }

  function validateAndProceed() {
    setLoading(true)

    let formData = new FormData()
    formData.append('address[address]', address.address ? address.address.trim() : '')
    formData.append('address[country_id]', address.country.id || '')
    formData.append('address[state_id]', address.state.id || '')
    formData.append('address[city]', address.city ? address.city.trim() : '')
    formData.append('address[postal_code]', address.postalCode ? address.postalCode.trim() : '')
    formData.append('order[notes]', order.notes || '')
    formData.append('address[is_company]', address.isCompany)
    formData.append('address[company_name]', address.companyName ? address.companyName.trim() : '')
    formData.append('address[vat]', address.vat ? address.vat.trim() : '')
    formData.append('address[additional_info]', address.additionalInfo ? address.additionalInfo.trim() : '')

    Rails.ajax({
      url: updateCheckoutBillingPath,
      type: 'POST',
      dataType: 'json',
      data: formData,
      success: res => {
        if (res.order) {
          setOrder(res.order)
          setCurrentStep(3)
        }
      },
      error: res => {
        handleErrors(res)
      },
      complete: res => {
        setLoading(false)
      }
    })
  }

  return (
    <div className='step-content'>
    { currentStep > step && renderStepOverview(stepOverview) }

      { currentStep === step &&
        <>
{/*
          <div className='switch-wrapper' style={{ marginBottom: 20 }}>
            <div className='switch-toggle'>
              <label className='switch'>
                <input
                  type='checkbox'
                  checked={ address.isCompany }
                  value={ address.isCompany }
                  onChange={ handleChangeBuyAsCompany }
                />
                <span className='slider round'/>
              </label>
            </div>

            <div className='switch-text'>
              { translations.checkout.buyAsCompany }
            </div>
          </div>
*/}

          <InputField
            label={ `<div class='flex-box'>${ translations.checkout.address }&nbsp;<div class='color-scheme'>*</div></div>` }
            parseLabel
          >
            <input
              type='text'
              value={ address.address || '' }
              onChange={ event => setAddress({ address: event.target.value }) }
            />
          </InputField>

          <SelectInput
            label={ `<div class='flex-box'>${ translations.checkout.country }&nbsp;<div class='color-scheme'>*</div></div>` }
            parseLabel
            options={ countries }
            currentOption={ address.country.id || '' }
            setCurrentOption={ changeCountry }
          />

          { states.length > 0 &&
            <SelectInput
              label={ `<div class='flex-box'>${ translations.checkout.state }&nbsp;<div class='color-scheme'>*</div></div>` }
              parseLabel
              options={ states }
              currentOption={ address.state.id || '' }
              setCurrentOption={ option => setAddress({ state: { id: option.value, title: option.title } }) }
            />
          }

          <div className='flex-box content-space-between'>
            <InputField
              style={{ width: '60%' }}
              label={ `<div class='flex-box'>${ translations.checkout.city }&nbsp;<div class='color-scheme'>*</div></div>` }
              parseLabel
            >
              <input
                type='text'
                value={ address.city || '' }
                onChange={ event => setAddress({ city: event.target.value }) }
              />
            </InputField>

            <InputField
              style={{ width: '35%' }}
              label={ `<div class='flex-box'>${ translations.checkout.postalCode }&nbsp;<div class='color-scheme'>*</div></div>` }
              parseLabel
            >
              <input
                type='text'
                value={ address.postalCode || '' }
                onChange={ event => setAddress({ postalCode: event.target.value }) }
              />
            </InputField>
          </div>

          <InputField label={ translations.checkout.note }>
            <textarea
              value={ order.notes || '' }
              onChange={ event => setOrder( prevState => ({ ...prevState, notes: event.target.value }) ) }
            />
          </InputField>

          { address.isCompany &&
            <>
              <InputField
                label={ `<div class='flex-box'>${ translations.checkout.companyName }&nbsp;<div class='color-scheme'>*</div></div>` }
                parseLabel
              >
                <input
                  type='text'
                  value={ address.companyName || '' }
                  onChange={ event => setAddress({ companyName: event.target.value }) }
                />
              </InputField>

              <InputField label={ translations.checkout.vatNumber }>
                <input
                  type='text'
                  value={ address.vat || '' }
                  onChange={ event => setAddress({ vat: event.target.value }) }
                />
              </InputField>

              <InputField label={ translations.checkout.additionalInfo }>
                <textarea
                  value={ address.additionalInfo || '' }
                  onChange={ event => setAddress({ additionalInfo: event.target.value }) }
                />
              </InputField>
            </>
          }

          <div className='complete-step-wrapper flex-box content-center'>
            { loading ?
              <Loader size='small'/>
            :
              <div className='button' onClick={ validateAndProceed }>
                { translations.checkout.continue }
              </div>
            }
          </div>
        </>
      }
    </div>
  )
}
