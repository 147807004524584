import React, { useState , useEffect } from 'react'
import TextEditor from '../shared/TextEditor'
import Note from './Note'
import moment from 'moment'

export default function TaskNote({ appProps, entityType, entityUid, taskId, fetchNotePath, taskTitle, entityTitle }) {
  const [currentNote, setCurrentNote] = useState(null)
  const [content, setContent] = useState('')
  const [courseNotes, setCourseNotes] = useState([])
  const [showTextEditor, setShowTextEditor] = useState(false)
  const [currentPage, setCurrentPage] = useState(0)
  const [totalPages, setTotalPages] = useState(null)
  const [totalNotes, setTotalNotes] = useState(null)
  const [loading, setLoading] = useState(true)

  const { translations } = appProps
  const filteredNotes = courseNotes.filter( note => !currentNote || (currentNote && currentNote.id !== note.id) )
  const currentDate = moment(new Date()).format("DD/MM/YYYY")

  useEffect(fetchNote, [taskId])

  function onDelete() {
    setCurrentNote(null)
    setContent('')
  }

  function cancelEdit() {
    setContent('')
    setShowTextEditor(false)
  }

  function fetchNote() {
    Rails.ajax({
      url: `${ fetchNotePath }?notable_type=${ entityType }&notable_uid=${ entityUid }&task_id=${ taskId }`,
      type: 'GET',
      dataType: 'json',
      success: res => {
        setCurrentNote(res.note)
        setShowTextEditor(false)
        setTotalNotes(res.totalNotes)
      },
      error: err => {},
      complete: res => {
        setLoading(false)
      }
    })
  }

  function loadMore() {
    Rails.ajax({
      url:`${ appProps.notesPath }?page=${ currentPage + 1 }&notable_type=${ entityType }&notable_uid=${ entityUid }`,
      type: 'GET',
      dataType: 'json',
      success: res => {
        setCurrentPage(currentPage + 1)
        setCourseNotes([...courseNotes, ...res.notes])
        setTotalPages(res.totalPages)
      },
      error: err => {}
    })
  }

  function save() {
    let formData = new FormData()
    formData.append('content', content)
    formData.append('notable_type', entityType)
    formData.append('notable_uid', entityUid)
    formData.append('task_id', taskId)

    Rails.ajax({
      url: appProps.notesPath,
      type: 'POST',
      data: formData,
      dataType: 'json',
      success: res => {
        setCurrentNote(res.note)
        setShowTextEditor(false)
      },
      error: err => {}
    })
  }

  return(
    <div className='new-note-wrapper'>
      { !loading &&
        <>
          { currentNote ?
            <Note
              key={ currentNote.id }
              appProps={ appProps }
              note={ currentNote }
              isEditable
              onDelete={ onDelete }
              onUpdate={ updatedNote => setCurrentNote(updatedNote) }
            />
          :
            <div className='note-wrapper card flex-box items-center flex-column'>
              <div className='note-info flex-box items-center contents-space-between'>
                <div className='note-details'>
                  <div className='note-title'>{ taskTitle }</div>
                  <div className='note-additional-details'>
                    { currentDate } - { entityTitle }
                  </div>
                </div>
              </div>
              <TextEditor
                content={ content }
                setContent={ updatedContent => setContent(updatedContent) }
                translations_characters={ translations.general.characters }
                translataions_words={ translations.general.words }
              />
              <div className='actions-wrapper flex-box content-end items-center'>
                <button
                  type='button'
                  className={`button action ${!content ? 'disabled' : ''}`}
                  disabled={ !content }
                  onClick={ save }
                >
                  { translations.general.save }
                </button>
              </div>
            </div>
          }
        </>
      }

      { totalNotes > 0 && courseNotes.length === 0 &&
        <div className='actions-wrapper flex-box content-start items-center'>
          <div
            type='button'
            className='button plain'
            style={{ textDecoration: 'underline' }}
            onClick={ loadMore }
          >
            { translations.general.notes }: { totalNotes }
          </div>
        </div>
      }

      { filteredNotes.length > 0 &&
        <div>
          { filteredNotes.map( courseNote => (
            <Note
              key={ courseNote.id }
              appProps={ appProps }
              note={ courseNote }
              isEditable={ false }
            />
          ))}

          { totalPages > currentPage &&
            <button
              type='button'
              className='button plain'
              onClick={ loadMore }
            >
              { translations.general.load_more }
            </button>
          }
        </div>
      }
    </div>
  )
}
