import React, { useState, useEffect, lazy, Suspense } from 'react'
import Sidebar from './shared/Sidebar'

const accountComponents = {
  notification_settings: 'NotificationSettings',
  reminders: 'Reminders',
  billing: 'Billing',
  languages: 'Languages',
  events: 'Events',
  privacy: 'Privacy',
  delete: 'Delete',
  practice_log: 'PracticeLog',
  orders: 'Orders'
}

let AccountComponent = () => <div/>

export default function Account({ appProps, screenClass, pageName, pageTitle, privacyPolicy }) {
  const [renderKey, setRenderKey] = useState(0)

  useEffect(() => {
    // Rails.ajax({
    //   type: 'GET',
    //   url: '/account_info',
    //   dataType: 'json',
    //   success: res => {
    //     if(res.account) {
    //       appProps.setCurrentAppProps({
    //         user: {
    //           ...appProps.user,
    //           // avatar: res.account.avatarUrl,
    //           fullname: res.account.fullname,
    //           email: res.account.email
    //         },
    //       })
    //     }
    //   },
    //   error: res => {}
    // })

    AccountComponent = lazy( () => import(`./screens/${ accountComponents[pageName] }`) )
    setTimeout( () => setRenderKey(Math.random()), 100)
  }, [])

  return (
    <div className='body-bg'>
      <div className='main-container' style={{ paddingTop: `${appProps.headerHeight}px` }}>
        <div className={ screenClass }>
          <Sidebar
            appProps={ appProps }
          />
          <div className='main-content'>
            <Suspense
              key={ renderKey }
              fallback={ <div/> }
            >
              <AccountComponent
                pageName={ pageName }
                appProps={ appProps }
                privacyPolicy={ privacyPolicy }
                pageTitle={ pageTitle }
              />
            </Suspense>
          </div>
        </div>
      </div>
    </div>
  )
}
