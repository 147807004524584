import React, { useState } from 'react'
import TabsIndex from '../shared/TabsIndex'
import Note from './Note'
import CustomSelect from '../shared/CustomSelect'

export default function Notes({ appProps, screenClass, pageTitle, initialNotes, initialTotalPages }) {
  const [ currentPage , setCurrentPage ] = useState(1)
  const [ filterStatus , setFilterStatus ] = useState('latest')
  const [ notes , setNotes ] = useState(initialNotes)
  const [ totalPages , setTotalPages ] = useState(initialTotalPages)

  const { translations } = appProps
  const selectOptions = [{ title: translations.general.latest, value: 'latest' }, { title: translations.general.oldest, value: 'oldest' }]

  function onDelete(noteId) {
    setNotes(notes.filter( note => note.id != noteId ))
  }

  function onUpdate(updatedNote) {
    const updatedNotes = notes
    const noteIndex = updatedNotes.findIndex(note => note.id == updatedNote.id)
    if (noteIndex >= 0) {
      updatedNotes.splice(noteIndex, 1, updatedNote)
      setNotes(updatedNotes)
    }
  }

  function handleChangeFilter(option) {
    const value = option.value
    if (filterStatus != value) {
      setFilterStatus(value)
      fetchNotes(1, value)
    }
  }

  function fetchNotes(page, filter = filterStatus) {
    Rails.ajax({
      url: `${ appProps.notesPath }?page=${ page }&order=${ filter }`,
      type: 'GET',
      dataType: 'json',
      success: res => {
        setTotalPages(res.totalPages)
        setCurrentPage(page)

        if (page === 1) {
          setNotes(res.notes)
        } else {
          setNotes([ ...notes, ...res.notes ])
        }
      },
      error: err => {}
    })
  }

  return (
    <div className={ `lxp-wrapper ${ screenClass }` }>
      <TabsIndex
        appProps={ appProps }
        pageTitle={ pageTitle }
      />

      <div className='section body-bg'>
        <div className='main-container'>
          { notes.length > 0 ?
            <div className='notes-wrapper'>
              <div className='notes-options'>
                <CustomSelect
                  value={ filterStatus }
                  onClick = { handleChangeFilter }
                  options = { selectOptions }
                />
              </div>

              { notes.map( note => (
                <Note
                  key={ note.id }
                  appProps={ appProps }
                  note={ note }
                  onDelete={ onDelete }
                  onUpdate={ onUpdate }
                  isEditable
                />
              ))}

              { totalPages > currentPage &&
                <button type='button' className='button' onClick={ () => fetchNotes(currentPage + 1) }>
                  { translations.general.load_more }
                </button>
              }
            </div>

          :
            <div>
              { translations.general.no_notes }
            </div>
          }
        </div>
      </div>
    </div>
  )
}
