import React, { useState, useEffect } from 'react'

export default function DropMenu({
  menuPosition, title, dropMenuData, dataLength, titleButtonPath, titleButtonText,
  titleAction, action, actionText, actionIcon, selected, setSelected, menuKey,
  itemRenderer, hideCount, footerRenderer, colorScheme, hasUnreadNotifications
}) {
  const [openMenu, setOpenMenu] = useState(false)

  const menuTitle = title + (hideCount ? '' : ` (${ dataLength || dropMenuData.length })`)

  useEffect( () => setOpenMenu(selected), [selected] )

  function handleOpen(isOpen) {
    if (setSelected) {
      setSelected(isOpen ? menuKey : null)
    } else {
      setOpenMenu(isOpen)
    }
  }

  function handleClick(event, data) {
    if (data.onClick) {
      event.preventDefault()
      data.onClick(data)
    }
  }

  return (
    <>
      <div className={ `drop-menu-wrapper ${ menuPosition || '' }` } onMouseEnter={ () => handleOpen(true) }  onMouseLeave={ () => handleOpen(false) } >
        <div className={ `toggle-icon ${ openMenu ? 'open' : '' }` }>
          { menuKey === 'notifications' && hasUnreadNotifications &&
            <div className='unread-notification' style={{ background: colorScheme || 'black' }}/>
          }
        </div>
        <div className={ `drop-menu ${ openMenu ? 'open' : '' }` }>
          <div className='flex-box flex-column'>
            { title &&
              <div className='main-title flex-box items-center content-space-between'>
                <h3>
                  { menuTitle }
                </h3>
                { titleButtonPath &&
                  <a href={ titleButtonPath } className='button plain' rel='nofollow noindex noreferrer'>
                    { titleButtonText }
                  </a>
                }

                { menuKey == 'notifications' && !titleButtonPath &&
                  <div className='button plain' onClick={ titleAction }>
                    { titleButtonText }
                  </div>
                }
              </div>
            }

            { itemRenderer && dropMenuData.map( (data, index) => itemRenderer(data, index) ) }

            { !itemRenderer && dropMenuData.map( (data, index) => (
              <h6 key={ index } className='flex-box items-center content-start flex-wrap'>
                <a
                  href={ data.path || '' }
                  data-method={ data.requestType ? data.requestType : null }
                  className={ `clickable-link dark flex-box items-center ${ data.icon }_icon${ data.selected ? '_filled' : '' }` }
                  target={ data.target || '_self' }
                  onClick={ event => handleClick(event, data) }
                >
                  { data.image &&
                    <div className='image-wrapper'>
                      <img src={ data.image }></img>
                    </div>
                  }
                  { data.selected ? data.selectedTitle : data.title }
                </a>
                { data.timestamp &&
                  <div className='info'>
                    { data.timestamp }
                  </div>
                }
              </h6>
            ))}

            { footerRenderer && footerRenderer() }

            { action &&
              <div
                className='button'
                style={{ marginTop: 10, width: '100%' }}
                onClick={ () => window.location.href = action }
              >
                { actionIcon &&
                  <i className={ actionIcon } style={{ marginRight: 10 }}/>
                }
                { actionText }
              </div>
            }
          </div>
        </div>
      </div>
    </>
  )
}
