import React, { useState, useEffect } from 'react'
import { NO_IMAGE_SOURCE } from '../GlobalConstants'
import { getTranslation } from '@reactiveonline/frontend_shared_components/utils'

export default function Instructors({ instructors, screenClass, appProps }) {
  const [selectedInstructor, setSelectedInstructor] = useState(instructors[0])

  const translation = getTranslation(selectedInstructor.translations, appProps.currentLocale, appProps.defaultLocale)

  useEffect( () => {
    if (instructors.length && !instructors.includes(selectedInstructor)) {
      setSelectedInstructor(instructors[0])
    }
  }, [instructors])

  function handleInstructorChange(instructor){
    setSelectedInstructor(instructor)
  }

  return (
    <>
      { instructors.length === 1 ?
        <div className='instructors-wrapper flex-box'>
          <div key={ selectedInstructor.id } className='instructor flex-box'>
            <div className={`instructor-image ${ screenClass }`}>
              <img src={ selectedInstructor.image || NO_IMAGE_SOURCE } />
            </div>
            <div className='instructor-details'>
              <h4 className='instructor-name'>
                { selectedInstructor.fullName }
              </h4>
              { translation.title &&
                <h5 className='instructor-title'>
                  { translation.title }
                </h5>
              }
              { translation.bio &&
                <div className='instructor-bio editor' dangerouslySetInnerHTML={{__html: translation.bio }}></div>
              }
            </div>
          </div>
        </div>
      :
        <div className='instructors-wrapper flex-box flex-column'>
          <div className='instructors-images flex-box'>
            { instructors.map( instructor => (
              <div
                key={ instructor.id }
                className={ `instructor-image ${ screenClass || '' }` }
                onClick={ () => setSelectedInstructor(instructor) }
              >
                <img
                  className={ instructor.id === selectedInstructor.id ? 'selected' : '' }
                  src={ instructor.image || NO_IMAGE_SOURCE }
                />
              </div>
            ))}
          </div>

          <div className='instructor flex-box flex-wrap'>
            <div className='instructor-details'>
              <h4 className='instructor-name'>
                { selectedInstructor.fullName }
              </h4>
              { translation.title &&
                <h5 className='instructor-title'>
                  { translation.title }
                </h5>
              }
              { translation.bio &&
                <div className='instructor-bio editor' dangerouslySetInnerHTML={{__html: translation.bio }}></div>
              }
            </div>
          </div>
        </div>
      }
    </>
  )
}
