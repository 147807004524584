import React, { useState } from 'react'
import DropMenu from './DropMenu'
import { NO_IMAGE_SOURCE } from '../GlobalConstants'
import { Loader } from '@reactiveonline/frontend_shared_components'
import { truncateString, getTranslation } from '@reactiveonline/frontend_shared_components/utils'

export default function CartDropMenu({ appProps, selectedDropMenu, setSelectedDropMenu }) {
  const [loading, setLoading] = useState(false)

  const {
    translations, order, checkoutPath, destroyLineItemPath, setCurrentAppProps,
    flashMessage, currentLocale, defaultLocale
  } = appProps

  function onRemoveItem(lineItem) {
    setLoading(true)

    let formData = new FormData()
    formData.append('line_item_id', lineItem.id)

    Rails.ajax({
      url: destroyLineItemPath,
      type: 'DELETE',
      dataType: 'json',
      data: formData,
      success: res => {
        setCurrentAppProps({ order: res.order })
        flashMessage.show(translations.checkout.removeItemSuccess)
      },
      complete: res => {
        setLoading(false)
      }
    })
  }

  function footerRenderer() {
    return (
      <>
        <div className='flex-box content-space-between' style={{ marginTop: 20 }}>
          <h5>
            { translations.checkout.subtotal }:
          </h5>
          <h5>
            { order.netTotal }
          </h5>
        </div>

        <div className='flex-box content-space-between'>
          <h5>
            { order.tax.title }:
          </h5>
          <h5>
            { order.subtotalVat }
          </h5>
        </div>


        <div className='flex-box content-space-between'>
          <h3>
            { translations.checkout.total }:
          </h3>
          <h3>
            { order.total }
          </h3>
        </div>
      </>
    )
  }

  function itemRenderer(lineItem) {
    const item = lineItem.item
    const title = truncateString(getTranslation(item.translations, currentLocale, defaultLocale).title, 15)
    const imageSource = item.image.isTranslatable ? item.image[currentLocale] : item.image.source
    const instructors = truncateString(`${ translations.general.with } ${ item.instructors.map( i => i.fullName ).join(', ') }`, 20)

    return (
      <div key={ lineItem.id } className='header-cart'>
        <div className='line-item-wrapper flex-box items-center content-center'>
          <img src={ imageSource || NO_IMAGE_SOURCE } onClick={ () => window.location.href = item.path }/>

          <div className='line-item-info flex-box flex-column flex-1'>
            <div className='flex-box content-space-between'>
              <div className='title' onClick={ () => window.location.href = item.path }>
                { title }
              </div>

              { loading ?
                <Loader size='xsmall'/>
              :
                <i className='trash_icon' onClick={ () => onRemoveItem(lineItem) }/>
              }
            </div>

            { item.instructors && item.instructors.length > 0 &&
              <div className='description'>
                { instructors }
              </div>
            }

            <div className='price'>
              { item.price }
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <li className='account drop-menu' id='drop-menu'>
      <div className='menu-handler cart_icon'>
        <div className='alerts-counter'>
          { order.lineItems.length }
        </div>
      </div>

      <DropMenu
        dropMenuData={ order.lineItems }
        title={ translations.checkout.cart }
        menuPosition='main-header'
        selected={ selectedDropMenu === 'cart' }
        setSelected={ setSelectedDropMenu }
        menuKey='cart'
        action={ checkoutPath }
        actionText={ translations.checkout.checkout }
        actionIcon='cart_icon'
        itemRenderer={ itemRenderer }
        hideCount
        footerRenderer={ footerRenderer }
      />
    </li>
  )
}
