import React from 'react'
import CourseListItem from './CourseListItem'
import { NO_IMAGE_SOURCE } from '../GlobalConstants'
import { Loader } from '@reactiveonline/frontend_shared_components'
import { getTranslation } from '@reactiveonline/frontend_shared_components/utils'

export default function Listing({
  appProps, screenClass, coursesType, divisionTitle, loadMore, handleLoadMore,
  loading, courseLocale
}) {
  const { translations, currentLocale } = appProps

  return (
    <div className='section body-bg'>
      <div className='main-container'>
        <div className='section-title'>
          <h2>{ divisionTitle }</h2>
        </div>
        <div className='section-divider'></div>
        <div className='list-item-wrapper'>
          { coursesType.map( course => {
            const translation = getTranslation(course.translations, courseLocale, currentLocale)
            const stripedHtml = translation.shortDescription ? translation.shortDescription.replace(/<[^>]+>/g, '').substring(0, 150) : ''

            return (
              <CourseListItem
                key={ course.uid }
                appProps={ appProps }
                itemClass={ screenClass }
                item={ course }
                description={ stripedHtml ? `${ stripedHtml } ...` : '' }
                actionPath={ course.showPath }
                quickMenu
                entityType={ course.entityType }
                courseLocale={ courseLocale }
              />
            )
          })
          }
        </div>

        { loading &&
          <Loader
            position={ 'center' }
            size={ 'medium' }
          />
        }

        { !loading && loadMore &&
          <div className='button-wrapper flex-box items-center content-center'>
            <a className='button' onClick={ handleLoadMore }>
              { translations.general.load_more }
            </a>
          </div>
        }
      </div>
    </div>
  )
}
