import React from 'react'
import CourseListItem from '../courses/CourseListItem'
import { getTranslation } from '@reactiveonline/frontend_shared_components/utils'

export default function Preview({appProps, courses, tenantUrl}) {
  return (
    <div className='main-content'>
      <div className='list-item-wrapper'>

        { courses.map( (course, index) => {
          const translation = getTranslation(course.translations, 'en', 'de')
          const stripedHtml = translation.shortDescription ? translation.shortDescription.replace(/<[^>]+>/g, '').substring(0, 150) : ''

          return (
            <CourseListItem
              key={ index }
              appProps={ {...appProps, setCurrentAppProps: () => {}} }
              itemClass={ '' }
              item={ course }
              description={ stripedHtml ? `${ stripedHtml } ...` : '' }
              actionPath={ course.showPath }
              quickMenu
              isPreview
              entityType={ course.entityType }
              tenantUrl={ tenantUrl }
            />
          )
        })
        }
      </div>
    </div>
  )
}
